import {
  Dimension,
  Pricing,
  AwsPrivateOffer,
  UsageDimension,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

export const mergeAgreementAndSourceOffers = (
  agreementOffer: AwsPrivateOffer,
  sourceOffer: AwsPrivateOffer | null,
  marketplacePricing: Pricing | null,
): AwsPrivateOffer => {
  const sourceOfferPricing = sourceOffer?.pricing || {};
  const agreementOfferPricing = agreementOffer?.pricing || {};
  const sourceOfferDimensions = sourceOfferPricing.dimensions || [];
  const agreementOfferDimensions = agreementOfferPricing.dimensions || [];
  const marketplacePricingDimensions = marketplacePricing?.dimensions || [];
  const sourceOfferUsageDimensions = sourceOfferPricing.usageDimensions || [];
  const agreementOfferSchedules = agreementOfferPricing.schedule || [];
  const sourceOfferSchedules = sourceOfferPricing.schedule || [];

  const agreementOfferUsageDimensions =
    agreementOfferPricing?.usageDimensions || [];

  const sourceOfferDimensionsByApiName = sourceOfferDimensions.reduce(
    (acc, d) => {
      acc[d.apiName] = d;
      return acc;
    },
    {},
  );

  const marketplaceDimensionsByApiName = marketplacePricingDimensions.reduce(
    (acc, d) => {
      acc[d.apiName] = d;
      return acc;
    },
    {},
  );

  const mergedDimensions = agreementOfferDimensions.map((d): Dimension => {
    const matchingSourceOfferDimension =
      sourceOfferDimensionsByApiName[d.apiName];

    const matchingMarketplaceDimension =
      marketplaceDimensionsByApiName[d.apiName];

    const name =
      matchingSourceOfferDimension?.name || matchingMarketplaceDimension?.name;

    return {
      ...(matchingSourceOfferDimension || {}),
      name,
      apiName: d.apiName || matchingSourceOfferDimension?.apiName,
      quantity: d.quantity || matchingSourceOfferDimension?.quantity,
      price: d.price || matchingSourceOfferDimension?.price,
    };
  });

  const sourceOfferUsageDimensionsBySku = sourceOfferUsageDimensions.reduce(
    (acc, d) => {
      acc[d.sku] = d;
      return acc;
    },
    {},
  );

  const mergedUsageDimensions = agreementOfferUsageDimensions.map(
    (d): UsageDimension => {
      const matchingSourceOfferUsageDimension =
        sourceOfferUsageDimensionsBySku[d.sku];

      return {
        ...(matchingSourceOfferUsageDimension || {}),
        sku: d.sku || matchingSourceOfferUsageDimension?.sku,
        price: d.price || matchingSourceOfferUsageDimension?.price,
        description:
          d.description || matchingSourceOfferUsageDimension?.description,
      };
    },
  );

  return {
    ...(sourceOffer || {}),
    offerType: agreementOffer.offerType || sourceOffer?.offerType,
    offerId: agreementOffer.offerId || sourceOffer?.offerId,
    productId: agreementOffer.productId || sourceOffer?.productId,
    buyerBillingAccountRef:
      agreementOffer.buyerBillingAccountRef ||
      sourceOffer?.buyerBillingAccountRef,
    offerExpirationAt:
      agreementOffer.offerExpirationAt || sourceOffer?.offerExpirationAt,
    pricing: {
      ...sourceOfferPricing,
      dimensions: mergedDimensions,
      usageDimensions: mergedUsageDimensions,
      schedule: agreementOfferSchedules || sourceOfferSchedules || [],
      serviceStartAt:
        agreementOfferPricing.serviceStartAt ||
        sourceOfferPricing.serviceStartAt,
      serviceEndAt:
        agreementOfferPricing.serviceEndAt || sourceOfferPricing.serviceEndAt,
    },
    offerMetadata: {
      ...(sourceOffer?.offerMetadata || {}),
      ...agreementOffer.offerMetadata,
    },
    extraData: {
      ...(sourceOffer?.extraData || { users: [] }),
    },
  };
};
