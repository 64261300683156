const CANVAS_SESSION_KEY = 'canvas_session';
const ACCESS_TOKEN_KEY = 'otk_access_token';
const REFRESH_TOKEN_KEY = 'otk_refresh_token';
const ACCESS_TOKEN_EXPIRES_AT = 'otk_access_token_expires_at';

export const setCanvasSession = (canvasSession: boolean): void => {
  sessionStorage.setItem(CANVAS_SESSION_KEY, canvasSession.toString());
};

export const isCanvasSession = (): boolean => {
  const value = sessionStorage.getItem(CANVAS_SESSION_KEY) || 'false';
  return value === 'true';
};

export const setAccessToken = (accessToken: string): void => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessToken = (): string => {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY) || '';
};

export const setRefreshToken = (refreshToken: string): void => {
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const getRefreshToken = (): string => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY) || '';
};

export const setAccessTokenExpiresAt = (expiresAt: number): void => {
  sessionStorage.setItem(ACCESS_TOKEN_EXPIRES_AT, expiresAt.toString());
};

export const getAccessTokenExpiresAt = (): number => {
  return parseInt(sessionStorage.getItem(ACCESS_TOKEN_EXPIRES_AT) || '0');
};

export const clearSessionStorage = (): void => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(ACCESS_TOKEN_EXPIRES_AT);
};
