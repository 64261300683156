import { createContext } from 'react';
import { Agreement } from '../../../generic/api/types/Agreement';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

export interface AgreementContextValues {
  offerId: string | null;
  sourceOffer: AwsPrivateOffer | null;
  agreementOffer: AwsPrivateOffer | null;
  mergedAgreementAndSourceOffer: AwsPrivateOffer;
  agreement: Agreement | null;
  loadingAgreementOffer: boolean;
}

const AmendmentContext = createContext<AgreementContextValues>({
  offerId: null,
  sourceOffer: null,
  agreementOffer: null,
  mergedAgreementAndSourceOffer: null,
  agreement: null,
  loadingAgreementOffer: false,
});

export default AmendmentContext;
