import { FieldKey, FormValues, RenewalType } from './formSchema';
import {
  FieldKey as BuyersFieldKeys,
  FormBuyer,
} from '../../../../generic/OfferBuyers/formSchema';
import {
  AwsPrivateOffer,
  Buyer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const emptyInitialBuyer = [
  {
    name: null,
    email: null,
    title: null,
  },
];

export const initialFormValuesForNewOffer = (
  offerType: OfferType,
): FormValues => {
  const buyers: FormBuyer[] =
    offerType === OfferType.PartnerResale ? [] : emptyInitialBuyer;

  return {
    [FieldKey.PartnerName]: null,
    [FieldKey.PartnerAccountNumber]: null,
    [FieldKey.BuyerAccountNumber]: null,
    [FieldKey.CompanyName]: null,
    [BuyersFieldKeys.Buyers]: buyers,
    [FieldKey.OfferName]: null,
    [FieldKey.OfferDescription]: null,
    [FieldKey.Renewal]: false,
    [FieldKey.RenewalType]: null,
  };
};

const toFormBuyer = (b: Buyer): FormBuyer => ({
  name: b.fullName,
  email: b.emailAddress,
  title: b.title,
});

export const mapBuyersToFormBuyers = (buyers: Buyer[]): FormBuyer[] =>
  buyers.map(toFormBuyer);

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  const partnerOffer = offer.partnerOffer;

  const renewalType = offer.awsRenewalMovingToMarketplace
    ? RenewalType.NewAwsMarketplaceCustomer
    : RenewalType.ExistingAwsMarketplaceCustomer;

  const buyers = offer.buyers
    ? mapBuyersToFormBuyers(offer.buyers)
    : emptyInitialBuyer;

  return {
    partnerName: partnerOffer?.partnerName,
    partnerAccountNumber: partnerOffer?.partnerRef,
    buyerAccountNumber: offer.buyerBillingAccountRef,
    companyName: offer.buyerCompanyName,
    buyers,
    offerName: offer.offerName,
    offerDescription: offer.offerDescription,
    renewal: offer.renewal,
    renewalType,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  const offerName = `Amended - ${offer?.offerName || ''}`;

  const renewalType = offer?.renewal
    ? RenewalType.ExistingAwsMarketplaceCustomer
    : null;

  return {
    ...initialFormValuesForExistingOffer(offer),
    offerName,
    renewalType,
  };
};
