import React from 'react';
import ActivityItem from './ActivityItem';
import { byCreatedAtDescending } from '../utils/offer/activityUtils';
import { Activity } from '../types/TackleOffer';

const ActivitiesList: React.FC<{
  activities: Activity[];
}> = ({ activities = [] }) => {
  const sortedActivities = [...activities].sort(byCreatedAtDescending);

  return (
    <>
      {sortedActivities.map((activity, index) => (
        <ActivityItem key={`activity-${index}`} activity={activity} />
      ))}
    </>
  );
};

export default ActivitiesList;
