import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import * as yup from 'utils/yup-extended';
import { FieldKeys as NonUserInputFieldKey } from '../formTypes';

export enum FieldKey {
  OfferId = 'offerId',
  SourceOfferId = 'sourceOfferId',
  isAmended = 'isAmended',
  SellingAuthorizationId = 'sellingAuthorizationId',
}

export type FormValues = {
  [FieldKey.OfferId]: string | null;
  [FieldKey.SourceOfferId]: string | null;
  [FieldKey.isAmended]: boolean;
  [FieldKey.SellingAuthorizationId]: string | null;
};

const awsOfferIDRegex = /^offer-[0-9a-z]{13}$/;
const awsResellerAuthorizationIDRegex = /^resaleauthz-[0-9a-z]{13}$/;

const offerIDSchema = (mode: OfferPageMode) =>
  yup
    .string()
    .nullable()
    .matches(awsOfferIDRegex, {
      message:
        'Offer ID must start with offer- followed by 13 characters of numbers and letters',
    })
    .when('$', {
      is: isForYupExtended(
        NonUserInputFieldKey.OfferType,
        (ot: OfferType) =>
          mode === OfferPageMode.Associate && ot === OfferType.Direct,
      ),
      then: (schema: yup.StringSchema) => schema.required().progress(true),
      otherwise: (schema: yup.StringSchema) => schema.progress(false),
    });

const sourceOfferIDSchema = (mode: OfferPageMode) =>
  yup
    .string()
    .nullable()
    .when('$', {
      is: isForYupExtended(
        FieldKey.isAmended,
        (isAmended) => mode === OfferPageMode.Associate && !!isAmended,
      ),
      then: (schema: yup.StringSchema) =>
        schema
          .matches(awsOfferIDRegex, {
            message:
              'Source offer ID must start with offer- followed by 13 characters of numbers and letters',
          })
          .progress(true),
      otherwise: (schema: yup.StringSchema) => schema.progress(false),
    });

const sellingAuthorizationIDSchema = (mode: OfferPageMode) =>
  yup
    .string()
    .nullable()
    .when('$', {
      is: isForYupExtended(
        NonUserInputFieldKey.OfferType,
        (ot) =>
          mode === OfferPageMode.Associate && ot === OfferType.PartnerResale,
      ),
      then: (schema: yup.StringSchema) =>
        schema
          .matches(awsResellerAuthorizationIDRegex, {
            message:
              'Selling authorization ID must start with resaleauthz- followed by 13 characters of numbers and letters',
          })
          .required()
          .progress(true),
      otherwise: (schema: yup.StringSchema) => schema.progress(false),
    });

export const formSchema = (mode: OfferPageMode): yup.ObjectSchema<FormValues> =>
  yup.object({
    [FieldKey.OfferId]: offerIDSchema(mode),
    [FieldKey.isAmended]: yup.boolean(),
    [FieldKey.SourceOfferId]: sourceOfferIDSchema(mode),
    [FieldKey.SellingAuthorizationId]: sellingAuthorizationIDSchema(mode),
  });
