import { makeStyles } from 'vendor/material';

export const useMessageHandlerStyles = makeStyles((theme) => ({
  toastSuccess: {
    backgroundColor: '#00875A !important',
    color: '#FFFFFF !important',
  },
  toastText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginRight: '8px',
  },
  toastLink: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  toastLinkUnderline: {
    textDecoration: 'underline',
  },
  'toast-success .Toastify__close-button': {
    color: '#FFFFFF !important',
  },
}));
