const MarketplaceOpInProgressSvg = () => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.384766 16.6992V14.6992H3.10977C2.25977 13.9659 1.5931 13.0826 1.10977 12.0492C0.626432 11.0159 0.384766 9.89922 0.384766 8.69922C0.384766 6.83255 0.951432 5.18672 2.08477 3.76172C3.2181 2.33672 4.65143 1.39922 6.38477 0.949219V3.04922C5.2181 3.46589 4.25977 4.18672 3.50977 5.21172C2.75977 6.23672 2.38477 7.39922 2.38477 8.69922C2.38477 9.59922 2.56393 10.4284 2.92227 11.1867C3.2806 11.9451 3.7681 12.5992 4.38477 13.1492V10.6992H6.38477V16.6992H0.384766ZM11.3848 16.6992C10.5514 16.6992 9.8431 16.4076 9.25977 15.8242C8.67643 15.2409 8.38477 14.5326 8.38477 13.6992C8.38477 12.8992 8.65977 12.2117 9.20977 11.6367C9.75977 11.0617 10.4348 10.7576 11.2348 10.7242C11.5181 10.1242 11.9389 9.63672 12.4973 9.26172C13.0556 8.88672 13.6848 8.69922 14.3848 8.69922C15.2681 8.69922 16.0306 8.98672 16.6723 9.56172C17.3139 10.1367 17.7014 10.8492 17.8348 11.6992C18.5348 11.6992 19.1348 11.9409 19.6348 12.4242C20.1348 12.9076 20.3848 13.4909 20.3848 14.1742C20.3848 14.8742 20.1431 15.4701 19.6598 15.9617C19.1764 16.4534 18.5848 16.6992 17.8848 16.6992H11.3848ZM14.2848 7.69922C14.1681 7.01589 13.9431 6.38255 13.6098 5.79922C13.2764 5.21589 12.8681 4.69922 12.3848 4.24922V6.69922H10.3848V0.699219H16.3848V2.69922H13.6598C14.3764 3.33255 14.9639 4.07422 15.4223 4.92422C15.8806 5.77422 16.1764 6.69922 16.3098 7.69922H14.2848ZM11.3848 14.6992H17.8848C18.0181 14.6992 18.1348 14.6492 18.2348 14.5492C18.3348 14.4492 18.3848 14.3326 18.3848 14.1992C18.3848 14.0659 18.3348 13.9492 18.2348 13.8492C18.1348 13.7492 18.0181 13.6992 17.8848 13.6992H16.1348V12.4492C16.1348 11.9659 15.9639 11.5534 15.6223 11.2117C15.2806 10.8701 14.8681 10.6992 14.3848 10.6992C13.9014 10.6992 13.4889 10.8701 13.1473 11.2117C12.8056 11.5534 12.6348 11.9659 12.6348 12.4492V12.6992H11.3848C11.1014 12.6992 10.8639 12.7951 10.6723 12.9867C10.4806 13.1784 10.3848 13.4159 10.3848 13.6992C10.3848 13.9826 10.4806 14.2201 10.6723 14.4117C10.8639 14.6034 11.1014 14.6992 11.3848 14.6992Z"
      fill="#253858"
    />
  </svg>
);

export default MarketplaceOpInProgressSvg;
