import React, { useCallback, useContext } from 'react';
import { FormSections, formSectionToTitle } from '../formSections';
import { CashMultiple } from 'mdi-material-ui';
import { Card } from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import ProductSelector from '../../../../generic/ProductSelector/ProductSelector';
import { useFormikContext } from 'formik';
import PricingConfig from './PricingConfig/PricingConfig';
import CurrencyConfig from './CurrencyConfig/CurrencyConfig';
import { FormValues } from '../formSchema';
import DimensionsConfig from './DimensionsConfig/DimensionsConfig';
import UsageDimensionsConfig from './UsageDimensionsConfig/UsageDimensionsConfig';
import ScheduleConfig from './ScheduleConfig/ScheduleConfig';
import {
  BillingTerm,
  DurationType,
  PaymentModel,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import UsageDuration from './UsageDuration/UsageDuration';
import { toMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { FieldKey } from './formTypes';
import {
  Product,
  ProductListingType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { Optional } from 'utils/optional/optional';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import ServiceDetailsConfig from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ProductAndPricingFormSection/ServiceDetailsConfig/ServiceDetailsConfig';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const ifNewIsPayGoElseOldIsPayGoOrCurrentValue = <T,>(
  newProduct: Product | null,
  priorProduct: Product | null,
  ifNewIsPayGo: T,
  ifOldIsPayGo: T,
  currentValue: T,
): T | null =>
  newProduct?.listingType === ProductListingType.PayGo
    ? ifNewIsPayGo
    : priorProduct?.listingType === ProductListingType.PayGo
    ? ifOldIsPayGo
    : currentValue;

const ProductAndPricingFormSection: React.FunctionComponent = () => {
  const {
    values: { productRef, paymentModel, billingTerm, billingTermCadence },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const { mode, productsByProductId } = useContext(OfferPageContext);
  const { offerIsAmendment } = useContext(OfferContext);
  const isAmendmentMode = mode === OfferPageMode.Amend || offerIsAmendment;

  const onProductSelected = useCallback(
    async (priorProductRef: string | null, newProductRef: string | null) => {
      if (priorProductRef === newProductRef) {
        return;
      }

      const priorProduct = Optional.ofNullable(priorProductRef)
        .map(toMatchingProduct(productsByProductId))
        .orElse(null);

      const newProduct = Optional.ofNullable(newProductRef)
        .map(toMatchingProduct(productsByProductId))
        .orElse(null);

      const newPaymentModel = ifNewIsPayGoElseOldIsPayGoOrCurrentValue(
        newProduct,
        priorProduct,
        PaymentModel.PayGo,
        PaymentModel.PaymentSchedule,
        paymentModel || PaymentModel.PaymentSchedule,
      );

      const newBillingTerm = ifNewIsPayGoElseOldIsPayGoOrCurrentValue(
        newProduct,
        priorProduct,
        null,
        BillingTerm.Custom,
        billingTerm || BillingTerm.Custom,
      );

      const newBillingTermCadence = ifNewIsPayGoElseOldIsPayGoOrCurrentValue(
        newProduct,
        priorProduct,
        null,
        DurationType.Months,
        billingTermCadence || DurationType.Months,
      );

      await setFieldValue(FieldKey.PaymentModel, newPaymentModel);
      await setFieldValue(FieldKey.BillingTerm, newBillingTerm);
      await setFieldValue(FieldKey.BillingTermCadence, newBillingTermCadence);
    },
    [
      productsByProductId,
      setFieldValue,
      paymentModel,
      billingTerm,
      billingTermCadence,
    ],
  );

  const isPayGo = paymentModel === PaymentModel.PayGo;

  return (
    <div id={FormSections.ProductAndPricing}>
      <Card
        title={formSectionToTitle[FormSections.ProductAndPricing]}
        subtitle="Select a listing and set pricing for your offer."
        icon={<CashMultiple />}
      >
        <Box>
          <Box mb={2}>
            <ProductSelector
              marketplace={Marketplace.Aws}
              onProductSelected={onProductSelected}
            />
          </Box>
          {productRef && (
            <>
              {!isPayGo && <PricingConfig />}
              {isAmendmentMode && <ServiceDetailsConfig />}
              <CurrencyConfig />
              {isPayGo && <UsageDuration />}
              {!isPayGo && <DimensionsConfig />}
              <UsageDimensionsConfig />
              {!isPayGo && <ScheduleConfig />}
            </>
          )}
        </Box>
      </Card>
    </div>
  );
};

export default ProductAndPricingFormSection;
