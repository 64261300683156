import { createContext } from 'react';
import { Product } from '../api/types/Product';
import { Offer } from '../types/Offer';
import { TackleOffer } from '../types/TackleOffer';

export interface OfferContextValues<O extends Offer> {
  offer: O;
  setOffer: (o: O) => void;
  tackleOffer: TackleOffer | null;
  tackleOfferForMode: TackleOffer | null;
  product: Product | null;
  offerIsAmendment: boolean;
  offerHasPendingMarketplaceOp: boolean;
  offerIsMarketplaceEditable: boolean;
}

const OfferContext = createContext<OfferContextValues<Offer>>({
  offer: null,
  setOffer: () => {},
  tackleOffer: null,
  tackleOfferForMode: null,
  product: null,
  offerIsAmendment: false,
  offerHasPendingMarketplaceOp: false,
  offerIsMarketplaceEditable: true,
});

export default OfferContext;
