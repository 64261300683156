import {
  setAccessToken,
  setRefreshToken,
  getRefreshToken,
  setAccessTokenExpiresAt,
  getAccessTokenExpiresAt,
  getAccessToken,
  isCanvasSession,
} from './salesforceSessionStorage';

export interface IOneTimeKeyRenewResponse {
  access_token: string;
  expires_in_seconds: number;
  refresh_token: string;
}

// If the access token is within 30 seconds of expiring, we will renew it.
export const ACCESS_TIME_EXPIRATION_LEEWAY = 30 * 1000; // 30s in ms

let isRotatingTokens = false;

export const getOrRenewAccessToken = async () => {
  if (!isCanvasSession()) {
    return;
  }

  try {
    if (needsNewAccessToken() && !isRotatingTokens) {
      isRotatingTokens = true;
      const refreshToken = getRefreshToken();
      await rotateTokens(refreshToken);
    }
    return getAccessToken();
  } finally {
    isRotatingTokens = false;
  }
};

export const needsNewAccessToken = (): boolean => {
  const expiresAt = getAccessTokenExpiresAt();
  const now = Date.now();
  const future = expiresAt - ACCESS_TIME_EXPIRATION_LEEWAY;
  return now >= future;
};

export const rotateTokens = async (refresh_token: string) => {
  const otkRenewRequest = await fetch(
    `${process.env.REACT_APP_TKL_AUTH_SERVICE_URL}/api/v1/auth/one-time-keys/renew`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token,
      }),
    },
  );

  if (otkRenewRequest.ok) {
    const otkRenewRequestJson =
      (await otkRenewRequest.json()) as IOneTimeKeyRenewResponse;

    // store the new values
    setAccessToken(otkRenewRequestJson.access_token);
    setRefreshToken(otkRenewRequestJson.refresh_token);
    setAccessTokenExpiresAt(
      Date.now() + otkRenewRequestJson.expires_in_seconds * 1000,
    );

    return otkRenewRequestJson;
  }
  console.log('Failed to renew access token');
};
