import { Field } from 'formik';
import DateSelector from 'pages/PrivateOffers/pages/Next/generic/DateSelector/DateSelector';
import OfferFormSection from 'pages/PrivateOffers/pages/Next/generic/OfferFormSection/OfferFormSection';
import { startOfTodayAsUtcDatetime } from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { Box, Grid } from 'vendor/material';
import { maxFutureDatedStartDate } from '../../utils/dateUtils';
import { FieldKey } from '../formTypes';
import { useContext } from 'react';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { ActivitySlug } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.UsageEndDate]: 'Usage end date',
};

const UsageDuration = () => {
  const { tackleOfferForMode: offer } = useContext(OfferContext);

  const isDisabled = !!offer?.activities?.find(
    (activity) =>
      activity.slug === ActivitySlug.BuyerAcceptedOffer ||
      activity.slug === ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
  );

  return (
    <Box my={3}>
      <OfferFormSection
        title="Usage duration"
        subtitle="After this date, if the customer continues to use, they will be charged the public price."
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Field name={FieldKey.ServiceEndAt}>
              {({ field, meta }) => (
                <DateSelector
                  {...field}
                  label={`${fieldKeyToLabel[FieldKey.UsageEndDate]} *`}
                  minDate={startOfTodayAsUtcDatetime().toISO()}
                  maxDate={maxFutureDatedStartDate().toISO()}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  disabled={isDisabled}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </OfferFormSection>
    </Box>
  );
};

export default UsageDuration;
