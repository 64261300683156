import { FieldOption } from './types';
export const FIELD_MAPPERL_LABEL = 'field-mapper-label' as const;
export const CO_SELL_LABEL = 'Co-Sell' as const;
export const OFFERS_LABEL = 'Offers' as const;
export const FORM_PATH_DELINEATOR = '#' as const;
export const DOMAIN_PATH_DELINEATOR = '.' as const;

export enum Cloud {
  AWS = 'aws',
  GCP = 'gcp',
  MSFT = 'msft',
  AZURE = 'azure',
}

export enum Domain {
  CO_SELL = 'co-sell',
  OFFER = 'offer',
}

export enum Operation {
  CREATE_DIRECT_OFFER = 'createDirectOffer',
  CREATE_PARTNER_OFFER = 'createPartnerOffer',
  CREATE_OPPORTUNITY = 'createOpportunity',
}

export enum ColumnWidths {
  FIELD_NAME = 2,
  FIELD_TYPE = 1,
  SALESFORCE_FIELD = 5,
  PICKLIST_BUTTON = 1,
  DEFAULT_VALUE = 3,
}

export enum DefaultFieldType {
  NONE = 'none',
  FREEFORM_TEXT = 'freeform-text',
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
}

// NOTE: "microsoft" is used interchangeably in Downstream. For the purposes of Data Mapper we want it to be set to "msft"
// when interacting with the API
export const CLOUD_LOOKUP = {
  [Cloud.AWS]: Cloud.AWS,
  [Cloud.GCP]: Cloud.GCP,
  [Cloud.MSFT]: Cloud.MSFT,
  microsoft: Cloud.MSFT,
  [Cloud.AZURE]: Cloud.MSFT,
} as const;

export const CLOUD_LABEL_LOOKUP = {
  [Cloud.AWS]: 'AWS',
  [Cloud.GCP]: 'GCP',
  [Cloud.MSFT]: 'Microsoft',
  [Cloud.AZURE]: 'Microsoft',
} as const;

export const DOMAIN_LABEL_LOOKUP = {
  [Domain.CO_SELL]: 'Co-Sell',
  [Domain.OFFER]: 'Offer',
} as const;

export const OPERATION_LABEL_LOOKUP = {
  [Operation.CREATE_DIRECT_OFFER]: 'Direct offer',
  [Operation.CREATE_PARTNER_OFFER]: 'Partner offer',
  [Operation.CREATE_OPPORTUNITY]: 'Opportunity',
} as const;

export enum DomainDataType {
  STRING = 'string',
  NUMBER = 'number',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  OBJECT = 'object',
  NULL = 'null',
}

export enum DomainFormFieldType {
  TEXT = 'text',
  PICKLIST = 'picklist',
  URL = 'url',
  STRING = 'string',
  ARRAY = 'array',
  DATE = 'date',
  LONG_TEXT = 'long_text',
  CURRENCY = 'currency',
  NONE = 'none',
}

export enum FieldOptionDataType {
  REFERENCE = 'reference',
  CHILD = 'child',
  BOOLEAN = 'boolean',
  SCALAR = 'scalar',
  STRING = 'string',
  PICKLIST = 'picklist',
  URL = 'url',
  PHONE = 'phone',
  TEXTAREA = 'textarea',
  PERCENT = 'percent',
  CURRENCY = 'currency',
  EMAIL = 'email',
  ENCRYPTEDSTRING = 'encryptedstring',
  ADDRESS = 'address',
  CALCULATED = 'calculated',
  COMBOBOX = 'combobox',
  MULTIPICKLIST = 'multipicklist',
  FLOAT_ARRAY = 'floatarray',
  ID = 'id',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  DOUBLE = 'double',
  INT = 'int',
  LONG = 'long',
  TIME = 'time',
  CUSTOM_TEXT = 'custom_text',
}

// NOTE: These types are in progress and will be refined
export const FIELD_TYPE_LABEL_LOOKUP = {
  [DomainFormFieldType.TEXT]: 'Text',
  [DomainFormFieldType.PICKLIST]: 'Picklist',
  [DomainFormFieldType.URL]: 'URL',
  [DomainFormFieldType.STRING]: 'String',
  [DomainFormFieldType.DATE]: 'Date',
  [DomainFormFieldType.LONG_TEXT]: 'Long text',
  [DomainFormFieldType.CURRENCY]: 'Currency',
} as const;

export enum StartingObjectId {
  OPPORTUNITY = 'Opportunity',
  QUOTE = 'Quote',
}

export const INVALID_FIELD_DOMAIN_PATH = 'invalid_field';

export const INVALID_FIELD = {
  domainPath: INVALID_FIELD_DOMAIN_PATH,
  domainDataType: DomainDataType.STRING,
  formMetadata: { label: 'Invalid field' },
  mappingData: {
    domainDataType: DomainDataType.STRING,
  },
} as const;

export const SELECT_FIELD_OPTIONS_QUERY_LIMIT = 2000 as const;
export const DEFAULT_FIELD_OPTIONS_QUERY_LIMIT = 1000 as const;

export const OPERATION_DEFAULT_VALUE_BY_CLOUD_MAP = {
  [Cloud.AWS]: {
    [Domain.CO_SELL]: Operation.CREATE_OPPORTUNITY,
    [Domain.OFFER]: Operation.CREATE_DIRECT_OFFER,
  },
  [Cloud.GCP]: {
    [Domain.CO_SELL]: Operation.CREATE_OPPORTUNITY,
    [Domain.OFFER]: Operation.CREATE_DIRECT_OFFER,
  },
  [Cloud.MSFT]: {
    [Domain.CO_SELL]: Operation.CREATE_OPPORTUNITY,
    [Domain.OFFER]: Operation.CREATE_DIRECT_OFFER,
  },
} as const;

/**
 * Salesforce Field Options (Objects)
 */

export const OPPORTUNITY_STARTING_OBJECT: FieldOption = {
  fieldId: 'Opportunity',
  fieldLabel: 'Opportunity',
  parentId: 'Opportunity',
  parentLabel: 'Opportunity',
  dataType: FieldOptionDataType.REFERENCE,
  fieldCharacterLimit: '12',
  referenceTo: 'Opportunity',
  relationshipName: 'reference',
} as const;

export const QUOTE_STARTING_OBJECT: FieldOption = {
  fieldId: 'Quote',
  fieldLabel: 'Quote',
  parentId: 'Quote',
  parentLabel: 'Quote',
  dataType: FieldOptionDataType.REFERENCE,
  fieldCharacterLimit: '12',
  referenceTo: 'Quote',
  relationshipName: 'reference',
} as const;

export const ACCOUNT_CONTACTS_CHILD_OBJECT = {
  dataType: FieldOptionDataType.CHILD,
  fieldId: 'Contacts',
  fieldLabel: 'Contacts',
  parentId: 'Account',
  parentLabel: 'Account',
  referenceTo: 'Contact',
  relationshipName: 'Contacts',
} as const;

// RBAC granular permissions
export const SETTINGS_LIST_MAPPINGS = 'settings:ListMappings' as const;
export const SETTINGS_GET_MAPPING = 'settings:GetMapping' as const;
export const SETTINGS_CREATE_MAPPING = 'settings:CreateMapping' as const;
export const SETTINGS_UPDATE_MAPPING = 'settings:UpdateMapping' as const;
export const SETTINGS_DELETE_MAPPING = 'settings:DeleteMapping' as const;
export const SETTINGS_GET_MAPPING_FIELD_OPTIONS_STATUS =
  'settings:GetMappingFieldOptionsStatus' as const;
export const SETTINGS_LIST_MAPPING_FIELD_OPTIONS =
  'settings:ListMappingFieldOptions' as const;
export const SETTINGS_SYNC_MAPPING_FIELD_OPTIONS =
  'settings:SyncMappingFieldOptions' as const;

export const FIELD_MAPPER_GRANULAR_RBAC_PERMISSIONS = [
  SETTINGS_LIST_MAPPINGS,
  SETTINGS_GET_MAPPING,
  SETTINGS_CREATE_MAPPING,
  SETTINGS_UPDATE_MAPPING,
  SETTINGS_DELETE_MAPPING,
  SETTINGS_GET_MAPPING_FIELD_OPTIONS_STATUS,
  SETTINGS_LIST_MAPPING_FIELD_OPTIONS,
  SETTINGS_SYNC_MAPPING_FIELD_OPTIONS,
] as const;

// Picklist enabled fields
export const PICKLIST_FIELD_ENABLED_OPTIONS = [
  FieldOptionDataType.REFERENCE,
  FieldOptionDataType.CHILD,
  FieldOptionDataType.PICKLIST,
  FieldOptionDataType.MULTIPICKLIST,
];
