import * as yup from '../../../../../../../../../../utils/yup-extended';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKeys as NonUserInputFieldKey } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formTypes';
import { FieldKey as ProductSelectorFieldKeys } from 'pages/PrivateOffers/pages/Next/generic/ProductSelector/formSchema';

export enum FieldKey {
  Sku = 'sku',
  Description = 'description',
  Price = 'price',
}

export interface FormUsageDimension {
  [FieldKey.Sku]: string | null;
  [FieldKey.Description]: string | null;
  [FieldKey.Price]: number | null;
}

const priceSchema = yup
  .number()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      ProductSelectorFieldKeys.ProductRef,
      (pr: string | null) => !!pr,
    ),
    then: (schema: yup.NumberSchema) => schema.min(0).progress(true),
    otherwise: (schema: yup.NumberSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      NonUserInputFieldKey.CreateInMarketplace,
      (cim: boolean) => cim,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

export const usageDimensionsSchema = yup.array<FormUsageDimension>().of(
  yup.object({
    [FieldKey.Sku]: yup.string().required().progress(false),
    [FieldKey.Description]: yup.string().nullable().progress(false),
    [FieldKey.Price]: priceSchema,
  }),
);
