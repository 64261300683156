import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferType } from '../types/TackleOffer';

export const labelsByOfferType = {
  [OfferType.Direct]: {
    title: 'Direct',
    subtitle: 'Custom offer you send directly to your buyer',
  },
  [OfferType.PartnerResale]: {
    title: 'Partner',
    subtitle: 'Custom offer for your channel partner to send to the buyer',
  },
};

export const labelsByOfferCreationMode = {
  [OfferPageMode.New]: {
    title: 'New offer',
    subtitle: 'Build a custom offer for a specific marketplace.',
  },
  [OfferPageMode.Associate]: {
    title: 'Associate to an existing offer',
    subtitle:
      'Link to an existing offer already created in the marketplace to track the status and receive a bookable artifact.',
  },
};
