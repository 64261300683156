import { Agreement } from 'pages/PrivateOffers/pages/Next/generic/api/types/Agreement';
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  Grid,
  List,
  makeStyles,
  Typography,
} from 'vendor/material';
import { Banner, Button, Link, Tabs } from '@tackle-io/platform-ui';
import {
  formatDateFieldValue,
  getUtcDateTimeFromSerializedIsoDateTime,
} from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import {
  Product,
  ProductListingType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import {
  PaymentModel,
  AwsPrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import { getSchedulesAfterToday } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ProductAndPricingFormSection/utils/scheduleUtils';
import AmendmentContext from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContext';
import { paymentModelResourceText } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/offerUtils';

interface BuyerAgreementsSidePanelProps {
  agreementsForBuyer: Agreement[];
  onBuyerAgreementsSidePanelClosed: () => void;
}

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: '40px',
    width: '42vw',
  },
  activeAgreementsTitle: {
    fontWeight: 600,
    lineHeight: '30px',
  },
  reviewsSubText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: '#253858',
  },
  tabsContainer: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  tabContent: {
    paddingTop: theme.spacing(2),
  },
  emptyTabText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: '#5E6C84',
  },
  agreementsListItemContainer: {
    borderRadius: '4px',
    paddingBottom: theme.spacing(2),
  },
  agreementListItemHeader: {
    backgroundColor: '#F4F5F7',
    display: 'flex',
    alignItems: 'center',
    color: '#253858',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
  agreementListItemBody: {
    backgroundColor: '#FAFBFC',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: '#253858',
  },
  agreementDetailLabel: {
    fontWeight: 600,
  },
  agreementDetailValue: {
    fontWeight: 400,
  },
  actionsFooter: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
  },
  actionButton: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

const amendableStatuses = new Set<string>(['ACTIVE']);

const listingTypesValidForAmend = new Set<ProductListingType>([
  ProductListingType.Saas,
  ProductListingType.Ccp,
]);

const isAmendableAgreement = (
  agreement: Agreement,
  productsByProductId: { [p: string]: Product },
): boolean => {
  const matchingProduct = findMatchingProduct(
    agreement.productId,
    productsByProductId,
  );

  return (
    amendableStatuses.has(agreement.status) &&
    listingTypesValidForAmend.has(matchingProduct?.listingType)
  );
};

const byEndDateAscending = (a: Agreement, b: Agreement): number => {
  const endDateA = getUtcDateTimeFromSerializedIsoDateTime(a.endDate);
  const endDateB = getUtcDateTimeFromSerializedIsoDateTime(b.endDate);

  return endDateA < endDateB ? -1 : 1;
};

interface AgreementListItemProps {
  agreement: Agreement;
  productsByProductId: { [p: string]: Product };
  offerId?: string | null;
  amendable?: boolean;
}

const resourceForProductType = {
  [ProductListingType.Saas]: 'SaaS',
  [ProductListingType.Ccp]: 'SaaS + usage',
  [ProductListingType.PayGo]: 'Pay go',
};

const AgreementListItem: React.FunctionComponent<AgreementListItemProps> = ({
  agreement,
  productsByProductId,
  offerId = null,
  amendable = true,
}) => {
  const classes = useStyles();
  const [offer, setOffer] = useState<AwsPrivateOffer | null>(null);
  const paymentModel = offer?.pricing?.paymentModel;

  const matchingProduct = findMatchingProduct(
    agreement?.productId,
    productsByProductId,
  );

  const productName = matchingProduct?.name || 'Not Tackle managed';

  const productType =
    resourceForProductType[matchingProduct?.listingType] || 'SaaS';

  const pricingModel =
    matchingProduct?.listingType === ProductListingType.PayGo
      ? paymentModelResourceText[PaymentModel.PayGo]
      : offer
      ? paymentModelResourceText[paymentModel] || 'Unknown'
      : '-';

  const schedules = offer?.pricing?.schedule || [];
  const remainingPayments = getSchedulesAfterToday(schedules);

  const remainingTotalPayments =
    schedules.length > 0
      ? `${remainingPayments.length} / ${schedules.length}`
      : '-';

  const {
    awsApi: { getOfferForAgreement },
  } = useContext(ApiContext);

  const { onAmend } = useContext(OfferPageContext);

  useEffect(() => {
    (async () => {
      const o = await getOfferForAgreement(agreement.offerId);
      setOffer(o);

      /// TODO - figure this out
      // const a = await getAgreementOffer(agreement.offerId);
      // console.log(a);
    })();
  }, [getOfferForAgreement, agreement.offerId]);

  return (
    <Box className={classes.agreementsListItemContainer}>
      <Box className={classes.agreementListItemHeader}>{agreement.id}</Box>
      <Box className={classes.agreementListItemBody}>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>Product</Box>
              <Box className={classes.agreementDetailValue}>{productName}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>Product type</Box>
              <Box className={classes.agreementDetailValue}>{productType}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>Pricing model</Box>
              <Box className={classes.agreementDetailValue}>{pricingModel}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>
                Remaining / total payments
              </Box>
              <Box className={classes.agreementDetailValue}>
                {remainingTotalPayments}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>Start date</Box>
              <Box className={classes.agreementDetailValue}>
                {formatDateFieldValue(agreement.startDate) || '-'}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>End date</Box>
              <Box className={classes.agreementDetailValue}>
                {formatDateFieldValue(agreement.endDate) || '-'}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.agreementDetailLabel}>
                Source offer ID
              </Box>
              <Box className={classes.agreementDetailValue}>
                {offer ? (
                  <Link to={`/private-offers/aws/${offer.poId}`} external>
                    {agreement.offerId}
                  </Link>
                ) : (
                  agreement.offerId
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box paddingBottom={2}>
          <Grid container spacing={1}>
            {/* TODO - add this back once we GA contracts and have it available in the Tackle widget */}
            {/*<Grid item>*/}
            {/*  <Button appearance="white">View contract</Button>*/}
            {/*</Grid>*/}
            {amendable && (
              <Grid item>
                <Button
                  appearance="primary"
                  onClick={() => {
                    onAmend(agreement.offerId, offer);
                  }}
                  disabled={offerId === agreement.offerId}
                >
                  Amend
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const BuyerAgreementsSidePanel: React.FunctionComponent<
  BuyerAgreementsSidePanelProps
> = ({ agreementsForBuyer, onBuyerAgreementsSidePanelClosed }) => {
  const classes = useStyles();
  const { productsByProductId } = useContext(OfferPageContext);
  const { offerId } = useContext(AmendmentContext);

  const { amendableAgreements, unamendableAgreements } =
    agreementsForBuyer.reduce(
      (acc, a) => {
        const agreementIsAmendable = isAmendableAgreement(
          a,
          productsByProductId,
        );

        return {
          ...acc,
          amendableAgreements: agreementIsAmendable
            ? [...acc.amendableAgreements, a]
            : acc.amendableAgreements,
          unamendableAgreements: !agreementIsAmendable
            ? [...acc.unamendableAgreements, a]
            : acc.unamendableAgreements,
        };
      },
      { amendableAgreements: [], unamendableAgreements: [] },
    );

  const sortedAmendableAgreements = [...amendableAgreements].sort(
    byEndDateAscending,
  );
  const sortedUnamendableAgreements = [...unamendableAgreements].sort(
    byEndDateAscending,
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={true}
      onClose={onBuyerAgreementsSidePanelClosed}
    >
      <Box className={classes.mainContent}>
        <Box mb={4}>
          <Typography className={classes.activeAgreementsTitle}>
            Active agreements
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography className={classes.reviewsSubText}>
            Review your active contracts with this buyer to select a contract to
            amend.
          </Typography>
        </Box>
        <Box mb={3}>
          <Banner
            type="info"
            borderPosition="top"
            title="AWS Marketplace supports amended offers for SaaS products with contract and contract+usage pricing models only."
            body={
              <Link
                to="https://docs.aws.amazon.com/marketplace/latest/userguide/private-offers-upgrades-and-renewals.html#private-offers-upgrades-and-renewals-supported-products"
                external
                showExternalIcon
              >
                Learn more
              </Link>
            }
          />
        </Box>
        <Box className={classes.tabsContainer}>
          <Tabs
            tabs={[
              {
                label: `Amendable contracts (${amendableAgreements.length})`,
                content: (
                  <Box className={classes.tabContent}>
                    {sortedAmendableAgreements.length === 0 && (
                      <Typography className={classes.emptyTabText}>
                        You don't have any amendable active contracts. Create a
                        new direct offer for your buyer instead.
                      </Typography>
                    )}
                    {sortedAmendableAgreements.length > 0 && (
                      <List>
                        {sortedAmendableAgreements.map((a) => (
                          <AgreementListItem
                            key={a.id}
                            agreement={a}
                            productsByProductId={productsByProductId}
                            offerId={offerId}
                          />
                        ))}
                      </List>
                    )}
                  </Box>
                ),
              },
              {
                label: `Unamendable contracts (${unamendableAgreements.length})`,
                content: (
                  <Box className={classes.tabContent}>
                    {sortedUnamendableAgreements.length === 0 && (
                      <Typography className={classes.emptyTabText}>
                        You don't have any unamendable contracts.
                      </Typography>
                    )}
                    {sortedUnamendableAgreements.length > 0 && (
                      <List>
                        {sortedUnamendableAgreements.map((a) => (
                          <AgreementListItem
                            key={a.id}
                            agreement={a}
                            productsByProductId={productsByProductId}
                            amendable={false}
                          />
                        ))}
                      </List>
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </Box>
        <Box className={classes.actionsFooter}>
          <Button
            variant="outlined"
            appearance="primary"
            onClick={onBuyerAgreementsSidePanelClosed}
            className={classes.actionButton}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default BuyerAgreementsSidePanel;
