import {
  FieldKey,
  FormUserToNotify,
  FormValues,
} from 'pages/PrivateOffers/pages/Next/generic/NotifyUsersSelector/formSchema';
import { Optional } from 'utils/optional/optional';
import {
  AwsPrivateOffer,
  UserToNotify,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Auth0User } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0User';
import { User } from 'pages/PrivateOffers/pages/Next/generic/api/types/User';

export const initialFormValuesForNewOffer = (userEmail: string): FormValues => {
  const usersToNotify = userEmail ? [{ email: userEmail }] : [];

  return { [FieldKey.UsersToNotify]: usersToNotify };
};

const toFormUserToNotify = ({ email }: UserToNotify): FormUserToNotify => ({
  email: email,
});

export const usersToNotifToFormUsersToNotify = (
  usersToNotify: UserToNotify[],
): FormUserToNotify[] => usersToNotify.map(toFormUserToNotify);

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  const { extraData } = offer;

  return {
    [FieldKey.UsersToNotify]: usersToNotifToFormUsersToNotify(extraData.users),
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
  user: User,
  users: Auth0User[],
): FormValues => {
  const {
    extraData: { users: usersToNotify },
  } = offer;
  const auth0UserEmails = new Set<string>(users.map((u) => u.email));

  const validExistingUsersToNotify = Optional.ofNullable(usersToNotify)
    .map((utn) =>
      utn.filter((u) => auth0UserEmails.has(u.email)).map(toFormUserToNotify),
    )
    .orElse([]);

  const userIsInUsersToNotify = validExistingUsersToNotify.some(
    (u) => u.email === user?.email,
  );

  const formUsersToNotify = userIsInUsersToNotify
    ? validExistingUsersToNotify
    : [{ email: user?.email }, ...validExistingUsersToNotify];

  return {
    ...initialFormValuesForExistingOffer(offer),
    [FieldKey.UsersToNotify]: formUsersToNotify,
  };
};

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
  user: User,
  users: Auth0User[],
): FormValues => {
  return { ...initialFormValuesForCloneOffer(offer, user, users) };
};
