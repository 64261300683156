import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { convertRawRequestToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertRawRequestToAceFormValues';
import { removeUndefinedOrEmptyObjectProperties } from 'packages/cosell/src/utilities/typeConverters/utils';
import { UpdateAceOpportunityFormValues } from '../AceOpportunityForm/AceOpportunityFormValues';
import { RawRequestWithOperation } from 'packages/cosell/src/hooks/useOpportunityEventsQuery/extractLatestRawRequestFromEvents';
import {
  AceOpportunityResponse,
  IncompleteAceOpportunityResponse,
} from 'packages/cosell/src/types/responses/AceOpportunityResponse';

export const getFormValues = ({
  lastAceRequestHasErrors,
  aceLatestRawRequest,
  opportunity,
}: {
  lastAceRequestHasErrors: boolean;
  aceLatestRawRequest: RawRequestWithOperation | undefined;
  opportunity: AceOpportunityResponse | IncompleteAceOpportunityResponse;
}): UpdateAceOpportunityFormValues | null => {
  if (!opportunity) return null;

  if (lastAceRequestHasErrors) {
    const trimmedRawRequestFormData = removeUndefinedOrEmptyObjectProperties(
      convertRawRequestToAceFormValues(aceLatestRawRequest),
    );
    const syncedOpportunityData = opportunity.isCreatedInCloud
      ? convertOpportunityResponseToAceFormValues(opportunity)
      : {};
    return {
      ...syncedOpportunityData,
      ...trimmedRawRequestFormData,
    };
  } else {
    return opportunity.isCreatedInCloud
      ? convertOpportunityResponseToAceFormValues(opportunity)
      : null;
  }
};
