import React, { useContext, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from 'vendor/material';
import { Button, ProviderIcon, TextField } from '@tackle-io/platform-ui';
import { Magnify } from 'mdi-material-ui';
import { FieldKey as ProductFieldKey, FieldKey } from './formSchema';
import { Field, useFormikContext } from 'formik';
import { DataId, OffersProductArea, PageLocation } from '../analytics';
import { ampli } from 'utils/analytics/ampli/index';
import { AWSProduct } from '../api/types/Product';
import { validProductsForMarketplace } from './utils/validProductsForMarketplace';
import { Optional } from 'utils/optional/optional';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from '../OfferPageContext/offerPageContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import classNames from 'classnames';
import FieldError from 'pages/PrivateOffers/components/FieldError/FieldError';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { Marketplace } from '../types/TackleOffer';

interface ProductSelectorProps {
  marketplace: Marketplace;
  onProductSelected?: (
    priorProductRef: string | null,
    newProductRef: string | null,
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  productsContainer: {
    maxHeight: theme.spacing(7.5) * 5,
    overflow: 'auto',
    border: '1px solid lightgray',
  },
  disabledProduct: {
    backgroundColor: '#EBECF0',
  },
  listItem: {
    cursor: 'pointer',
    '.MuiTypography-root': {
      '&:hover': {
        fontWeight: 800,
      },
    },
  },
}));

const fieldKeyToLabel = {
  [FieldKey.ProductRef]: 'Product',
};

const productNameIncludes = (lowerCasedSearch: string) => (p: AWSProduct) =>
  p.name.toLowerCase().includes(lowerCasedSearch);

const ProductSelector: React.FunctionComponent<ProductSelectorProps> = ({
  marketplace,
  onProductSelected,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const lowerCasedSearch = search.toLowerCase();
  const { mode, productsByProductId } = useContext(OfferPageContext);
  const { tackleOfferForMode: offer, offerIsAmendment } =
    useContext(OfferContext);
  const isAmendmentMode = mode === OfferPageMode.Amend || offerIsAmendment;
  const createdInMarketplaceAt = offer?.createdInMarketplaceAt;

  const {
    values: { productRef: formProductRef },
  } = useFormikContext<{ productRef: string | null }>();

  const [priorProductRef, setPriorProductRef] = useState<string | null>(
    formProductRef,
  );

  const onSearchChanged = ({ target: { value: newSearch } }) => {
    setSearch(newSearch);
  };

  const validMarketplaceProducts = Object.values(productsByProductId).filter(
    validProductsForMarketplace(marketplace),
  );

  const filteredProducts = lowerCasedSearch
    ? validMarketplaceProducts.filter(productNameIncludes(lowerCasedSearch))
    : validMarketplaceProducts;

  const selectedProductClasses = classNames({
    [classes.disabledProduct]: isAmendmentMode,
  });

  return (
    <Box>
      <Field name={ProductFieldKey.ProductRef}>
        {({ field, meta, form }) => {
          const selectedProduct = filteredProducts.find(
            (p) => p.productid === field.value,
          );

          const onChangeProductClicked = () => {
            ampli.buttonClicked(
              {
                button_product_area: OffersProductArea,
                button_location: PageLocation.OfferEditPage,
                button_name: DataId.ProductSelectorChangeProductButton,
              },
              { extra: { marketplace } },
            );

            setPriorProductRef(field.value);
            form.setFieldValue(FieldKey.ProductRef, null);
          };

          const onProductClicked = (productRef: string) => () => {
            ampli.buttonClicked(
              {
                button_product_area: OffersProductArea,
                button_location: PageLocation.OfferEditPage,
                button_name: DataId.ProductSelectorProduct,
              },
              { extra: { marketplace, productRef } },
            );

            form.setFieldValue(FieldKey.ProductRef, productRef);

            Optional.ofNullable(onProductSelected).ifPresent((ops) =>
              ops(priorProductRef, productRef),
            );

            form.validateForm();
          };

          const fieldError = getFormattedError(
            FieldKey.ProductRef,
            fieldKeyToLabel,
            meta,
          );

          return (
            <Box>
              {!field.value && (
                <Box mb={2}>
                  <TextField
                    data-id={DataId.ProductSelectorSearchField}
                    name="productSearch"
                    iconLeft={<Magnify />}
                    onChange={onSearchChanged}
                  />
                </Box>
              )}
              <Box className={classes.productsContainer}>
                <List disablePadding={isAmendmentMode}>
                  {field.value && (
                    <ListItem
                      key={`${marketplace}-${field.value}`}
                      className={selectedProductClasses}
                    >
                      <ListItemIcon>
                        <ProviderIcon provider={marketplace} fontSize="large" />
                      </ListItemIcon>
                      <ListItemText primary={selectedProduct?.name} />
                      <ListItemSecondaryAction>
                        <Button
                          data-id={DataId.ProductSelectorChangeProductButton}
                          appearance="secondary"
                          onClick={onChangeProductClicked}
                          disabled={!!createdInMarketplaceAt || isAmendmentMode}
                        >
                          Change
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {!field.value && (
                    <>
                      {filteredProducts.length === 0 && (
                        <ListItem>
                          <ListItemText primary="No listings were found" />
                        </ListItem>
                      )}
                      {filteredProducts.length > 0 &&
                        filteredProducts.map((p, i) => (
                          <ListItem
                            key={p.productid}
                            data-id={`${DataId.ProductSelectorProduct}-${i}`}
                            onClick={onProductClicked(p.productid)}
                          >
                            <ListItemIcon>
                              <ProviderIcon
                                provider={marketplace}
                                fontSize="large"
                              />
                            </ListItemIcon>
                            <ListItemText
                              className={classes.listItem}
                              primary={p.name}
                            />
                          </ListItem>
                        ))}
                    </>
                  )}
                </List>
              </Box>
              {fieldError && <FieldError error={fieldError} />}
            </Box>
          );
        }}
      </Field>
    </Box>
  );
};

export default ProductSelector;
