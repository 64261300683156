import ky from 'ky';
import { authStore } from 'stores/Auth';
import invariant from 'tiny-invariant';
import { getOrRenewAccessToken } from './oneTimeKeyAuth';

/**
 * provides ky instances for tackle http apis with the bearer token prefilled along with default headers.
 *  - coSellApi: for aws co-sell v3 api
 *  - dataMapperPublicApi: for data mapper api
 *
 * NOTE: Temporary for development
 * this was created to work with the api without conflicts.
 * Once co-sell api is integrated, this will be removed or refactored.
 *
 */

export const getKyConfig = (accessToken?: string) => ({
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      async (request: Request) => {
        let bearerToken = accessToken;

        if (!bearerToken) {
          const sfToken = await getOrRenewAccessToken();
          bearerToken = sfToken ?? (await authStore.getToken());
        }

        invariant(bearerToken, 'Bearer token is required for the request');

        if (bearerToken) {
          request.headers.set('Authorization', `Bearer ${bearerToken}`);
        }
      },
    ],
  },
});

/**
 * the auth service api
 * allow to pass the access token to the if needed
 * */
const authApi = (accessToken?: string) =>
  ky.extend({
    prefixUrl: `${process.env.REACT_APP_TKL_AUTH_SERVICE_URL}`,
    ...getKyConfig(accessToken),
  });

// the co-sell v3 api
const coSellApi = ky.extend({
  prefixUrl: `${process.env.REACT_APP_TACKLE_CO_SELL_V3_API_URL}/api`,
  ...getKyConfig(),
});

// the data mapper public api
const dataMapperPublicApi = ky.extend({
  prefixUrl: `${process.env.REACT_APP_DATA_MAPPER_API_URL}/api`,
  ...getKyConfig(),
});

const crmConnectorSalesforceApi = ky.extend({
  prefixUrl: `${process.env.REACT_APP_CRM_CONNECTOR_API_URL}`,
  ...getKyConfig(),
});

export { authApi, coSellApi, dataMapperPublicApi, crmConnectorSalesforceApi };
