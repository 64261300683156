import {
  useListMappingsQuery,
  LIST_MAPPINGS_QUERY_LIMIT,
} from 'pages/Settings/Account/components/FieldMapper/api/hooks/useListMappingsQuery';
import { useMappedFieldsQuery } from 'packages/salesforce-canvas/cosell/src/hooks/useMappedFields/useMappedFieldsQuery';
import {
  Cloud,
  Domain,
  Operation,
} from 'pages/Settings/Account/components/FieldMapper/utils/constants';
import { useMemo } from 'react';

type MappedFieldsQueryResult = ReturnType<typeof useMappedFieldsQuery>['data'];

type MappedInitialValuesMap = {
  create: MappedFieldsQueryResult['initialValues'];
  launch: Pick<
    MappedFieldsQueryResult['initialValues'],
    | 'currencyCode'
    | 'deliveryModel'
    | 'customerSoftwareValue'
    | 'marketplaceOfferId'
    | 'effectiveDate'
    | 'expirationDate'
  >;
  closedLost: Pick<
    MappedFieldsQueryResult['initialValues'],
    'closedLostReason'
  >;
};

const getLaunchMappedFields = ({
  fieldsWithErrors,
  initialValues,
}: {
  fieldsWithErrors: MappedFieldsQueryResult['fieldsWithErrors'];
  initialValues: MappedInitialValuesMap['launch'];
}) => {
  return {
    fieldsWithErrors,
    initialValues: {
      currencyCode: initialValues.currencyCode,
      deliveryModel: initialValues.deliveryModel,
      customerSoftwareValue: initialValues.customerSoftwareValue,
      marketplaceOfferId: initialValues.marketplaceOfferId,
      effectiveDate: initialValues.effectiveDate,
      expirationDate: initialValues.expirationDate,
    },
  };
};

const getClosedLostMappedFields = ({
  fieldsWithErrors,
  initialValues,
}: {
  fieldsWithErrors: MappedFieldsQueryResult['fieldsWithErrors'];
  initialValues: MappedInitialValuesMap['closedLost'];
}) => {
  return {
    fieldsWithErrors,
    initialValues: {
      closedLostReason: initialValues?.closedLostReason,
    },
  };
};

export const useAwsCoSellMappedFields = ({
  crmId,
  enabled = false,
  formType = 'create',
}: {
  crmId: string;
  enabled: boolean;
  formType?: 'create' | 'launch' | 'closedLost';
}) => {
  /** first check if the vendor has any mappings,
   * then only fetch mappings if a mappingId is available.
   */
  const listMappingQuery = useListMappingsQuery({
    cloud: Cloud.AWS,
    domain: Domain.CO_SELL,
    operation: Operation.CREATE_OPPORTUNITY,
    isDefault: true,
    isPublished: true,
    limit: LIST_MAPPINGS_QUERY_LIMIT,
    enabled,
  });

  const mappingId = listMappingQuery.data?.mappings?.[0]?.mappingId;

  const mappedFieldsQuery = useMappedFieldsQuery({
    cloud: Cloud.AWS,
    domain: Domain.CO_SELL,
    operation: Operation.CREATE_OPPORTUNITY,
    crmId,
    mappingId,
    enabled: !!mappingId,
  });

  const mappedData = useMemo(() => {
    if (!mappedFieldsQuery.isSuccess || !mappedFieldsQuery.data || !formType) {
      return null;
    }

    switch (formType) {
      case 'launch':
        return getLaunchMappedFields(mappedFieldsQuery.data);
      case 'closedLost':
        return getClosedLostMappedFields(mappedFieldsQuery.data);
      case 'create':
        /** return everything */
        return mappedFieldsQuery.data;
    }
  }, [formType, mappedFieldsQuery.data, mappedFieldsQuery.isSuccess]);

  return {
    isInitialLoading:
      listMappingQuery.isInitialLoading || mappedFieldsQuery.isInitialLoading,
    mappedData: mappedData,
    isError: mappedFieldsQuery.isError || listMappingQuery.isError,
    refetch: mappedFieldsQuery.isError
      ? mappedFieldsQuery.refetch
      : listMappingQuery.refetch,
    isRefetching:
      mappedFieldsQuery.isRefetching || listMappingQuery.isRefetching,
  };
};
