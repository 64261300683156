import React, { useContext } from 'react';
import { Banner, Card, Link } from '@tackle-io/platform-ui';
import { TrophyAward } from 'mdi-material-ui';
import { Box } from 'vendor/material/index';
import LabelAndValue from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/components/LabelAndValue';
import {
  AwsPrivateOffer,
  ResellerAgreementType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { resellerAgreementContractUrl } from 'pages/PrivateOffers/pages/Next/aws/shared/urls';
import OfferContext, {
  OfferContextValues,
} from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

const ResellerAgreementSection: React.FunctionComponent = () => {
  const { offer } = useContext(
    OfferContext,
  ) as OfferContextValues<AwsPrivateOffer>;

  const resellerAgreementType = offer.partnerOffer?.resellerAgreement?.type;

  return (
    <Card
      title="Reseller agreement"
      icon={<TrophyAward />}
      bodyStyle={{ backgroundColor: '#FAFBFC' }}
    >
      {!!offer.createdInMarketplaceAt && (
        <Box mb={4}>
          <Banner
            title="This offer has been created in the Cloud marketplace and this section cannot be edited."
            borderPosition="top"
          />
        </Box>
      )}
      <Box mb={2}>
        <Box>
          <LabelAndValue
            label="Reseller agreement version"
            value={
              // TODO - link to view / download selected EULA
              resellerAgreementType === ResellerAgreementType.NoneSelected ? (
                'None'
              ) : resellerAgreementType ===
                ResellerAgreementType.Rcmp20211201 ? (
                <Link
                  to={resellerAgreementContractUrl}
                  external
                  showExternalIcon
                >
                  Reseller contract from AWS Marketplace (RCMP)
                </Link>
              ) : (
                'Custom'
              )
            }
          />
        </Box>
      </Box>
    </Card>
  );
};

export default ResellerAgreementSection;
