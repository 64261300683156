import React, { useContext } from 'react';
import { Banner, Card, Divider, ProviderIcon } from '@tackle-io/platform-ui';
import { CashMultiple } from 'mdi-material-ui';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Skeleton,
  Typography,
} from 'vendor/material';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import OfferSection from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/components/OfferSection';
import LabelAndValue from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/components/LabelAndValue';
import {
  CurrencyCode,
  formatValueWithCurrencyPrefix,
  getCurrencyResource,
} from 'utils/currency';
import SectionDivider from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/components/SectionDivider';
import {
  BillingTerm,
  PaymentModel,
  Pricing,
  AwsPrivateOffer,
  Schedule,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { toFinite } from 'lodash';
import {
  formatDateFieldValue,
  formatDateFieldValueZeroPadded,
  getMonthsDifferenceFromDateTimes,
  getUtcDateTimeFromSerializedIsoDateTime,
} from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import OfferContext, {
  OfferContextValues,
} from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from '../../../generic/OfferPageContext/offerPageContext';
import { getUsageDuration } from '../../../generic/utils/duration/durationUtil';
import AmendmentContext from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContext';
import { Marketplace, OfferType } from '../../../generic/types/TackleOffer';

const useStyles = makeStyles((theme) => ({
  productContainer: {
    backgroundColor: '#F4F5F7',
    border: '1px solid lightgray',
  },
  amendmentBillingTermText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
  paymentsRowContainer: {
    display: 'flex',
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.NEUTRAL100}`,
  },
  paymentRow: {
    padding: theme.spacing(1),
    '&:nth-child(odd)': {
      background: theme.palette.NEUTRAL020,
    },
  },
  value: {
    fontSize: 12,
    fontWeight: 400,
  },
}));

const paymentModelToResource = {
  [PaymentModel.PaymentSchedule]: 'Payment schedule',
  [PaymentModel.PerProduct]: 'Upfront pricing',
};

const contractStartResource = {
  [BillingTerm.Custom]: 'Start on acceptance',
  [BillingTerm.FutureDated]: 'Future dated',
};

const usageDimensionPricePrecision = 8;
const usageDimensionPriceFormat = '$0,0.00000000';

interface BillingTermBasedContractDetailsSectionProps {
  offerType: OfferType;
  pricing: Pricing;
  currencyCode: CurrencyCode;
}

interface PayGoContractDetailsSectionProps {
  pricing: Pricing;
  acceptedAtDate: string;
}

const BillingTermBasedContractDetailsSection: React.FunctionComponent<
  BillingTermBasedContractDetailsSectionProps
> = ({ offerType, pricing, currencyCode }) => (
  <>
    <OfferSection title="Contract details">
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <LabelAndValue
            label="Payment model"
            value={paymentModelToResource[pricing.paymentModel]}
          />
        </Grid>
        {offerType === OfferType.Direct && (
          <Grid item md={4} xs={12}>
            <LabelAndValue
              label="Contract start"
              value={contractStartResource[pricing.billingTerm]}
            />
          </Grid>
        )}
        <Grid item md={4} xs={12}>
          <LabelAndValue
            label="Billing terms"
            value={
              pricing.durationValue ? `${pricing.durationValue} months` : null
            }
          />
        </Grid>
        {offerType === OfferType.PartnerResale && (
          <LabelAndValue
            label="Max service start date"
            value={formatDateFieldValue(pricing.serviceStartAt)}
          />
        )}
      </Grid>
    </OfferSection>
    <SectionDivider />
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={6}>
          <LabelAndValue
            label="Gross amount"
            value={formatValueWithCurrencyPrefix(
              pricing.totalContractValue,
              currencyCode,
            )}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <LabelAndValue
            label="Net amount"
            value={formatValueWithCurrencyPrefix(
              pricing.netContractValue,
              currencyCode,
            )}
          />
        </Grid>
      </Grid>
    </Box>
  </>
);

const PayGoContractDetailSection: React.FunctionComponent<
  PayGoContractDetailsSectionProps
> = ({ pricing, acceptedAtDate }) => {
  const classes = useStyles();
  return (
    <OfferSection title="Contract details">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LabelAndValue
            label="Usage end date"
            value={formatDateFieldValue(pricing.serviceEndAt)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.value}>
            {`Est. usage duration: ${getUsageDuration(
              pricing,
              acceptedAtDate,
            )} days`}
          </Typography>
        </Grid>
      </Grid>
    </OfferSection>
  );
};

const getAmendmentOnAcceptanceBillingTermServiceLength = (
  acceptedAt: string,
  serviceEndAt: string,
) => {
  const acceptedDate = getUtcDateTimeFromSerializedIsoDateTime(acceptedAt);

  const serviceEndAtDate =
    getUtcDateTimeFromSerializedIsoDateTime(serviceEndAt);

  const monthsBetweenAcceptanceAndServiceEnd = getMonthsDifferenceFromDateTimes(
    acceptedDate,
    serviceEndAtDate,
  );

  return <>{monthsBetweenAcceptanceAndServiceEnd} months billing term</>;
};

const getAmendmentServiceLength = (
  pricing: Pricing,
  offerAcceptedAt: string,
  isFutureDatedOffer: boolean,
) =>
  isFutureDatedOffer ? (
    <>{pricing.durationValue} months billing term</>
  ) : !!offerAcceptedAt ? (
    getAmendmentOnAcceptanceBillingTermServiceLength(
      offerAcceptedAt,
      pricing.serviceEndAt,
    )
  ) : (
    <>Billing term calculated on acceptance</>
  );

const ProductAndPricingSection: React.FunctionComponent = () => {
  const classes = useStyles();
  const { productsByProductId } = useContext(OfferPageContext);

  const { offer, offerIsAmendment } = useContext(
    OfferContext,
  ) as OfferContextValues<AwsPrivateOffer>;

  const { agreement, loadingAgreementOffer } = useContext(AmendmentContext);
  const product = findMatchingProduct(offer?.productId, productsByProductId);
  const pricing = offer.pricing;
  const currencyCode = pricing.currencyCode as CurrencyCode;
  const paymentModel = pricing.paymentModel;
  const isUsingPaymentSchedule = paymentModel === PaymentModel.PaymentSchedule;
  const isPayGoOffer = pricing.paymentModel === PaymentModel.PayGo;
  const isFutureDatedOffer = pricing.billingTerm === BillingTerm.FutureDated;
  const schedules = pricing.schedule || [];
  const acceptedAtDate = offer.acceptedAt;
  const dimensionGridSize = isUsingPaymentSchedule ? 4 : 3;

  const totalPayments = schedules
    .reduce((a: number, b: Schedule) => a + toFinite(b.invoiceAmount), 0)
    .toFixed(2);

  return (
    <Card
      title="Product and pricing"
      icon={<CashMultiple />}
      bodyStyle={{ backgroundColor: '#FAFBFC' }}
    >
      {!!offer.createdInMarketplaceAt && (
        <Box mb={4}>
          <Banner
            title="This offer has been created in the Cloud marketplace and this section cannot be edited."
            borderPosition="top"
          />
        </Box>
      )}
      <Box mb={4} className={classes.productContainer}>
        <List>
          <ListItem>
            <ListItemIcon>
              <ProviderIcon provider={Marketplace.Aws} fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={product?.name} />
          </ListItem>
        </List>
      </Box>
      {offerIsAmendment && (
        <>
          <OfferSection title="Service details">
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <LabelAndValue
                  label="Previous service end date"
                  value={
                    loadingAgreementOffer ? (
                      <Skeleton />
                    ) : (
                      formatDateFieldValue(agreement?.endDate)
                    )
                  }
                />
              </Grid>
              {isFutureDatedOffer && (
                <Grid item md={4} xs={12}>
                  <LabelAndValue
                    label="Service start date"
                    value={formatDateFieldValue(pricing.serviceStartAt)}
                  />
                </Grid>
              )}
              <Grid item md={4} xs={12}>
                <LabelAndValue
                  label="New service end date"
                  value={formatDateFieldValue(pricing.serviceEndAt)}
                />
                <Typography className={classes.amendmentBillingTermText}>
                  {getAmendmentServiceLength(
                    pricing,
                    acceptedAtDate,
                    isFutureDatedOffer,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </OfferSection>
          <SectionDivider />
        </>
      )}
      <OfferSection title="Currency">
        <LabelAndValue
          label="Currency"
          value={getCurrencyResource(currencyCode)}
        />
      </OfferSection>
      <SectionDivider />
      {!isPayGoOffer && (
        <>
          <OfferSection title="Dimensions">
            {pricing.dimensions?.map((d) => (
              <Grid container spacing={2} key={d.apiName}>
                <Grid item md={dimensionGridSize} xs={12}>
                  <LabelAndValue label="Name" value={d.name} />
                </Grid>
                <Grid item md={dimensionGridSize} xs={12}>
                  <LabelAndValue label="API name" value={d.apiName} />
                </Grid>
                {!isUsingPaymentSchedule && (
                  <Grid item md={dimensionGridSize} xs={12}>
                    <LabelAndValue
                      label="Price"
                      value={formatValueWithCurrencyPrefix(
                        d.price,
                        currencyCode,
                      )}
                    />
                  </Grid>
                )}
                <Grid item md={dimensionGridSize} xs={12}>
                  <LabelAndValue
                    label="Quantity"
                    value={toFinite(d.quantity)}
                  />
                </Grid>
              </Grid>
            ))}
          </OfferSection>
          <SectionDivider />
        </>
      )}
      <OfferSection title="Usage dimensions">
        {pricing.usageDimensions?.map((ud) => (
          <Grid container spacing={2} key={ud.sku}>
            <Grid item md={4} xs={12}>
              <LabelAndValue label="SKU" value={ud.sku} />
            </Grid>
            <Grid item md={4} xs={12}>
              <LabelAndValue
                label="Fee amount"
                value={formatValueWithCurrencyPrefix(
                  toFinite(ud.price),
                  currencyCode,
                  usageDimensionPriceFormat,
                  usageDimensionPricePrecision,
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <LabelAndValue label="Description" value={ud.description} />
            </Grid>
          </Grid>
        ))}
      </OfferSection>
      <SectionDivider />
      {!isPayGoOffer && (
        <BillingTermBasedContractDetailsSection
          offerType={offer.offerType}
          pricing={pricing}
          currencyCode={currencyCode}
        />
      )}
      {isPayGoOffer && (
        <PayGoContractDetailSection
          pricing={pricing}
          acceptedAtDate={acceptedAtDate}
        />
      )}
      {isUsingPaymentSchedule && (
        <>
          <SectionDivider />
          <OfferSection title="Payment schedule">
            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>#</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Invoice date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Invoice amount</Typography>
                  </Box>
                </Grid>
                <Grid container className={classes.paymentsRowContainer}>
                  {schedules.map((s, i) => (
                    <Grid
                      container
                      key={`payment-${i}`}
                      className={classes.paymentRow}
                    >
                      <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-start">
                          {i + 1}
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        {formatDateFieldValueZeroPadded(s.invoiceDate)}
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-end">
                          {formatValueWithCurrencyPrefix(
                            s.invoiceAmount,
                            currencyCode,
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Box mb={2}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                Total payments:{' '}
                {formatValueWithCurrencyPrefix(totalPayments, currencyCode)}
              </Box>
            </Box>
          </OfferSection>
        </>
      )}
    </Card>
  );
};

export default ProductAndPricingSection;
