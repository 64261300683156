export enum FieldKey {
  Name = 'name',
  APIName = 'apiName',
  Price = 'price',
  Quantity = 'quantity',
}

export interface FormDimension {
  [FieldKey.Name]: string | null;
  [FieldKey.APIName]: string | null;
  [FieldKey.Price]: number | null;
  [FieldKey.Quantity]: number | null;
}

export const newDimension: FormDimension = {
  [FieldKey.Name]: '',
  [FieldKey.APIName]: '',
  [FieldKey.Quantity]: null,
  [FieldKey.Price]: null,
};
