// import coSellClient from 'packages/cosell/api/coSellClient';
import { createContext, useContext } from 'react';

interface AwsOpportunityContextProps {
  triggerSyncOpportunityById: (opportunityId: string) => Promise<any>;
}

export const AwsOpportunityActionsContext =
  createContext<AwsOpportunityContextProps>({
    triggerSyncOpportunityById: null,
  });

export interface OpportunityProviderProps {
  children?: React.ReactElement;
}

export const AwsOpportunityActionsProvider = ({
  children,
}: OpportunityProviderProps) => {
  // const authorizedCosellClient = coSellClient(token);

  async function triggerSyncOpportunityById(opportunityId) {
    console.log('triggerSyncOpportunityById: ' + opportunityId);
  }

  return (
    <AwsOpportunityActionsContext.Provider
      value={{
        triggerSyncOpportunityById,
      }}
    >
      {children}
    </AwsOpportunityActionsContext.Provider>
  );
};

export function useAwsOpportunityActions() {
  const context = useContext(AwsOpportunityActionsContext);
  if (context === undefined) {
    throw new Error(
      'useAwsOpportunityActions must be used within a AwsOpportunityActionsProvider',
    );
  }
  return context;
}
