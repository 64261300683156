import * as yup from '../../../../../../../../utils/yup-extended';
import {
  formSchema as buyersFormSchema,
  FormValues as BuyerFormValues,
} from '../../../../generic/OfferBuyers/formSchema';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKeys as NonUserInputFieldKey } from '../formTypes';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

export enum FieldKey {
  PartnerName = 'partnerName',
  PartnerAccountNumber = 'partnerAccountNumber',
  BuyerAccountNumber = 'buyerAccountNumber',
  CompanyName = 'companyName',
  OfferName = 'offerName',
  OfferDescription = 'offerDescription',
  Renewal = 'renewal',
  RenewalType = 'renewalType',
}

export enum RenewalType {
  NewAwsMarketplaceCustomer = 'new_aws_marketplace_customer',
  ExistingAwsMarketplaceCustomer = 'existing_aws_marketplace_customer',
}

export type FormValues = {
  [FieldKey.PartnerName]: string | null;
  [FieldKey.PartnerAccountNumber]: string;
  [FieldKey.BuyerAccountNumber]: string;
  [FieldKey.CompanyName]: string;
  [FieldKey.OfferName]: string | null;
  [FieldKey.OfferDescription]: string | null;
  [FieldKey.Renewal]: boolean;
  [FieldKey.RenewalType]: string | null;
} & BuyerFormValues;

const partnerNameSchema = yup.string().nullable().max(100).progress(false);

export const AwsAccountIDRegex = /^[0-9]{12}$/;

const partnerAccountNumberSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      NonUserInputFieldKey.OfferType,
      (ot: OfferType) => ot === OfferType.PartnerResale,
    ),
    then: (schema: yup.StringSchema) =>
      schema
        .matches(AwsAccountIDRegex, {
          message: 'Partner AWS account number must be 12 numbers',
          excludeEmptyString: true,
        })
        .progress(true),
    otherwise: (schema: yup.StringSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        NonUserInputFieldKey.OfferType,
      ],
      (cim: boolean, ot: OfferType) => cim && ot === OfferType.PartnerResale,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const buyerAccountNumberSchema = yup
  .string()
  .nullable()
  .matches(AwsAccountIDRegex, {
    message: 'Buyer AWS account number must be 12 numbers',
    excludeEmptyString: true,
  })
  .progress(true)
  .when('$', {
    is: isForYupExtended(
      NonUserInputFieldKey.CreateInMarketplace,
      (cim: boolean) => cim,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const companyNameSchema = yup.string().nullable().required().progress(true);
const offerNameDescription = yup.string().nullable().max(150).progress(false);
const offerDescriptionSchema = yup.string().nullable().max(255).progress(false);
const renewalSchema = yup.boolean().required().progress(true);

const renewalTypeSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(FieldKey.Renewal, (renewal: boolean) => renewal),
    then: (schema: yup.StringSchema) => schema.progress(true),
    otherwise: (schema: yup.StringSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      NonUserInputFieldKey.CreateInMarketplace,
      (cim: boolean) => cim,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

export const formSchema: yup.ObjectSchema<FormValues> = yup
  .object({
    [FieldKey.PartnerName]: partnerNameSchema,
    [FieldKey.PartnerAccountNumber]: partnerAccountNumberSchema,
    [FieldKey.BuyerAccountNumber]: buyerAccountNumberSchema,
    [FieldKey.CompanyName]: companyNameSchema,
    [FieldKey.OfferName]: offerNameDescription,
    [FieldKey.OfferDescription]: offerDescriptionSchema,
    [FieldKey.Renewal]: renewalSchema,
    [FieldKey.RenewalType]: renewalTypeSchema,
  })
  .concat(buyersFormSchema);
