import React, { useState } from 'react';
import { Typography, Box } from 'vendor/material';
import { Button, Modal } from '@tackle-io/platform-ui';
import tackleStandardLogo from 'components/TackleForSalesforce/Assets/tackle-standard-logo.svg';
import { useDisconnectButtonStyles } from './DisconnectButton.styles';

interface DisconnectButtonProps {
  onDisconnect: (closeModal: () => void) => Promise<void>;
}

export default function SalesforceDisconnectButton({
  onDisconnect,
}: DisconnectButtonProps) {
  const [openDisconnectPopup, setOpenDisconnectPopup] = useState(false);
  const classes = useDisconnectButtonStyles();

  return (
    <>
      <Button
        variant="text"
        className={classes.disconnectButton}
        onClick={() => setOpenDisconnectPopup(true)}
      >
        Disconnect from Tackle
      </Button>

      <Modal
        width="small"
        open={openDisconnectPopup}
        onClose={() => setOpenDisconnectPopup(false)}
      >
        <Box className={classes.popupContainer}>
          <img
            src={tackleStandardLogo}
            alt="Tackle Logo"
            className={classes.popupImage}
          />
          <Typography className={classes.popupTitle}>
            Disconnect from Tackle
          </Typography>
          <Typography className={classes.popupDescription}>
            This action will stop all syncing with Tackle.
          </Typography>
          <Box className={classes.popupActions}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.cancelButton}
              onClick={() => setOpenDisconnectPopup(false)}
            >
              Cancel
            </Button>

            <Button
              appearance="destructive"
              className={classes.confirmButton}
              onClick={async () => {
                await onDisconnect(() => setOpenDisconnectPopup(false));
              }}
            >
              Disconnect from Tackle
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
