import { FormSections, formSectionToTitle } from '../formSections';
import React, { useCallback, useContext } from 'react';
import { Card, InputRadio, Link } from '@tackle-io/platform-ui';
import { ResellerAgreementType } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import OfferDocumentUploader from 'pages/PrivateOffers/pages/Next/generic/OfferDocumentUploader/OfferDocumentUploader';
import { Field, useFormikContext } from 'formik';
import { FieldKey } from './formSchema';
import {
  createEventNameInjectingOnBlurHandler,
  createEventWrappingOnChangeHandler,
} from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import OfferFormSection from 'pages/PrivateOffers/pages/Next/generic/OfferFormSection/OfferFormSection';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import { RadioGroup } from 'vendor/material';
import { resellerAgreementContractUrl } from 'pages/PrivateOffers/pages/Next/aws/shared/urls';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { FormValues } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formSchema';
import EulaSvg from 'pages/PrivateOffers/pages/Next/assets/EulaSvg';

const documentTypeOptions = [
  { label: 'No thanks', value: ResellerAgreementType.NoneSelected },
  {
    label: 'Reseller contract from AWS Marketplace (RCMP)',
    value: ResellerAgreementType.Rcmp20211201,
    href: resellerAgreementContractUrl,
  },
  { label: 'Custom contract', value: ResellerAgreementType.Custom },
];

const ResellerAgreementFormSection = () => {
  const { offerIsMarketplaceEditable } = useContext(OfferContext);
  const { setFieldValue } = useFormikContext<FormValues>();

  const onChange = useCallback(async () => {
    await setFieldValue(FieldKey.ResellerAgreementFiles, []);
  }, [setFieldValue]);

  return (
    <div id={FormSections.ResellerAgreement}>
      <Card
        title={formSectionToTitle[FormSections.ResellerAgreement]}
        subtitle="Upload the reseller agreement for your partner. This will not be visible to the buyer."
        icon={<EulaSvg />}
      >
        <>
          <OfferFormSection title="Reseller agreement version">
            <Field name={FieldKey.ResellerAgreementType}>
              {({ field }) => (
                <RadioGroup
                  {...field}
                  data-id={DataId.ResellerAgreementTypeField}
                  onBlur={createEventNameInjectingOnBlurHandler(
                    field.name,
                    field.onBlur,
                  )}
                >
                  {documentTypeOptions.map((option) => (
                    <span key={option.value}>
                      <InputRadio
                        label={option.label}
                        value={option.value}
                        onClick={createEventWrappingOnChangeHandler(
                          field.name,
                          field.onChange,
                        )}
                        disabled={!offerIsMarketplaceEditable}
                      />
                      {option.href && (
                        <Link to={option.href} external showExternalIcon>
                          View
                        </Link>
                      )}
                    </span>
                  ))}
                </RadioGroup>
              )}
            </Field>
          </OfferFormSection>
          <OfferDocumentUploader
            fieldName={FieldKey.ResellerAgreementDocumentUrns}
            acceptedDocumentTypes=".pdf, .txt"
            uploadDocumentButtonText="Upload reseller agreement(s)"
            afterUpload={onChange}
            afterRemove={onChange}
            disabled={!offerIsMarketplaceEditable}
          />
        </>
      </Card>
    </div>
  );
};

export default ResellerAgreementFormSection;
