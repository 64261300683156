import { Tag, Button } from '@tackle-io/platform-ui';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './ActiveFilterChips.styles';
import { Close } from 'mdi-material-ui';
import { ActiveFilter } from '../FilterPanel/FilterPanel';
import { FILTER_TYPES } from '../../utilities/filters/constants';

interface ActiveFilterChipsProps {
  activeFilters: Array<ActiveFilter>;
  dataId?: string;
  onChange?: Function;
}

export const ActiveFilterChips: React.FC<ActiveFilterChipsProps> = ({
  activeFilters,
  dataId = '',
  onChange = () => {},
}) => {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const handleRemoveSelectedFilter = (filter: ActiveFilter) => {
    const searchParams = new URLSearchParams(location.search);

    if (filter.type === FILTER_TYPES.MULTI_SELECT) {
      const updatedValues = searchParams
        .getAll(filter.key)
        .filter((value) => value !== filter.value);
      searchParams.delete(filter.key);
      updatedValues.forEach((value) => searchParams.append(filter.key, value));
    } else {
      searchParams.delete(filter.key);
    }
    const correctedPath = `${location.pathname}?${searchParams.toString()}`;
    history.push(correctedPath);
    onChange();
  };
  const handleClearAllFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    activeFilters.forEach((filter) => {
      searchParams.delete(filter.key);
    });
    const correctedPath = `${location.pathname}?${searchParams.toString()}`;
    history.push(correctedPath);
    onChange();
  };

  const filterTags = activeFilters.map((filter) => {
    let chipText = null;
    switch (filter.type) {
      case FILTER_TYPES.BOOLEAN:
        chipText = `${filter.label}`;
        break;
      default:
        chipText = `${filter.label}: ${filter.title || filter.value}`;
    }
    return (
      <div
        key={`${filter.key}-${filter.value}`}
        onClick={() => handleRemoveSelectedFilter(filter)}
      >
        <Tag color="blue">
          <div className={classes.filterTag}>
            <span>{chipText}</span>
            <Close fontSize="inherit" />
          </div>
        </Tag>
      </div>
    );
  });
  if (filterTags.length < 1) return null;

  return (
    <div className={classes.activeFiltersContainer} data-id={dataId}>
      {filterTags}
      <Button
        size="small"
        variant="text"
        appearance="primary"
        onClick={handleClearAllFilters}
      >
        Clear All
      </Button>
    </div>
  );
};
