import { FormSections, formSectionToTitle } from '../formSections';
import { Card, Checkbox } from '@tackle-io/platform-ui';
import { Link } from 'mdi-material-ui';
import { Grid } from 'vendor/material';
import { Field, useFormikContext } from 'formik';
import { DataId } from '../../../../generic/analytics';
import { FieldKey } from './formSchema';
import { FormValues } from '../formSchema';
import React from 'react';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import TextField from 'components/FieldsPricing/TextField/TextField';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.OfferId]: 'Offer ID',
  [FieldKey.SourceOfferId]: 'Source Offer ID',
  [FieldKey.isAmended]: 'Are you amending a previous offer?',
};

const sourceOfferFieldHelperText =
  'If this is an amendment offer, enter the ID of the original offer here. This value must be unique between other offers';

const DirectOfferLinkOfferFormSection = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  return (
    <div id={FormSections.LinkOffer}>
      <Card
        title={formSectionToTitle[FormSections.LinkOffer]}
        subtitle="Enter the Offer ID to track the progress here in Tackle."
        icon={<Link />}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Field name={FieldKey.OfferId}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  label={`${fieldKeyToLabel[FieldKey.OfferId]} *`}
                  error={getFormattedError(
                    FieldKey.OfferId,
                    fieldKeyToLabel,
                    meta,
                  )}
                  helperText="Unique identifier assigned by the marketplace for each offer"
                  data-id={DataId.OfferIdField}
                  required
                />
              )}
            </Field>
          </Grid>
          <Grid item xs>
            <Field name={FieldKey.isAmended} type="checkbox">
              {({ field }) => {
                const handleChange = async (
                  e: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  field.onChange(e);

                  if (!Boolean(e.currentTarget.checked)) {
                    await setFieldValue(FieldKey.SourceOfferId, null);
                  }
                };

                return (
                  <Checkbox
                    {...field}
                    data-id={DataId.IsAmendedCheckBox}
                    label={fieldKeyToLabel[FieldKey.isAmended]}
                    onChange={handleChange}
                  />
                );
              }}
            </Field>
          </Grid>
          {!!values.isAmended && (
            <Grid item xs>
              <Field name={FieldKey.SourceOfferId}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label={fieldKeyToLabel[FieldKey.SourceOfferId]}
                    error={getFormattedError(
                      FieldKey.SourceOfferId,
                      fieldKeyToLabel,
                      meta,
                    )}
                    helperText={sourceOfferFieldHelperText}
                    data-id={DataId.SourceOfferIdField}
                  />
                )}
              </Field>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default DirectOfferLinkOfferFormSection;
