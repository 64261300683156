import { Button, Provider, ProviderIcon } from '@tackle-io/platform-ui';
import { ChevronLeft } from 'mdi-material-ui';
import { Box, makeStyles } from 'vendor/material';
import {
  CurrentComponent,
  ProspectComponentIdentifier,
} from './ProspectComponentSelector';

interface ExplainabilityProps {
  cloudMarketplace: Provider;
  score: string;
  companyName: string;
  navigateBack: (currentComponent: CurrentComponent) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '30px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  featureHeader: {
    padding: '4px 8px',
    borderRadius: '4px 0px 0px 0px',
    background: '#F4F5F7',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  featureDefinition: {
    padding: '0px 8px 0px 8px',
  },
}));

const formatCloudText = (cloudMarketplace: Provider): string => {
  switch (cloudMarketplace) {
    case 'aws':
      return 'AWS';
    case 'azure':
      return 'Microsoft';
    case 'microsoft':
      return 'Microsoft';
    case 'gcp':
      return 'Google';
    default:
      return cloudMarketplace;
  }
};

const Explainability: React.FC<ExplainabilityProps> = (
  props: ExplainabilityProps,
) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      gridGap={12}
    >
      <Box display="flex" gridGap={8}>
        <ProviderIcon provider={props.cloudMarketplace} />
        <span className={classes.header}>Prospect score</span>
      </Box>
      <Box>
        <span>
          Tackle's machine learning looks at over <b>150 factors</b> that
          influence an account's propensity to buy on a cloud marketplace. It
          found{' '}
          <b>
            {props.companyName} has a {props.score.toLocaleLowerCase()}{' '}
            propensity to buy on {formatCloudText(props.cloudMarketplace)}{' '}
            Marketplace
          </b>{' '}
          based on the following top factors.
        </span>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={4}>
        <span className={classes.featureHeader}>Product assortment</span>
        <span className={classes.featureDefinition}>
          The variety, types and quantity of cloud-based products currently
          utilized by the account.
        </span>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={4}>
        <span className={classes.featureHeader}>Cloud spend</span>
        <span className={classes.featureDefinition}>
          The anticipated budget allocation for investment in cloud
          infrastructure and related products.
        </span>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={4}>
        <span className={classes.featureHeader}>Business size</span>
        <span className={classes.featureDefinition}>
          Features indicating how large the company is, such as number of
          employees and social media followings.
        </span>
      </Box>
      <Box>
        <Button
          variant="outlined"
          appearance="primary"
          startIcon={<ChevronLeft />}
          onClick={() =>
            props.navigateBack({
              componentId: ProspectComponentIdentifier.DETAIL_AND_SCORING,
            })
          }
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default Explainability;
