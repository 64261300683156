import { Box, Divider, Grid, makeStyles } from 'vendor/material';
import AWSOfferListViewItem from './AWSOfferListViewItem';
import StartAWSOffer from './StartAWSOffer';
import { useContext, useEffect, useState } from 'react';
import { Button } from '@tackle-io/platform-ui';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { useRbac } from 'utils/rbac';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
}));

const AWSOffersForOpportunity = ({
  awsOffers,
}: {
  awsOffers: AwsPrivateOffer[];
}) => {
  const { hasPermission } = useRbac();
  const classes = useStyles();
  const {
    marketplaceAgnosticApi: { getProducts },
  } = useContext(ApiContext);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    getProducts(Marketplace.Aws).then(setProducts);
  }, [getProducts]);

  const [startingNewAWSOffer, setStartingNewAWSOffer] = useState(false);
  if (startingNewAWSOffer) {
    return <StartAWSOffer onCancel={() => setStartingNewAWSOffer(false)} />;
  }

  return (
    <Box height="100%" mt={1}>
      <Grid
        container
        direction="column"
        className={classes.container}
        spacing={2}
      >
        <Grid item xs className={classes.contents}>
          <Grid container direction="column" wrap="nowrap" spacing={2}>
            {awsOffers.map((awsOffer) => {
              return (
                <Grid item key={awsOffer.poId}>
                  <AWSOfferListViewItem
                    awsOffer={awsOffer}
                    products={products}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {hasPermission('offers:CreateDraftOffer') && (
          <>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                appearance="primary"
                onClick={() => setStartingNewAWSOffer(true)}
              >
                Add AWS offer
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AWSOffersForOpportunity;
