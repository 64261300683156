import { FormValues } from './formSchema';
import { FormBuyer } from '../../../generic/OfferBuyers/formSchema';
import { RenewalType } from './BasicInformationFormSection/formSchema';
import {
  BillingTerm,
  Buyer,
  Dimension,
  DurationType,
  EULA,
  EULAType,
  ExtraData,
  PartnerOffer,
  PaymentModel,
  Pricing,
  AwsPrivateOffer,
  ResellerAgreementType,
  Salesforce,
  Schedule,
  UsageDimension,
  UserToNotify,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { FormDimension } from './ProductAndPricingFormSection/DimensionsConfig/DimensionRow/formTypes';
import { FormSchedule } from './ProductAndPricingFormSection/ScheduleConfig/ScheduleRow/formTypes';
import { FormUsageDimension } from './ProductAndPricingFormSection/UsageDimensionsConfig/UsageDimensionRow/formSchema';
import { FormUserToNotify } from 'pages/PrivateOffers/pages/Next/generic/NotifyUsersSelector/formSchema';
import { Marketplace, OfferType } from '../../../generic/types/TackleOffer';

const pricingVersion = 'simple-pricing-v1-aws';

const isEmptyBuyer = (formValues: FormValues) => {
  const firstBuyer = formValues.buyers.at(0);

  return !firstBuyer.name && !firstBuyer.email && !firstBuyer.title;
};

const formBuyersToOfferBuyers = (buyers: FormBuyer[]): Buyer[] =>
  buyers.map(
    (b: FormBuyer): Buyer => ({
      emailAddress: b.email,
      fullName: b.name,
      title: b.title,
    }),
  );

const formDimensionsToOfferPricingDimensions = (
  dimensions: FormDimension[],
  paymentModel: PaymentModel,
): Dimension[] =>
  dimensions.map(
    (d: FormDimension): Dimension => ({
      apiName: d.apiName?.trim(),
      name: d.name?.trim(),
      price:
        paymentModel === PaymentModel.PerProduct ? d.price?.toString() : null,
      quantity: d.quantity?.toString(),
    }),
  );

const formSchedulesToOfferPricingSchedules = (
  schedules: FormSchedule[],
): Schedule[] =>
  schedules.map(
    (s: FormSchedule): Schedule => ({
      invoiceDateType: null,
      invoiceDate: s.invoiceDate,
      invoiceAmount: s.invoiceAmount?.toString(),
    }),
  );

const formUsageDimensionsToOfferPricingUsageDimensions = (
  usageDimensions: FormUsageDimension[],
): UsageDimension[] =>
  usageDimensions.map(
    (ud: FormUsageDimension): UsageDimension => ({
      description: ud.description,
      price: ud.price?.toString(),
      sku: ud.sku,
    }),
  );

const formUsersToNotifyToUsers = (
  usersToNotify: FormUserToNotify[],
): UserToNotify[] =>
  usersToNotify.map(
    (utn: FormUserToNotify): UserToNotify => ({ email: utn.email }),
  );

const getPayGoPricingConfig = (formValues: FormValues) => ({
  duration: null,
  durationType: null,
  serviceStartAt: null,
  serviceEndAt: formValues.serviceEndAt,
});

const getFutureDatedPricingConfig = (formValues: FormValues) => ({
  duration: null,
  durationType: DurationType.Months,
  serviceStartAt: formValues.serviceStartAt,
  serviceEndAt: formValues.serviceEndAt,
});

const getStartOnAcceptancePricingConfig = (
  formValues: FormValues,
  offerIsAmendment: boolean,
) => {
  const serviceStartAt =
    offerIsAmendment || formValues.offerType === OfferType.PartnerResale
      ? formValues.serviceStartAt
      : null;

  const serviceEndAt = offerIsAmendment ? formValues.serviceEndAt : null;

  return {
    duration: formValues.durationInCadence?.toString(),
    durationType: formValues.billingTermCadence as DurationType,
    serviceStartAt,
    serviceEndAt,
  };
};

const getBillingTermBasedPricingConfig = (formValues: FormValues) =>
  formValues.billingTerm === BillingTerm.FutureDated
    ? getFutureDatedPricingConfig
    : getStartOnAcceptancePricingConfig;

const getPricingConfig = (
  formValues: FormValues,
  offerIsAmendment: boolean,
) => {
  const pricingConfigProducer =
    formValues.paymentModel === PaymentModel.PayGo
      ? getPayGoPricingConfig
      : getBillingTermBasedPricingConfig(formValues);

  return pricingConfigProducer(formValues, offerIsAmendment);
};

const getPricing = (
  formValues: FormValues,
  offerIsAmendment: boolean,
): Pricing => {
  const paymentModel = formValues.paymentModel as PaymentModel;

  const billingTerm =
    paymentModel === PaymentModel.PayGo
      ? BillingTerm.Custom
      : (formValues.billingTerm as BillingTerm);

  const durationValue =
    paymentModel === PaymentModel.PayGo ? null : formValues.durationValue;

  const dimensions =
    paymentModel === PaymentModel.PayGo
      ? []
      : formDimensionsToOfferPricingDimensions(
          formValues.dimensions,
          paymentModel,
        );

  const usageDimensions = formUsageDimensionsToOfferPricingUsageDimensions(
    formValues.usageDimensions,
  );

  const schedule =
    paymentModel === PaymentModel.PaymentSchedule
      ? formSchedulesToOfferPricingSchedules(formValues.schedules)
      : [];

  return {
    version: pricingVersion,
    paymentModel: paymentModel as PaymentModel,
    billingTerm,
    ...getPricingConfig(formValues, offerIsAmendment),
    durationValue,
    currencyCode: formValues.currencyCode,
    dimensions,
    usageDimensions,
    schedule,
    showOnEmail: true,
    marketplaceFee: formValues.marketplaceFee.toString(),
  };
};

const getEULA = (formValues: FormValues): EULA => {
  const type = formValues.eulaType as EULAType;
  const documentUrns = formValues.eulaDocumentUrns;
  const files = formValues.eulaFiles;

  return { type, documentUrns, files };
};

const getExtraData = (formValues: FormValues): ExtraData => {
  const users = formUsersToNotifyToUsers(formValues.usersToNotify);

  return { users };
};

const getOfferMetadata = (formValues: FormValues): { [key: string]: any } =>
  formValues.offerMetadataFields
    .filter((item) => Boolean(item.key))
    .reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );

const getPreRegistrationDetails = (formValues: FormValues) =>
  formValues.registrationPageFields.reduce(
    (acc, field) => ({
      ...acc,
      [field.displayTitle]: field.fieldValue,
    }),
    {},
  );

const getSalesforce = (formValues: FormValues): Salesforce | null =>
  formValues.opportunityId ? { opportunityId: formValues.opportunityId } : null;

const getPartnerOffer = (formValues: FormValues): PartnerOffer | null =>
  formValues.offerType === OfferType.PartnerResale
    ? {
        partnerName: formValues.partnerName,
        partnerRef: formValues.partnerAccountNumber,
        resellerAgreement: {
          type: formValues.resellerAgreementType as ResellerAgreementType,
          documentUrns: formValues.resellerAgreementDocumentUrns,
          files: formValues.resellerAgreementFiles,
        },
        partnerOfferRef: formValues.sellingAuthorizationId,
      }
    : null;

export const formValuesToPrivateOffer = (
  formValues: FormValues,
  offerIsAmendment: boolean = false,
): Partial<AwsPrivateOffer> => {
  const buyers =
    formValues.buyers.length === 1 && isEmptyBuyer(formValues)
      ? []
      : formBuyersToOfferBuyers(formValues.buyers);

  const awsRenewalMovingToMarketplace =
    formValues.renewalType === RenewalType.NewAwsMarketplaceCustomer;

  return {
    marketplace: formValues.cloud as Marketplace,
    offerType: formValues.offerType as OfferType,
    sourceOfferId: formValues.sourceOfferId || null,
    buyerBillingAccountRef: formValues.buyerAccountNumber,
    buyerCompanyName: formValues.companyName,
    buyers,
    offerName: formValues.offerName,
    offerDescription: formValues.offerDescription,
    renewal: formValues.renewal,
    awsRenewalMovingToMarketplace,
    productId: formValues.productRef,
    ...(formValues.productRef
      ? { pricing: getPricing(formValues, offerIsAmendment) }
      : {}),
    eula: getEULA(formValues),
    offerExpirationAt: formValues.offerAcceptanceDeadline,
    extraData: getExtraData(formValues),
    enableZeroDollarPrices: true,
    offerMetadata: getOfferMetadata(formValues),
    preRegistrationDetails: getPreRegistrationDetails(formValues),
    salesforce: getSalesforce(formValues),
    partnerOffer: getPartnerOffer(formValues),
    offerId: formValues.offerId || null,
  };
};
