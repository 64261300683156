import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Loader } from '@tackle-io/platform-ui';
import ErrorComponent from 'pages/Error/Error';
import { Box } from 'vendor/material';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import { Product } from '../api/types/Product';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferPageContext, { OfferPageContextValues } from './offerPageContext';
import { OfferRunFrom } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerRunFrom';
import { ApiContextProvider } from '../ApiContext/ApiContextProvider';
import { Offer } from '../types/Offer';
import { Marketplace } from '../types/TackleOffer';

interface OfferPageContextProviderProps<O extends Offer> {
  marketplace: Marketplace;
  mode: OfferPageMode;
  runFrom: OfferRunFrom;
  accessTokenProvider?: () => Promise<string>;
  afterSubmit?: (offer: O) => void;
  onEdit?: (offer: O) => void;
  onAmend?: (offerId: string, offer: O | null) => void;
  onCancel?: (offer: O | null) => void;
  onOpenOffer?: (Offer: O) => void;
  afterArchive?: (offer: O) => void;
}

type APIValues = Pick<
  OfferPageContextValues<Offer>,
  'user' | 'users' | 'productsByProductId'
>;

const ApiWrappedOfferPageContextProvider: React.FunctionComponent<
  Omit<OfferPageContextProviderProps<Offer>, 'accessTokenProvider'>
> = ({
  marketplace,
  mode,
  runFrom,
  afterSubmit,
  onEdit,
  onAmend,
  onCancel,
  onOpenOffer,
  children,
  afterArchive,
}) => {
  const {
    marketplaceAgnosticApi: { getUser, getAuth0Users, getProducts },
    hasSynced,
  } = useContext(ApiContext);

  const [apiValues, setApiValues] = useState<APIValues>({
    user: null,
    users: [],
    productsByProductId: {},
  });

  useEffect(() => {
    (async () => {
      const user = await getUser();
      const users = await getAuth0Users();
      const products = await getProducts(marketplace);

      const productsByProductId = products.reduce(
        (acc: { [pId: string]: Product }, p: Product) => ({
          ...acc,
          [p.productid]: p,
        }),
        {},
      );

      setApiValues((previousValues) => ({
        ...previousValues,
        user,
        users,
        productsByProductId,
      }));
    })();

    return () => {
      setApiValues({
        user: null,
        users: [],
        productsByProductId: {},
      });
    };
  }, [getUser, getAuth0Users, getProducts, marketplace]);

  const memoizedAfterSubmit = useCallback(
    (o: Offer) => afterSubmit(o),
    [afterSubmit],
  );

  const memoizedOnEdit = useCallback((o: Offer) => onEdit(o), [onEdit]);

  const memoizedOnAmend = useCallback(
    (oId: string, o: Offer | null) => onAmend(oId, o),
    [onAmend],
  );

  const memoizedOnCancel = useCallback(
    (o: Offer | null) => onCancel(o),
    [onCancel],
  );

  const memoizedOnOpenOffer = useCallback(
    (o: Offer) => onOpenOffer(o),
    [onOpenOffer],
  );

  const memoizedAfterArchived = useCallback(
    (o: Offer) => afterArchive(o),
    [afterArchive],
  );

  const userSynced = hasSynced(OfferAPIKey.User);
  const usersSynced = hasSynced(OfferAPIKey.Users);
  const productsSynced = hasSynced(OfferAPIKey.Products);
  const apiValuesHaveSynced = userSynced && usersSynced && productsSynced;

  const contextValues: OfferPageContextValues<Offer> = {
    ...apiValues,
    marketplace,
    mode,
    runFrom,
    afterSubmit: memoizedAfterSubmit,
    onEdit: memoizedOnEdit,
    onAmend: memoizedOnAmend,
    onCancel: memoizedOnCancel,
    onOpenOffer: memoizedOnOpenOffer,
    afterArchive: memoizedAfterArchived,
  };

  return !apiValuesHaveSynced ? (
    <Loader />
  ) : Object.keys(contextValues.productsByProductId).length === 0 ? (
    <ErrorComponent>
      <Box mb={2}>No listings available for private offer creation</Box>
      <Button
        appearance="primary"
        onClick={() => {
          onCancel(null);
        }}
      >
        Back
      </Button>
    </ErrorComponent>
  ) : (
    <OfferPageContext.Provider value={contextValues}>
      {children}
    </OfferPageContext.Provider>
  );
};

export const OfferPageContextProvider: React.FunctionComponent<
  OfferPageContextProviderProps<Offer>
> = ({
  accessTokenProvider,
  marketplace,
  mode,
  runFrom,
  afterSubmit,
  onEdit,
  onAmend,
  onCancel,
  onOpenOffer,
  children,
  afterArchive,
}) => (
  <ApiContextProvider
    marketplace={marketplace}
    accessTokenProvider={accessTokenProvider}
  >
    <ApiWrappedOfferPageContextProvider
      marketplace={marketplace}
      mode={mode}
      runFrom={runFrom}
      afterSubmit={afterSubmit}
      onEdit={onEdit}
      onAmend={onAmend}
      onCancel={onCancel}
      onOpenOffer={onOpenOffer}
      afterArchive={afterArchive}
    >
      {children}
    </ApiWrappedOfferPageContextProvider>
  </ApiContextProvider>
);
