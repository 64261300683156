import React, { useContext, useEffect, useState } from 'react';
import { SalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMapping';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { Loader } from '@tackle-io/platform-ui';
import SalesforceDataMappingContext, {
  SalesforceDataMappingContextValues,
} from './salesforceDataMappingContext';
import { Marketplace, OfferType } from '../types/TackleOffer';

interface SalesforceDataMappingContextProviderProps {
  offerType: OfferType;
  opportunityId?: string;
  mappingId?: string;
  children: React.ReactNode;
}

const SalesforceDataMappingContextProvider: React.FunctionComponent<
  SalesforceDataMappingContextProviderProps
> = ({ offerType, opportunityId, mappingId, children }) => {
  const [salesforceDataMapping, setSalesforceDataMapping] =
    useState<SalesforceDataMapping>(null);

  const [loadingSalesforceDataMapping, setLoadingSalesforceDataMapping] =
    useState(!!opportunityId);

  const {
    marketplaceAgnosticApi: { getSalesforceDataMapping },
  } = useContext(ApiContext);

  useEffect(() => {
    if (!opportunityId) {
      return;
    }

    (async () => {
      const mapping = await getSalesforceDataMapping(
        Marketplace.Aws,
        offerType,
        opportunityId,
        mappingId,
      );

      setSalesforceDataMapping(mapping);
      setLoadingSalesforceDataMapping(false);
    })();
  }, [offerType, opportunityId, mappingId, getSalesforceDataMapping]);

  const contextValues: SalesforceDataMappingContextValues = {
    opportunityId,
    mappingId,
    salesforceDataMapping,
  };

  return loadingSalesforceDataMapping ? (
    <Loader />
  ) : (
    <SalesforceDataMappingContext.Provider value={contextValues}>
      {children}
    </SalesforceDataMappingContext.Provider>
  );
};

export default SalesforceDataMappingContextProvider;
