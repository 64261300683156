import React from 'react';
import { FormSections, formSectionToTitle } from '../formSections';
import { Card } from '@tackle-io/platform-ui';
import { Link } from 'mdi-material-ui';
import { FieldKey } from './formSchema';
import { Grid } from 'vendor/material';
import { Field } from 'formik';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import TextField from 'components/FieldsPricing/TextField/TextField';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.SellingAuthorizationId]: 'Selling authorization ID',
  [FieldKey.OfferId]: 'Partner offer ID',
};

const PartnerOfferLinkOfferFormSection = () => {
  return (
    <div id={FormSections.LinkOffer}>
      <Card
        title={formSectionToTitle[FormSections.LinkOffer]}
        subtitle="Enter the CPPO ID to track the progress here in Tackle."
        icon={<Link />}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Field name={FieldKey.SellingAuthorizationId}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  label={`${
                    fieldKeyToLabel[FieldKey.SellingAuthorizationId]
                  } *`}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  helperText="This is from the seller authorization you created in the AWS Marketplace."
                  data-id={DataId.SellingAuthorizationIdField}
                  required
                />
              )}
            </Field>
          </Grid>
          <Grid item xs>
            <Field name={FieldKey.OfferId}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  label={fieldKeyToLabel[FieldKey.OfferId]}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  helperText="Optional: This ID is generated by the partner when they create the offer."
                  data-id={DataId.SourceOfferIdField}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default PartnerOfferLinkOfferFormSection;
