import { Box, Grid, makeStyles } from 'vendor/material';
import { ScoreTag } from './ScoreTag';
import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import { ProspectScoreCrmAccountQuery } from 'generated/graphql';
import { ChevronRight } from 'mdi-material-ui';
import {
  CurrentComponent,
  ProspectComponentIdentifier,
} from './ProspectComponentSelector';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px 16px 16px 16px',
    margin: '0px',
  },
  demographicsContainer: {},
  demographicsItem: {
    padding: '8px 8px 0px 0px',
  },
  demographicLabel: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  demographicValue: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  scoresHeader: {
    padding: '4px 8px',
    gap: '8px',
    borderRadius: '4px',
    background: '#F4F5F7',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  scoreColumnHeader: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  cloudScore: {
    marginTop: '4px',
    padding: '12px 0px 12px 0px',
    borderBottom: '1px solid #F4F5F7',
  },
  cloudScoreLabel: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#091E42',
  },
}));

export interface ProspectDetailsAndScoringProps {
  crmAccountScore: ProspectScoreCrmAccountQuery['prospectScoreCrmAccount'];
  scoreDetailClick: (component: CurrentComponent) => void;
}

const ProspectDetailsAndScoring: React.FC<ProspectDetailsAndScoringProps> = ({
  crmAccountScore,
  scoreDetailClick,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      spacing={2}
    >
      <Grid item container className={classes.demographicsContainer}>
        <Grid item xs={6} className={classes.demographicsItem}>
          <Box display="flex" flexDirection="column">
            <span className={classes.demographicLabel}>Domain</span>
            <span>
              {crmAccountScore?.user_entered_domain || crmAccountScore?.domain}
            </span>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.demographicsItem}>
          <Box display="flex" flexDirection="column">
            <span className={classes.demographicLabel}>Industry</span>
            <span>{crmAccountScore?.primary_industry}</span>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.demographicsItem}>
          <Box display="flex" flexDirection="column">
            <span className={classes.demographicLabel}>Region</span>
            <span>{crmAccountScore?.region}</span>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.demographicsItem}>
          <Box display="flex" flexDirection="column">
            <span className={classes.demographicLabel}>Size</span>
            <span>{crmAccountScore?.revenue_band}</span>
          </Box>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid
          item
          xs={12}
          className={classes.scoresHeader}
          alignItems="stretch"
          container
        >
          <span>Scores by marketplace</span>
        </Grid>
        <Grid item container>
          <Grid item xs={4} className={classes.scoreColumnHeader}>
            <span>Cloud</span>
          </Grid>
          <Grid item xs={8} className={classes.scoreColumnHeader}>
            <span>Score</span>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cloudScore}
            alignContent="center"
            container
          >
            <Box display={'flex'} gridGap={4}>
              <ProviderIcon fontSize={'small'} provider={'aws'} />
              <span className={classes.cloudScoreLabel}>AWS</span>
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.cloudScore}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ScoreTag score={crmAccountScore?.score_aws} />
              <Button
                variant="text"
                appearance="secondary"
                startIcon={<ChevronRight />}
                onClick={() =>
                  scoreDetailClick({
                    componentId: ProspectComponentIdentifier.EXPLAINABILITY,
                    componentProps: {
                      cloudMarketplace: 'aws',
                      score: crmAccountScore?.score_aws,
                      companyName: crmAccountScore?.company_name,
                    },
                  })
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cloudScore}
            alignContent="center"
            container
          >
            <Box display={'flex'} gridGap={4}>
              <ProviderIcon fontSize={'small'} provider={'gcp'} />
              <span className={classes.cloudScoreLabel}>Google</span>
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.cloudScore}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ScoreTag score={crmAccountScore?.score_gcp} />
              <Button
                variant="text"
                appearance="secondary"
                startIcon={<ChevronRight />}
                onClick={() =>
                  scoreDetailClick({
                    componentId: ProspectComponentIdentifier.EXPLAINABILITY,
                    componentProps: {
                      cloudMarketplace: 'gcp',
                      score: crmAccountScore?.score_gcp,
                      companyName: crmAccountScore?.company_name,
                    },
                  })
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cloudScore}
            alignContent="center"
            container
          >
            <Box display={'flex'} gridGap={4}>
              <ProviderIcon fontSize={'small'} provider="microsoft" />
              <span className={classes.cloudScoreLabel}>Microsoft</span>
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.cloudScore}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ScoreTag score={crmAccountScore?.score_azure} />
              <Button
                variant="text"
                appearance="secondary"
                startIcon={<ChevronRight />}
                onClick={() =>
                  scoreDetailClick({
                    componentId: ProspectComponentIdentifier.EXPLAINABILITY,
                    componentProps: {
                      cloudMarketplace: 'microsoft',
                      score: crmAccountScore?.score_azure,
                      companyName: crmAccountScore?.company_name,
                    },
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProspectDetailsAndScoring;
