import { FieldKey } from './formTypes';
import { FormValues } from './formSchema';
import { FieldKey as ProductFieldKey } from '../../../../generic/ProductSelector/formSchema';
import { CurrencyCode } from 'utils/currency';
import { toFinite } from 'lodash';
import {
  FormDimension,
  newDimension,
} from './DimensionsConfig/DimensionRow/formTypes';
import { FormUsageDimension } from '../../../../../../utils/formatFormData';
import {
  FormSchedule,
  newSchedule,
} from './ScheduleConfig/ScheduleRow/formTypes';
import {
  AwsPrivateOffer,
  BillingTerm,
  Dimension,
  DurationType,
  PaymentModel,
  Schedule,
  UsageDimension,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Pricing } from 'utils/pricingTypes';
import { getSchedulesAfterToday } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ProductAndPricingFormSection/utils/scheduleUtils';

const toFormSchedule = ({ invoiceDate, invoiceAmount }): FormSchedule => ({
  invoiceDateType: null,
  invoiceDate: invoiceDate,
  invoiceAmount: invoiceAmount ? toFinite(invoiceAmount) : null,
});

export const schedulesToFormSchedules = (
  schedules: Schedule[],
): FormSchedule[] => schedules.map(toFormSchedule);

export const dimensionsToFormDimensions = (
  dimensions: Dimension[],
): FormDimension[] =>
  dimensions.map(({ name, apiName, price, quantity }) => ({
    name,
    apiName,
    price: price ? toFinite(price) : null,
    quantity: quantity ? toFinite(quantity) : null,
  }));

export const usageDimensionsToFormUsageDimensions = (
  usageDimensions: UsageDimension[],
): FormUsageDimension[] =>
  usageDimensions.map(({ sku, description, price }) => ({
    sku,
    description,
    price: price ? toFinite(price) : null,
  }));

export const initialFormValuesForNewOffer: FormValues = {
  version: Pricing.SimplePricingV1Aws,
  [ProductFieldKey.ProductRef]: null,
  [FieldKey.PaymentModel]: PaymentModel.PaymentSchedule,
  [FieldKey.BillingTerm]: BillingTerm.Custom,
  [FieldKey.DurationValue]: 0,
  [FieldKey.BillingTermCadence]: DurationType.Months,
  [FieldKey.DurationInCadence]: null,
  [FieldKey.ServiceStartAt]: null,
  [FieldKey.ServiceEndAt]: null,
  [FieldKey.CurrencyCode]: CurrencyCode.UnitedStatesDollar,
  [FieldKey.Dimensions]: [newDimension],
  [FieldKey.UsageDimensions]: [],
  [FieldKey.Schedules]: [newSchedule],
};

const getInitialPayGoPricingConfigFormValues = (offer: AwsPrivateOffer) => {
  const { pricing } = offer;

  return {
    [FieldKey.BillingTerm]: null,
    [FieldKey.DurationValue]: pricing.durationValue,
    [FieldKey.BillingTermCadence]: null,
    [FieldKey.DurationInCadence]: null,
    [FieldKey.ServiceStartAt]: null,
    [FieldKey.ServiceEndAt]: pricing.serviceEndAt,
  };
};

const getInitialFutureDatedPricingConfigFormValues = (
  offer: AwsPrivateOffer,
) => {
  const { pricing } = offer;

  return {
    [FieldKey.BillingTermCadence]: null,
    [FieldKey.DurationInCadence]: null,
    [FieldKey.ServiceStartAt]: pricing.serviceStartAt,
    [FieldKey.ServiceEndAt]: pricing.serviceEndAt,
  };
};

const getInitialStartOnAcceptanceConfigFormValues = (
  offer: AwsPrivateOffer,
) => {
  const { pricing } = offer;

  const durationInCadence =
    pricing.duration && pricing.duration !== '0'
      ? toFinite(pricing.duration)
      : null;

  return {
    [FieldKey.BillingTermCadence]: pricing.durationType,
    [FieldKey.DurationInCadence]: durationInCadence,
    [FieldKey.ServiceStartAt]: pricing.serviceStartAt,
    [FieldKey.ServiceEndAt]: pricing.serviceEndAt,
  };
};

const getInitialBillingTermBasedPricingConfigFormValues = (
  offer: AwsPrivateOffer,
) => {
  const { pricing } = offer;

  const pricingConfigProducer =
    pricing.billingTerm === BillingTerm.FutureDated
      ? getInitialFutureDatedPricingConfigFormValues
      : getInitialStartOnAcceptanceConfigFormValues;

  return {
    [FieldKey.BillingTerm]: pricing.billingTerm,
    [FieldKey.DurationValue]: pricing.durationValue,
    ...pricingConfigProducer(offer),
  };
};

const getInitialPricingConfigFormValues = (offer: AwsPrivateOffer) => {
  const { pricing } = offer;

  const pricingConfigProducer =
    pricing.paymentModel === PaymentModel.PayGo
      ? getInitialPayGoPricingConfigFormValues
      : getInitialBillingTermBasedPricingConfigFormValues;

  return pricingConfigProducer(offer);
};

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  const { productId, pricing } = offer;

  const schedules =
    pricing.paymentModel === PaymentModel.PaymentSchedule
      ? pricing.schedule.length === 0
        ? [newSchedule]
        : schedulesToFormSchedules(pricing.schedule)
      : [newSchedule];

  const dimensions =
    pricing.paymentModel === PaymentModel.PayGo
      ? [newDimension]
      : dimensionsToFormDimensions(pricing.dimensions as Dimension[]);

  const usageDimensions = usageDimensionsToFormUsageDimensions(
    pricing.usageDimensions,
  );

  return {
    version: pricing.version,
    [ProductFieldKey.ProductRef]: productId,
    [FieldKey.PaymentModel]: pricing.paymentModel,
    ...getInitialPricingConfigFormValues(offer),
    [FieldKey.CurrencyCode]: pricing.currencyCode,
    [FieldKey.Dimensions]: dimensions,
    [FieldKey.UsageDimensions]: usageDimensions,
    [FieldKey.Schedules]: schedules,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  const {
    pricing: { schedule },
  } = offer;

  const schedules = getSchedulesAfterToday(schedule).map(toFormSchedule);

  return {
    ...initialFormValuesForExistingOffer(offer),
    [FieldKey.PaymentModel]: PaymentModel.PaymentSchedule,
    [FieldKey.Schedules]: schedules,
  };
};
