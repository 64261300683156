import {
  ApolloClient,
  createHttpLink,
  split,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { reportError } from './monitor';
import { getOrRenewAccessToken } from 'packages/salesforce-canvas/api/oneTimeKeyAuth';
import { setContext } from '@apollo/client/link/context';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      const status = extensions?.response?.status || 0;
      // don't bother reporting 4XX errors to sentry
      if (status >= 500) {
        reportError(
          new Error(
            `[GraphQL error]: ${operation.operationName} Message: ${message}, Path: ${path}`,
          ),
          {},
          [message, ...path.map(String)],
        );
      }
    });
  }
  if (networkError) reportError(networkError);
});

const retryLink = new RetryLink();
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_URL}/graphql`,
  fetchOptions: { mode: 'cors' },
});

const batchHttpLink = new BatchHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_URL}/graphql`,
  fetchOptions: { mode: 'cors' },
  batchMax: 50, // opt in only so this can be pretty large
  batchKey: (operation) => operation.getContext().batchKey,
});

const otkAuthLink = setContext(async (_operation, { headers }) => {
  const token = await getOrRenewAccessToken();
  if (!token) return { headers };

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const getApolloClient = (authLink): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: from([
      authLink,
      otkAuthLink,
      errorLink,
      retryLink,
      split(
        // only batch if the operation specifies a batchKey
        (operation) => operation.getContext().batchKey,
        batchHttpLink,
        httpLink,
      ),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        AwsRegistration: { merge: true },
        AwsRegistrationForm: { merge: true },
        AwsRegistrationFormButton: { merge: true },
        AwsRegistrationHero: { merge: true },
        AwsRegistrationSuccess: { merge: true },
        AwsStatus: { merge: true },
        AzureRegistration: { merge: true },
        AzureRegistrationForm: { merge: true },
        AzureRegistrationFormButton: { merge: true },
        AzureRegistrationHero: { merge: true },
        AzureRegistrationSuccess: { merge: true },
        CounterResponse: { keyFields: false },
        GcpRegistration: { merge: true },
        GcpRegistrationForm: { merge: true },
        GcpRegistrationFormButton: { merge: true },
        GcpRegistrationHero: { merge: true },
        GcpRegistrationSuccess: { merge: true },
        LeaderboardResponse: { keyFields: false },
        MarketplaceRevenueTimeSeries: { keyFields: false },
        Metric: { keyFields: false },
        RedHatRegistration: { merge: true },
        RedHatRegistrationForm: { merge: true },
        RedHatRegistrationFormButton: { merge: true },
        RedHatRegistrationHero: { merge: true },
        RedHatRegistrationSuccess: { merge: true },
        TimeSeriesResponse: { keyFields: false },
        VendorAwsAssumeRoleConfig: { merge: true },
        VendorAwsConfig: { merge: true },
        VendorAwsOnboarding: { merge: true },
        VendorAzureConfig: { merge: true },
        VendorAzureOnboarding: { merge: true },
        VendorCasConfig: { merge: true },
        VendorConfiguration: { merge: true },
        VendorConfigurationStatuses: { merge: true },
        VendorDataFeedConfig: { merge: true },
        VendorDataFeedSqsQueueConfig: { merge: true },
        VendorFeatureFlags: { merge: true },
        VendorGcpConfig: { merge: true },
        VendorGcpOnboarding: { merge: true },
        VendorOnboarding: { merge: true },
        VendorRedHatConfig: { merge: true },
        VendorReportingHealth: { merge: true },
        VendorSqsQueueConfig: { merge: true },
        VendorStatus: { merge: true },
      },
    }),
    defaultOptions: { query: { errorPolicy: 'all' } },
  });

export default getApolloClient;
