import classnames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import { Typography } from 'vendor/material';
import { HeaderColumn } from '../../types';
import {
  defaultOpportunityColumnsObj,
  OpportunitiesTableColumnNames,
} from '../../utils';
import SortHeader from '../SortHeader/SortHeader';
import useStyles from './TableHeader.styles';
import { OpportunityColumnKey } from '../../../../helpers/urlParamsHelper';
import useOpportunitiesListQuery from 'packages/cosell/src/hooks/useOpportunitiesListQuery';

interface TableHeaderProps {
  tableColumns: HeaderColumn[];
  setColumnWidths: Dispatch<SetStateAction<Object>>;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  tableColumns,
  setColumnWidths,
}) => {
  const classes = useStyles();
  const { sortState } = useOpportunitiesListQuery();
  // Gets the widths of the column headers and passes that value to the
  // TableRowCell in order to maintain consistency
  const getColumnWidths = useCallback((): void => {
    let columnObj = { ...defaultOpportunityColumnsObj };

    for (let c in headerRowRef?.current?.children) {
      const name = headerRowRef?.current?.children[c].id;
      const width = headerRowRef?.current?.children[c].clientWidth;
      columnObj[name] = width;
    }
    setColumnWidths(columnObj);
  }, [setColumnWidths]);

  useLayoutEffect(() => {
    getColumnWidths();
  }, [getColumnWidths]);

  const headerRowRef = useRef<HTMLDivElement>(null);
  const flexGrowSmallColumns = [
    OpportunitiesTableColumnNames.COSELL_STAGE,
    OpportunitiesTableColumnNames.COSELL_STATUS,
    OpportunitiesTableColumnNames.DATE_CREATED,
  ];
  const flexGrowMediumColumns = [OpportunitiesTableColumnNames.CUSTOMER];
  const flexFromLargeColumns = [OpportunitiesTableColumnNames.PROJECT_NAME];

  const shouldAlignRight = (
    columnName: OpportunitiesTableColumnNames,
  ): boolean => {
    const rightAlignmentColumns = [OpportunitiesTableColumnNames.SYNC_STATUS];
    return rightAlignmentColumns.includes(columnName);
  };

  return (
    <div className={classes.container} role={'row'} ref={headerRowRef}>
      {tableColumns.map((column) => {
        return (
          <div
            key={column.field}
            className={classnames(classes.headerColumnWrapper, {
              [classes.flexGrowCheckbox]:
                column.field === OpportunitiesTableColumnNames.CHECKBOX,
              [classes.fixedSyncStatus]:
                column.field === OpportunitiesTableColumnNames.SYNC_STATUS,
              [classes.flexGrowSm]: flexGrowSmallColumns.includes(column.field),
              [classes.flexGrowMd]: flexGrowMediumColumns.includes(
                column.field,
              ),
              [classes.flexGrowLg]: flexFromLargeColumns.includes(column.field),
              [classes.alignRight]: shouldAlignRight(column.field),
            })}
            id={column.field}
            role={'columnheader'}
            tabIndex={0}
          >
            <SortHeader
              columnKey={OpportunityColumnKey[column.field]}
              sortState={sortState}
            >
              <Typography className={classes.headerText}>
                {column.headerName}
              </Typography>
            </SortHeader>
          </div>
        );
      })}
    </div>
  );
};
