import {
  FieldKey,
  FormValues,
} from '../../../../generic/AdditionalFieldsFormSection/formSchema';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { additionalFieldKeysToOmit } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/additionalFieldUtils';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.MarketplaceFee]: '0',
  [FieldKey.OfferMetadataFields]: [],
};

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
  product: Product,
): FormValues => {
  if (!product) {
    return initialFormValuesForNewOffer;
  }

  const { contractStandardFields = [] } = product;
  const standardFieldTitles = new Set(
    contractStandardFields.map((f) => f.title),
  );

  const offerMetadataFields = Object.entries(offer?.offerMetadata || {})
    .filter(([key]) => !additionalFieldKeysToOmit.has(key))
    .map(([key, value]) => ({
      [FieldKey.OfferMetadataFieldLabel]: key,
      [FieldKey.OfferMetadataFieldValue]: value,
      [FieldKey.IsDisabled]: standardFieldTitles.has(key),
    }));

  return {
    [FieldKey.MarketplaceFee]: offer?.pricing?.marketplaceFee || '0',
    [FieldKey.OfferMetadataFields]: offerMetadataFields,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
  product: Product,
): FormValues => {
  return { ...initialFormValuesForExistingOffer(offer, product) };
};

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
  product: Product,
): FormValues => {
  const { contractStandardFields = [] } = product;

  const standardFieldTitles = new Set(
    contractStandardFields.map((f) => f.title),
  );

  const { offerMetadataFields: initialOfferMetadataFields, ...rest } =
    initialFormValuesForExistingOffer(offer, product);

  const offerMetadataFields = initialOfferMetadataFields.map((field) => {
    const value = standardFieldTitles.has(field.key) ? '' : field.value;
    return { ...field, value };
  });

  return { offerMetadataFields, ...rest };
};
