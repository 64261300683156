import { Box, Drawer, Grid, makeStyles, Typography } from 'vendor/material';
import { Banner, Button, Select } from '@tackle-io/platform-ui';
import TextField from '../../../../../../../../../../components/FieldsPricing/TextField/TextField';
import DateSelector from '../../../../../../generic/DateSelector/DateSelector';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  FieldKey,
  formSchema,
  FormValues,
  PaymentFrequency,
} from './formSchema';
import { getFormattedError } from '../../../../../../generic/utils/field/fieldUtils';
import PaymentSchedulePreview from './PaymentSchedulePreview';
import { CurrencyCode, currencySymbolByCurrencyCode } from 'utils/currency';
import { FormSchedule } from '../ScheduleRow/formTypes';
import { generatePaymentSchedule } from './utils/utils';
import {
  DataId,
  OffersProductArea,
  PageLocation,
} from '../../../../../../generic/analytics';
import { ampli } from 'utils/analytics/ampli/index';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

interface CreatePaymentScheduleProps {
  onPaymentSchedulerClosed: () => void;
  onPaymentScheduleCreated: (s: FormSchedule[]) => void;
  currencyCode: CurrencyCode;
  durationValue: number | null;
}

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),
    width: '33vw',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    padding: theme.spacing(2),
  },
}));

const fieldKeyToLabel = {
  [FieldKey.TotalContractValue]: 'Total contract value',
  [FieldKey.DurationInMonths]: 'Duration in months',
  [FieldKey.PaymentFrequency]: 'Payment frequency',
  [FieldKey.DateOfFirstPayment]: 'Date of first payment',
};

const paymentFrequencyToLabel = {
  [PaymentFrequency.Monthly]: 'Monthly',
  [PaymentFrequency.Annually]: 'Annually',
  [PaymentFrequency.Quarterly]: 'Quarterly',
  [PaymentFrequency.SemiAnnually]: 'Semi-annually',
};

const getInitialValues = (durationInMonths: number | null): FormValues => ({
  [FieldKey.TotalContractValue]: 0,
  [FieldKey.DurationInMonths]: durationInMonths,
  [FieldKey.PaymentFrequency]: PaymentFrequency.Monthly,
  [FieldKey.DateOfFirstPayment]: null,
});

const CreatePaymentSchedule: React.FunctionComponent<
  CreatePaymentScheduleProps
> = ({
  onPaymentSchedulerClosed,
  onPaymentScheduleCreated,
  currencyCode,
  durationValue,
}) => {
  const classes = useStyles();
  const [showPreview, setShowPreview] = useState(false);
  const currencySymbol = currencySymbolByCurrencyCode[currencyCode];

  const onSubmit = (v: FormValues) => {
    const schedule = generatePaymentSchedule(v);

    onPaymentScheduleCreated(schedule);
  };

  const { values, handleChange, handleBlur, getFieldMeta, errors, submitForm } =
    useFormik<FormValues>({
      initialValues: getInitialValues(durationValue),
      validationSchema: formSchema,
      validateOnMount: true,
      onSubmit,
    });

  const disablePreviewAndCreateButtons = Object.keys(errors).length > 0;

  const onBackClicked = () => {
    ampli.buttonClicked(
      {
        button_product_area: OffersProductArea,
        button_location: PageLocation.OfferEditPage,
        button_name: DataId.PaymentScheduleBackButton,
      },
      { extra: { marketplace: Marketplace.Aws } },
    );

    setShowPreview(false);
  };

  const onPreviewClicked = () => {
    ampli.buttonClicked(
      {
        button_product_area: OffersProductArea,
        button_location: PageLocation.OfferEditPage,
        button_name: DataId.PaymentSchedulePreviewButton,
      },
      { extra: { marketplace: Marketplace.Aws } },
    );

    setShowPreview(true);
  };

  const onCreateClicked = async () => {
    ampli.buttonClicked(
      {
        button_product_area: OffersProductArea,
        button_location: PageLocation.OfferEditPage,
        button_name: DataId.PaymentScheduleCreateButton,
      },
      { extra: { marketplace: Marketplace.Aws } },
    );

    await submitForm();
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={true}
      onClose={onPaymentSchedulerClosed}
    >
      <Box className={classes.mainContent}>
        <Box mb={2}>
          <Typography variant="h6">Create payment schedule</Typography>
        </Box>
        {showPreview && (
          <PaymentSchedulePreview
            schedules={generatePaymentSchedule(values)}
            currencyCode={currencyCode}
          />
        )}
        {!showPreview && (
          <>
            <Box mb={2}>
              <Banner
                type="warning"
                title="Any existing payments will be replaced with the new payment schedule."
              />
            </Box>
            <Box mb={2}>
              <Box mb={2}>
                <TextField
                  data-id={DataId.PaymentScheduleTotalContractValueField}
                  name={FieldKey.TotalContractValue}
                  label={`${fieldKeyToLabel[FieldKey.TotalContractValue]} *`}
                  type="number"
                  mode="numberformat"
                  fixedDecimalScale
                  decimalScale={2}
                  helperText="Total contract value of all the payments in the schedule"
                  value={values.totalContractValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  numberPrefix={currencySymbol}
                  error={getFormattedError(
                    FieldKey.TotalContractValue,
                    fieldKeyToLabel,
                    getFieldMeta(FieldKey.TotalContractValue),
                  )}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  data-id={DataId.PaymentScheduleDurationInMonthsField}
                  name={FieldKey.DurationInMonths}
                  label={`${fieldKeyToLabel[FieldKey.DurationInMonths]} *`}
                  type="number"
                  helperText="Length of contract in months"
                  value={values.durationInMonths}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getFormattedError(
                    FieldKey.DurationInMonths,
                    fieldKeyToLabel,
                    getFieldMeta(FieldKey.DurationInMonths),
                  )}
                  readOnly
                />
              </Box>
              <Box mb={2}>
                <Select
                  data-id={DataId.PaymentSchedulePaymentFrequencyField}
                  name={FieldKey.PaymentFrequency}
                  label={`${fieldKeyToLabel[FieldKey.PaymentFrequency]} *`}
                  defaultValue={values.paymentFrequency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getFormattedError(
                    FieldKey.PaymentFrequency,
                    fieldKeyToLabel,
                    getFieldMeta(FieldKey.PaymentFrequency),
                  )}
                >
                  {Object.values(PaymentFrequency).map((pf) => (
                    <option key={pf} id={pf} value={pf}>
                      {paymentFrequencyToLabel[pf]}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box mb={2}>
                <DateSelector
                  data-id={DataId.PaymentScheduleFirstPaymentDateField}
                  name={FieldKey.DateOfFirstPayment}
                  label={`${fieldKeyToLabel[FieldKey.DateOfFirstPayment]} *`}
                  value={values.dateOfFirstPayment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getFormattedError(
                    FieldKey.DateOfFirstPayment,
                    fieldKeyToLabel,
                    getFieldMeta(FieldKey.DateOfFirstPayment),
                  )}
                />
              </Box>
            </Box>
          </>
        )}
        <Box className={classes.footer}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={onPaymentSchedulerClosed}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              {showPreview && (
                <Button
                  data-id={DataId.PaymentScheduleBackButton}
                  color="secondary"
                  variant="outlined"
                  onClick={onBackClicked}
                >
                  Back
                </Button>
              )}
              {!showPreview && (
                <Button
                  data-id={DataId.PaymentSchedulePreviewButton}
                  color="secondary"
                  variant="outlined"
                  onClick={onPreviewClicked}
                  disabled={disablePreviewAndCreateButtons}
                >
                  Preview
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                data-id={DataId.PaymentScheduleCreateButton}
                appearance="primary"
                onClick={onCreateClicked}
                disabled={disablePreviewAndCreateButtons}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CreatePaymentSchedule;
