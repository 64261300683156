import * as yup from 'utils/yup-extended';
import { FieldKey, FormDimension } from './formTypes';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { PaymentModel } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { FieldKey as ProductSelectorFieldKeys } from 'pages/PrivateOffers/pages/Next/generic/ProductSelector/formSchema';
import { FieldKeys as NonUserInputFieldKey } from '../../../formTypes';
import { duplicateApiNamesTest } from './schemaTests';

const nameSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [
        ProductSelectorFieldKeys.ProductRef,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (pr: string | null, pm: PaymentModel) => {
        return !!pr && pm !== PaymentModel.PayGo;
      },
    ),
    then: (schema: yup.StringSchema) => schema.max(80).progress(true),
    otherwise: (schema: yup.StringSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (cim: boolean, pm: PaymentModel) => cim && pm !== PaymentModel.PayGo,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const invalidApiNameMessage =
  'Api name must start with a letter and may contain up to 36 letters, numbers and underscores.';

const apiNameSchema = yup
  .string()
  .nullable()
  .max(36)
  .matches(/^[a-zA-Z][_a-zA-Z\d]{0,35}$/, {
    message: invalidApiNameMessage,
    excludeEmptyString: true,
  });

const priceSchema = yup
  .number()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [
        ProductSelectorFieldKeys.ProductRef,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (pr: string | null, pm: PaymentModel) =>
        !!pr && pm === PaymentModel.PerProduct,
    ),
    then: (schema: yup.NumberSchema) => schema.min(0).progress(true),
    otherwise: (schema: yup.NumberSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (cim: boolean, pm: PaymentModel) => cim && pm === PaymentModel.PerProduct,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const quantitySchema = yup
  .number()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      ProductSelectorFieldKeys.ProductRef,
      (pr: string | null) => !!pr,
    ),
    then: (schema: yup.NumberSchema) => schema.min(1).progress(true),
    otherwise: (schema: yup.NumberSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (cim: boolean, pm: PaymentModel) => cim && pm !== PaymentModel.PayGo,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

export const dimensionSchema = yup.object().shape({
  [FieldKey.Name]: nameSchema,
  [FieldKey.APIName]: apiNameSchema,
  [FieldKey.Price]: priceSchema,
  [FieldKey.Quantity]: quantitySchema,
});

export const dimensionsSchema = yup.array<FormDimension>().when('$', {
  is: isForYupExtended(
    ProductSelectorFieldKeys.ProductRef,
    (pr: string) => !!pr,
  ),
  then: (schema: yup.ArraySchema<FormDimension>) =>
    schema
      .when('$', {
        is: isForYupExtended(
          [
            NonUserInputFieldKey.CreateInMarketplace,
            ProductAndPricingFieldKey.Dimensions,
            ProductAndPricingFieldKey.PaymentModel,
          ],
          (cim: boolean, d: FormDimension[], pm: PaymentModel) =>
            cim && d.length === 0 && pm !== PaymentModel.PayGo,
        ),
        then: (schema: yup.ArraySchema<FormDimension>) =>
          schema.min(1, 'At least 1 dimension is required'),
        otherwise: (schema: yup.ArraySchema<FormDimension>) =>
          schema.when('$', {
            is: isForYupExtended(
              ProductAndPricingFieldKey.PaymentModel,
              (pm: PaymentModel) => pm !== PaymentModel.PayGo,
            ),
            then: (schema: yup.ArraySchema<FormDimension>) =>
              schema
                .of(dimensionSchema)
                .test('Duplicate api names', '', duplicateApiNamesTest),
          }),
      })
      .progress(true),
});
