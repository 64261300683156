import {
  AbsolutePrice,
  Activity,
  Buyer,
  Dimension,
  EULA,
  ExtraData,
  PartnerOffer,
  Pricing,
  AwsPrivateOffer,
  Salesforce,
  Schedule,
  Unit,
  UsageDimension,
  UserToNotify,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import {
  AbsolutePriceJSON,
  ActivityJSON,
  BuyerJSON,
  DimensionJSON,
  EULAJSON,
  ExtraDataJSON,
  PartnerOfferJSON,
  PricingJSON,
  AwsPrivateOfferJSON,
  SalesforceJSON,
  ScheduleJSON,
  UnitJSON,
  UsageDimensionJSON,
  UserToNotifyJSON,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOfferJSON';
import { Optional } from 'utils/optional/optional';
import { formatIsoDateTimeAsIsoDate } from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';

const activitiesToActivityJSONs = (activities: Activity[]): ActivityJSON[] =>
  activities.map((a) => ({
    activity_type: a.activityType,
    created_at: a.createdAt,
    metadata: a.metadata,
    slug: a.slug,
    user_id: a.userId,
  }));

const buyersToBuyerJSONs = (buyers: Buyer[]): BuyerJSON[] =>
  buyers.map((b) => ({
    email_address: b.emailAddress,
    full_name: b.fullName,
    title: b.title,
  }));

const toEulaJSON = (eula: EULA | null): EULAJSON => {
  return {
    document_urns: eula?.documentUrns || [],
    type: eula?.type,
  };
};

const usersToNotifyToUsersToNotifyJSONs = (
  usersToNotify: UserToNotify[],
): UserToNotifyJSON[] =>
  usersToNotify.map((u) => ({
    email: u.email,
  }));

const toExtraDataJSON = (extraData: ExtraData | null): ExtraDataJSON | null => {
  return Optional.ofNullable(extraData)
    .map((ed) => ({
      users: usersToNotifyToUsersToNotifyJSONs(ed.users || []),
    }))
    .orElse(null);
};

const toPartnerOfferJSON = (
  partnerOffer: PartnerOffer | null,
): PartnerOfferJSON | null => {
  return Optional.ofNullable(partnerOffer)
    .map((po) => ({
      partner_name: po.partnerName,
      partner_offer_ref: po.partnerOfferRef,
      partner_ref: po.partnerRef,
      reseller_agreement: Optional.ofNullable(partnerOffer.resellerAgreement)
        .map((ra) => ({
          document_urns: ra.documentUrns,
          type: ra.type,
        }))
        .orElse(null),
    }))
    .orElse(null);
};

const absolutePricesToAbsolutePricesJSON = (
  absolutePrices: AbsolutePrice[],
): AbsolutePriceJSON[] =>
  absolutePrices.map((ap) => ({
    billing_term: {
      type: ap.billingTerm.type,
      value: ap.billingTerm.value,
    },
    payment_option: {
      type: ap.paymentOption.type,
      value: ap.paymentOption.value,
    },
  }));

const dimensionsToDimensionJSONs = (dimensions: Dimension[]): DimensionJSON[] =>
  dimensions.map((d) => ({
    absolute_prices: absolutePricesToAbsolutePricesJSON(d.absolutePrices || []),
    api_name: d.apiName,
    description: d.description,
    dimension_value: d.dimensionValue,
    discount_percentage: d.discountPercentage,
    name: d.name,
    price: d.price,
    quantity: d.quantity,
  }));

const schedulesToScheduleJSONs = (schedules: Schedule[]): ScheduleJSON[] =>
  schedules.map((s) => ({
    invoice_amount: s.invoiceAmount,
    invoice_date: formatIsoDateTimeAsIsoDate(s.invoiceDate),
    invoice_date_type: s.invoiceDateType,
  }));

const unitsToUnitJSONs = (units: Unit[]): UnitJSON[] =>
  units.map((u) => ({
    duration_price: u.durationPrice,
    hourly_price: u.hourlyPrice,
    unit: u.unit,
  }));

const usageDimensionsToUsageDimensionJSONs = (
  usageDimensions: UsageDimension[],
): UsageDimensionJSON[] =>
  usageDimensions.map((u) => ({
    description: u.description,
    price: u.price,
    sku: u.sku,
  }));

const toPricingJSON = (pricing: Pricing | null): PricingJSON | null => {
  return Optional.ofNullable(pricing)
    .map((p) => ({
      allow_auto_renew: p.allowAutoRenew,
      billing_term: p.billingTerm,
      currency_code: p.currencyCode,
      dimensions: dimensionsToDimensionJSONs(p.dimensions || []),
      duration: p.duration,
      duration_type: p.durationType,
      duration_value: p.durationValue,
      marketplace_fee: p.marketplaceFee,
      monthly_contract_value: p.monthlyContractValue,
      net_contract_value: p.netContractValue,
      payment_model: p.paymentModel,
      schedule: schedulesToScheduleJSONs(p.schedule || []),
      service_end_at: formatIsoDateTimeAsIsoDate(p.serviceEndAt),
      service_start_at: formatIsoDateTimeAsIsoDate(p.serviceStartAt),
      show_on_email: p.showOnEmail,
      total_contract_value: p.totalContractValue,
      units: unitsToUnitJSONs(p.units || []),
      usage_dimensions: usageDimensionsToUsageDimensionJSONs(
        p.usageDimensions || [],
      ),
      version: p.version,
    }))
    .orElse(null);
};

const toSalesforceJSON = (eula: Salesforce | null): SalesforceJSON | null =>
  Optional.ofNullable(eula)
    .map((s) => ({
      custom_object_id: s.customObjectId,
      opportunity_id: s.opportunityId,
    }))
    .orElse(null);

export const awsPrivateOfferToAwsPrivateOfferJSON = (
  offer: AwsPrivateOffer,
): AwsPrivateOfferJSON => ({
  accepted_at: offer.acceptedAt,
  activities: activitiesToActivityJSONs(offer.activities || []),
  archived_at: offer.archivedAt,
  aws_renewal_moving_to_marketplace: offer.awsRenewalMovingToMarketplace,
  buyer_billing_account_ref: offer.buyerBillingAccountRef,
  buyer_company_name: offer.buyerCompanyName,
  buyers: buyersToBuyerJSONs(offer.buyers || []),
  cancelled_at: offer.cancelledAt,
  cloud_contract_id: offer.cloudContractId,
  created_at: offer.createdAt,
  created_in_marketplace_at: offer.createdInMarketplaceAt,
  enable_zero_dollar_prices: offer.enableZeroDollarPrices,
  eula: toEulaJSON(offer.eula),
  extra_data: toExtraDataJSON(offer.extraData),
  last_modified_at: offer.lastModifiedAt,
  marketplace: offer.marketplace,
  offer_description: offer.offerDescription,
  offer_end_at: offer.offerEndAt,
  offer_expiration_at: formatIsoDateTimeAsIsoDate(offer.offerExpirationAt),
  offer_metadata: offer.offerMetadata,
  offer_name: offer.offerName,
  offer_point_of_contact: offer.offerPointOfContact,
  offer_point_of_contact_name: offer.offerPointOfContactName,
  offer_start_at: offer.offerStartAt,
  offer_type: offer.offerType,
  offerid: offer.offerId,
  opened_instructions_at: offer.openedInstructionsAt,
  partner_offer: toPartnerOfferJSON(offer.partnerOffer),
  po_id: offer.poId,
  pre_registration_details: offer.preRegistrationDetails,
  pricing: toPricingJSON(offer.pricing),
  productid: offer.productId,
  renewal: offer.renewal,
  salesforce: toSalesforceJSON(offer.salesforce),
  salesforce_id: offer.salesforceId,
  sent_at: offer.sentAt,
  source_offer_id: offer.sourceOfferId,
  stage: offer.stage,
  status: offer.status,
  vendor_id: offer.vendorId,
  viewed_offer_at: offer.viewedOfferAt,
});
