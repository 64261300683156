import { Grid } from 'vendor/material';
import React, { useContext } from 'react';
import { FieldKey } from './formSchema';
import { Field } from 'formik';
import TextField from '../../../../../../../../../../components/FieldsPricing/TextField/TextField';
import { getFormattedError } from '../../../../../../generic/utils/field/fieldUtils';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { DataId } from '../../../../../../generic/analytics';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

interface UsageDimensionRowProps {
  index: number;
}

const UsageDimensionRow: React.FunctionComponent<UsageDimensionRowProps> = ({
  index,
}) => {
  const { offerIsMarketplaceEditable } = useContext(OfferContext);
  const usageDimensionPath = `${ProductAndPricingFieldKey.UsageDimensions}[${index}]`;
  const skuFieldName = `${usageDimensionPath}.${FieldKey.Sku}`;
  const descriptionFieldName = `${usageDimensionPath}.${FieldKey.Description}`;
  const feeFieldName = `${usageDimensionPath}.${FieldKey.Price}`;

  const fieldKeyToLabel = {
    [skuFieldName]: 'SKU',
    [descriptionFieldName]: 'Description',
    [feeFieldName]: 'Fee amount',
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={12} xs={12}>
        <Field name={skuFieldName}>
          {({ field, meta }) => (
            <TextField
              {...field}
              data-id={`${DataId.UsageDimensionSkuField}-${index}`}
              label={fieldKeyToLabel[field.name]}
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled
            />
          )}
        </Field>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Field name={feeFieldName}>
          {({ field, meta }) => (
            <TextField
              {...field}
              data-id={`${DataId.UsageDimensionFeeField}-${index}`}
              label={fieldKeyToLabel[field.name]}
              type="number"
              mode="numberformat"
              fixedDecimalScale
              decimalScale={8}
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled={!offerIsMarketplaceEditable}
            />
          )}
        </Field>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Field name={descriptionFieldName}>
          {({ field, meta }) => (
            <TextField
              {...field}
              data-id={`${DataId.UsageDimensionDescriptionField}-${index}`}
              label={fieldKeyToLabel[field.name]}
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

export default UsageDimensionRow;
