import { ExpectedCustomerSpend } from 'packages/cosell/src/types/AceOpportunity.type';
import {
  AceInvolvementTypeEnum,
  AceInvolvementVisibilityEnum,
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
} from 'packages/cosell/src/types/enums';
import { MarketingSourceEnum } from 'packages/cosell/src/types/enums/MarketingSourceEnum';

// This type is only used for Save draft or Submit to cloud
export type CreateAceOpportunityFormValues = {
  nextStep?: string;
  projectTitle: string;
  customerBusinessProblem: string;
  expectedCustomerSpendAmount: ExpectedCustomerSpend[number]['amount'];
  expectedCustomerSpendCurrencyCode: ExpectedCustomerSpend[number]['currencyCode'];
  /** Monthly is currently the only option */
  expectedCustomerSpendFrequency: ExpectedCustomerSpend[number]['frequency'];
  /** AWS is currently the only option */
  expectedCustomerSpendTargetCompany: ExpectedCustomerSpend[number]['targetCompany'];
  targetCloseDate: string;
  involvementType?: AceInvolvementTypeEnum;
  visibility?: AceInvolvementVisibilityEnum;
  opportunityType: string;
  primaryContactFirstName?: string;
  primaryContactLastName?: string;
  primaryContactEmail?: string;
  primaryContactPhone?: string;
  primaryContactTitle?: string;
  customerCompanyName: string | undefined;
  customerWebsite: string | undefined;
  countryCode: string | undefined;
  duns?: string;
  state: string | undefined;
  city?: string;
  address?: string;
  postalCode: string | undefined;
  industry: string | undefined;
  industryOther?: string;
  nationalSecurity?: string | null;
  parentOppId?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerEmail?: string;
  customerPhone?: string;
  customerTitle?: string;
  marketingSource: MarketingSourceEnum;
  competitiveTracking?: string;
  otherCompetitorNames?: string;
  campaignName?: string;
  awsAccountId?: string;
  awsProducts?: string[];
  marketingActivityUseCases?: string[];
  marketingActivityChannel?: string[];
  isMarketingDevelopmentFunded: string;
  deliveryModels: string[];
  customerUseCase: string;
  apnPrograms?: string[];
  salesActivities?: string[];
  /** This is only used for UI display toggle, no matching api data */
  solutionsOfferedToggle?: SolutionsOfferedToggleEnum;
  solutions: string[] | null;
  otherSolutionDescription?: string | null;
  /** This is a UI-only field designed to support the primaryNeedsFromAws logic. */
  partnerNeedType?: string;
  primaryNeedsFromAws?: string[];
  additionalComments?: string;
  status?: AceOpportunityReviewStatusEnum;
  /** optional hidden fields to pass through to Co-sell API
   *  The data-mapper claim check for attribute syncing
   */
  tklMetadata?: string;
};

export enum SolutionsOfferedToggleEnum {
  EXISTING_SOLUTIONS = 'existingSolutions',
  OTHER_SOLUTION = 'otherSolution',
}

export interface UpdateAceOpportunityFormValues
  extends CreateAceOpportunityFormValues {
  stage?: AceOpportunityStageEnum;
  closedLostReason?: string;
  reviewComments?: string;
  marketplaceOfferId?: string;
  /** The following fields are for launch request.
   * currently, both SRRP and non-SRRP opportunities are using the same fields
   */
  deliveryModel?: string;
  effectiveDate?: string;
  expirationDate?: string;
  currencyCode?: string;
  customerSoftwareValue?: string;
}
