import * as yup from 'utils/yup-extended';
import { ResellerAgreementType } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKeys as NonUserInputFieldKey } from '../formTypes';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

export enum FieldKey {
  ResellerAgreementType = 'resellerAgreementType',
  ResellerAgreementDocumentUrns = 'resellerAgreementDocumentUrns',
  ResellerAgreementFiles = 'resellerAgreementFiles',
}

export type FormValues = {
  [FieldKey.ResellerAgreementType]: string | null;
  [FieldKey.ResellerAgreementDocumentUrns]: string[];
  [FieldKey.ResellerAgreementFiles]: string[];
};

const documentLengthErrorMessage =
  'A single document is required with a mx of 5.';

const resellerAgreementTypeSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      NonUserInputFieldKey.OfferType,
      (ot: OfferType) => ot === OfferType.PartnerResale,
    ),
    then: (schema: yup.StringSchema) =>
      schema
        .oneOf(Object.values(ResellerAgreementType))
        .required()
        .progress(true),
    otherwise: (schema: yup.StringSchema) => schema.progress(false),
  });

const resellerDocumentUrnsSchema = yup
  .array<string>()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [NonUserInputFieldKey.OfferType, FieldKey.ResellerAgreementType],
      (ot: OfferType, rat: ResellerAgreementType) =>
        ot === OfferType.PartnerResale && rat === ResellerAgreementType.Custom,
    ),
    then: (schema: yup.ArraySchema<string>) =>
      schema
        .min(1, documentLengthErrorMessage)
        .max(5, documentLengthErrorMessage)
        .required()
        .progress(true),
    otherwise: (schema: yup.ArraySchema<string>) =>
      schema.max(5, 'Maximum of 5 documents allowed.').progress(false),
  });

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.ResellerAgreementType]: resellerAgreementTypeSchema,
  [FieldKey.ResellerAgreementDocumentUrns]: resellerDocumentUrnsSchema,
  [FieldKey.ResellerAgreementFiles]: yup
    .array<string>()
    .nullable()
    .progress(false),
});
