import React, { useContext, useMemo, useState } from 'react';
import EditOfferPage from '../../../generic/EditOfferPage/EditOfferPage';
import { Form, Formik } from 'formik';
import BasicInformationFormSection from './BasicInformationFormSection/BasicInformationFormSection';
import { formSchema, FormValues } from './formSchema';
import { Box, Grid } from 'vendor/material';
import QuickLinks from './QuickLinks/QuickLinks';
import EndUserLicenseAgreementFormSection from './EndUserLicenseAgreementForSection/EndUserLicenseAgreementFormSection';
import ProductAndPricingFormSection from './ProductAndPricingFormSection/ProductAndPricingFormSection';
import OfferValidityFormSection from './OfferValidityPeriodFormSection/OfferValidityFormSection';
import NotifyUsersFormSection from 'pages/PrivateOffers/pages/Next/generic/NotifyUsersFormSection/NotifyUsersFormSection';
import RegistrationDetailsFormSection from 'pages/PrivateOffers/pages/Next/generic/RegistrationDetailsFormSection/RegistrationDetailsFormSection';
import AdditionalFieldsFormSection from 'pages/PrivateOffers/pages/Next/generic/AdditionalFieldsFormSection/AdditionalFieldsFormSection';
import CreatedInMarketplaceBanner from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/CreatedInMarketplaceBanner/CreatedInMarketplaceBanner';
import ResellerAgreementFormSection from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ResllerAgreementForSection/ResellerAgreementFormSection';
import OfferContext from '../../../generic/OfferContext/offerContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import LinkOfferFormSection from './LinkOfferFormSection/LinkOfferFormSection';
import OfferPageContext from '../../../generic/OfferPageContext/offerPageContext';
import { FieldKeys } from './formTypes';
import { OfferType } from '../../../generic/types/TackleOffer';
import { FormSections, formSectionToTitle } from './formSections';

interface EditFormProps {
  initialValues: FormValues;
  onSubmit: (fv: FormValues) => void;
  dataMappingErrors?: { [k: string]: string };
}

const EditForm: React.FunctionComponent<EditFormProps> = ({
  initialValues,
  onSubmit,
  dataMappingErrors = {},
}) => {
  const [createInMarketplace, setCreateInMarketplace] = useState(false);
  const { mode } = useContext(OfferPageContext);
  const validationSchema = useMemo(() => formSchema(mode), [mode]);
  const { tackleOfferForMode: offer } = useContext(OfferContext);
  const createdInMarketplaceAt = offer?.createdInMarketplaceAt;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, errors, submitForm, setFieldValue }) => {
        const onSaveDraft = async () => {
          setCreateInMarketplace(false);
          await setFieldValue(FieldKeys.CreateInMarketplace, false);
          await submitForm();
        };

        const onSubmitToCloud = async () => {
          setCreateInMarketplace(true);
          await setFieldValue(FieldKeys.CreateInMarketplace, true);
          await submitForm();
        };

        return (
          <Form>
            <EditOfferPage
              sidePanel={<QuickLinks formValues={values} formErrors={errors} />}
              dataMappingErrors={dataMappingErrors}
              initialFormValues={initialValues}
              formValues={values}
              onSaveDraft={onSaveDraft}
              onSubmitToCloud={onSubmitToCloud}
              createInMarketplace={createInMarketplace}
              createdInMarketplaceBanner={
                !!createdInMarketplaceAt ? <CreatedInMarketplaceBanner /> : null
              }
            >
              <Grid container>
                <Grid item md={12}>
                  {mode === OfferPageMode.Associate && (
                    <Box mb={2}>
                      <LinkOfferFormSection />
                    </Box>
                  )}
                  <Box mb={2}>
                    <BasicInformationFormSection />
                  </Box>
                  <Box mb={2}>
                    <ProductAndPricingFormSection />
                  </Box>
                  <Box mb={2}>
                    <EndUserLicenseAgreementFormSection />
                  </Box>
                  {values.offerType === OfferType.PartnerResale && (
                    <Box mb={2}>
                      <ResellerAgreementFormSection />
                    </Box>
                  )}
                  {values.productRef && (
                    <Box mb={2}>
                      <RegistrationDetailsFormSection
                        sectionId={FormSections.RegistrationDetails}
                        sectionTitle={
                          formSectionToTitle[FormSections.RegistrationDetails]
                        }
                      />
                    </Box>
                  )}
                  {values.productRef && (
                    <Box mb={2}>
                      <AdditionalFieldsFormSection
                        sectionId={FormSections.AdditionalFields}
                        sectionTitle={
                          formSectionToTitle[FormSections.AdditionalFields]
                        }
                      />
                    </Box>
                  )}
                  <Box mb={2}>
                    <OfferValidityFormSection />
                  </Box>
                  <Box mb={2}>
                    <NotifyUsersFormSection
                      sectionId={FormSections.NotifyUsers}
                      sectionTitle={
                        formSectionToTitle[FormSections.NotifyUsers]
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </EditOfferPage>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditForm;
