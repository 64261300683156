import React from 'react';
import { Grid, Typography, Box, Button } from 'vendor/material';
import { Tag, Spinner } from '@tackle-io/platform-ui';
import { useSalesforceTableStyles } from './ConnectionDetailsTable.style';
import integration from './Assets/Integrations.svg';
import openInNew from './Assets/open_in_new.svg';

interface SalesforceConnectionTableProps {
  data: {
    instanceUrl: string;
    organization: {
      name: string;
      isSandbox: boolean;
    };
    user: {
      email: string;
    };
    status: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  isConnected?: boolean;
  openSalesforceOAuthPopUp?: () => void;
  disconnected?: boolean;
  connectionStatus?: string;
  headerType?: 'tackle' | 'salesforce';
  useLiveStatus?: boolean;
}

interface TableHeaderProps {
  headerType: 'tackle' | 'salesforce';
  classes: any;
}

const TableHeader: React.FC<TableHeaderProps> = ({ headerType, classes }) => {
  const firstHeaderTitle =
    headerType === 'tackle' ? 'Salesforce Org' : 'Tackle Org';

  return (
    <Box className={classes.headerContainer}>
      {headerType === 'salesforce' && (
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src={integration}
              alt="Integration icon"
              className={classes.integrationIcon}
              style={{ marginRight: '8px' }}
            />
            <Typography className={classes.headerText}>
              Tackle Integration
            </Typography>
          </Box>
          <Typography className={classes.headerSubText}>
            Manage your Salesforce connection to Tackle.
          </Typography>
        </>
      )}
      <Box className={classes.tableHeaderBox}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {firstHeaderTitle}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Connecting Salesforce User
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 600 }}
              className={classes.statusColumn}
            >
              Status
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 600 }}
              className={classes.environmentColumn}
            >
              Environment
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const SalesforceConnectionTable: React.FC<SalesforceConnectionTableProps> = ({
  data,
  isConnected = false,
  openSalesforceOAuthPopUp,
  disconnected = false,
  connectionStatus = 'not connected',
  headerType = 'tackle',
  useLiveStatus = false,
}) => {
  const classes = useSalesforceTableStyles();
  const displayStatus = useLiveStatus
    ? disconnected
      ? 'not connected'
      : data?.status ?? 'not connected'
    : connectionStatus;

  return (
    <Box className={classes.tableContainer}>
      <TableHeader headerType={headerType} classes={classes} />
      {data ? (
        <Grid container>
          <Grid item xs={3}>
            <Box className={`${classes.cell} ${classes.alignUnderHeader}`}>
              {headerType === 'salesforce' ? (
                <a
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <Box display="flex" alignItems="center">
                    {data.organization.name}
                    <img
                      src={openInNew}
                      alt="Open in new tab"
                      className={classes.externalIcon}
                    />
                  </Box>
                </a>
              ) : (
                <a
                  href={data.instanceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <Box display="flex" alignItems="center">
                    {data.organization.name}
                    <img
                      src={openInNew}
                      alt="Open in new tab"
                      className={classes.externalIcon}
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    className={classes.instanceUrl}
                    data-full-url={data.instanceUrl}
                  >
                    {data.instanceUrl}
                  </Typography>
                </a>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={`${classes.cell} ${classes.user}`}>
              {data.user.email}
              <Typography variant="caption" className={classes.timestamp}>
                {new Date(data.createdAt).toLocaleString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={`${classes.cell} ${classes.statusCell}`}>
              <Tag color={displayStatus === 'connected' ? 'green' : 'grey'}>
                {displayStatus === 'connected' ? 'Connected' : 'Not connected'}
              </Tag>
              <Typography variant="caption" className={classes.timestamp}>
                {new Date(data.updatedAt).toLocaleString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.cell}>
              {data.organization.isSandbox ? (
                <Tag color="gray">Sandbox</Tag>
              ) : (
                <Tag>Production</Tag>
              )}
            </Box>
          </Grid>
          {(!isConnected || disconnected) && openSalesforceOAuthPopUp && (
            <Grid item xs={12}>
              <Box className={classes.cell} textAlign="center">
                <Button
                  variant="contained"
                  className={classes.connectButton}
                  onClick={openSalesforceOAuthPopUp}
                >
                  Connect to Tackle
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box className={classes.noDataMessage}>
          <Spinner type="ellipsis" />
        </Box>
      )}
    </Box>
  );
};

export default SalesforceConnectionTable;
