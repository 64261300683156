import EditForm from './EditForm/EditForm';
import React, { useContext } from 'react';
import { FormValues } from './EditForm/formSchema';
import { formValuesToPrivateOffer } from './EditForm/formValuesToPrivateOffer';
import { initialFormValuesForAmendOffer } from './EditForm/initialFormValues';
import {
  AwsPrivateOfferCanvasPageProps,
  AwsPrivateOfferPageWithDataProviders,
} from 'pages/PrivateOffers/pages/Next/aws/shared/AwsPrivateOfferPage';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { useHistory, useParams } from 'react-router-dom';
import { mergeInitialValuesWithSalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/mergeInitialValuesWithSalesforceDataMapping';
import SalesforceDataMappingContextProvider from 'pages/PrivateOffers/pages/Next/generic/SalesforceDataMappingContext/SalesforceDataMappingContextProvider';
import {
  toPrivateOfferAfterSubmit,
  toPrivateOfferOnCancel,
  toPrivateOfferOnOpenOffer,
} from '../../generic/utils/page/editOfferPageCallbackUtils';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import SalesforceDataMappingContext from 'pages/PrivateOffers/pages/Next/generic/SalesforceDataMappingContext/salesforceDataMappingContext';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import AmendmentContextProvider from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContextProvider';
import AmendmentContext from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContext';
import OfferPageContext from '../../generic/OfferPageContext/offerPageContext';
import BuyerAgreementsContextProvider from './BuyerAgreementsContext/BuyerAgreementsContextProvider';
import { toPrivateOfferOnAmend } from 'pages/PrivateOffers/pages/Next/aws/edit/utils/page/editOfferPageCallbackUtils';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { OfferRunFrom } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerRunFrom';
import { OfferType } from '../../generic/types/TackleOffer';

const AmendAwsPrivateOfferPage: React.FunctionComponent = () => {
  const {
    awsApi: { createOffer },
  } = useContext(ApiContext);

  const { user, users, productsByProductId, afterSubmit } =
    useContext(OfferPageContext);

  const { mergedAgreementAndSourceOffer } = useContext(AmendmentContext);

  const product = findMatchingProduct(
    mergedAgreementAndSourceOffer.productId,
    productsByProductId,
  );

  const { salesforceDataMapping } = useContext(SalesforceDataMappingContext);

  const initialValues = initialFormValuesForAmendOffer(
    mergedAgreementAndSourceOffer,
    product,
    user,
    users,
  );

  // TODO - replace with amendment specific merge fn
  // New offers always take the data mapping over the initial value. Amendment
  // offers will take most values, but dimensions and schedules will be additive
  // We also don't currently have dimensions or schedules in the mapping data
  const mergedInitialValues = mergeInitialValuesWithSalesforceDataMapping(
    initialValues,
    salesforceDataMapping?.privateOffer,
    productsByProductId,
  );

  const onSubmit = async (formValues: FormValues) => {
    const amendedOffer = formValuesToPrivateOffer(formValues, true);

    const createdOffer = await createOffer(
      amendedOffer,
      formValues.createInMarketplace,
    );

    if (createdOffer) {
      afterSubmit(createdOffer);
    }
  };

  return (
    <EditForm
      dataMappingErrors={salesforceDataMapping?.errors}
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
    />
  );
};

interface AmendAwsPrivateOfferPageWithDataProvidersProps {
  accessTokenProvider?: () => Promise<string>;
  runFrom: OfferRunFrom;
  afterSubmit?: (offer: AwsPrivateOffer) => void;
  onOpenOffer?: (offer: AwsPrivateOffer) => void;
  onAmend?: (offerId: string, offer: AwsPrivateOffer | null) => void;
  onCancel?: (offer: AwsPrivateOffer | null) => void;
  offerId: string;
  poId?: string;
  opportunityId?: string;
  mappingId?: string;
}

const AmendAwsPrivateOfferPageWithDataProviders: React.FunctionComponent<
  AmendAwsPrivateOfferPageWithDataProvidersProps
> = ({
  accessTokenProvider,
  runFrom,
  afterSubmit,
  onOpenOffer,
  onAmend,
  onCancel,
  offerId,
  poId,
  opportunityId,
  mappingId,
}) => (
  <AwsPrivateOfferPageWithDataProviders
    includeVendorContext={true}
    accessTokenProvider={accessTokenProvider}
    mode={OfferPageMode.Amend}
    runFrom={runFrom}
    poId={poId}
    afterSubmit={afterSubmit}
    onOpenOffer={onOpenOffer}
    onAmend={onAmend}
    onCancel={onCancel}
  >
    <SalesforceDataMappingContextProvider
      offerType={OfferType.Direct}
      opportunityId={opportunityId}
      mappingId={mappingId}
    >
      <AmendmentContextProvider offerId={offerId}>
        <BuyerAgreementsContextProvider>
          <AmendAwsPrivateOfferPage />
        </BuyerAgreementsContextProvider>
      </AmendmentContextProvider>
    </SalesforceDataMappingContextProvider>
  </AwsPrivateOfferPageWithDataProviders>
);

type AmendAwsPrivateOfferCanvasPagePros = {
  offerId: string;
  poId?: string;
  opportunityId?: string;
  mappingId?: string;
} & Omit<AwsPrivateOfferCanvasPageProps, 'mode' | 'children' | 'runFrom'>;

export const AmendAwsPrivateOfferCanvasPage: React.FunctionComponent<
  AmendAwsPrivateOfferCanvasPagePros
> = (props) => (
  <AmendAwsPrivateOfferPageWithDataProviders
    {...props}
    runFrom={OfferRunFrom.Canvas}
  />
);

const poIdParam = 'poId';
const opportunityIdParam = 'opportunityId';
const mappingIdParam = 'mappingId';

const AmendAwsPrivateOfferPlatformPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { offerId } = useParams<{ offerId: string }>();
  const { search } = history.location;
  const searchParams = new URLSearchParams(search);
  const poId = searchParams.get(poIdParam);
  const opportunityId = searchParams.get(opportunityIdParam);
  const mappingId = searchParams.get(mappingIdParam);
  const afterSubmit = toPrivateOfferAfterSubmit(history);
  const onAmend = toPrivateOfferOnAmend(history);
  const onCancel = toPrivateOfferOnCancel(history);

  return (
    <AmendAwsPrivateOfferPageWithDataProviders
      runFrom={OfferRunFrom.Platform}
      offerId={offerId}
      poId={poId}
      opportunityId={opportunityId}
      mappingId={mappingId}
      afterSubmit={afterSubmit}
      onAmend={onAmend}
      onCancel={onCancel}
      onOpenOffer={toPrivateOfferOnOpenOffer}
    />
  );
};

export default AmendAwsPrivateOfferPlatformPage;
