import { Optional } from 'utils/optional/optional';
import { getUtcDateTimeFromSerializedIsoDateTime } from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import {
  Activity,
  ActivitySlug,
  TackleOffer,
} from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import { Offer } from '../../types/Offer';

export const byCreatedAtDescending = (a: Activity, b: Activity): number => {
  const createdAtA = getUtcDateTimeFromSerializedIsoDateTime(a.createdAt);
  const createdAtB = getUtcDateTimeFromSerializedIsoDateTime(b.createdAt);

  return createdAtA < createdAtB ? 1 : -1;
};

const byCreatedAtAscending = (a: Activity, b: Activity): number => {
  const createdAtA = getUtcDateTimeFromSerializedIsoDateTime(a.createdAt);
  const createdAtB = getUtcDateTimeFromSerializedIsoDateTime(b.createdAt);

  return createdAtA < createdAtB ? -1 : 1;
};

export const getLatestOfferActivitySlug = (
  offer: Offer | TackleOffer | null,
): ActivitySlug => {
  const { activities = [] } = offer || {};
  const sortedActivities = [...activities].sort(byCreatedAtAscending);

  return (
    sortedActivities.find(
      (a) => a.slug === ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
    )?.slug ||
    sortedActivities.at(activities.length - 1)?.slug ||
    ActivitySlug.VendorDraftedOffer
  );
};

export const getMarketplaceErrors = (activities: Activity[]): string[] =>
  Optional.ofNullable(activities)
    .filter((a) => a.length > 0)
    .map((a) => a.at(a.length - 1))
    .filter((a) => a.metadata?.errors?.length > 0)
    .map((a) => a.metadata?.errors?.map((e: { message: string }) => e.message))
    .orElse([]);
