import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

export const mergeExistingOfferWithUpdates = (
  existingOffer: AwsPrivateOffer,
  updatedOffer: AwsPrivateOffer,
): AwsPrivateOffer => {
  const { pricing: updatedPricing, partnerOffer: updatedPartnerOffer } =
    updatedOffer;

  return {
    ...existingOffer,
    awsRenewalMovingToMarketplace: updatedOffer.awsRenewalMovingToMarketplace,
    buyerBillingAccountRef: updatedOffer.buyerBillingAccountRef,
    buyerCompanyName: updatedOffer.buyerCompanyName,
    buyers: updatedOffer.buyers,
    extraData: updatedOffer.extraData,
    offerDescription: updatedOffer.offerDescription,
    offerExpirationAt: updatedOffer.offerExpirationAt,
    offerMetadata: updatedOffer.offerMetadata,
    offerName: updatedOffer.offerName,
    preRegistrationDetails: updatedOffer.preRegistrationDetails,
    pricing: {
      ...existingOffer.pricing,
      billingTerm: updatedPricing?.billingTerm,
      currencyCode: updatedPricing?.currencyCode,
      dimensions: updatedPricing?.dimensions,
      duration: updatedPricing?.duration,
      durationType: updatedPricing?.durationType,
      durationValue: updatedPricing?.durationValue,
      marketplaceFee: updatedPricing?.marketplaceFee,
      paymentModel: updatedPricing?.paymentModel,
      schedule: updatedPricing?.schedule,
      serviceEndAt: updatedPricing?.serviceEndAt,
      serviceStartAt: updatedPricing?.serviceStartAt,
      usageDimensions: updatedPricing?.usageDimensions,
    },
    productId: updatedOffer.productId,
    renewal: updatedOffer.renewal,
    eula: updatedOffer.eula,
    partnerOffer: {
      ...existingOffer.partnerOffer,
      partnerName: updatedPartnerOffer?.partnerName,
      partnerRef: updatedPartnerOffer?.partnerRef,
      resellerAgreement: updatedPartnerOffer?.resellerAgreement
        ? updatedPartnerOffer.resellerAgreement
        : null,
    },
  };
};
