import { Banner } from '@tackle-io/platform-ui';
import useOpportunityEventsQuery from 'packages/cosell/src/hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import { AceCloudErrorResponse } from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';
import { useState } from 'react';
import { theme } from 'ui';

const BANNER_TITLE = 'Co-sell error messages';

const ERROR_MESSAGE_MAP = {
  ConflictException:
    'Resource already exists or has conflicting updates. Fetch the latest state of the resource to verify that your request matches the expected state, and then retry your request.',
  AccessDeniedException:
    'Invalid permissions. Check that your AWS Identity and Access Management (IAM) policies are configured correctly. For help, contact your AWS administrator.',
  InternalServerException:
    'Server error. Please try again. If the issue persists, contact AWS Support.',
  ResourceNotFoundException:
    "Source not found or invalid permissions. Check the resource ID and make sure it's in the correct AWS region. Also check that your AWS Identity and Access Management (IAM) role has permissions to access the resource.",
  ThrottlingException:
    'Too many requests sent. Review the Quotas provided to avoid requests being throttled.',
  UnknownError:
    'An unknown error occurred. If this persists, please contact support.',
};

const DEFAULT_ERROR_MESSAGE_MAP = {
  submission: {
    title: 'AWS submission error',
    message:
      'One or more AWS errors happened when submitting to the cloud. Edit this co-sell to view and fix each error.',
  },
  generic: {
    title: 'AWS error',
    message:
      'AWS experienced one or more errors. Review fields below to update and re-submit to AWS.',
  },
};

type AceCloudResponseErrorType = keyof typeof ERROR_MESSAGE_MAP;

const getErrorMessage = ({
  errorType,
  error,
}: {
  errorType: AceCloudResponseErrorType | (string & {});
  error: AceCloudErrorResponse;
}) => {
  if (errorType in ERROR_MESSAGE_MAP) {
    return ERROR_MESSAGE_MAP[errorType as AceCloudResponseErrorType];
  }

  return error.errorMessage ?? error;
};

/** this is for error messages that cannot be rendered inline. */
export const AceCoSellErrorMessagesBanner = ({
  defaultCollapsed = false,
  opportunityId,
  title = BANNER_TITLE,
  bodyContentText,
  defaultError,
}: {
  defaultCollapsed?: boolean;
  opportunityId: string;
  title?: string;
  bodyContentText?: string;
  defaultError?: keyof typeof DEFAULT_ERROR_MESSAGE_MAP;
}) => {
  const [isOpen, setIsOpen] = useState(!defaultCollapsed);
  const {
    aceOpportunityErrors,
    isOpportunityEventsLoading,
    opportunityEventsError,
  } = useOpportunityEventsQuery(opportunityId);

  if (
    isOpportunityEventsLoading ||
    opportunityEventsError ||
    (!isOpportunityEventsLoading && !aceOpportunityErrors.length)
  ) {
    return null;
  }

  const defaultErrorContent =
    DEFAULT_ERROR_MESSAGE_MAP[defaultError] ?? undefined;

  const bodyContentMessage = bodyContentText ?? defaultErrorContent?.message;

  const bodyContent = bodyContentMessage ? (
    <p
      style={{
        marginTop: 0,
      }}
    >
      {bodyContentMessage}
    </p>
  ) : (
    <ul
      style={{
        marginTop: 0,
        paddingInline: theme.spacing(3),
      }}
    >
      {aceOpportunityErrors.map((error, i) => (
        <li key={`${error.eventID}_${error.timestamp}_${i}`}>
          {getErrorMessage({
            errorType: error.errorType,
            error: error.error,
          })}
        </li>
      ))}
    </ul>
  );

  return (
    <Banner
      body={bodyContent}
      borderPosition="top"
      isCollapsible
      onChange={() => setIsOpen((current) => !current)}
      open={isOpen}
      title={defaultErrorContent?.title ?? title}
      type="danger"
    />
  );
};
