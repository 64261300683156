import * as yup from '../../../../../../utils/yup-extended';

export enum FieldKey {
  Buyers = 'buyers',
}

export interface FormBuyer {
  name: string | null;
  email: string | null;
  title: string | null;
}

const buyerSchema = yup.object<FormBuyer>({
  name: yup.string().nullable().progress(false),
  email: yup.string().nullable().progress(false),
  title: yup.string().nullable().progress(false),
});

export interface FormValues {
  [FieldKey.Buyers]: FormBuyer[];
}

export const formSchema = yup.object<FormValues>({
  [FieldKey.Buyers]: yup.array<FormBuyer>().of(buyerSchema),
});
