import React from 'react';
import DirectOfferLinkOfferFormSection from './DirectOfferLinkOfferFormSection';
import PartnerOfferLinkOfferFormSection from './PartnerOfferLinkOfferFormSection';
import { useFormikContext } from 'formik';
import { FormValues } from '../formSchema';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const LinkOfferFormSection = () => {
  const {
    values: { offerType },
  } = useFormikContext<FormValues>();
  return (
    <>
      {offerType === OfferType.Direct && <DirectOfferLinkOfferFormSection />}
      {offerType === OfferType.PartnerResale && (
        <PartnerOfferLinkOfferFormSection />
      )}
    </>
  );
};

export default LinkOfferFormSection;
