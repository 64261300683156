import {
  Product,
  ProductListingType,
  ProductStatus,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { Cloud } from 'utils/cloudTypes';
import { Marketplace } from '../../types/TackleOffer';

const offerCreationSupportedProductStatuses = new Set<ProductStatus>([
  ProductStatus.Published,
  ProductStatus.ReadyForPreview,
]);

const awsOfferCreationSupportedListingTypes = new Set<string>([
  ProductListingType.Saas,
  ProductListingType.Ccp,
  ProductListingType.PayGo,
]);

const awsProductInclusionPredicate = (p: Product) =>
  p.cloud === Cloud.Aws &&
  offerCreationSupportedProductStatuses.has(p.status) &&
  awsOfferCreationSupportedListingTypes.has(p.listingType) &&
  !!p.encryptedProductid;

const marketplaceToProductInclusionPredicate: {
  [c: string]: (p: Product) => boolean;
} = {
  [Marketplace.Aws]: awsProductInclusionPredicate,
};

export const validProductsForMarketplace =
  (marketplace: Marketplace) => (product: Product) => {
    const productInclusionPredicate =
      marketplaceToProductInclusionPredicate[marketplace] || (() => true);

    return productInclusionPredicate(product);
  };
