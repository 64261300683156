import { makeStyles } from 'vendor/material';

export const useConnectedAppCallbackStyles = makeStyles((theme) => ({
  successContainer: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    color: theme.palette.GREEN500,
    minWidth: '400px',
  },
  errorContainer: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    color: theme.palette.RED500,
  },
  successIcon: {
    width: '50px',
    height: '50px',
  },
}));
