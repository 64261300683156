import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { History } from 'history';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

export const toPrivateOfferOnAmend =
  (history: History) => (offerId: string, offer: AwsPrivateOffer | null) => {
    history.push({
      pathname: `/private-offers/${Marketplace.Aws}/amend/${offerId}`,
      search: offer ? 'poId=' + offer.poId : '',
    });
  };
