import {
  Cloud,
  Domain,
  Operation,
} from 'pages/Settings/Account/components/FieldMapper/utils/constants';
import { dataMapperPublicApi } from '../tackleApiClient';
import { reportError } from 'utils/monitor';
import { CreateAceOpportunityRequest } from 'packages/cosell/src/types/requests/AceOpportunityRequest';
import type { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

/**
 * TODO: break out types bases on data-mapper operation mapping type when the API supports it
 * currently all form types are for the create form
 */
export type CosellAwsMappedFields = Partial<
  Pick<
    CreateAceOpportunityRequest,
    | 'awsSubmission'
    | 'crmId'
    | 'customer'
    | 'lifeCycle'
    | 'marketing'
    | 'nationalSecurity'
    | 'opportunityTeam'
    | 'opportunityType'
    | 'primaryNeedsFromAws'
    | 'project'
    | 'solutions'
    | 'awsProducts'
  > & {
    awsMarketplaceOffers: AceOpportunityResponse['awsMarketplaceOffers'];
    softwareRevenue: AceOpportunityResponse['softwareRevenue'];
  }
>;

type MappingTypeMap = {
  [Domain.CO_SELL]: {
    [Cloud.AWS]: {
      [Operation.CREATE_OPPORTUNITY]: CosellAwsMappedFields;
      [Operation.CREATE_DIRECT_OFFER]: CosellAwsMappedFields; // no direct offer in co-sell domain
      [Operation.CREATE_PARTNER_OFFER]: CosellAwsMappedFields; // no partner offer in co-sell domain
    };
    [Cloud.MSFT]: {
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>;
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>; // no direct offer in co-sell domain
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>; // no partner offer in co-sell domain
    };
    [Cloud.AZURE]: {
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>;
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>; // no direct offer in co-sell domain
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>; // no partner offer in co-sell domain
    };
    [Cloud.GCP]: {
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>;
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>; // no direct offer in co-sell domain
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>; // no partner offer in co-sell domain
    };
  };
  [Domain.OFFER]: {
    [Cloud.AWS]: {
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>;
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>;
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>; // no createOpportunity in offer domain
    };
    [Cloud.MSFT]: {
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>;
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>;
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>; // no createOpportunity in offer domain
    };
    [Cloud.AZURE]: {
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>;
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>;
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>; // no createOpportunity in offer domain
    };
    [Cloud.GCP]: {
      [Operation.CREATE_DIRECT_OFFER]: Record<string, unknown>;
      [Operation.CREATE_PARTNER_OFFER]: Record<string, unknown>;
      [Operation.CREATE_OPPORTUNITY]: Record<string, unknown>; // no createOpportunity in offer domain
    };
  };
};

// TODO: [sw] check with SF canvas team if this needs to be updated
export interface MappedFieldsResponse<
  TDomain extends Domain,
  TCloud extends Cloud,
  TOperation extends keyof MappingTypeMap[TDomain][TCloud],
> {
  /** $tkl-metadata is the data-mapper claim check
   * It is used to create a link between the Co-sell API opportunity and the data-mapping
   * so that whenever the SF opportunity is updated the Co-sell opportunity is updated.*/
  '$tkl-metadata': string;
  mappingId: string;
  mappedFields: MappingTypeMap[TDomain][TCloud][TOperation];
  errors?: Record<string, string>;
  domainValidationErrors?: Record<string, string>;
  message: string;
  warnings?: Record<string, string>;
  isValid: boolean;
}

/**
 * Fetch mapped fields from data-mapper-api for a given crmId (salesforce record id) and mappingId.
 */
export const getMappedFields = async <
  TDomain extends Domain,
  TCloud extends Cloud,
  TOperation extends Operation,
>({
  crmId,
  cloud,
  domain,
  operation,
  mappingId,
}: {
  /** salesforce record id */
  crmId: string;
  cloud: TCloud;
  domain: TDomain;
  operation: TOperation;
  /** data-mapping template id */
  mappingId?: string;
}): Promise<MappedFieldsResponse<TDomain, TCloud, TOperation>> => {
  const queryParams = new URLSearchParams({
    crmId,
    ...(!!mappingId && { mappingId }),
    ...(!!cloud && { cloud }),
    ...(!!domain && { domain }),
    ...(!!operation && { operation }),
  });

  try {
    const result = (await dataMapperPublicApi
      .get(`mapped-fields?${queryParams?.toString()}`, {})
      .json()) as MappedFieldsResponse<TDomain, TCloud, TOperation>;

    return result;
  } catch (error) {
    reportError(error);
    throw error;
  }
};
