import { useState } from 'react';
import { Loader } from '@tackle-io/platform-ui';
import { useQuery } from '@tanstack/react-query';
import { CheckCircle } from 'mdi-material-ui';
import { useConnectedAppCallbackStyles } from './ConnectedAppCallback.styles';
import { Icon } from 'vendor/material';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import { unescape } from 'lodash';
import { useAuth } from 'vendor/auth0';
import {
  SalesforceCrmConnectorResponse,
  SALESFORCE_AUTH_COMPLETE,
} from './CanvasTackleIntegrationStatus';
import { AccountConnectionModal } from './AccountConnectionModal';
import { gql } from '@apollo/client';
import { useSalesforceConnectionDetailsQuery } from 'generated/graphql';

const CODE_PARAM = 'code';
const STATE_PARAM = 'state';

const SALESFORCE_OAUTH_CALLBACK_KEY = 'salesforceOAuthCallback';

export const ACCOUNT_SWITCHING_GET_VENDORS_QUERY = gql`
  query SalesforceConnectionDetails {
    salesforceConnectionDetails {
      vendorId
      vendorName
      vendorType
      registration {
        organization {
          id
          name
          instanceName
          isSandbox
          organizationType
          namespacePrefix
        }
        user {
          userId
          organizationId
          email
          givenName
          familyName
          userType
        }
        instanceUrl
        vendorId
        tackleOrgId
        errorCode
        errorMessage
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const getSalesforceToken = async (
  salesforceInstanceUrl: string,
  code: string,
  namespace: string = 'tackle',
  vendorId: string,
  getTackleTokenFunction: () => Promise<string | undefined>,
) => {
  const body = {
    loginURL: salesforceInstanceUrl,
    authorizationCode: unescape(code),
    redirectURL:
      process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_REGISTER_REDIRECT_URL,
    namespace: namespace,
    vendorId: vendorId,
  };

  const token = await getTackleTokenFunction();

  const resp = await fetch(
    `${process.env.REACT_APP_CRM_CONNECTOR_API_URL}/register`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    },
  );

  const data = await resp.json();
  if (resp.ok) {
    return {
      crmConnectorData: data as SalesforceCrmConnectorResponse,
    };
  } else {
    return Promise.reject(data);
  }
};

const ConnectedAppCallback = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get(CODE_PARAM)?.toString();
  const state = JSON.parse(urlParams.get(STATE_PARAM)?.toString());
  const classes = useConnectedAppCallbackStyles();
  const salesforceInstanceUrl = state.loginUrl;
  const { getAccessTokenSilently } = useAuth();
  const {
    data: sfRegistrationInformation,
    loading: sfRegistrationLoading,
    error: sfRegistrationError,
  } = useSalesforceConnectionDetailsQuery();
  const [vendorId, setVendorId] = useState<string | null>(null);

  const handleAuthenticate = async (selectedVendorId: string) => {
    setVendorId(selectedVendorId);
  };

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [
      SALESFORCE_OAUTH_CALLBACK_KEY,
      salesforceInstanceUrl,
      code,
      state,
      getAccessTokenSilently,
      vendorId,
    ],
    queryFn: () =>
      getSalesforceToken(
        salesforceInstanceUrl,
        code,
        state.packageNamespace,
        vendorId,
        getAccessTokenSilently,
      ),
    enabled: !!vendorId,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  if (sfRegistrationLoading) {
    return <Loader />;
  }

  if (sfRegistrationError) {
    return (
      <p>Error loading vendor information: {sfRegistrationError.message}</p>
    );
  }

  if (!sfRegistrationInformation) {
    return <p>No vendor information available</p>;
  }

  if (!vendorId) {
    return (
      <AccountConnectionModal
        sfRegistrationInformation={sfRegistrationInformation}
        onAuthenticate={handleAuthenticate}
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <div className={classes.successContainer}>
        <Icon fontSize="large">
          <CheckCircle />
        </Icon>
        <h2>Successfully connected to Salesforce</h2>
        <button
          onClick={() => {
            const successData = JSON.stringify({
              type: SALESFORCE_AUTH_COMPLETE,
              payload: data,
            });
            window.opener?.postMessage(successData, window.location.origin);

            window.close();
          }}
        >
          Close Window
        </button>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classes.errorContainer}>
        <h2>There was an issue connecting to Salesforce</h2>
        <p>Please try again</p>
        <button
          onClick={() => {
            const errorData = JSON.stringify({
              status: 'error',
              error: 'Failed to connect to Salesforce.',
              details: data,
            });
            const targetOrigin = document.referrer;
            window.opener?.postMessage?.(errorData, targetOrigin);
          }}
        >
          Close Window
        </button>
      </div>
    );
  }

  return null;
};

export const WrappedConnectedAppCallback = () => {
  return (
    <ReactQueryClientProvider>
      <ConnectedAppCallback />
    </ReactQueryClientProvider>
  );
};

export default ConnectedAppCallback;
