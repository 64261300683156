import { Box, Typography } from 'vendor/material';
import { FormSections } from '../formSections';
import React, { useContext } from 'react';
import { FormValues } from '../formSchema';
import QuickLink from './QuickLink';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { FormikErrors } from 'formik';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

interface QuickLinksProps {
  formValues: FormValues;
  formErrors?: FormikErrors<FormValues>;
}

const QuickLinks: React.FunctionComponent<QuickLinksProps> = ({
  formValues,
  formErrors,
}) => {
  const { mode } = useContext(OfferPageContext);

  return (
    <>
      <Box mb={2} onClick={() => console.log({ formValues, formErrors })}>
        <Typography>Quick links</Typography>
      </Box>
      {mode === OfferPageMode.Associate && (
        <QuickLink
          formSection={FormSections.LinkOffer}
          formValues={formValues}
          mode={mode}
        />
      )}
      <QuickLink
        formSection={FormSections.BasicInformation}
        formValues={formValues}
        mode={mode}
      />
      <QuickLink
        formSection={FormSections.ProductAndPricing}
        formValues={formValues}
        mode={mode}
      />
      <QuickLink
        formSection={FormSections.EndUserLicenseAgreement}
        formValues={formValues}
        mode={mode}
      />
      {formValues.offerType === OfferType.PartnerResale && (
        <QuickLink
          formSection={FormSections.ResellerAgreement}
          formValues={formValues}
          mode={mode}
        />
      )}
      {formValues.productRef && (
        <QuickLink
          formSection={FormSections.RegistrationDetails}
          formValues={formValues}
          mode={mode}
        />
      )}
      {formValues.productRef && (
        <QuickLink
          formSection={FormSections.AdditionalFields}
          formValues={formValues}
          mode={mode}
        />
      )}
      <QuickLink
        formSection={FormSections.OfferValidityPeriod}
        formValues={formValues}
        mode={mode}
      />
      <QuickLink
        formSection={FormSections.NotifyUsers}
        formValues={formValues}
        mode={mode}
      />
    </>
  );
};

export default QuickLinks;
