import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Loader } from '@tackle-io/platform-ui';
import ErrorComponent from 'pages/Error/Error';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { Box } from 'vendor/material';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { Product } from '../api/types/Product';
import OfferContext, { OfferContextValues } from './offerContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferPageContext from '../OfferPageContext/offerPageContext';
import { getLatestOfferActivitySlug } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import {
  ActivitySlug,
  Marketplace,
} from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import { Offer } from 'pages/PrivateOffers/pages/Next/generic/types/Offer';
import { awsPrivateOfferToTackleOffer } from 'pages/PrivateOffers/pages/Next/generic/types/transformers/awsPrivateOfferToTackleOffer';
import { msftPrivateOfferToTackleOffer } from 'pages/PrivateOffers/pages/Next/generic/types/transformers/msftPrivateOfferToTackleOffer';

interface OfferContextProviderProps {
  poId: string;
  children: React.ReactElement;
}

const restrictEditActivitySlugs = new Set([
  ActivitySlug.VendorCreatedMarketplaceOfferPending,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationPending,
  ActivitySlug.VendorCancelledMarketplaceOfferPending,
]);

const tackleOfferTransformerByMarketplace = {
  [Marketplace.Aws]: awsPrivateOfferToTackleOffer,
  [Marketplace.Azure]: msftPrivateOfferToTackleOffer,
};

const productIdProducerByMarketplace = {
  [Marketplace.Aws]: (o?: any) => o?.productId,
  [Marketplace.Azure]: (o?: any) => o?.productRef,
};

const OfferContextProvider: React.FunctionComponent<
  OfferContextProviderProps
> = ({ poId, children }) => {
  const [currentPoId, setCurrentPoId] = useState<string | null>(null);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [product, setProduct] = useState<Product | null>(null);

  const { marketplace, mode, productsByProductId, onCancel } =
    useContext(OfferPageContext);

  const {
    apiForMarketplace: { getOffer },
  } = useContext(ApiContext);

  const tackleOfferTransformer = useMemo(
    () => tackleOfferTransformerByMarketplace[marketplace],
    [marketplace],
  );

  const productIdProducer = useMemo(
    () => productIdProducerByMarketplace[marketplace],
    [marketplace],
  );

  const loadingOffer = useMemo(
    () => !!poId && poId !== currentPoId,
    [poId, currentPoId],
  );

  const tackleOffer = useMemo(
    () =>
      offer && tackleOfferTransformer ? tackleOfferTransformer(offer) : null,
    [tackleOfferTransformer, offer],
  );

  const tackleOfferForMode = useMemo(
    () =>
      mode === OfferPageMode.Amend || mode === OfferPageMode.Clone
        ? null
        : tackleOffer,
    [mode, tackleOffer],
  );

  const offerIsAmendment = useMemo(
    () => !!tackleOfferForMode?.sourceOfferId,
    [tackleOfferForMode],
  );

  const offerHasPendingMarketplaceOp = useMemo(() => {
    const lastActivitySlug = getLatestOfferActivitySlug(tackleOfferForMode);

    return restrictEditActivitySlugs.has(lastActivitySlug);
  }, [tackleOfferForMode]);

  const offerIsMarketplaceEditable = useMemo(
    () =>
      !tackleOfferForMode?.createdInMarketplaceAt &&
      !offerHasPendingMarketplaceOp,
    [tackleOfferForMode, offerHasPendingMarketplaceOp],
  );

  useEffect(() => {
    if (!poId) {
      return;
    }

    (async () => {
      const fetchedOffer = await getOffer(poId);
      const product = findMatchingProduct(
        productIdProducer(fetchedOffer),
        productsByProductId,
      );

      setProduct(product);
      setOffer(fetchedOffer);
      setCurrentPoId(poId);
    })();
  }, [
    getOffer,
    poId,
    productIdProducer,
    productsByProductId,
    mode,
    setOffer,
    setProduct,
  ]);

  const contextValues: OfferContextValues<Offer> = {
    offer,
    setOffer,
    tackleOffer,
    tackleOfferForMode,
    product,
    offerIsAmendment,
    offerHasPendingMarketplaceOp,
    offerIsMarketplaceEditable,
  };

  return loadingOffer ? (
    <Loader />
  ) : poId && !offer ? (
    <ErrorComponent>
      <Box mb={2}>Private offer not found</Box>
      <Button
        appearance="primary"
        onClick={() => {
          onCancel(null);
        }}
      >
        Back
      </Button>
    </ErrorComponent>
  ) : (
    <OfferContext.Provider value={contextValues}>
      {children}
    </OfferContext.Provider>
  );
};

export default OfferContextProvider;
