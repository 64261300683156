import { useState, useEffect, useMemo } from 'react';
import TackleStandardLogo from '../assets/TackleStandardLogo';
import { Modal, RadioGroup, Button } from '@tackle-io/platform-ui';
import { makeStyles, Grid, Typography, Box } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#063649',
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#091E42',
    marginBottom: theme.spacing(2),
  },
  text2: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#063649',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

export const isOptionDisabled = (vendor) => {
  return !!vendor.registration;
};

export const getFormattedOptions = (salesforceConnectionDetails) => {
  return (
    salesforceConnectionDetails?.map((vendor) => {
      const isDisabled = isOptionDisabled(vendor);
      const label = `${vendor.vendorName}${
        isDisabled ? ' (already connected)' : ''
      }`;

      return {
        label,
        value: vendor.vendorId,
        disabled: isDisabled, // Disable if registration exists
      };
    }) || []
  );
};

export const AccountConnectionModal = ({
  sfRegistrationInformation,
  onAuthenticate,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { salesforceConnectionDetails } = sfRegistrationInformation;

  const formattedOptions = useMemo(() => {
    return getFormattedOptions(salesforceConnectionDetails);
  }, [salesforceConnectionDetails]);

  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    const enabledOptions = formattedOptions.filter(
      (option) => !option.disabled,
    );
    if (enabledOptions.length === 1) {
      setSelectedValue(enabledOptions[0].value);
    } else {
      setSelectedValue('');
    }
  }, [formattedOptions]);

  const handleRadioChange = (newValue) => {
    const selectedOption = formattedOptions.find(
      (option) => option.value === newValue,
    );
    if (!selectedOption?.disabled) {
      setSelectedValue(newValue);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    window.close();
  };

  const handleAuthenticate = () => {
    onAuthenticate(selectedValue);
    setIsOpen(false);
  };

  const singleOptionWithRegistration =
    formattedOptions.length === 1 && formattedOptions[0].disabled;

  return (
    <Modal width="medium" open={isOpen} onClose={handleClose}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <TackleStandardLogo />
        </Grid>
        <Grid item>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding={4}
          >
            <Typography className={classes.header}>
              Permission to authenticate
            </Typography>
            <Typography className={classes.text}>
              Providing permission to authenticate allows you to set up the
              Tackle app and sync your Marketplace, Co-Sell, and Prospect data.
            </Typography>
            {singleOptionWithRegistration ? (
              <Box>
                <Typography className={classes.text2}>
                  Connecting to the following Tackle account:
                </Typography>
                <Typography className={classes.text}>
                  {formattedOptions[0].label}
                </Typography>
              </Box>
            ) : (
              <>
                <Typography className={classes.text2}>
                  Tackle account
                </Typography>
                <Typography className={classes.text}>
                  Select the Tackle account you want to associate this
                  Salesforce instance to. If the desired account is already
                  connected, you can disconnect it by following instructions
                  here [link to Tackle Docs].
                </Typography>
                <Box ml={2}>
                  <RadioGroup
                    options={formattedOptions}
                    value={selectedValue}
                    onChange={handleRadioChange}
                    direction="vertical"
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              appearance="primary"
              variant="outlined"
              onClick={handleClose}
              style={{ marginRight: '12px', fontWeight: 'bold' }}
            >
              Cancel
            </Button>
            <Button
              appearance="primary"
              variant="contained"
              color="primary"
              style={{ fontWeight: 'bold' }}
              onClick={handleAuthenticate}
              disabled={
                !selectedValue ||
                formattedOptions.find(
                  (option) => option.value === selectedValue,
                )?.disabled
              }
            >
              Authenticate
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};
