import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import {
  CalendarClockIcon,
  SyncedIcon,
  WarningIcon,
} from 'packages/cosell/assets';
import React from 'react';
import {
  formatDate,
  calculateDaysBetweenTwoDates,
} from 'packages/cosell/src/utilities/formatDate';

export interface RightRailContentConfig {
  title: string;
  icon?: JSX.Element;
  //* Intro text is require is important when we want text before a subIcon & title */
  introText?: string;
  guidanceText: string | React.ReactNode;
  subTitle?: string | null;
  subIcon?: JSX.Element;
}

// TITLES CONSTANTS
export const NEW_INVITATION = 'New invitation';
const PENDING_CLOUD_ACCEPTANCE = 'Pending cloud acceptance';
const PENDING_CLOUD_REJECTION = 'Pending cloud rejection';
const INVITATION_ACCEPTED = 'Invitation accepted';
const INVITATION_REJECTED = 'Invitation rejected';
const INVITATION_EXPIRED = 'Invitation expired';
export const CLOUD_CONTACTS_TITLE = 'Cloud contacts';

// GUIDANCE TEXTS CONSTANTS
export const NEW_INVITATION_INTRO_TEXT =
  'You’ve been invited to co-sell with your AWS cloud partner! Accept it to view all the invitation details and start co-selling with your cloud partner, or reject the invitation.';

export const NEW_INVITATION_GUIDANCE_TEST =
  'You have 15 days to accept this invitation. AWS automatically rejects invitations that are not accepted by the expiration date.';
export const NO_CLOUD_CONTACT_GUIDANCE_TEXT =
  'You don’t have any cloud contacts yet. Your cloud partner will share them after this co-sell opportunity has been submitted and approved for co-selling.';

const PENDING_INVITATION_ACCEPTED_GUIDANCE_TEXT =
  "This invitation was accepted for co-selling! It might take a few minutes for AWS to create the opportunity. When it's created, you can view it from your list of opportunities in Tackle.";

const PENDING_INVITATION_REJECTED_GUIDANCE_TEXT =
  'This invitation was rejected. It might take a few minutes for AWS to reject the opportunity.';
const INVITATION_ACCEPTED_GUIDANCE_TEXT =
  'This invitation was accepted for co-selling!';
const INVITATION_REJECTED_GUIDANCE_TEXT =
  'This invitation was rejected for the reason above. You can contact your cloud partner to follow up.';

const InvitationExpired = ({ expirationDate }) => (
  <span>
    <b>This invitation expired on {formatDate(expirationDate)}.</b> AWS
    automatically rejects invitations that are not accepted by the expiration
    date.
  </span>
);
export const getDaysToAcceptText = ({
  invitationDate,
  expirationDate,
}: {
  invitationDate?: string;
  expirationDate?: string;
}) => {
  const daysToAccept = calculateDaysBetweenTwoDates(
    invitationDate,
    expirationDate,
  );
  return `You have ${daysToAccept} days to accept this invitation. AWS automatically rejects invitations that are not accepted by the expiration date.'`;
};

const getPendingGuidanceText = ({
  pendingOperationId,
  invitationDate,
  expirationDate,
}: {
  pendingOperationId: 'acceptInvitation' | 'rejectInvitation' | null;
  invitationDate?: string;
  expirationDate?: string;
}) => {
  if (pendingOperationId === 'acceptInvitation') {
    return PENDING_INVITATION_ACCEPTED_GUIDANCE_TEXT;
  } else if (pendingOperationId === 'rejectInvitation') {
    return PENDING_INVITATION_REJECTED_GUIDANCE_TEXT;
  }

  return getDaysToAcceptText({ invitationDate, expirationDate });
};

const getPendingTitle = (
  pendingOperation: 'acceptInvitation' | 'rejectInvitation' | null,
) => {
  if (pendingOperation === 'acceptInvitation') {
    return PENDING_CLOUD_ACCEPTANCE;
  } else if (pendingOperation === 'rejectInvitation') {
    return PENDING_CLOUD_REJECTION;
  }

  return NEW_INVITATION;
};

export const getRightRailContentConfig = ({
  status,
  rejectionReason,
  pendingOperationId,
  expirationDate,
  invitationDate,
}: {
  status: AceInvitationCloudStatusEnum;
  rejectionReason?: string | null;
  pendingOperationId: 'acceptInvitation' | 'rejectInvitation' | null;
  expirationDate?: string;
  invitationDate?: string;
}): RightRailContentConfig => {
  // handle default status configuration
  const config: Record<AceInvitationCloudStatusEnum, RightRailContentConfig> = {
    [AceInvitationCloudStatusEnum.PENDING]: {
      title: getPendingTitle(pendingOperationId),
      icon: <SyncedIcon />,
      introText: NEW_INVITATION_INTRO_TEXT,
      subIcon: <CalendarClockIcon />,
      subTitle: `Expires on ${formatDate(expirationDate)}`,
      guidanceText: getPendingGuidanceText({
        pendingOperationId,
        invitationDate,
        expirationDate,
      }),
    },
    [AceInvitationCloudStatusEnum.ACCEPTED]: {
      title: INVITATION_ACCEPTED,
      icon: <SyncedIcon />,
      guidanceText: INVITATION_ACCEPTED_GUIDANCE_TEXT,
    },
    [AceInvitationCloudStatusEnum.REJECTED]: {
      title: INVITATION_REJECTED,
      icon: <SyncedIcon />,
      guidanceText: INVITATION_REJECTED_GUIDANCE_TEXT,
      subIcon: <WarningIcon />,
      subTitle: rejectionReason,
    },
    [AceInvitationCloudStatusEnum.EXPIRED]: {
      title: INVITATION_EXPIRED,
      icon: <SyncedIcon />,
      guidanceText: <InvitationExpired expirationDate={expirationDate} />,
    },
  };

  return config[status];
};

// the cloud partner will share cloud contacts after approve or reject the opportunity
export const shouldRenderCloudContact = (
  status: AceInvitationCloudStatusEnum,
): boolean => {
  const approvedOrRejected = [
    AceInvitationCloudStatusEnum.REJECTED,
    AceInvitationCloudStatusEnum.ACCEPTED,
  ];
  return approvedOrRejected.includes(status);
};
