import { Box, Grid, Typography } from 'vendor/material';
import { ReactComponent as LockedIllustration } from '../../../../images/Locked_Illustration.svg';

const NotEnoughPermissionsTabContent = ({ blurb }: { blurb: string }) => {
  return (
    <Box p={4}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <LockedIllustration />
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">
            You need access!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" align="center">
            {blurb}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotEnoughPermissionsTabContent;
