import { FieldMetaProps } from 'formik/dist/types';
import {
  FieldChangeEvent,
  FieldOnChangeHandler,
} from './fieldChangeEventUtils';
import { FocusEvent } from 'react';

export const getFormattedError = (
  fieldKey: string,
  fieldKeyToLabel: { [k: string]: string },
  meta: FieldMetaProps<any>,
) => {
  return meta?.touched
    ? typeof meta?.error === 'string'
      ? meta?.error?.replace(fieldKey, fieldKeyToLabel[fieldKey])
      : undefined
    : undefined;
};

export const getFormattedErrorIgnoringTouched = (
  fieldKey: string,
  fieldKeyToLabel: { [k: string]: string },
  meta: FieldMetaProps<any>,
) => {
  return typeof meta?.error === 'string'
    ? meta?.error?.replace(fieldKey, fieldKeyToLabel[fieldKey])
    : undefined;
};

export const createEventWrappingOnChangeHandler =
  <T>(name: string, onChange: FieldOnChangeHandler<T>) =>
  (value: T) => {
    const event: FieldChangeEvent<T> = {
      type: 'change',
      target: { name, value },
    };

    onChange(event);
  };

export const createEventNameInjectingOnBlurHandler =
  (name: string, onBlur: (e: FocusEvent<HTMLInputElement>) => void) =>
  (e: FocusEvent<HTMLInputElement>) => {
    const eventWithTargetName = { ...e, target: { ...e.target, name } };
    onBlur(eventWithTargetName);
  };
