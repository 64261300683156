import { toDateTime } from 'utils/dates';
import { DateTime } from 'luxon';

export const formatDate = (date: string) =>
  toDateTime(date).toFormat('MM/dd/yyyy');

// Returns whole-number representing the days between date1 and date2
export const calculateDaysBetweenTwoDates = (date1: string, date2: string) => {
  const formatDate1 = DateTime.fromISO(date1).startOf('day');
  const formatDate2 = DateTime.fromISO(date2).startOf('day');
  const difference = formatDate1.diff(formatDate2, ['days']);
  return Math.floor(Math.abs(difference.days));
};
