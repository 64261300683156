import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from 'vendor/material/index';
import { AlertCircle } from 'mdi-material-ui';
import { Banner, Button } from '@tackle-io/platform-ui';
import React, { useContext } from 'react';
import ApiContext, {
  InvalidCreateOfferPayloadError,
  LogicalValidationError,
  OfferSubmissionError,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';

interface OfferSubmissionErrorsBannerProps {
  poId: string | null;
  offerSubmissionError: OfferSubmissionError;
}

const useStyles = makeStyles((theme) => ({
  errorListItem: {
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorListItemIcon: {
    marginTop: theme.spacing(0.5),
    minWidth: 0,
    paddingRight: theme.spacing(1),
  },
}));

const OfferSubmissionErrorsBanner: React.FunctionComponent<
  OfferSubmissionErrorsBannerProps
> = ({ poId, offerSubmissionError }) => {
  const classes = useStyles();
  const { dismissOfferSubmissionError } = useContext(ApiContext);
  const asSchemaValidationError = offerSubmissionError as SchemaValidationError;

  const asLogicalValidationErrors =
    offerSubmissionError as LogicalValidationError[];

  const asInvalidOfferSubmissionError =
    offerSubmissionError as InvalidCreateOfferPayloadError;

  const invalidOfferSubmissionErrorDescription =
    asInvalidOfferSubmissionError?.description;

  const errors = offerSubmissionError
    ? asSchemaValidationError?.schema_validation_errors ||
      (invalidOfferSubmissionErrorDescription && [
        invalidOfferSubmissionErrorDescription,
      ]) ||
      asLogicalValidationErrors.map((lve) => lve.message)
    : [];

  return (
    <Banner
      type="warning"
      borderPosition="top"
      title="API error"
      body={
        <Box>
          {asSchemaValidationError && asSchemaValidationError?.message && (
            <Box mb={2}>{asSchemaValidationError.message}</Box>
          )}
          <Box mb={2}>
            <List disablePadding>
              {errors.map((e: string, i: number) => (
                <ListItem
                  key={`offer-api-error-${i}`}
                  className={classes.errorListItem}
                >
                  <ListItemIcon className={classes.errorListItemIcon}>
                    <AlertCircle />
                  </ListItemIcon>
                  <ListItemText primary={e} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Button
            onClick={() => {
              dismissOfferSubmissionError(poId);
            }}
          >
            Dismiss
          </Button>
        </Box>
      }
      isCollapsible
      defaultOpen
    />
  );
};

export default OfferSubmissionErrorsBanner;
