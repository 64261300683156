import { Bell } from 'mdi-material-ui';
import React from 'react';
import { Card } from '@tackle-io/platform-ui';
import NotifyUsersSelector from 'pages/PrivateOffers/pages/Next/generic/NotifyUsersSelector/NotifyUsersSelector';

interface NotifyUsersFormSectionProps {
  sectionId: string;
  sectionTitle: string;
}

const NotifyUsersFormSection: React.FunctionComponent<
  NotifyUsersFormSectionProps
> = ({ sectionId, sectionTitle }) => (
  <div id={sectionId}>
    <Card
      title={sectionTitle}
      subtitle="Keep stakeholders informed about this offer."
      icon={<Bell />}
    >
      <NotifyUsersSelector />
    </Card>
  </div>
);

export default NotifyUsersFormSection;
