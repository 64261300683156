import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from 'vendor/material';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from '@tackle-io/platform-ui';
import integrationIconUrl from './../assets/integration_icon.png';
import { useCanvasTackleIntegrationStyles } from './CanvasTackleIntegration.styles';
import { Card, Button } from '@tackle-io/platform-ui';
import { TransitConnectionVariant } from 'mdi-material-ui';
import SalesforceDisconnectButton from 'components/TackleForSalesforce/DisconnectButton';
import SalesforceConnectionTable from 'components/TackleForSalesforce/ConnectionDetailsTable';
import { useCanvasSession } from '../useCanvasSession';
import { useDisconnectSalesforce } from './salesforceIntegration/DisconnectSalesforce';
import { useOAuthPopup } from './salesforceIntegration/OAuthPopup';
import { useSalesforceMessageHandler } from './salesforceIntegration/MessageHandler';
import { useSalesforceConnectionData } from './salesforceIntegration/SalesforceConnectionData';

const PACKAGE_NAMESPACE_PARAM = 'namespace';
export const SALESFORCE_AUTH_COMPLETE = 'SALESFORCE_AUTH_COMPLETE';
export interface SalesforceCrmConnectorResponse {
  vendorId: string;
  orgId: string;
  instanceUrl: string;
  namespace: string;
  createdAt: string;
}

export default function CanvasTackleIntegrationStatus() {
  const canvasSessionHook = useCanvasSession();
  const classes = useCanvasTackleIntegrationStyles();
  const [isConnecting, setIsConnecting] = useState(false);
  const [, setPopupMessageResponse] = useState(null);
  const [isConnected, setIsConnected] = useState(true);
  const [disconnected, setDisconnected] = useState(
    !canvasSessionHook.context?.parameters?.isConnected,
  );

  const urlParams = new URLSearchParams(window.location.search);
  const packageNamespace = urlParams.get(PACKAGE_NAMESPACE_PARAM) ?? '';
  const getOAuthLink = document.referrer + '/services/oauth2/authorize';

  const { disconnectSalesforce } = useDisconnectSalesforce();
  const {
    openSalesforceOAuthPopUp,
    isPendingConnection,
    setIsPendingConnection,
  } = useOAuthPopup(getOAuthLink, packageNamespace);
  const { salesforceData, dataError } = useSalesforceConnectionData();

  useSalesforceMessageHandler(
    setPopupMessageResponse,
    setIsConnecting,
    setIsPendingConnection,
  );

  if (canvasSessionHook.isLoadingCanvasSession) {
    return <Spinner type="ellipsis" />;
  }
  if (canvasSessionHook.isError) {
    return <>Error loading CanvasSession</>;
  }

  const isConnectedSession =
    !!canvasSessionHook.context?.parameters?.isConnected;

  if (!isConnectedSession && !salesforceData) {
    return (
      <div className={classes.integrationWindow}>
        <Card
          icon={<TransitConnectionVariant />}
          title="Setup your integration"
          subtitle="Set up the Tackle app and sync your Marketplace, Co-Sell, and Prospect data. Creating an integration user is also recommended, but it is not required."
        >
          <Box className={classes.connectContainer}>
            <img
              src={integrationIconUrl}
              alt="Integration icon"
              className={classes.integrationIcon}
            />
            <Button
              className={classes.connectButton}
              variant="contained"
              color="secondary"
              disabled={isPendingConnection}
              onClick={openSalesforceOAuthPopUp}
            >
              {isConnecting ? 'Connecting...' : 'Connect to Tackle'}
            </Button>
          </Box>
        </Card>
      </div>
    );
  }

  if (dataError) {
    return <>Error loading Salesforce data: {dataError.message}</>;
  }

  const handleDisconnect = async (closeModal: () => void) => {
    if (salesforceData?.instanceUrl) {
      await disconnectSalesforce(closeModal, salesforceData.instanceUrl);
      setIsConnected(false);
      setDisconnected(true);
      salesforceData.status = 'not connected';
    } else {
      toast.error('Error disconnecting from Salesforce.');
    }
  };
  return (
    <div className={classes.integrationWindow}>
      <SalesforceConnectionTable
        data={salesforceData}
        isConnected={
          canvasSessionHook.context?.parameters?.isConnected ?? isConnected
        }
        openSalesforceOAuthPopUp={openSalesforceOAuthPopUp}
        disconnected={disconnected}
        connectionStatus={salesforceData?.status ?? 'not connected'}
        headerType="salesforce"
        useLiveStatus={true}
      />
      <Box className={classes.spacer} />
      {isConnected && (
        <SalesforceDisconnectButton onDisconnect={handleDisconnect} />
      )}
    </div>
  );
}
