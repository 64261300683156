import { createContext, useContext } from 'react';

export interface CoSellContextType {
  /** the tackle co-sell opportunity id */
  opportunityId?: string;
  /** the tackle co-sell invitation id */
  invitationId?: string;
  /** explicitly set the render environment the component is in
   *  defaults to downstream
   */
  renderEnv?: 'sf_canvas' | 'downstream';
  /** authenticated vendorId from the Salesforce OTK session */
  vendorId?: string;
}
/** vendorId is required by the context provider, so should be set on init */
export const CoSellContext = createContext<CoSellContextType>({ vendorId: '' });

export type CoSellContextProviderProps = Pick<
  CoSellContextType,
  'opportunityId' | 'invitationId' | 'renderEnv' | 'vendorId'
> & {
  children: React.ReactNode;
};

/**
 * context to keep track of the vendor id
 */
export const CoSellContextProvider = ({
  children,
  opportunityId,
  invitationId,
  renderEnv = 'downstream',
  vendorId,
}: CoSellContextProviderProps) => {
  return (
    <CoSellContext.Provider
      value={{
        opportunityId,
        invitationId,
        renderEnv,
        vendorId,
      }}
    >
      {children}
    </CoSellContext.Provider>
  );
};

export function useCoSellContext() {
  const context = useContext(CoSellContext);
  if (context === undefined) {
    throw new Error(
      'useCoSellContext must be used within a CoSellContextProvider',
    );
  }
  return context;
}
