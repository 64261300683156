import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { FieldKey, FormValues } from './formSchema';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.OfferId]: null,
  [FieldKey.isAmended]: false,
  [FieldKey.SourceOfferId]: null,
  [FieldKey.SellingAuthorizationId]: null,
};

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  return {
    [FieldKey.OfferId]: offer?.offerId,
    [FieldKey.isAmended]: !!offer?.sourceOfferId,
    [FieldKey.SourceOfferId]: offer?.sourceOfferId,
    [FieldKey.SellingAuthorizationId]: offer?.partnerOffer?.partnerOfferRef,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  return { ...initialFormValuesForExistingOffer(offer) };
};

export const initialFormValuesForAmendOffer = (
  _: AwsPrivateOffer,
): Omit<FormValues, 'sourceOfferId'> => {
  return {
    [FieldKey.OfferId]: null,
    [FieldKey.isAmended]: false,
    [FieldKey.SellingAuthorizationId]: null,
  };
};
