import { ClipboardText } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo } from 'react';
import { Banner, Card, Link, TextField } from '@tackle-io/platform-ui';
import {
  FieldKey,
  RegistrationPageField,
} from 'pages/PrivateOffers/pages/Next/generic/RegistrationDetailsFormSection/formSchema';
import { Field, FieldArray, useFormikContext } from 'formik';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { FormValues as FullFormValues } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formSchema';
import { Box, Grid, Typography } from 'vendor/material/index';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

interface RegistrationDetailsFormSectionProps {
  sectionId: string;
  sectionTitle: string;
}

// Note: This is what was previously used, but doesn't account for Select input types
export const determineHTMLInputType = (
  field: RegistrationPageField,
): HTMLInputElement['type'] => {
  // Attempt to determine HTML Input type by data_type
  // data_type is not a standard type, infer from the data_type or title
  const fieldType = field.dataType;
  if (fieldType === 'number') return 'number';
  if (fieldType === 'boolean' || fieldType === 'true/false') return 'checkbox';

  const title = field.title.toLowerCase();
  if (title.includes('name')) return 'name';
  if (title.includes('email')) return 'email';
  if (title.includes('company address')) return 'street-address';
  if (title.includes('website')) return 'url';
  if (title.includes('phone number')) return 'tel';
  if (title === 'company') return 'organization';

  return 'text';
};

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.RegistrationPageFields]: 'Registration Page Fields',
};

const RegistrationDetailsFormSection: React.FunctionComponent<
  RegistrationDetailsFormSectionProps
> = ({ sectionId, sectionTitle }) => {
  const { values, setFieldValue, initialValues } =
    useFormikContext<FullFormValues>();
  const { productRef } = values;
  const { productsByProductId } = useContext(OfferPageContext);
  const { offerHasPendingMarketplaceOp } = useContext(OfferContext);

  const matchingProduct = useMemo(
    () => findMatchingProduct(productRef, productsByProductId),
    [productRef, productsByProductId],
  );

  useEffect(() => {
    (async () => {
      const regFields = matchingProduct.registrationPageFields
        .filter(
          (field) =>
            !['Company', 'Full Name', 'Email Address'].includes(
              field?.displayTitle,
            ),
        )
        .map((field) => {
          const existingField =
            matchingProduct.productid === initialValues.productRef
              ? initialValues.registrationPageFields.find(
                  (f) => f[FieldKey.DisplayTitle] === field?.displayTitle,
                )
              : undefined;
          return {
            [FieldKey.DisplayTitle]: field?.displayTitle || '',
            [FieldKey.DataType]: field?.dataType || 'text',
            [FieldKey.TackleRequired]: field?.tackleRequired || false,
            [FieldKey.Title]: field?.title || '',
            [FieldKey.Enabled]: field?.enabled || false,
            [FieldKey.FieldValue]: existingField
              ? existingField[FieldKey.FieldValue]
              : '',
          };
        });
      await setFieldValue(FieldKey.RegistrationPageFields, regFields);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matchingProduct.registrationPageFields,
    initialValues.productRef,
    initialValues.registrationPageFields,
  ]);

  const showInfoBanner =
    matchingProduct?.registrationHosting === 'self-hosted' ||
    matchingProduct?.registrationPageFields.every((pf) => pf.tackleRequired);

  return (
    <div id={sectionId}>
      <Card
        title={`${sectionTitle} (optional)`}
        subtitle="Enter registration information for the listing selected. Adding details now ensures you receive the New Order notification immediately after you extend your offer."
        icon={<ClipboardText />}
      >
        {showInfoBanner ? (
          <Box mb={2}>
            <Banner
              title="The listing does not have any custom registration fields"
              type="info"
              borderPosition="top"
              isCollapsible={true}
              defaultOpen={false}
              body={
                <Typography component="span">
                  <p>
                    By default, Tackle captures the Company name, Email address,
                    and Full name of the customer.
                  </p>
                  <p>
                    To add additional registration fields to your{' '}
                    <strong>Tackle-hosted</strong> registration that can be
                    prefilled on the offer for an accurate purchase order
                    receipt, update the registration fields on the{' '}
                    <Link
                      to={`/listings/${matchingProduct?.cloud}/${matchingProduct?.productidInternal}/registration/registration-fields`}
                      external
                    >
                      listing in Tackle
                    </Link>
                    . This step is optional.
                  </p>
                </Typography>
              }
            />
          </Box>
        ) : (
          <FieldArray name={FieldKey.RegistrationPageFields}>
            {({ form }) => {
              return (
                <Grid container direction="column" spacing={2}>
                  {form.values[FieldKey.RegistrationPageFields].map(
                    (
                      filteredField: RegistrationPageField,
                      index: number,
                    ): React.ReactNode => {
                      return (
                        <Grid item key={filteredField.title}>
                          <Field
                            name={`${FieldKey.RegistrationPageFields}[${index}].${FieldKey.FieldValue}`}
                          >
                            {({ field, meta }) => {
                              return (
                                <TextField
                                  {...field}
                                  data-id={DataId.RegistrationPageField}
                                  label={filteredField.displayTitle}
                                  type={determineHTMLInputType(filteredField)}
                                  error={getFormattedError(
                                    FieldKey.RegistrationPageFields[index],
                                    fieldKeyToLabel,
                                    meta,
                                  )}
                                  disabled={offerHasPendingMarketplaceOp}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              );
            }}
          </FieldArray>
        )}
      </Card>
    </div>
  );
};

export default RegistrationDetailsFormSection;
