import React, { useCallback, useContext, useEffect, useState } from 'react';
import usePolling from 'pages/PrivateOffers/pages/Next/generic/hooks/usePolling';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';
import { Button, Link, Modal, ProviderIcon } from '@tackle-io/platform-ui';
import SectionDivider from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/components/SectionDivider';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { getLatestOfferActivitySlug } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import { isOfferExpired } from '../../utils/offer/offerUtils';
import OfferContext from '../../OfferContext/offerContext';
import OfferPageContext from '../../OfferPageContext/offerPageContext';
import MarketplaceOpInProgressSvg from '../../../assets/MarketplaceOpInProgressSvg';
import DraftOfferSvg from '../../../assets/DraftOfferSvg';
import { useRbac } from 'utils/rbac';
import {
  MarketplaceOperationPendingPollingInterval,
  shouldStartPolling,
  stopPollingPredicate,
} from '../../utils/polling/marketplaceOperationPollingUtils';
import { Offer } from '../../types/Offer';
import { ActivitySlug, OfferType } from '../../types/TackleOffer';

interface OfferActionsProps {
  offerUrl?: string;
  hideSendEmailButton?: boolean;
}

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.NEUTRAL000,
    border: `1px solid ${theme.palette.BLUE400}`,
  },
  actionTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  actionDescription: {
    fontSize: 12,
    fontWeight: 400,
  },
  offerUrlContainer: {
    wordBreak: 'break-word',
  },
  modalContent: {
    marginBottom: theme.spacing(2),
  },
}));

const OfferDraftedTitle = 'Draft offer';
const OfferCreatedTitle = 'Offer created in marketplace';
const OfferEmailSentTitle = 'Offer email sent';
const OfferEmailReadTitle = 'Offer email read';
const OfferViewedTitle = 'Offer viewed';
const OfferAcceptedTitle = 'Offer accepted';
const OfferCanceledTitle = 'Offer cancelled';
const MarketplaceOpInProgressTitle = 'Marketplace operation  in progress';

const actionTitleByActivitySlug = {
  [ActivitySlug.VendorDraftedOffer]: OfferDraftedTitle,
  [ActivitySlug.VendorCreatedOffer]: OfferDraftedTitle,
  [ActivitySlug.VendorCreatedOfferInSalesforce]: OfferDraftedTitle,
  [ActivitySlug.VendorClonedOffer]: OfferDraftedTitle,
  [ActivitySlug.VendorSentPurchaseInstructions]: OfferEmailSentTitle,
  [ActivitySlug.VendorReSentPurchaseInstructions]: OfferEmailSentTitle,
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: OfferEmailReadTitle,
  [ActivitySlug.BuyerViewedOffer]: OfferViewedTitle,
  [ActivitySlug.BuyerAcceptedOffer]: OfferAcceptedTitle,
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]:
    MarketplaceOpInProgressTitle,
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]: OfferCreatedTitle,
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]: OfferDraftedTitle,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]:
    MarketplaceOpInProgressTitle,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]:
    OfferCreatedTitle,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]:
    OfferCreatedTitle,
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]:
    MarketplaceOpInProgressTitle,
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]: OfferCanceledTitle,
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]: OfferCreatedTitle,
};

const actionIconByActivitySlug = {
  [ActivitySlug.VendorDraftedOffer]: DraftOfferSvg,
  [ActivitySlug.VendorCreatedOffer]: DraftOfferSvg,
  [ActivitySlug.VendorCreatedOfferInSalesforce]: DraftOfferSvg,
  [ActivitySlug.VendorClonedOffer]: DraftOfferSvg,
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]:
    MarketplaceOpInProgressSvg,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]:
    MarketplaceOpInProgressSvg,
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]:
    MarketplaceOpInProgressSvg,
};

const OfferDraftedDescription =
  'This draft offer has not been submitted to the cloud. You can continue editing, and when you are ready, submit for cloud review.';
const OfferCreatedDescription =
  'Now that the offer is created, send an email invite to your buyer to view and accept your offer. Your buyer will receive an email with instructions to access the offer. Users added for notifications will receive status updates.';
const OfferEmailSentDescription =
  'Your buyer has been sent an offer email with instructions to view and accept your offer, but has not yet read the email. You can resend the email to ensure they receive your offer.';
const OfferEmailReadDescription =
  'Your buyer has read the offer email sent but has not yet viewed the offer in the marketplace. You can resend the email to ensure they view your offer.';
const OfferViewedDescription =
  'Your buyer has viewed your offer in the marketplace. The last step is for the buyer to accept the offer. You can resend the offer to ensure they accept it.';
const OfferAcceptedDescription =
  'Your buyer has accepted your offer. If this is a future dated agreement (FDA), the subscription will become active on the start date. Download your bookable artifact.';
const OfferCancelledDescription =
  "This offer has been successfully cancelled. The buyer will no longer be able to accept this offer. If you'd like to continue to transact with this customer, proceed with creating a new offer.";
const MarketplaceOpInProgressDescription =
  'This offer is being updated. Please wait a few moments while we get a response from the cloud.';

const actionDescriptionByActivitySlug = {
  [ActivitySlug.VendorDraftedOffer]: OfferDraftedDescription,
  [ActivitySlug.VendorCreatedOffer]: OfferDraftedDescription,
  [ActivitySlug.VendorCreatedOfferInSalesforce]: OfferDraftedDescription,
  [ActivitySlug.VendorClonedOffer]: OfferDraftedDescription,
  [ActivitySlug.VendorSentPurchaseInstructions]: OfferEmailSentDescription,
  [ActivitySlug.VendorReSentPurchaseInstructions]: OfferEmailSentDescription,
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: OfferEmailReadDescription,
  [ActivitySlug.BuyerViewedOffer]: OfferViewedDescription,
  [ActivitySlug.BuyerAcceptedOffer]: OfferAcceptedDescription,
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]:
    MarketplaceOpInProgressDescription,
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]: OfferCreatedDescription,
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]: OfferDraftedDescription,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]:
    MarketplaceOpInProgressDescription,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]:
    OfferCreatedDescription,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]:
    OfferCreatedDescription,
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]:
    MarketplaceOpInProgressDescription,
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]:
    OfferCancelledDescription,
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]: OfferCreatedDescription,
};

const OfferActions: React.FunctionComponent<OfferActionsProps> = ({
  offerUrl,
  hideSendEmailButton,
}) => {
  const { hasPermission } = useRbac();
  const classes = useStyles();
  const { onEdit, afterArchive } = useContext(OfferPageContext);
  const {
    apiForMarketplace: {
      getOfferSilently,
      updateOffer,
      sendBuyerInstructions,
      cancelOffer,
      archiveOffer,
    },
    isSubmitting,
  } = useContext(ApiContext);

  const { tackleOffer, offer, setOffer } = useContext(OfferContext);
  const [showModal, setShowModal] = useState(false);
  const [isCancelAndArchive, setIsCancelAndArchive] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelOfferError, setCancelOfferError] = useState<boolean>(false);

  const updateEditOfferContextOffer = useCallback(
    (o: Offer) => {
      setOffer(o);
    },
    [setOffer],
  );

  const latestActivitySlug = getLatestOfferActivitySlug(offer);

  const { isPolling } = usePolling<Offer>({
    startPollingPredicate: shouldStartPolling(latestActivitySlug),
    pollingFn: async () => await getOfferSilently(offer.poId),
    stopPollingPredicate,
    afterPollingComplete: updateEditOfferContextOffer,
    pollingInterval: MarketplaceOperationPendingPollingInterval,
  });

  const onEditClicked = () => {
    onEdit(offer);
  };

  const onSubmitToCloudClicked = useCallback(async () => {
    await updateOffer(offer.poId, offer, true);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [updateOffer, offer, getOfferSilently, updateEditOfferContextOffer]);

  const onSendEmailClicked = useCallback(async () => {
    await sendBuyerInstructions(offer.poId);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [
    sendBuyerInstructions,
    offer,
    getOfferSilently,
    updateEditOfferContextOffer,
  ]);

  const onCancelOfferClicked = useCallback(async () => {
    setShowModal(false);
    await cancelOffer(offer.poId);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [cancelOffer, offer, getOfferSilently, updateEditOfferContextOffer]);

  const onCancelAndArchiveClicked = useCallback(async () => {
    setIsCancelAndArchive(true);
    setShowModal(false);
    await cancelOffer(offer.poId);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [cancelOffer, offer, getOfferSilently, updateEditOfferContextOffer]);

  const handleArchiveOffer = useCallback(async () => {
    setLoading(true);
    await archiveOffer(offer.poId);
    setTimeout(() => {
      afterArchive(offer);
    }, 750);
  }, [archiveOffer, offer, afterArchive]);

  useEffect(() => {
    if (
      isCancelAndArchive &&
      latestActivitySlug === ActivitySlug.VendorCancelledMarketplaceOfferSuccess
    ) {
      handleArchiveOffer();
    }
    if (
      isCancelAndArchive &&
      latestActivitySlug === ActivitySlug.VendorCancelledMarketplaceOfferFailed
    ) {
      setCancelOfferError(true);
      setIsCancelAndArchive(false);
    }
    if (
      latestActivitySlug === ActivitySlug.VendorCancelledMarketplaceOfferFailed
    ) {
      setCancelOfferError(true);
    }
  }, [
    handleArchiveOffer,
    archiveOffer,
    isCancelAndArchive,
    latestActivitySlug,
    offer.poId,
  ]);

  const offerIsExpired = isOfferExpired(tackleOffer);

  const title = offerIsExpired
    ? 'Offer expired'
    : actionTitleByActivitySlug[latestActivitySlug] || '';

  const Icon = actionIconByActivitySlug[latestActivitySlug];
  const description = actionDescriptionByActivitySlug[latestActivitySlug] || '';

  const submittingToTheCloud =
    isSubmitting(OfferAPIKey.Offer) ||
    latestActivitySlug === ActivitySlug.VendorCreatedMarketplaceOfferPending;

  const cancellingOffer =
    isSubmitting(OfferAPIKey.CancelOffer) ||
    latestActivitySlug === ActivitySlug.VendorCancelledMarketplaceOfferPending;

  const offerCreatedInMarketplace = !!tackleOffer.createdInMarketplaceAt;

  const showActionsForInProgressOffer =
    offerCreatedInMarketplace &&
    !offerIsExpired &&
    !offer.acceptedAt &&
    !offer.cancelledAt;

  const offerEmailsHaveBeenSent =
    !!offer.sentAt ||
    !!offer.activities.find(
      (a) =>
        a.slug === ActivitySlug.VendorSentPurchaseInstructions ||
        a.slug === ActivitySlug.VendorReSentPurchaseInstructions,
    );

  const modalButtonsLoading = cancellingOffer || isPolling || loading;

  return (
    <Box className={classes.actionContainer}>
      <Box mb={2}>
        <Box mb={2} pr={1}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography display="inline" className={classes.actionTitle}>
                {title}
              </Typography>
            </Grid>
            {Icon && (
              <Grid item xs={1}>
                <Icon />
              </Grid>
            )}
          </Grid>
        </Box>
        <Typography className={classes.actionDescription}>
          {description}
        </Typography>
      </Box>
      {!offer.cancelledAt &&
        !offer.acceptedAt &&
        (hasPermission('offers:UpdateOffer') ||
          (hasPermission('offers:CreateDraftOffer') &&
            !offerCreatedInMarketplace)) && (
          <Box mb={2}>
            <Button
              fullWidth
              variant="outlined"
              appearance="primary"
              onClick={onEditClicked}
              disabled={isPolling}
            >
              Edit offer
            </Button>
          </Box>
        )}
      {hasPermission('offers:CreateOfferOnMarketplace') &&
        !offerCreatedInMarketplace &&
        !offerIsExpired && (
          <Box mb={2}>
            <Button
              fullWidth
              appearance="primary"
              onClick={onSubmitToCloudClicked}
              disabled={isPolling}
              startIcon={<span />}
              loading={submittingToTheCloud}
            >
              {submittingToTheCloud ? 'Updating...' : 'Submit to cloud'}
            </Button>
          </Box>
        )}
      {showActionsForInProgressOffer && (
        <Box mb={2}>
          <Grid container spacing={1}>
            {!hideSendEmailButton &&
              hasPermission('offers:SendOfferInstructions') && (
                <Grid item xs>
                  <Button
                    fullWidth
                    appearance="primary"
                    onClick={onSendEmailClicked}
                    disabled={isPolling}
                    startIcon={<span />}
                    loading={isSubmitting(OfferAPIKey.SendBuyerInstructions)}
                  >
                    {offerEmailsHaveBeenSent ? 'Resend' : 'Send email'}
                  </Button>
                </Grid>
              )}
            {hasPermission('offers:CancelOffer') && (
              <Grid item xs>
                <Button
                  fullWidth
                  appearance="destructive"
                  onClick={() => setShowModal(true)}
                  disabled={isPolling}
                  startIcon={<span />}
                  loading={cancellingOffer}
                >
                  {cancelOfferError
                    ? 'Try again?'
                    : cancellingOffer
                    ? 'Updating...'
                    : 'Cancel'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {offer.cancelledAt && !offer.archivedAt && (
        <Grid item xs>
          <Button
            fullWidth
            appearance="primary"
            variant="outlined"
            onClick={() => {
              setIsArchive(true);
              setShowModal(true);
            }}
            disabled={isPolling || loading}
            startIcon={<span />}
            loading={cancellingOffer || loading}
          >
            {cancellingOffer ? 'Updating...' : 'Archive offer'}
          </Button>
        </Grid>
      )}
      {offerCreatedInMarketplace && (
        <>
          <SectionDivider />
          <Box mb={2}>
            <Box mb={2}>
              <Typography className={classes.actionTitle}>
                {tackleOffer.sourceOfferId
                  ? 'Amended offer URL for buyer'
                  : offer.offerType === OfferType.PartnerResale
                  ? 'Partner offer URL for partner'
                  : 'Direct offer URL for buyer'}
              </Typography>
            </Box>
            <Box className={classes.offerUrlContainer}>
              <Link
                to={offerUrl}
                className={classes.actionDescription}
                external
              >
                {offerUrl}
              </Link>
            </Box>
          </Box>
        </>
      )}
      <Modal
        width={'medium'}
        open={showModal}
        footerActions={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                disabled={modalButtonsLoading}
                loading={modalButtonsLoading}
                appearance="primary"
                variant="outlined"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </Grid>
            {isArchive ? (
              <Grid item>
                <Button
                  disabled={modalButtonsLoading}
                  loading={modalButtonsLoading}
                  appearance="primary"
                  onClick={handleArchiveOffer}
                >
                  Archive offer
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    disabled={modalButtonsLoading}
                    loading={modalButtonsLoading}
                    appearance="destructive"
                    variant="outlined"
                    onClick={onCancelAndArchiveClicked}
                  >
                    Cancel and archive
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={modalButtonsLoading}
                    loading={modalButtonsLoading}
                    appearance="destructive"
                    onClick={onCancelOfferClicked}
                  >
                    Cancel offer
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        }
      >
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.modalContent}
        >
          <Grid container item spacing={2} wrap="nowrap">
            <Grid item>
              <ProviderIcon provider={offer.marketplace} />
            </Grid>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6">
                  {`Are you sure you want to ${
                    isArchive ? 'archive' : 'cancel'
                  } this offer?`}
                </Typography>
              </Grid>
              <Grid item>
                {isArchive ? (
                  <Typography variant="body1">
                    This will hide it from your Tackle offers list. The offer
                    will not be deleted.
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    This will cancel the offer on the marketplace. To cancel
                    your offer and hide it from your Tackle private offers list,
                    click <strong>Cancel and archive</strong>. Click{' '}
                    <strong>Cancel</strong> to return to offer details.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
};

export default OfferActions;
