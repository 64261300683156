import * as yup from 'utils/yup-extended';

export enum FieldKey {
  MarketplaceFee = 'marketplaceFee',
  OfferMetadataFields = 'offerMetadataFields',
  OfferMetadataFieldLabel = 'key',
  OfferMetadataFieldValue = 'value',
  IsDisabled = 'isDisabled',
}

export type OfferMetadataField = {
  [FieldKey.OfferMetadataFieldLabel]: string;
  [FieldKey.OfferMetadataFieldValue]: string;
  [FieldKey.IsDisabled]: boolean;
};

export type FormValues = {
  [FieldKey.MarketplaceFee]: string;
  [FieldKey.OfferMetadataFields]: OfferMetadataField[];
};

const offerMetadataFieldsSchema = yup.array().of(
  yup.object({
    [FieldKey.OfferMetadataFieldLabel]: yup.string().required().progress(true),
    [FieldKey.OfferMetadataFieldValue]: yup.string().nullable().progress(false),
    [FieldKey.IsDisabled]: yup.boolean(),
  }),
);

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.MarketplaceFee]: yup.string().nullable().progress(false),
  [FieldKey.OfferMetadataFields]: offerMetadataFieldsSchema,
});
