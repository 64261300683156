import { useCallback } from 'react';
import {
  authApi,
  offersApi,
  salesforceDataMapperApi,
} from 'pages/PrivateOffers/pages/Next/generic/api/offerAPIs';
import { User } from 'pages/PrivateOffers/pages/Next/generic/api/types/User';
import { Auth0User } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0User';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { Auth0UsersResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0UsersResponseJSON';
import { auth0UserJSONsToAuth0Users } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/auth0UserJSONToAuth0User';
import { userJSONToUser } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/userJSONToUser';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import { ContextValueSetters } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { Document } from 'pages/PrivateOffers/pages/Next/generic/api/types/Document';
import { documentJSONToDocument } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/documentJSONToDocument';
import { DocumentUploadResponseJSON } from '../api/types/DocumentJSON';
import { UserResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/UserResponseJSON';
import { SalesforceDataMappingResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMappingResponseJSON';
import { salesforceDataMappingJSONToSalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/salesforceDataMappingJSONToSalesforceDataMapping';
import { SalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMapping';
import { Marketplace, OfferType } from '../types/TackleOffer';
import useOfferApi, {
  convertJsonToArrayType,
} from 'pages/PrivateOffers/pages/Next/generic/hooks/useOfferApi';

export interface UseMarketplaceAgnosticOfferApi {
  getUser: () => Promise<User>;
  getAuth0Users: () => Promise<Auth0User[]>;
  getProducts: (marketplace: Marketplace) => Promise<Product[]>;
  postDocument: (document: File) => Promise<Document>;
  getSalesforceDataMapping: (
    marketplace: Marketplace,
    offerType: OfferType,
    opportunityId: string,
    mappingId?: string,
  ) => Promise<SalesforceDataMapping | null>;
}

const convertToUser = async (responseJson: UserResponseJSON | null) =>
  userJSONToUser(responseJson?.user);

const convertToUsers = async (responseJson: Auth0UsersResponseJSON | null) =>
  auth0UserJSONsToAuth0Users(responseJson?.users || []);

const convertToProducts = async (responseJson: any) =>
  convertJsonToArrayType<Product>(responseJson);

const convertToDocument = async (responseJson: DocumentUploadResponseJSON) =>
  documentJSONToDocument(responseJson?.data);

const convertToSalesforceDataMapping = async (
  responseJson: SalesforceDataMappingResponseJSON,
) => salesforceDataMappingJSONToSalesforceDataMapping(responseJson);

const useMarketplaceAgnosticOfferApi = (
  accessTokenProvider: () => Promise<string>,
  contextValueSetters: ContextValueSetters,
): UseMarketplaceAgnosticOfferApi => {
  const { stateTrackingGet, stateTrackingSubmit } =
    useOfferApi(contextValueSetters);

  const getUser = useCallback(
    async (): Promise<User> =>
      stateTrackingGet(
        OfferAPIKey.User,
        () => authApi(accessTokenProvider).get('whoami'),
        convertToUser,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAuth0Users = useCallback(
    async (): Promise<Auth0User[]> =>
      stateTrackingGet(
        OfferAPIKey.Users,
        () => offersApi(accessTokenProvider).get('public/v2/users'),
        convertToUsers,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getProducts = useCallback(
    async (marketplace: Marketplace): Promise<Product[]> =>
      stateTrackingGet(
        OfferAPIKey.Products,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/products?cloud=${marketplace}`,
          ),
        convertToProducts,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const postDocument = useCallback(
    async (document: File): Promise<Document> => {
      const body = new FormData();
      body.append('file', document);

      return stateTrackingSubmit(
        OfferAPIKey.Document,
        () =>
          offersApi(accessTokenProvider).post(
            `public/v2/documents?filename=${document.name}`,
            { body },
          ),
        convertToDocument,
      );
    },
    [stateTrackingSubmit, accessTokenProvider],
  );

  const getSalesforceDataMapping = useCallback(
    async (
      marketplace: Marketplace,
      offerType: OfferType,
      opportunityId: string,
      mappingId?: string,
    ): Promise<SalesforceDataMapping | null> => {
      const operation =
        offerType === OfferType.PartnerResale
          ? 'createPartnerOffer'
          : 'createDirectOffer';

      const url =
        opportunityId && mappingId
          ? `api/mapped-fields?crmId=${opportunityId}&mappingId=${mappingId}`
          : `api/mapped-fields?crmId=${opportunityId}&cloud=${marketplace}&domain=offer&operation=${operation}`;

      return stateTrackingGet(
        OfferAPIKey.SalesforceDataMapping,
        () => salesforceDataMapperApi(accessTokenProvider).get(url),
        convertToSalesforceDataMapping,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  return {
    getUser,
    getAuth0Users,
    getProducts,
    postDocument,
    getSalesforceDataMapping,
  };
};

export default useMarketplaceAgnosticOfferApi;
