import React from 'react';
import useStyles from './CreateOfferModal.styles';
import { FormControlLabel, Typography } from 'vendor/material';
import { Radio } from '@tackle-io/platform-ui';
import { ampli } from 'utils/analytics/ampli/index';
import { DataId, OffersProductArea, PageLocation } from '../analytics';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferType } from '../types/TackleOffer';

interface RadioLabel {
  title: string;
  subtitle: string;
}

type ValueType = OfferType | OfferPageMode;

interface CreateOfferModalRadioProps {
  dataId: DataId;
  label: RadioLabel;
  value: ValueType;
  selectedValue: ValueType;
  onClick: () => void;
}

const onClickWrapper =
  ({ onClick, dataId }) =>
  () => {
    ampli.buttonClicked({
      button_product_area: OffersProductArea,
      button_location: PageLocation.CreateOfferModal,
      button_name: dataId,
    });

    onClick();
  };

const CreateOfferModalRadioLabel = ({ label: { title, subtitle } }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.optionTitle}>{title}</Typography>
      <Typography className={classes.optionSubtitle}>{subtitle}</Typography>
    </>
  );
};

const CreateOfferModalRadio: React.FunctionComponent<
  CreateOfferModalRadioProps
> = ({ dataId, label, value, selectedValue, onClick }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.optionLabel}
      value={value}
      label={<CreateOfferModalRadioLabel label={label} />}
      control={
        <Radio
          data-id={dataId}
          checked={selectedValue === value}
          onClick={onClickWrapper({ onClick, dataId })}
        />
      }
    />
  );
};

export default CreateOfferModalRadio;
