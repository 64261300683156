import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './CoSellOpportunitiesTable.styles';
import type {
  ColumnWidths,
  HeaderColumn,
  AceOpportunitySummary,
} from './types';
import {
  OpportunitiesTableColumnNames,
  defaultOpportunityColumnsObj,
} from './utils';
import { GeneralEmptyStateIcon } from 'packages/cosell/assets/GeneralEmptyStateIcon';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import useOpportunitiesListQuery from 'packages/cosell/src/hooks/useOpportunitiesListQuery';
import { AceOpportunityRow, TableHeader, CoSellPagination } from './components';
import { PAGE_LIMIT } from '../../helpers/urlParamsHelper';
import { TableError, TableLoadingRows } from '../../components';

export const renderAceOpportunityRows = ({
  opportunities,
  columnWidths,
}: {
  opportunities: AceOpportunitySummary[];
  columnWidths: ColumnWidths;
}) =>
  opportunities.map((opportunity, rowNumber) => {
    const opportunityKey = opportunity.id;
    return (
      <AceOpportunityRow
        key={`${opportunityKey}_${rowNumber}__opportunity_id`}
        opportunity={opportunity}
        columnWidths={columnWidths}
        dataId={
          rowNumber === 0
            ? coSellLandingPageDataId.OPPORTUNITIES_TABLE_HEADER_ROW
            : undefined
        }
      />
    );
  });

export const CoSellOpportunitiesTable = () => {
  const classes = useStyles();
  // TODO: Update column widths so that there's a horizontal scrollbar on smaller than 1024px screen
  const [columnWidths, setColumnWidths] = useState({
    ...defaultOpportunityColumnsObj,
  });
  const [incomingPage, setIncomingPage] = useState<number | null>(null);

  useEffect(() => {
    if (typeof incomingPage === 'number' && tableHeaderContainerEl.current) {
      tableHeaderContainerEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [incomingPage]);

  const {
    opportunitiesList,
    opportunitiesListError,
    isOpportunitiesListLoading,
    total,
    currentPageCount,
    isNextPage,
    nextIndex,
  } = useOpportunitiesListQuery();

  const opportunities = opportunitiesList?.opportunities || [];

  const tableHeaderContainerEl = useRef<HTMLDivElement>(null);

  //* TODO: Enable Sync status when API team supports accurately*/
  const AceOpportunityHeaders: HeaderColumn[] = [
    {
      field: OpportunitiesTableColumnNames.PROJECT_NAME,
      headerName: 'Project name',
      dataId: coSellLandingPageDataId.OPPORTUNITIES_PROJECT_NAME_HEADER,
    },
    {
      field: OpportunitiesTableColumnNames.CUSTOMER,
      headerName: 'Customer',
      dataId: coSellLandingPageDataId.OPPORTUNITIES_CUSTOMER_HEADER,
    },
    {
      field: OpportunitiesTableColumnNames.COSELL_STATUS,
      headerName: 'Co-Sell status',
      dataId: coSellLandingPageDataId.OPPORTUNITIES_CO_SELL_STATUS_HEADER,
    },
    {
      field: OpportunitiesTableColumnNames.COSELL_STAGE,
      headerName: 'Co-Sell stage',
      dataId: coSellLandingPageDataId.OPPORTUNITIES_CO_SELL_STAGE_HEADER,
    },
    {
      field: OpportunitiesTableColumnNames.DATE_CREATED,
      headerName: 'Date created',
      dataId: coSellLandingPageDataId.OPPORTUNITIES_DATE_CREATED_HEADER,
    },
    // {
    //   field: OpportunitiesTableColumnNames.SYNC_STATUS,
    //   headerName: 'Sync status',
    //   dataId: coSellLandingPageDataId.OPPORTUNITIES_SYNC_STATUS_HEADER,
    // },
  ];

  const emptyResults = opportunities.length < 1;

  if (opportunitiesListError) return <TableError />;

  const isEmptyTable = !isOpportunitiesListLoading && emptyResults;

  const isLoading = isOpportunitiesListLoading;

  const emptyTableContent = (
    <Box className={classes.emptyTable}>
      <GeneralEmptyStateIcon className={classes.emptyIcon} />
      <Typography className={classes.emptyHeader}>No results found</Typography>
      <Typography component={'p'} className={classes.emptyDescription}>
        Try changing your search criteria to find what you're looking for.
      </Typography>
    </Box>
  );

  return (
    <>
      <div className={classes.opportunitiesTableContainer} role="grid">
        <div ref={tableHeaderContainerEl} />
        <TableHeader
          tableColumns={AceOpportunityHeaders}
          setColumnWidths={setColumnWidths}
        />
        {isLoading ? (
          <TableLoadingRows pageLimit={PAGE_LIMIT} />
        ) : (
          <>
            {isEmptyTable
              ? emptyTableContent
              : renderAceOpportunityRows({
                  opportunities,
                  columnWidths,
                })}
          </>
        )}
      </div>
      {!isEmptyTable && (
        <CoSellPagination
          setIncomingPage={setIncomingPage}
          total={total}
          currentPageCount={currentPageCount}
          isNextPage={isNextPage}
          nextIndex={nextIndex}
        />
      )}
    </>
  );
};
