import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Box, Grid } from 'vendor/material';

const AZUREOffersForOpportunity = ({
  azureOffers,
}: {
  azureOffers: AwsPrivateOffer[];
}) => {
  return (
    <Box height="100%">
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <span>Microsoft not yet implemented</span>
        </Grid>
        <Grid item>
          <pre>{JSON.stringify(azureOffers, null, 2)}</pre>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AZUREOffersForOpportunity;
