import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useLocation } from 'react-router-dom';
import {
  PAGE_SIZE_KEY,
  SEARCH_KEY,
  FROM_KEY,
  SOURCE_KEY,
  CLOUD_STATUS_KEY,
  CLOUD_SALES_STAGE_KEY,
  HAS_ERRORS_KEY,
  HAS_CRM_ID_KEY,
  TAB_KEY,
  OpportunityColumnKey,
  SortDirection,
  SORT_ORDER_KEY,
  SORT_FIELD_KEY,
  DEFAULT_OPPORTUNITIES_SORT_KEY,
  DEFAULT_OPPORTUNITIES_SORT_DIRECTION,
} from '../pages/CoSellLandingPage/helpers/urlParamsHelper';
import { getActivePanelFilters } from '../utilities/filters/utils';
import { useEffect, useMemo, useState } from 'react';
import { ActiveFilter } from '../components/FilterPanel/FilterPanel';

const useOpportunitiesListQuery = () => {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const q = searchParams.get(SEARCH_KEY);
  const pageSize = searchParams.get(PAGE_SIZE_KEY);
  const from = searchParams.get(FROM_KEY);
  const cloudStatus = searchParams.get(CLOUD_STATUS_KEY);
  const cloudSalesStage = searchParams.get(CLOUD_SALES_STAGE_KEY);
  const hasErrors = searchParams.get(HAS_ERRORS_KEY);
  const hasCrmId = searchParams.get(HAS_CRM_ID_KEY);
  const source = searchParams.get(SOURCE_KEY);
  const tabKey = searchParams.get(TAB_KEY);
  const sortFieldKey = (searchParams.get(SORT_FIELD_KEY) ||
    DEFAULT_OPPORTUNITIES_SORT_KEY) as OpportunityColumnKey;
  const sortOrder = (searchParams.get(SORT_ORDER_KEY) ||
    DEFAULT_OPPORTUNITIES_SORT_DIRECTION) as SortDirection;
  const [activePanelFilters, setActivePanelFilters] = useState<ActiveFilter[]>(
    getActivePanelFilters({ searchParams }) || [],
  );

  const sortState = {
    sortKey: sortFieldKey,
    sortDirection: sortOrder,
  };

  useEffect(() => {
    setActivePanelFilters(getActivePanelFilters({ searchParams }));
  }, [source, cloudStatus, cloudSalesStage, hasErrors, hasCrmId, searchParams]);

  const {
    data: opportunitiesList,
    error: opportunitiesListError,
    isLoading: isOpportunitiesListLoading,
  } = useQuery({
    queryKey: [
      'cosell-opportunities-list',
      'ace',
      {
        q,
        pageSize,
        from,
        activePanelFilters,
        sortState,
      },
    ],
    queryFn: () => {
      return coSellClient.getOpportunitiesList({
        q,
        pageSize,
        from,
        activePanelFilters,
        sortState,
      });
    },
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
    enabled: tabKey !== 'invitations',
  });

  return {
    opportunitiesList,
    opportunitiesListError,
    isOpportunitiesListLoading,
    total: opportunitiesList?.total,
    isNextPage: opportunitiesList?.isNextPage,
    currentPageCount: opportunitiesList?.currentPageCount,
    nextIndex: opportunitiesList?.nextIndex,
    activePanelFilters,
    sortState,
  };
};

export default useOpportunitiesListQuery;
