import { FormSections, formSectionToTitle } from '../formSections';
import { Card, InputRadio, Link } from '@tackle-io/platform-ui';
import { EULAType } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import OfferDocumentUploader from 'pages/PrivateOffers/pages/Next/generic/OfferDocumentUploader/OfferDocumentUploader';
import { Field, useFormikContext } from 'formik';
import { FieldKey } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/EndUserLicenseAgreementForSection/formSchema';
import OfferFormSection from 'pages/PrivateOffers/pages/Next/generic/OfferFormSection/OfferFormSection';
import { RadioGroup } from 'vendor/material';
import {
  createEventNameInjectingOnBlurHandler,
  createEventWrappingOnChangeHandler,
} from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import { standardContractUrl } from 'pages/PrivateOffers/pages/Next/aws/shared/urls';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { useCallback, useContext } from 'react';
import { FormValues } from '../formSchema';
import EulaSvg from 'pages/PrivateOffers/pages/Next/assets/EulaSvg';

const documentTypeOptions = [
  { label: 'Public offer EULA', value: EULAType.Public },
  {
    label: 'Standard contract for AWS marketplace',
    value: EULAType.AwsStandard,
    href: standardContractUrl,
  },
  { label: 'Custom contract', value: EULAType.Custom },
];

const getDocumentTypeOptions = (isAmendmentMode: boolean) =>
  isAmendmentMode
    ? [
        {
          label: 'Legal terms from previous agreement',
          value: EULAType.UseExisting,
        },
        ...documentTypeOptions,
      ]
    : documentTypeOptions;

const EndUserLicenseAgreementFormSection = () => {
  const { mode } = useContext(OfferPageContext);
  const { setFieldValue } = useFormikContext<FormValues>();

  const { offerIsMarketplaceEditable, offerIsAmendment } =
    useContext(OfferContext);

  const documentTypeOptionsForMode = getDocumentTypeOptions(
    mode === OfferPageMode.Amend || offerIsAmendment,
  );

  const onChange = useCallback(async () => {
    await setFieldValue(FieldKey.EulaFiles, []);
  }, [setFieldValue]);

  return (
    <div id={FormSections.EndUserLicenseAgreement}>
      <Card
        title={formSectionToTitle[FormSections.EndUserLicenseAgreement]}
        subtitle="Select an end-user license agreement (EULA) to add to your offer."
        icon={<EulaSvg />}
      >
        <>
          <OfferFormSection title="EULA version">
            <Field name={FieldKey.EulaType}>
              {({ field }) => (
                <RadioGroup
                  {...field}
                  data-id={DataId.DocumentTypeField}
                  onBlur={createEventNameInjectingOnBlurHandler(
                    field.name,
                    field.onBlur,
                  )}
                >
                  {documentTypeOptionsForMode.map((option) => (
                    <span key={option.value}>
                      <InputRadio
                        label={option.label}
                        value={option.value}
                        onClick={createEventWrappingOnChangeHandler(
                          field.name,
                          field.onChange,
                        )}
                        disabled={!offerIsMarketplaceEditable}
                      />
                      {option.href && (
                        <Link to={option.href} external showExternalIcon>
                          View
                        </Link>
                      )}
                    </span>
                  ))}
                </RadioGroup>
              )}
            </Field>
          </OfferFormSection>
          <OfferFormSection
            title="End user license agreement documents"
            subtitle="Upload up to five documents to amend the ISV EULA, which will be combined into a single PDF. Files must be TXT or PDF and no larger than 10MB total."
          >
            <OfferDocumentUploader
              fieldName={FieldKey.EulaDocumentUrns}
              acceptedDocumentTypes=".pdf, .txt"
              uploadDocumentButtonText="Upload EULA(s)"
              afterUpload={onChange}
              afterRemove={onChange}
              disabled={!offerIsMarketplaceEditable}
            />
          </OfferFormSection>
        </>
      </Card>
    </div>
  );
};

export default EndUserLicenseAgreementFormSection;
