import React, { useContext } from 'react';
import OfferProgressStep from 'pages/PrivateOffers/pages/Next/generic/OfferProgressBar/OfferProgressStep';
import useStyles from './OfferProgressBar.styles';
import { Marketplace, OfferType, TackleOffer } from '../types/TackleOffer';
import OfferPageContext from '../OfferPageContext/offerPageContext';

interface OfferProgressBarProps {
  offer: TackleOffer | null;
}

const PartnerOfferProgressBar: React.FC<OfferProgressBarProps> = ({
  offer,
}) => {
  const classes = useStyles();
  const { marketplace } = useContext(OfferPageContext);
  const createdInMarketplace = !!offer?.createdInMarketplaceAt;
  const offerAccepted = !!offer?.acceptedAt;
  const offerCanceled = !!offer?.cancelledAt;

  // TODO - change this once we know how subscribed is determined
  const offerSubscribed = false;

  const offerCompleted =
    marketplace === Marketplace.Azure ? offerSubscribed : offerAccepted;

  return (
    <div
      className={classes.offerStepIndicator}
      data-id="new-offer-step-progress-indicator"
    >
      <>
        <OfferProgressStep
          step={0}
          title="Offer created in marketplace"
          priorStepCompleted={true}
          stepCompleted={createdInMarketplace}
          nextStepCompleted={offerAccepted || offerSubscribed}
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
        />
        {/* TODO - update the step logic to account for this, once we have the data to determine when this step has happened. */}
        <OfferProgressStep
          step={1}
          title="Partner created offer in marketplace"
          priorStepCompleted={createdInMarketplace}
          stepCompleted={offerAccepted}
          nextStepCompleted={offerAccepted || offerSubscribed}
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
        />
        <OfferProgressStep
          step={2}
          title="Offer accepted"
          priorStepCompleted={offerAccepted}
          stepCompleted={offerAccepted}
          nextStepCompleted={
            marketplace === Marketplace.Azure && offerSubscribed
          }
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
          lastStep={marketplace !== Marketplace.Azure}
        />
        {marketplace === Marketplace.Azure && (
          <OfferProgressStep
            step={3}
            title="Offer subscribed"
            priorStepCompleted={offerAccepted}
            stepCompleted={offerSubscribed}
            nextStepCompleted={false}
            offerIsCancelled={offerCanceled}
            offerIsCompleted={offerCompleted}
            lastStep
          />
        )}
      </>
    </div>
  );
};

const DirectOfferProgressBar: React.FC<OfferProgressBarProps> = ({ offer }) => {
  const classes = useStyles();
  const { marketplace } = useContext(OfferPageContext);
  const createdInMarketplace = !!offer?.createdInMarketplaceAt;
  const offerEmailSent = !!offer?.sentAt;
  const offerEmailRead = !!offer?.openedInstructionsAt;
  const offerViewed = !!offer?.viewedOfferAt;
  const offerAccepted = !!offer?.acceptedAt;
  const offerCanceled = !!offer?.cancelledAt;

  // TODO - change this once we know how subscribed is determined
  const offerSubscribed = false;

  const offerCompleted =
    marketplace === Marketplace.Azure ? offerSubscribed : offerAccepted;

  return (
    <div
      className={classes.offerStepIndicator}
      data-id="new-offer-step-progress-indicator"
    >
      <>
        <OfferProgressStep
          step={0}
          title="Offer created in marketplace"
          priorStepCompleted={true}
          stepCompleted={createdInMarketplace}
          nextStepCompleted={
            offerEmailSent ||
            offerEmailRead ||
            offerViewed ||
            offerAccepted ||
            offerSubscribed
          }
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
        />
        <OfferProgressStep
          step={1}
          title="Offer email sent"
          priorStepCompleted={createdInMarketplace}
          stepCompleted={offerEmailSent}
          nextStepCompleted={
            offerEmailRead || offerViewed || offerAccepted || offerSubscribed
          }
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
        />
        <OfferProgressStep
          step={2}
          title="Offer email read"
          priorStepCompleted={offerEmailSent}
          stepCompleted={offerEmailRead}
          nextStepCompleted={offerViewed || offerAccepted || offerSubscribed}
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
        />
        <OfferProgressStep
          step={3}
          title="Offer viewed"
          priorStepCompleted={offerEmailRead}
          stepCompleted={offerViewed}
          nextStepCompleted={offerAccepted || offerSubscribed}
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
        />
        <OfferProgressStep
          step={4}
          title="Offer accepted"
          priorStepCompleted={offerViewed}
          stepCompleted={offerAccepted}
          nextStepCompleted={
            marketplace === Marketplace.Azure && offerSubscribed
          }
          offerIsCancelled={offerCanceled}
          offerIsCompleted={offerCompleted}
          lastStep={marketplace !== Marketplace.Azure}
        />
        {marketplace === Marketplace.Azure && (
          <OfferProgressStep
            step={5}
            title="Offer subscribed"
            priorStepCompleted={offerAccepted}
            stepCompleted={offerSubscribed}
            nextStepCompleted={false}
            offerIsCancelled={offerCanceled}
            offerIsCompleted={offerCompleted}
            lastStep
          />
        )}
      </>
    </div>
  );
};

const OfferProgressBar: React.FunctionComponent<
  { offerType: OfferType } & OfferProgressBarProps
> = ({ offerType, offer }) => {
  const ProgressBarComponentForOfferType =
    offerType === OfferType.PartnerResale
      ? PartnerOfferProgressBar
      : DirectOfferProgressBar;

  return <ProgressBarComponentForOfferType offer={offer} />;
};

export default OfferProgressBar;
