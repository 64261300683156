import React, { SetStateAction, Dispatch } from 'react';
import { Formik, useFormikContext } from 'formik';
import { EditModal } from 'packages/cosell/src/components/EditModal';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { SingleSelectFormField } from 'packages/cosell/src/components';
import { Box } from 'vendor/material';
import { useAceInvitation } from 'packages/cosell/api/hooks/useAceInvitation';

const RightRailRejectModalContent = ({
  setRejectModalOpen,
  awsEnumListMap,
  onSubmit,
}: {
  setRejectModalOpen: Dispatch<SetStateAction<boolean>>;
  awsEnumListMap?: AwsEnumListResponse;
  onSubmit: (args: { rejectionReason: string }) => Promise<void>;
}) => {
  const { values } = useFormikContext<{ rejectionReason: string }>();
  const rejectionReason = values?.rejectionReason;

  return (
    <EditModal
      open={true}
      onClose={() => setRejectModalOpen(false)}
      onSubmit={() =>
        onSubmit({
          rejectionReason: rejectionReason,
        })
      }
      submitLabel="Reject now"
      cancelLabel="Back to invitation"
      title={'Are you sure you want to reject this invitation?'}
      submitAppearance="destructive"
      submitButtonDisabled={typeof rejectionReason !== 'string'}
    >
      <Box mb={4}>
        <SingleSelectFormField
          fieldId="rejectionReason"
          filterSelectedOptions={true}
          label="Reason for rejection"
          options={awsEnumListMap?.['rejectionReason']}
          dataId={coSellAceOpportunityFormFieldsDataId.REJECTION_REASON}
          required
        />
      </Box>
    </EditModal>
  );
};

const RightRailRejectModal = ({
  handleRejectInvitation,
  invitationId,
  setRejectModalOpen,
}: {
  invitationId: string;
  setRejectModalOpen: Dispatch<SetStateAction<boolean>>;
  handleRejectInvitation: () => void;
}) => {
  const { data: awsEnumListMap } = useAcePickListQuery();
  const { rejectInvitation } = useAceInvitation({
    invitationId,
  });

  const onSubmit = async ({ rejectionReason }: { rejectionReason: string }) => {
    if (invitationId) {
      await rejectInvitation.mutateAsync(
        {
          invitationId,
          rejectionReason,
        },
        {
          onSuccess: () => {
            handleRejectInvitation();
          },
        },
      );
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        // noOp because we use the button outside of the form to submit
        // noop
      }}
    >
      <RightRailRejectModalContent
        setRejectModalOpen={setRejectModalOpen}
        awsEnumListMap={awsEnumListMap}
        onSubmit={onSubmit}
      />
    </Formik>
  );
};

export default RightRailRejectModal;
