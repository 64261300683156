import { FormValues } from './formSchema';
import {
  initialFormValuesForAmendOffer as basicInformationInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as basicInformationInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as basicInformationInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as basicInformationInitialFormValuesForNewOffer,
} from './BasicInformationFormSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as productAndPricingInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as productAndPricingInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as productAndPricingInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as productAndPricingInitialFormValuesForNewOffer,
} from './ProductAndPricingFormSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as endUserLicenseAgreementInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as endUserLicenseAgreementInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as endUserLicenseAgreementInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as endUserLicenseAgreementInitialFormValuesForNewOffer,
} from './EndUserLicenseAgreementForSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as resellerAgreementInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as resellerAgreementInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as resellerAgreementInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as resellerAgreementInitialFormValuesForNewOffer,
} from './ResllerAgreementForSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as registrationDetailsInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as registrationDetailsInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as registrationDetailsInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as registrationDetailsInitialFormValuesForNewOffer,
} from './RegistrationDetailsFormSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as additionalFieldsInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as additionalFieldsInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as additionalFieldsInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as additionalFieldsInitialFormValuesForNewOffer,
} from './AdditionalFieldsFormSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as offerValidityInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as offerValidityInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as offerValidityInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as offerValidityInitialFormValuesForNewOffer,
} from './OfferValidityPeriodFormSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as notifyUsersInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as notifyUsersInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as notifyUsersInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as notifyUsersInitialFormValuesForNewOffer,
} from './NotifyUsersFormSection/initialFormValues';
import {
  initialFormValuesForAmendOffer as linkOfferInitialFormValuesForAmendOffer,
  initialFormValuesForCloneOffer as linkOfferInitialFormValuesForCloneOffer,
  initialFormValuesForExistingOffer as linkOfferInitialFormValuesForExistingOffer,
  initialFormValuesForNewOffer as linkOfferInitialFormValuesForNewOffer,
} from './LinkOfferFormSection/initialFormValues';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Auth0User } from '../../../generic/api/types/Auth0User';
import { User } from '../../../generic/api/types/User';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { Marketplace, OfferType } from '../../../generic/types/TackleOffer';

export const initialFormValuesForNewOffer = (
  offerType: OfferType,
  userId: string,
  opportunityId?: string,
): FormValues => ({
  cloud: Marketplace.Aws,
  opportunityId,
  offerType,
  sourceOfferId: null,
  createInMarketplace: false,
  ...basicInformationInitialFormValuesForNewOffer(offerType),
  ...productAndPricingInitialFormValuesForNewOffer,
  ...endUserLicenseAgreementInitialFormValuesForNewOffer,
  ...resellerAgreementInitialFormValuesForNewOffer,
  ...registrationDetailsInitialFormValuesForNewOffer,
  ...additionalFieldsInitialFormValuesForNewOffer,
  ...offerValidityInitialFormValuesForNewOffer(),
  ...notifyUsersInitialFormValuesForNewOffer(userId),
  ...linkOfferInitialFormValuesForNewOffer,
});

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
  product: Product | null,
): FormValues => {
  const { marketplace, offerType, sourceOfferId } = offer;

  return {
    cloud: marketplace,
    offerType,
    sourceOfferId,
    createInMarketplace: false,
    ...basicInformationInitialFormValuesForExistingOffer(offer),
    ...productAndPricingInitialFormValuesForExistingOffer(offer),
    ...endUserLicenseAgreementInitialFormValuesForExistingOffer(offer),
    ...resellerAgreementInitialFormValuesForExistingOffer(offer),
    ...registrationDetailsInitialFormValuesForExistingOffer(offer, product),
    ...additionalFieldsInitialFormValuesForExistingOffer(offer, product),
    ...offerValidityInitialFormValuesForExistingOffer(offer),
    ...notifyUsersInitialFormValuesForExistingOffer(offer),
    ...linkOfferInitialFormValuesForExistingOffer(offer),
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
  product: Product | null,
  user: any,
  users: Auth0User[],
): FormValues => {
  const { marketplace, offerType } = offer;

  return {
    cloud: marketplace,
    offerType,
    sourceOfferId: null,
    createInMarketplace: false,
    ...basicInformationInitialFormValuesForCloneOffer(offer),
    ...productAndPricingInitialFormValuesForCloneOffer(offer),
    ...endUserLicenseAgreementInitialFormValuesForCloneOffer(offer),
    ...resellerAgreementInitialFormValuesForCloneOffer(offer),
    ...registrationDetailsInitialFormValuesForCloneOffer(offer, product),
    ...additionalFieldsInitialFormValuesForCloneOffer(offer, product),
    ...offerValidityInitialFormValuesForCloneOffer(offer),
    ...notifyUsersInitialFormValuesForCloneOffer(offer, user, users),
    ...linkOfferInitialFormValuesForCloneOffer(offer),
  };
};

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
  product: Product | null,
  user: User,
  users: Auth0User[],
): FormValues => {
  const { offerType } = offer;

  return {
    cloud: Marketplace.Aws,
    offerType,
    sourceOfferId: offer.offerId,
    createInMarketplace: false,
    ...basicInformationInitialFormValuesForAmendOffer(offer),
    ...productAndPricingInitialFormValuesForAmendOffer(offer),
    ...endUserLicenseAgreementInitialFormValuesForAmendOffer(offer),
    ...resellerAgreementInitialFormValuesForAmendOffer(offer),
    ...registrationDetailsInitialFormValuesForAmendOffer(offer, product),
    ...additionalFieldsInitialFormValuesForAmendOffer(offer, product),
    ...offerValidityInitialFormValuesForAmendOffer(offer),
    ...notifyUsersInitialFormValuesForAmendOffer(offer, user, users),
    ...linkOfferInitialFormValuesForAmendOffer(offer),
  };
};
