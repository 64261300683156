import { awsCoSellToFormErrorFieldEnumMap } from 'packages/cosell/src/utilities/typeConverters/awsCoSellToFormErrorsFieldEnumMap';

/** a union of all data fields in list */
type AwsCoSellDataField = keyof typeof awsCoSellToFormErrorFieldEnumMap;

/**
 * returns the local form field name or label
 * for a given AWS field name
 * if not in map, returns the original name
 */
export function getFormFieldByAwsErrorFieldName({
  key,
  name,
}: {
  key: 'label' | 'name';
  name: string;
}) {
  if (!(name in awsCoSellToFormErrorFieldEnumMap)) {
    return name;
  }

  return awsCoSellToFormErrorFieldEnumMap[name as AwsCoSellDataField]?.[key];
}
