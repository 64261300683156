import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SalesforceCrmConnectorResponse } from '../CanvasTackleIntegrationStatus';
import { Box, Typography, Link } from 'vendor/material';
import { useMessageHandlerStyles } from './MessageHandler.styles';

export function useSalesforceMessageHandler(
  setPopupMessageResponse: React.Dispatch<any>,
  setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>,
  setIsPendingConnection: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const classes = useMessageHandlerStyles();

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      if (typeof event.data !== 'string') {
        console.error('Received non-JSON message:', event.data);
        return;
      }

      const message = JSON.parse(event.data);

      if (message.type !== 'SALESFORCE_AUTH_COMPLETE') {
        console.log('Unexpected message type:', message.type);
        return;
      }

      setPopupMessageResponse(message.payload);
      setIsPendingConnection(false);

      const crmConnectorData: SalesforceCrmConnectorResponse | null =
        message.payload?.crmConnectorData || null;

      if (!crmConnectorData) {
        console.warn('No CRM connector data provided.');
        return;
      }

      const instanceUrl = `${crmConnectorData.instanceUrl}/lightning/n/tackle__Setup`;

      const handleDismiss = () => {
        toast.dismiss();
        window.location.href = instanceUrl;
      };

      toast.success(
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{ marginRight: '8px' }}
            >
              <g clipPath="url(#clip0_918_7147)">
                <circle cx="12" cy="12" r="12" fill="white" />
                <path
                  d="M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                  fill="#00875A"
                />
              </g>
              <defs>
                <clipPath id="clip0_918_7147">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Typography variant="body1" className={classes.toastText}>
              <Link
                href={instanceUrl}
                target="_blank"
                className={classes.toastLink}
              >
                Connection successful!&nbsp;
                <span className={classes.toastLinkUnderline}>
                  Refresh this page
                </span>
                &nbsp;to view the details.
              </Link>
            </Typography>
          </Box>
          <Box onClick={handleDismiss} style={{ cursor: 'pointer' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_918_7151)">
                <path d="M0 0H24V24H0V0Z" fill="none" />
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#FFFFFF"
                />
              </g>
              <defs>
                <clipPath id="clip0_918_7151">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
        </Box>,
        {
          className: classes.toastSuccess,
          autoClose: false,
          closeButton: false,
        },
      );
    },
    [setPopupMessageResponse, setIsPendingConnection, classes],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);
}
