import React, { SetStateAction, Dispatch, useEffect } from 'react';
import { Box } from 'vendor/material';
import { EditModal, ModalHeader } from 'packages/cosell/src/components';
import AceOpportunityFormFields from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormFields';
import { Form, Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { convertOpportunityResponseToAceFormValues } from '../../utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { UpdateAceOpportunityFormValues } from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  getAwsMarketplaceOffers,
  getSoftwareRevenue,
} from '../../utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import { ampli } from 'utils/analytics/ampli';
import { getEditModalTackleOperationId } from 'packages/cosell/api/utils';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { EditModalType } from './RightRailButtonSection';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import AceOpportunityDetailsHeader from '../../pages/UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/AceOpportunityDetailsHeader';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import { LaunchAceOpportunityRequest } from '../../types/requests/AceOpportunityRequest';
import { removeUndefinedOrEmptyObjectProperties } from '../../utilities/typeConverters/utils';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { useAwsCoSellMappedFields } from 'packages/cosell/src/hooks/useAwsCoSellMappedFields';
import { Loader } from '@tackle-io/platform-ui';
import useOpportunityEventsQuery from '../../hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import {
  getInitialTouched,
  mapErrors,
} from '../UnifiedOpportunityForm/utils/mapErrors';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import {
  closeLostFormSchema,
  editLaunchFormSchema,
  editLaunchFormWithSaasDocRequiredSchema,
  launchFormSchema,
  launchFormWithSaasDocRequiredSchema,
} from '../UnifiedOpportunityForm/AceOpportunityForm/schemas/modalFormValidationSchema';
import useToast from 'hooks/useToast/useToast';
import { TOASTS } from 'packages/cosell/src/components';

const getFormValidationSchema = (
  editModalType: EditModalType,
  enableSaasDocumentationFields: Boolean,
) => {
  switch (editModalType) {
    case EditModalType.LAUNCH:
      return enableSaasDocumentationFields
        ? launchFormWithSaasDocRequiredSchema
        : launchFormSchema;
    case EditModalType.EDIT_LAUNCHED:
      return enableSaasDocumentationFields
        ? editLaunchFormWithSaasDocRequiredSchema
        : editLaunchFormSchema;
    case EditModalType.CLOSE_LOST:
      return closeLostFormSchema;
    default:
      return {}; // No schema validation by default
  }
};

interface RightRailEditModalProps {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
}

const RightRailEditModalContent: React.FC<{
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
  opportunity: AceOpportunityResponse;
  handleSubmitAceOpportunityToCloud: (
    values: UpdateAceOpportunityFormValues,
  ) => Promise<void>;
}> = ({
  editModalType,
  setEditModalOpen,
  opportunity,
  handleSubmitAceOpportunityToCloud,
}) => {
  const { setTouched, values, validateForm } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const validateBeforeSubmit = async () => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length > 0) {
      setTouched(setNestedObjectValues(formErrors, true));
    } else {
      handleSubmitAceOpportunityToCloud(values);
    }
  };

  const reviewStatus = opportunity.lifeCycle?.reviewStatus ?? null;

  const getModalTitle = () => {
    switch (editModalType) {
      case EditModalType.EDIT_LAUNCHED:
        return 'Edit launched co-sell opportunity';
      case EditModalType.LAUNCH:
        return (
          <ModalHeader
            title="Launch co-sell"
            cloud={DisplayCloudType.AWS}
            subtitle="Confirm the following information before you launch."
          />
        );
      case EditModalType.CLOSE_LOST:
        return (
          <ModalHeader
            title="Close lost co-sell"
            cloud={DisplayCloudType.AWS}
          />
        );
      default:
        return 'Edit opportunity';
    }
  };
  const getSubmitLabel = () => {
    switch (editModalType) {
      case EditModalType.LAUNCH:
        return 'Launch';
      case EditModalType.CLOSE_LOST:
        return 'Close lost';
      default:
        return 'Submit';
    }
  };

  return (
    <EditModal
      open={true}
      onClose={() => setEditModalOpen(null)}
      onSubmit={validateBeforeSubmit}
      title={getModalTitle()}
      submitLabel={getSubmitLabel()}
      submitAppearance={
        editModalType === EditModalType.CLOSE_LOST ? 'destructive' : 'primary'
      }
    >
      <div>
        {editModalType === EditModalType.EDIT_LAUNCHED && (
          <Box mt={2} mb={3}>
            <AceOpportunityDetailsHeader
              title={opportunity.customer?.account?.companyName}
              source={opportunity.source}
              lifeCycle={opportunity.lifeCycle}
              crmId={opportunity.metadata?.crmId}
            />
          </Box>
        )}
        <AceOpportunityFormFields
          editModalType={editModalType}
          reviewStatus={reviewStatus}
        />
      </div>
    </EditModal>
  );
};

const RightRailEditModal: React.FC<RightRailEditModalProps> = ({
  editModalType,
  setEditModalOpen,
}: {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
}) => {
  const { toaster } = useToast();
  const { opportunityId, renderEnv } = useCoSellContext();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });
  const { aceOpportunityErrors } = useOpportunityEventsQuery(opportunityId);
  const { isSaasDocumentationRequiredToLaunch } = useAceVendorConfig();
  const crmId = aceOpportunityQuery.data?.metadata?.crmId;

  const formTypeMap = {
    [EditModalType.LAUNCH]: 'launch',
    [EditModalType.CLOSE_LOST]: 'closedLost',
  };
  const shouldUseMappedFields = editModalType in formTypeMap;
  const mappedFields = useAwsCoSellMappedFields({
    crmId: crmId,
    enabled: !!crmId && shouldUseMappedFields && renderEnv === 'sf_canvas',
    formType: formTypeMap[editModalType],
  });

  const mappedFieldsLoadingError = mappedFields.isError;

  useEffect(() => {
    if (mappedFieldsLoadingError) {
      toaster(TOASTS.errorToast('Failed to load mapped fields'));
    }
  }, [mappedFieldsLoadingError, toaster]);

  /** this component is only rendered if it is a complete AceOpportunity */
  const opportunity = aceOpportunityQuery.data as AceOpportunityResponse;

  const enableSaasDocumentationFields =
    isSaasDocumentationRequiredToLaunch({
      deliveryModels: opportunity.project?.deliveryModels || [],
    }) ?? false;
  const formValidationSchema = getFormValidationSchema(
    editModalType,
    enableSaasDocumentationFields,
  );

  const handleEditOpportunity = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    const tackleOperationId = getEditModalTackleOperationId(editModalType);
    /** Close lost uses a different payload than Launch
     *  and needs to be handled separately
     */
    if (editModalType === EditModalType.CLOSE_LOST) {
      await updateAceOpportunity.mutateAsync({
        requestBody: {
          lifeCycle: {
            closedLostReason: values.closedLostReason,
          },
          project: {
            additionalComments: values.additionalComments,
          },
        },
        tackleOperationId,
      });

      return;
    }

    const launchRequest: LaunchAceOpportunityRequest = {
      lifeCycle: {
        targetCloseDate: values.targetCloseDate,
      },
      softwareRevenue: getSoftwareRevenue(values),
      solutions: values.solutions,
      awsMarketplaceOffers: getAwsMarketplaceOffers(values),
    };

    const trimmedLaunchRequest =
      removeUndefinedOrEmptyObjectProperties(launchRequest);

    updateAceOpportunity.mutateAsync({
      requestBody: trimmedLaunchRequest,
      tackleOperationId,
    });
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    try {
      await handleEditOpportunity(values);
      ampli.outboundSubmitted({ cloud: 'aws' });
      setEditModalOpen(null);
    } catch (error) {
      throw error;
    }
  };

  const initialValues = {
    ...convertOpportunityResponseToAceFormValues(opportunity),
    ...mappedFields.mappedData?.initialValues,
  };

  if (mappedFields.isInitialLoading) {
    return <Loader />;
  }
  const mappedErrors = mapErrors(aceOpportunityErrors);
  const initialTouched = getInitialTouched(mappedErrors);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      initialErrors={mappedErrors}
      initialTouched={initialTouched}
      onSubmit={() => {
        // noOp because we use the button outside of the form to submit
        // noop
      }}
    >
      <Form>
        <RightRailEditModalContent
          editModalType={editModalType}
          setEditModalOpen={setEditModalOpen}
          opportunity={opportunity}
          handleSubmitAceOpportunityToCloud={handleSubmitAceOpportunityToCloud}
        />
      </Form>
    </Formik>
  );
};

export default RightRailEditModal;
