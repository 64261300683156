import React from 'react';
import { Box, makeStyles } from 'vendor/material';
import {
  DataId,
  OffersProductArea,
  PageLocation,
} from '../../../../../../generic/analytics';
import { ampli } from 'utils/analytics/ampli/index';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

interface DimensionsLoadFailureProps {
  onRetry: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.BLUE300,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.BLUE500,
    },
    '&:hover': {
      color: theme.palette.BLUE400,
      textDecoration: 'underline',
    },
  },
}));

const DimensionsLoadFailure: React.FunctionComponent<
  DimensionsLoadFailureProps
> = ({ onRetry }) => {
  const classes = useStyles();

  const onRetryClicked = async () => {
    ampli.buttonClicked(
      {
        button_product_area: OffersProductArea,
        button_location: PageLocation.OfferEditPage,
        button_name: DataId.RetryLoadDimensionsButton,
      },
      { extra: { marketplace: Marketplace.Aws } },
    );

    await onRetry();
  };

  return (
    <Box data-id={DataId.RetryLoadDimensionsButton}>
      Please{' '}
      <span className={classes.link} onClick={onRetryClicked}>
        click here
      </span>{' '}
      to try reloading. If that fails and the issue persists, please contact
      Tackle support or manually enter your dimensions.
    </Box>
  );
};

export default DimensionsLoadFailure;
