import {
  AutocompleteRenderInputParams,
  InputLabel,
  TextField as MuiTextField,
  Typography,
} from 'vendor/material';
import React, { ChangeEvent, FocusEvent } from 'react';
import { useTextFieldStyles } from '@tackle-io/platform-ui';
import { FormDimension } from '../formTypes';
import { useStyles } from '../DimensionRow';
import FieldError from '../../../../../../../../../components/FieldError/FieldError';
import { generateAwsDimensionApiName } from 'components/FieldsPricing/utils';

export interface DimensionNameOption {
  name: string;
  lowerCasedName: string;
  apiName: string;
}

const toDimensionNameAndApiName = ({ name, apiName }: FormDimension) => ({
  name,
  apiName,
});

const byLowerCasedDimensionName = (
  { lowerCasedName: n1 }: DimensionNameOption,
  { lowerCasedName: n2 }: DimensionNameOption,
) => n1.localeCompare(n2);

export const getAvailableDimensionNameOptions = (
  marketplaceDimensionNameOptions: DimensionNameOption[],
  formDimensions: FormDimension[],
  index: number,
) => {
  const formDimensionAtIndex = formDimensions.at(index);
  const usedDimensions = formDimensions.map(toDimensionNameAndApiName);

  const shouldIncludeDimensionNameOption = (d: DimensionNameOption) =>
    formDimensionAtIndex?.name === d.name ||
    !usedDimensions.find(
      ({ name, apiName }) => name === d.name && apiName === d.apiName,
    );

  return marketplaceDimensionNameOptions
    .filter(shouldIncludeDimensionNameOption)
    .sort(byLowerCasedDimensionName);
};

export const getOptionLabel = (option: DimensionNameOption | string) =>
  typeof option === 'string' ? option : option.name;

const hasSameDimensionName =
  (dimensionNameInputValue: string) =>
  ({ name }: FormDimension) =>
    name === dimensionNameInputValue;

export const getFilteredOptions =
  (dimensionNameInputValue: string, formDimensions: FormDimension[]) =>
  (dimensionNameOptions: DimensionNameOption[]) => {
    const lowerCasedDimensionNameInputValue =
      dimensionNameInputValue.toLowerCase();

    const dimensionNameInputValueMatchesFormDimension = formDimensions.some(
      hasSameDimensionName(dimensionNameInputValue),
    );

    const filteredDimensionNameOptions = dimensionNameOptions.filter(
      (option) =>
        dimensionNameInputValueMatchesFormDimension ||
        option.lowerCasedName.includes(lowerCasedDimensionNameInputValue),
    );

    const showCreateNewDimensionOption =
      !!lowerCasedDimensionNameInputValue &&
      !filteredDimensionNameOptions.some(
        (option) => option.lowerCasedName === lowerCasedDimensionNameInputValue,
      );

    return showCreateNewDimensionOption
      ? [dimensionNameInputValue, ...filteredDimensionNameOptions]
      : filteredDimensionNameOptions;
  };

export const getRenderOption =
  (
    marketplaceDimensionNameOptions: DimensionNameOption[],
    classes: ReturnType<typeof useStyles>,
  ) =>
  (option: DimensionNameOption | string) => {
    const marketplaceDimensionNameOption =
      typeof option === 'string'
        ? null
        : marketplaceDimensionNameOptions.find(
            ({ name, apiName }) =>
              name === option.name && apiName === option.apiName,
          );

    return marketplaceDimensionNameOption ? (
      <div>
        <Typography className={classes.dimensionNameTitle}>
          {marketplaceDimensionNameOption.name}
        </Typography>
        <div className={classes.dimensionNameApiNameSubtitle}>
          <Typography variant="caption">
            {marketplaceDimensionNameOption.apiName}
          </Typography>
        </div>
      </div>
    ) : (
      <div className={classes.createDimensionOption}>
        <span>{option}</span>
        <span> - Create dimension</span>
      </div>
    );
  };

export const getRenderInput =
  (
    textFieldStyles: ReturnType<typeof useTextFieldStyles>,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    isNewDimension: boolean,
    name: string,
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
    error: string | null,
  ) =>
  (params: AutocompleteRenderInputParams) => {
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const eventWithTargetName = { ...e, target: { ...e.target, name } };
      onBlur(eventWithTargetName);
    };

    return (
      <>
        <InputLabel className={textFieldStyles.inputLabel} shrink>
          Dimension {isNewDimension && ' (new) '}*
        </InputLabel>
        <MuiTextField
          {...params}
          variant="outlined"
          placeholder="Choose or create"
          onChange={onChange}
          onBlur={handleBlur}
        />
        {!!error && <FieldError error={error} />}
      </>
    );
  };

export const getOnChange =
  (
    formDimensionAtIndex: FormDimension | null,
    index: number,
    onReplace: (i: number, d: FormDimension) => void,
    setSelectedDimensionNameOption: (o: DimensionNameOption) => void,
    setDimensionNameInputValue: (v: string) => void,
  ) =>
  (
    _: ChangeEvent<DimensionNameOption | string>,
    option: DimensionNameOption | string,
  ) => {
    const selectedDimensionNameOption =
      typeof option === 'string'
        ? {
            name: option,
            lowerCasedName: option.toLowerCase(),
            apiName: generateAwsDimensionApiName(option),
          }
        : option;

    const mergedSelectedDimension: FormDimension = {
      name: selectedDimensionNameOption.name,
      apiName: selectedDimensionNameOption.apiName,
      price: formDimensionAtIndex?.price ?? 0,
      quantity: formDimensionAtIndex?.quantity ?? 0,
    };

    onReplace(index, mergedSelectedDimension);
    setSelectedDimensionNameOption(selectedDimensionNameOption);
    setDimensionNameInputValue(selectedDimensionNameOption.name);
  };

const createDimensionForName = (name: string): FormDimension => ({
  name,
  apiName: generateAwsDimensionApiName(name),
  price: 0,
  quantity: 0,
});

export const getOnBlur =
  (
    selectedDimensionNameOption: DimensionNameOption,
    dimensionNameInputValue: string,
    index: number,
    onReplace: (i: number, d: FormDimension) => void,
    setSelectedDimensionNameOption: (v: DimensionNameOption) => void,
    setDimensionNameInputValue: (v: string) => void,
  ) =>
  (_: FocusEvent<HTMLDivElement>) => {
    if (
      selectedDimensionNameOption.name &&
      dimensionNameInputValue !== selectedDimensionNameOption.name
    ) {
      setDimensionNameInputValue(selectedDimensionNameOption.name);
      return;
    }

    if (!selectedDimensionNameOption.name && dimensionNameInputValue) {
      const newDimension = createDimensionForName(dimensionNameInputValue);
      const selectedDimensionNameOption = {
        name: newDimension.name,
        lowerCasedName: newDimension.name.toLowerCase(),
        apiName: newDimension.apiName,
      };

      onReplace(index, newDimension);
      setSelectedDimensionNameOption(selectedDimensionNameOption);
    }
  };
