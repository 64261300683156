import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';

/**
 * Fetches invitation events for a given invitation ID
 * @param invitationId - the invitation ID
 * @returns invitationEvents - the invitation events
 * @returns invitationEventsError - the invitation events error
 * @returns isOpportunityEventsLoading - whether the invitation events are loading
 * @returns invitationErrors - the errors from the latest invitation event with errors
 */
const useAceInvitationEventsQuery = (invitationId: string) => {
  const {
    data: invitationEvents,
    error: invitationEventsError,
    isInitialLoading: isInvitationEventsLoading,
  } = useQuery({
    queryKey: [invitationId, 'ace-invitation-events'],
    queryFn: () => coSellClient.getInvitationEvents(invitationId),
    enabled: !!invitationId,
  });

  return {
    invitationEvents,
    isInvitationEventsLoading,
    invitationEventsError,
  };
};

export default useAceInvitationEventsQuery;
