import {
  FieldKey,
  FormValues,
} from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/EndUserLicenseAgreementForSection/formSchema';
import {
  EULAType,
  AwsPrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.EulaType]: EULAType.Public,
  [FieldKey.EulaDocumentUrns]: [],
  [FieldKey.EulaFiles]: [],
};

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
): FormValues => ({
  ...initialFormValuesForNewOffer,
  [FieldKey.EulaType]: offer.eula.type,
  [FieldKey.EulaDocumentUrns]: offer.eula.documentUrns,
  [FieldKey.EulaFiles]: offer.eula.files,
});

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
): FormValues => ({
  [FieldKey.EulaType]: EULAType.UseExisting,
  [FieldKey.EulaDocumentUrns]: offer.eula?.documentUrns || [],
  [FieldKey.EulaFiles]: offer.eula?.files || [],
});
