import React, { useContext, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Button, Upload, UploadItem } from '@tackle-io/platform-ui';
import {
  createEventNameInjectingOnBlurHandler,
  getFormattedErrorIgnoringTouched,
} from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { Box } from 'vendor/material/index';
import { Upload as UploadIcon } from 'mdi-material-ui';
import {
  findNextUniqueFilenameIndex,
  getFileWithUniqueFilename,
} from './utils/documentFilenameUtils';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { FormValues } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formSchema';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import FieldError from 'pages/PrivateOffers/components/FieldError/FieldError';
import { Cloud } from 'utils/cloudTypes';
import { Document } from 'pages/PrivateOffers/pages/Next/generic/api/types/Document';
import { Optional } from 'utils/optional/optional';

interface DocumentUploaderProps {
  fieldName: string;
  acceptedDocumentTypes: string;
  uploadDocumentButtonText: string;
  disabled: boolean;
  afterUpload?: (document: Document) => Promise<void>;
  afterRemove?: (documentUrn: string) => Promise<void>;
}

interface DocumentDetails {
  documentUrn: string;
  file: File;
}

const noOp = () => {};

type FilenameToDocumentDetails = { [filename: string]: DocumentDetails };

const getDummyFileForDocumentUrn = (urn: string): File => {
  const filename = urn.substring(urn.lastIndexOf(':') + 1, urn.length);
  const fileType = filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length,
  );
  const type = fileType === 'pdf' ? 'application/pdf' : 'text/plain';

  return new File([], filename, { type });
};

const getInitialFilenameToDocumentDetails = (
  documentUrns: string[],
): FilenameToDocumentDetails =>
  documentUrns.reduce((acc: FilenameToDocumentDetails, urn: string) => {
    const file = getDummyFileForDocumentUrn(urn);

    return {
      ...acc,
      [file.name]: {
        documentUrn: urn,
        file,
      },
    };
  }, {});

const getFileToUpload = (
  file: File,
  filenameToDocumentDetails: FilenameToDocumentDetails,
): File => {
  const nextUniqueFilenameIndex = findNextUniqueFilenameIndex(
    file.name,
    Object.keys(filenameToDocumentDetails),
  );

  return nextUniqueFilenameIndex > 0
    ? getFileWithUniqueFilename(file, nextUniqueFilenameIndex)
    : file;
};

const OfferDocumentUploader: React.FunctionComponent<DocumentUploaderProps> = ({
  fieldName,
  acceptedDocumentTypes,
  uploadDocumentButtonText,
  afterUpload,
  afterRemove,
  disabled,
}) => {
  const {
    marketplaceAgnosticApi: { postDocument },
    isSubmitting,
  } = useContext(ApiContext);

  const submittingDocument = isSubmitting(OfferAPIKey.Document);
  const { values } = useFormikContext<FormValues>();

  const maxDocsLengthReached =
    values?.cloud === Cloud.Aws && values?.eulaDocumentUrns?.length >= 5;

  const showButton = !maxDocsLengthReached && !disabled;

  const [filenameToDocumentDetails, setFilenameToDocumentDetails] =
    useState<FilenameToDocumentDetails>(
      getInitialFilenameToDocumentDetails(values[fieldName]),
    );

  return (
    <FieldArray name={fieldName}>
      {({ push, remove, form: { values, getFieldMeta, handleBlur } }) => {
        const onUpload = async (files: File[]) => {
          if (disabled || isSubmitting(OfferAPIKey.Document)) {
            return;
          }

          const fileToUpload = getFileToUpload(
            files.at(0),
            filenameToDocumentDetails,
          );

          const uploadedDocument = await postDocument(fileToUpload);

          push(uploadedDocument.documentUrn);

          setFilenameToDocumentDetails((previousDocumentUrnToFile) => ({
            ...previousDocumentUrnToFile,
            [uploadedDocument.filename]: {
              documentUrn: uploadedDocument.documentUrn,
              file: fileToUpload,
            },
          }));

          await Optional.ofNullable(afterUpload).ifPresentAsync((callback) =>
            callback(uploadedDocument),
          );
        };

        const onRemove = async (file: File) => {
          if (disabled) {
            return;
          }

          const { documentUrn } = filenameToDocumentDetails[file.name];
          const urnIndex = values[fieldName]?.indexOf(documentUrn);

          const updatedDocumentNameToFileAndUrn = Object.entries(
            filenameToDocumentDetails,
          )
            .filter(([key]) => key !== file.name)
            .reduce(
              (
                acc: FilenameToDocumentDetails,
                v: [string, DocumentDetails],
              ) => ({ ...acc, [v[0]]: v[1] }),
              {},
            );

          setFilenameToDocumentDetails(updatedDocumentNameToFileAndUrn);
          remove(urnIndex);

          await Optional.ofNullable(afterRemove).ifPresentAsync((callback) =>
            callback(documentUrn),
          );
        };

        const fileList = Object.values(filenameToDocumentDetails).map(
          ({ file }) => file,
        );

        const fieldKeyToLabel = { [fieldName]: 'Documents' };
        const fieldMeta = getFieldMeta(fieldName);

        const documentUrnsError = getFormattedErrorIgnoringTouched(
          fieldName,
          fieldKeyToLabel,
          fieldMeta,
        );

        return (
          <>
            <Box mb={2}>
              <div
                id={fieldName}
                onBlur={createEventNameInjectingOnBlurHandler(
                  fieldName,
                  handleBlur,
                )}
              >
                <Upload
                  multiple
                  type="document"
                  accept={acceptedDocumentTypes}
                  onDrop={onUpload}
                  onRemove={onRemove}
                  onDownload={noOp}
                  fileList={fileList}
                  ItemComponent={(props) => (
                    <UploadItem downloadable={false} {...props} />
                  )}
                >
                  {showButton && (
                    <Button
                      variant="outlined"
                      appearance="primary"
                      startIcon={<UploadIcon />}
                      disabled={disabled || isSubmitting(OfferAPIKey.Document)}
                      loading={submittingDocument}
                    >
                      {uploadDocumentButtonText}
                    </Button>
                  )}
                </Upload>
              </div>
            </Box>
            {documentUrnsError && <FieldError error={documentUrnsError} />}
          </>
        );
      }}
    </FieldArray>
  );
};

export default OfferDocumentUploader;
