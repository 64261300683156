import React, { useContext, useState } from 'react';
import { Box, Grid } from 'vendor/material';
import OfferFormSection from '../../../../../generic/OfferFormSection/OfferFormSection';
import { FieldArray, useFormikContext } from 'formik';
import { FormValues } from '../../formSchema';
import ScheduleRow from './ScheduleRow/ScheduleRow';
import { Banner, Button, Divider, Tooltip } from '@tackle-io/platform-ui';
import CreatePaymentSchedule from './CreatePaymentSchedule/CreatePaymentSchedule';
import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';
import { FieldKey } from '../formTypes';
import { FormSchedule, newSchedule } from './ScheduleRow/formTypes';
import {
  DataId,
  OffersProductArea,
  PageLocation,
} from '../../../../../generic/analytics';
import { ampli } from 'utils/analytics/ampli/index';
import { PaymentModel } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import FieldError from 'pages/PrivateOffers/components/FieldError/FieldError';
import { getFormattedErrorIgnoringTouched } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const ScheduleConfig: React.FunctionComponent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { mode } = useContext(OfferPageContext);
  const { offerIsMarketplaceEditable, offerIsAmendment } =
    useContext(OfferContext);
  const isAmendmentMode = mode === OfferPageMode.Amend || offerIsAmendment;
  const [paymentSchedulerOpen, setPaymentSchedulerOpen] = useState(false);
  const cc = values.currencyCode as CurrencyCode;

  const scheduleValue = values.schedules
    .filter((s) => s.invoiceAmount > 0)
    .reduce((a: number, s: FormSchedule) => a + s.invoiceAmount, 0)
    .toFixed(2);

  const onPaymentSchedulerClosed = () => {
    setPaymentSchedulerOpen(false);
  };

  const onPaymentScheduleCreated = async (schedules: FormSchedule[]) => {
    await setFieldValue(FieldKey.Schedules, schedules);

    setPaymentSchedulerOpen(false);
  };

  return values.paymentModel === PaymentModel.PerProduct ? null : (
    <OfferFormSection title="Payment schedule">
      <Box mb={2}>
        <Banner
          borderPosition="top"
          title={
            isAmendmentMode
              ? 'At least one payment is required with a Payment schedule. If your buyer accepts this offer, any remaining payments from previous agreements will be cancelled and therefore should be included in this offer. Below are the remaining payments from the previous agreement.'
              : 'At least one payment is required with a Payment schedule. To avoid adding payments, change the payment model to upfront pricing.'
          }
          isCollapsible={false}
        />
      </Box>
      <Box>
        <FieldArray name={FieldKey.Schedules}>
          {({ form, remove, push }) => {
            const onAddScheduleClicked = () => {
              ampli.buttonClicked(
                {
                  button_product_area: OffersProductArea,
                  button_location: PageLocation.OfferEditPage,
                  button_name: DataId.AddScheduleButton,
                },
                { extra: { marketplace: Marketplace.Aws } },
              );

              push(newSchedule);
            };

            const onCreatePaymentScheduleClicked = () => {
              ampli.buttonClicked(
                {
                  button_product_area: OffersProductArea,
                  button_location: PageLocation.OfferEditPage,
                  button_name: DataId.CreatePaymentScheduleButton,
                },
                { extra: { marketplace: Marketplace.Aws } },
              );

              setPaymentSchedulerOpen(true);
            };

            const createPaymentScheduleButton = (
              <Button
                data-id={DataId.CreatePaymentScheduleButton}
                appearance="primary"
                variant="text"
                onClick={onCreatePaymentScheduleClicked}
                disabled={
                  !offerIsMarketplaceEditable || values.durationValue === null
                }
              >
                Create payment schedule
              </Button>
            );

            const maybeWithTooltipCreatePaymentScheduleButton =
              values.durationValue === null ? (
                <Tooltip content="Configure the offer billing terms to use the payment schedule creator">
                  <span>{createPaymentScheduleButton}</span>
                </Tooltip>
              ) : (
                createPaymentScheduleButton
              );

            const meta = form.getFieldMeta(FieldKey.Schedules);

            const fieldError = getFormattedErrorIgnoringTouched(
              FieldKey.Schedules,
              { [FieldKey.Schedules]: 'Schedules' },
              meta,
            );

            return (
              <Box>
                <Grid container spacing={2}>
                  {values.schedules.map((_, i) => (
                    <Grid item md={12} key={`schedule-${i}`}>
                      <ScheduleRow
                        index={i}
                        onRemove={remove}
                        currencyCode={cc}
                        allowDelete={values.schedules?.length > 1}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Box mt={2} mb={2}>
                  <Divider />
                </Box>
                <Box mb={2} justifyContent={'flex-end'} display="flex">
                  Total payments:{' '}
                  {formatValueWithCurrencyPrefix(scheduleValue, cc)}
                </Box>
                <Box>
                  <Button
                    data-id={DataId.AddScheduleButton}
                    appearance="primary"
                    variant="text"
                    onClick={onAddScheduleClicked}
                    disabled={!offerIsMarketplaceEditable}
                  >
                    Add schedule
                  </Button>
                  {maybeWithTooltipCreatePaymentScheduleButton}
                </Box>
                {fieldError && <FieldError error={fieldError} />}
              </Box>
            );
          }}
        </FieldArray>
      </Box>
      {paymentSchedulerOpen && (
        <CreatePaymentSchedule
          onPaymentSchedulerClosed={onPaymentSchedulerClosed}
          onPaymentScheduleCreated={onPaymentScheduleCreated}
          currencyCode={cc}
          durationValue={values.durationValue}
        />
      )}
    </OfferFormSection>
  );
};

export default ScheduleConfig;
