import { Box } from 'vendor/material';
import { SalesforceCoSellWidgetButtonGroup } from 'packages/cosell/src/components/SalesforceCoSellWidget/SalesforceCoSellWidgetButtonGroup/SalesforceCoSellWidgetButtonGroup';
import { SalesforceCoSellWidgetDetailTable } from 'packages/cosell/src/components/SalesforceCoSellWidget/SalesforceCoSellWidgetDetailTable/SalesforceCoSellWidgetDetailTable';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';
import { TackleOnlyOpportunityStatusEnum } from 'packages/cosell/src/types/enums/AceOpportunityReviewStatusEnum';
import { OpportunityTypeEnum } from 'packages/cosell/src/types/enums/OpportunityTypeEnum';
import { SourceEnum } from 'packages/cosell/src/types/enums';

export interface IncompleteAwsOpportunityProps {
  companyName: string;
  created: string;
  crmId: string;
  hasCloudErrors?: boolean;
  id: string;
  lastModified: string;
  opportunityType: OpportunityTypeEnum;
  source: SourceEnum;
  targetCloseDate: string;
  title: string;
  pendingRequestIds?: string[]; // deprecated
}

/** displays a summary of an incomplete co-sell opportunity with the listing opportunity data */
export default function IncompleteAwsOpportunity({
  opportunitySummary,
}: {
  opportunitySummary: IncompleteAwsOpportunityProps;
}) {
  const {
    companyName,
    created,
    crmId,
    hasCloudErrors,
    id: tackleOpportunityId,
    lastModified,
    opportunityType,
    source,
    targetCloseDate,
    title,
  } = opportunitySummary;

  return (
    <Box style={{ display: 'grid', gap: '1rem', minHeight: '200px' }}>
      {hasCloudErrors && (
        <AceCoSellErrorMessagesBanner
          defaultCollapsed
          opportunityId={tackleOpportunityId}
          defaultError="submission"
        />
      )}

      <SalesforceCoSellWidgetDetailTable
        opportunity={{
          id: tackleOpportunityId,
          cloudProviderId: undefined,
          created: created,
          title: title,
          source: source,
          crmId: crmId,
          lastModified: lastModified,
          targetCloseDate: targetCloseDate,
          companyName: companyName,
          opportunityType: opportunityType,
          hasCloudErrors: hasCloudErrors,
          status: hasCloudErrors
            ? TackleOnlyOpportunityStatusEnum.SUBMISSION_ERROR
            : undefined,
        }}
      />
      <SalesforceCoSellWidgetButtonGroup
        disableViewButton={true}
        isEditable={true}
        tackleCoSellId={tackleOpportunityId}
      />
    </Box>
  );
}
