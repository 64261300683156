import { ValidationError } from 'yup';

export const runAssertions = (
  result: boolean | ValidationError,
  expected: boolean | ValidationError[],
) => {
  const expectedPass = typeof expected === 'boolean';
  const resultToAssert = expectedPass
    ? result
    : (result as ValidationError).inner;

  expect(resultToAssert).toEqual(expected);

  if (!expectedPass) {
    (expected as ValidationError[]).forEach((v: ValidationError, i: number) => {
      const { errors, path } = resultToAssert[i];

      expect(v.errors).toEqual(errors);
      expect(v.path).toEqual(path);
    });
  }
};

export const combineValidationErrors = (
  errors: ValidationError[],
  path: string,
): ValidationError => {
  const combinedValidationError = new ValidationError('', '', path);
  combinedValidationError.inner = errors;

  return combinedValidationError;
};

export const hasValidationError = (e: ValidationError | null) => !!e;

const getValuesForKeys = (fieldKeys: string[], values: { [k: string]: any }) =>
  Object.entries(values)
    .filter(([k]) => fieldKeys.indexOf(k) > -1)
    .sort(([k1], [k2]) =>
      fieldKeys.indexOf(k1) > fieldKeys.indexOf(k2) ? 1 : -1,
    )
    .map(([_, v]) => v);

const multiValueIsWithProgress = (
  fieldKeys: string[],
  values: { [k: string]: any },
  predicate: (...args: any[]) => boolean,
) => {
  const gatheredValues = values ? getValuesForKeys(fieldKeys, values) : [];
  return predicate(...gatheredValues);
};

const singleValueIsWithProgress = (
  fieldKey: string,
  values: { [k: string]: any },
  predicate: (...args: any[]) => boolean,
) => {
  const fieldValue = values ? values[fieldKey] : null;
  return predicate(fieldValue);
};

export const isForYupExtended =
  (fieldKey: string | string[], predicate: (...args: any[]) => boolean) =>
  (v: any): any => {
    const fieldKeyIsArray = Array.isArray(fieldKey);
    const values = v?.values || v;

    return fieldKeyIsArray
      ? multiValueIsWithProgress(fieldKey as string[], values, predicate)
      : singleValueIsWithProgress(fieldKey as string, values, predicate);
  };
