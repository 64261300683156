import React, { ReactNode, createContext, useContext } from 'react';
import { gql } from 'graphql-tag';
import { useProspectEntitlementsQuery } from 'generated/graphql';

export const PROSPECT_ENTITLEMENTS = gql`
  query ProspectEntitlements($vendorId: String!) {
    prospectEntitlements(vendor_id: $vendorId) {
      precommit
      usage
      requested
      limit_use
    }
  }
`;

type ScanLimitDataContextValue = {
  loading: boolean;
  error?: Error;
  limitUse?: boolean;
};

const ScanLimitDataContext = createContext<
  ScanLimitDataContextValue | undefined
>(undefined);

type ScanLimitDataProviderProps = {
  children: ReactNode;
  vendorId: string;
};

export const ScanLimitDataProvider: React.FC<ScanLimitDataProviderProps> = ({
  children,
  vendorId,
}) => {
  const { loading, error, data } = useProspectEntitlementsQuery({
    variables: { vendorId },
  });

  return (
    <ScanLimitDataContext.Provider
      value={{
        loading,
        error,
        limitUse: data?.prospectEntitlements?.limit_use,
      }}
    >
      {children}
    </ScanLimitDataContext.Provider>
  );
};

export const useScanLimitData = (): ScanLimitDataContextValue => {
  const context = useContext(ScanLimitDataContext);
  if (!context) {
    throw new Error(
      'useScanLimitData must be used within a ScanLimitDataProvider',
    );
  }
  return context;
};
