import React from 'react';
import AwsEditOfferContextProvider from 'pages/PrivateOffers/pages/Next/aws/edit/AwsEditOfferContext/AwsEditOfferContextProvider';
import { useHistory, useParams } from 'react-router-dom';
import {
  toPrivateOfferAfterArchive,
  toPrivateOfferAfterSubmit,
  toPrivateOfferOnCancel,
  toPrivateOfferOnOpenOffer,
} from 'pages/PrivateOffers/pages/Next/generic/utils/page/editOfferPageCallbackUtils';
import OfferContextProvider from 'pages/PrivateOffers/pages/Next/generic/OfferContext/OfferContextProvider';
import { History } from 'history';
import ButtonLink from 'packages/salesforce-canvas/src/components/ButtonLink';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { OfferRunFrom } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerRunFrom';

export interface AwsPrivateOfferPageWithDataProvidersProps {
  poId: string;
  includeVendorContext: boolean;
  mode: OfferPageMode;
  runFrom: OfferRunFrom;
  accessTokenProvider?: () => Promise<string>;
  afterSubmit?: (offer: AwsPrivateOffer) => void;
  onEdit?: (offer: AwsPrivateOffer) => void;
  onAmend?: (offerId: string, offer: AwsPrivateOffer | null) => void;
  onCancel?: (offer: AwsPrivateOffer | null) => void;
  onOpenOffer?: (Offer: AwsPrivateOffer) => void;
  children: React.ReactElement;
  afterArchive?: (offer: AwsPrivateOffer) => void;
}

export const AwsPrivateOfferPageWithDataProviders: React.FunctionComponent<
  AwsPrivateOfferPageWithDataProvidersProps
> = ({
  includeVendorContext,
  mode,
  runFrom,
  accessTokenProvider,
  afterSubmit,
  onEdit,
  onAmend,
  onCancel,
  onOpenOffer,
  poId,
  children,
  afterArchive,
}) => (
  <AwsEditOfferContextProvider
    includeVendorContext={includeVendorContext}
    accessTokenProvider={accessTokenProvider}
    mode={mode}
    runFrom={runFrom}
    afterSubmit={afterSubmit}
    onEdit={onEdit}
    onAmend={onAmend}
    onCancel={onCancel}
    onOpenOffer={onOpenOffer}
    afterArchive={afterArchive}
  >
    <OfferContextProvider poId={poId}>{children}</OfferContextProvider>
  </AwsEditOfferContextProvider>
);

export type AwsPrivateOfferCanvasPageProps = {
  includeVendorContext?: boolean;
} & Omit<
  AwsPrivateOfferPageWithDataProvidersProps,
  'includeVendorContext' | 'runFrom'
>;

export const AwsPrivateOfferCanvasPage: React.FunctionComponent<
  AwsPrivateOfferCanvasPageProps
> = ({
  mode,
  includeVendorContext = true,
  accessTokenProvider,
  poId,
  afterSubmit,
  onEdit,
  onAmend,
  onCancel,
  children,
  afterArchive,
}) => (
  <AwsPrivateOfferPageWithDataProviders
    includeVendorContext={includeVendorContext}
    accessTokenProvider={accessTokenProvider}
    mode={mode}
    runFrom={OfferRunFrom.Canvas}
    poId={poId}
    afterSubmit={afterSubmit}
    onEdit={onEdit}
    onAmend={onAmend}
    onCancel={onCancel}
    afterArchive={afterArchive}
  >
    <>
      <ButtonLink
        onClick={() => {
          onCancel(null);
        }}
      >
        &#8249; back to opportunity
      </ButtonLink>
      {children}
    </>
  </AwsPrivateOfferPageWithDataProviders>
);

interface AwsPrivateOfferPlatformPageProps {
  mode: OfferPageMode;
  includeVendorContext?: boolean;
  onEditProducer?: (history: History) => (o: AwsPrivateOffer) => void;
  children: React.ReactElement;
}

const AwsPrivateOfferPlatformPage: React.FunctionComponent<
  AwsPrivateOfferPlatformPageProps
> = ({ mode, includeVendorContext = true, onEditProducer, children }) => {
  const history = useHistory();
  const { poId } = useParams<{ poId?: string }>();
  const afterSubmit = toPrivateOfferAfterSubmit(history);
  const afterArchive = toPrivateOfferAfterArchive(history);
  const onCancel = toPrivateOfferOnCancel(history);
  const onEdit = onEditProducer ? onEditProducer(history) : () => {};

  return (
    <AwsPrivateOfferPageWithDataProviders
      includeVendorContext={includeVendorContext}
      mode={mode}
      runFrom={OfferRunFrom.Platform}
      poId={poId}
      afterSubmit={afterSubmit}
      onEdit={onEdit}
      onCancel={onCancel}
      onOpenOffer={toPrivateOfferOnOpenOffer}
      afterArchive={afterArchive}
    >
      {children}
    </AwsPrivateOfferPageWithDataProviders>
  );
};

export default AwsPrivateOfferPlatformPage;
