import { Box, Grid } from 'vendor/material';
import React, { useContext } from 'react';
import OfferFormSection from 'pages/PrivateOffers/pages/Next/generic/OfferFormSection/OfferFormSection';
import { Field } from 'formik';
import DateSelector from 'pages/PrivateOffers/pages/Next/generic/DateSelector/DateSelector';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { FieldKey } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ProductAndPricingFormSection/formTypes';
import AmendmentContext from '../../../AmendmentContext/AmendmentContext';
import { minFutureDatedEndDate } from '../../utils/dateUtils';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

const fieldKeyToLabel = {
  [FieldKey.ServiceEndAt]: 'New service end date',
};

const ServiceDetailsConfig: React.FunctionComponent = () => {
  const { offerIsMarketplaceEditable } = useContext(OfferContext);
  const { agreement } = useContext(AmendmentContext);

  return (
    <Box mb={3}>
      <OfferFormSection title="Service details">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <DateSelector
              name="previousServiceEndAt"
              label="Previous service end date"
              value={agreement?.endDate}
              onChange={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item md={6}>
            <Field name={FieldKey.ServiceEndAt}>
              {({ field, meta }) => (
                <DateSelector
                  {...field}
                  label={`${fieldKeyToLabel[field.name]} *`}
                  minDate={minFutureDatedEndDate().toISO()}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  disabled={!offerIsMarketplaceEditable}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </OfferFormSection>
    </Box>
  );
};

export default ServiceDetailsConfig;
