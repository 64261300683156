import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useLocation } from 'react-router-dom';
import {
  PAGE_SIZE_KEY,
  SEARCH_KEY,
  FROM_KEY,
  CLOUD_STATUS_KEY,
  TAB_KEY,
  SORT_FIELD_KEY,
  SORT_ORDER_KEY,
  SortDirection,
  InvitationColumnKey,
  DEFAULT_INVITATIONS_SORT_DIRECTION,
  DEFAULT_INVITATIONS_SORT_KEY,
} from '../pages/CoSellLandingPage/helpers/urlParamsHelper';
import { useEffect, useMemo, useState } from 'react';
import { ActiveFilter } from '../components/FilterPanel/FilterPanel';
import { getActivePanelFilters } from '../utilities/filters/utils';

const useInvitationsListQuery = () => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const q = searchParams.get(SEARCH_KEY);
  const pageSize = searchParams.get(PAGE_SIZE_KEY);
  const from = searchParams.get(FROM_KEY);
  const cloudStatus = searchParams.get(CLOUD_STATUS_KEY);
  const tabKey = searchParams.get(TAB_KEY);
  const sortFieldKey = (searchParams.get(SORT_FIELD_KEY) ||
    DEFAULT_INVITATIONS_SORT_KEY) as InvitationColumnKey;
  const sortOrder = (searchParams.get(SORT_ORDER_KEY) ||
    DEFAULT_INVITATIONS_SORT_DIRECTION) as SortDirection;

  const [activePanelFilters, setActivePanelFilters] = useState<ActiveFilter[]>(
    getActivePanelFilters({ searchParams, isInvitations: true }) || [],
  );

  const sortState = {
    sortKey: sortFieldKey,
    sortDirection: sortOrder,
  };

  useEffect(() => {
    setActivePanelFilters(
      getActivePanelFilters({ searchParams, isInvitations: true }),
    );
  }, [cloudStatus, searchParams]);

  const {
    data: invitationsList,
    error: invitationsListError,
    isLoading: isInvitationsListLoading,
  } = useQuery({
    queryKey: [
      'cosell-invitations-list',
      'ace',
      { q, pageSize, from, activePanelFilters, sortState },
    ],
    queryFn: () => {
      return coSellClient.getInvitationsList({
        q,
        pageSize,
        from,
        activePanelFilters,
        sortState,
      });
    },
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
    enabled: tabKey === 'invitations',
  });

  return {
    invitationsList,
    invitationsListError,
    isInvitationsListLoading,
    total: invitationsList?.total,
    isNextPage: invitationsList?.isNextPage,
    currentPageCount: invitationsList?.currentPageCount,
    nextIndex: invitationsList?.nextIndex,
    activePanelFilters,
    sortState,
  };
};

export default useInvitationsListQuery;
