import * as Yup from 'yup';
import { REQUIRED } from './FormValidationConstants';

export const launchFormSchema = Yup.object().shape({
  solutions: Yup.array().required(REQUIRED),
  customerSoftwareValue: Yup.string()
    .nullable(true)
    .test(
      'is-required-when-currencyCode',
      'Customer software value is required when Currency is provided.',
      function (value) {
        const { currencyCode } = this.parent;
        if (currencyCode && !value) {
          return false;
        }
        return true;
      },
    ),
  currencyCode: Yup.string()
    .nullable(true)
    .test(
      'is-required-when-customerSoftwareValue',
      'Currency is required when Customer software value is provided.',
      function (value) {
        const { customerSoftwareValue } = this.parent;
        if (customerSoftwareValue && !value) {
          return false;
        }
        return true;
      },
    ),
});
export const launchFormWithSaasDocRequiredSchema = Yup.object().shape({
  solutions: Yup.array().required(REQUIRED),
  deliveryModel: Yup.string().required(REQUIRED),
  customerSoftwareValue: Yup.string().required(REQUIRED).nullable(true),
  currencyCode: Yup.string().required(REQUIRED).nullable(true),
});
export const closeLostFormSchema = Yup.object().shape({
  closedLostReason: Yup.string().required(REQUIRED),
});

// placeholder schema for edit launch form, update it if it is necessary
export const editLaunchFormSchema = launchFormSchema;

export const editLaunchFormWithSaasDocRequiredSchema =
  launchFormWithSaasDocRequiredSchema;
