import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './schemas/aceOpportunityFormValidationSchema';
import {
  CreateAceOpportunityFormValues,
  UpdateAceOpportunityFormValues,
} from './AceOpportunityFormValues';
import {
  convertAceFormValuesToUpdateRequest,
  getSoftwareRevenue,
} from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import {
  getEditPageSubmitTackleOperationId,
  TackleOperationId,
} from 'packages/cosell/api/utils';
import { Loader } from '@tackle-io/platform-ui';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { removeUndefinedOrEmptyObjectProperties } from 'packages/cosell/src/utilities/typeConverters/utils';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import useOpportunityEventsQuery from 'packages/cosell/src/hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';
import { useMemo } from 'react';
import { getFormValues } from '../utils/getFormValues';

interface EditAceOpportunityFormProps {
  opportunityId: string;
  onCancel: () => void;
  onUpdateSuccess: (opportunityId: string) => void;
}

/**
 *
 * TODO:
 *
 *   The edit form can then be prefilled with the original payload values
 */
const EditAceOpportunityForm = ({
  opportunityId,
  onCancel,
  onUpdateSuccess,
}: EditAceOpportunityFormProps) => {
  const acePickListQuery = useAcePickListQuery();

  const {
    isSaasDocumentationRequiredToLaunch,
    isError: isVendorConfigError,
    isLoading: isVendorConfigLoading,
  } = useAceVendorConfig();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });

  const opportunity = aceOpportunityQuery?.data;
  const lastAceRequestHasErrors = opportunity?.metadata?.hasCloudErrors;

  const { aceLatestRawRequest, aceOpportunityErrors } =
    useOpportunityEventsQuery(opportunityId);

  const handleSubmitToCloud = async (
    values: UpdateAceOpportunityFormValues,
  ) => {
    const requiredSaasDocumentationToLaunchParams =
      opportunity?.isCreatedInCloud &&
      isSaasDocumentationRequiredToLaunch({
        deliveryModels: opportunity.project?.deliveryModels ?? [],
      })
        ? { softwareRevenue: getSoftwareRevenue(values) }
        : null;

    const body = {
      ...convertAceFormValuesToUpdateRequest(values),
      ...requiredSaasDocumentationToLaunchParams,
    };
    const trimmedBody = removeUndefinedOrEmptyObjectProperties(body);
    const tackleOperationId =
      'lifeCycle' in opportunity
        ? getEditPageSubmitTackleOperationId(
            opportunity?.lifeCycle?.reviewStatus,
          )
        : TackleOperationId.UPDATE_START_ENGAGEMENT;

    return updateAceOpportunity.mutateAsync(
      {
        requestBody: trimmedBody,
        tackleOperationId,
      },
      {
        onSuccess(data) {
          if (data?.id) {
            onUpdateSuccess(data.id);
          }
        },
      },
    );
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);
    return updateAceOpportunity.mutateAsync(
      {
        requestBody,
        tackleOperationId: TackleOperationId.UPDATE_OPPORTUNITY,
      },
      {
        onSuccess(data) {
          if (data?.id) {
            onUpdateSuccess(data.id);
          }
        },
      },
    );
  };

  // Use useMemo to memoize the result of getFormValues
  const initialValues = useMemo(() => {
    return getFormValues({
      lastAceRequestHasErrors,
      aceLatestRawRequest,
      opportunity,
    });
  }, [lastAceRequestHasErrors, aceLatestRawRequest, opportunity]);

  if (
    aceOpportunityQuery.isLoading ||
    updateAceOpportunity.isLoading ||
    isVendorConfigLoading ||
    acePickListQuery.isLoading
  ) {
    return <Loader />;
  }

  /** an opportunity that has been created in the cloud.
   *  we do not want to pass in an incomplete (pendingCreation) opportunity
   * TODO: update the opportunity type in UnifiedOpportunityFormShell to accept a null opportunity
   * since should not set this to undefined.
   * undefined is used to satisfy typescript
   */

  if (isVendorConfigError || acePickListQuery.isError || !opportunity) {
    return <div>Required data failed to load, please refresh</div>;
  }

  const cloudCreatedOpportunity = opportunity.isCreatedInCloud
    ? opportunity
    : undefined;

  /** A generic error message can be confusing when the form
   * doesn't highlight a specific field due to the absence of a related field name.
   * Since inline errors cannot be displayed in such cases,
   * we should show the api error message in a banner instead.
   */
  const hasErrorWithNoFieldName = aceOpportunityErrors.some(
    (item) => !item.error.fieldName,
  );
  const shouldDisplayGenericErrorMessage = !hasErrorWithNoFieldName;

  return (
    <>
      <UnifiedOpportunityFormShell
        onCancel={onCancel}
        opportunityId={opportunityId}
        opportunity={cloudCreatedOpportunity}
        onSubmit={handleSubmitToCloud}
        /** strict mode type error here that needs to be addressed */
        onSaveAsDraft={handleSaveAceOpportunityAsDraft}
        initialValues={initialValues}
        provider={DisplayCloudType.AWS}
        validationSchema={aceOpportunityFormValidationSchema}
        initialErrors={aceOpportunityErrors}
      >
        {opportunity.metadata?.hasCloudErrors && (
          <div style={{ paddingTop: '1rem' }}>
            <AceCoSellErrorMessagesBanner
              opportunityId={opportunityId}
              defaultError={
                shouldDisplayGenericErrorMessage ? 'generic' : undefined
              }
            />
          </div>
        )}

        <AceOpportunityFormFields
          reviewStatus={
            cloudCreatedOpportunity?.lifeCycle?.reviewStatus ?? null
          }
          isCreatedInCloud={opportunity.isCreatedInCloud}
        />
      </UnifiedOpportunityFormShell>
    </>
  );
};

export default EditAceOpportunityForm;
