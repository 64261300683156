import { MsftPrivateOffer } from '../../api/types/MsftPrivateOffer';
import {
  Activity,
  ActivitySlug,
  Buyer,
  Marketplace,
  OfferType,
  TackleOffer,
} from '../TackleOffer';

export const msftPrivateOfferToTackleOffer = (
  offer: MsftPrivateOffer,
): TackleOffer => {
  const buyerDetails = offer?.buyerDetails;

  const activities = offer.activities.map(
    (a): Activity => ({
      slug: a.slug as ActivitySlug,
      createdAt: a.createdAt,
      metadata: a.metadata,
      activityType: a.activityType,
      userId: a.userId,
    }),
  );

  const offerBuyers = offer.buyerDetails?.buyers || [];

  const buyers = offerBuyers.map(
    (b): Buyer => ({
      emailAddress: b.emailAddress,
      fullName: b.fullName,
      title: b.title,
    }),
  );

  const usersToNotify = offer.usersToNotify.map((u) => ({
    email: u.email,
  }));

  return {
    poId: offer.poId,
    marketplace: Marketplace.Azure,
    offerType: offer.offerType as OfferType,
    offerId: offer.offerRef,
    sourceOfferId: null,
    productId: offer.productRef,
    billingAccountId: buyerDetails?.billingAccountId,
    buyerCompanyName: buyerDetails?.companyName,
    offerName: offer.name,
    activities,
    buyers,
    usersToNotify,
    offerMetadata: offer.metadata,
    preRegistrationDetails: {},
    acceptedAt: offer.acceptedAt,
    archivedAt: offer.archivedAt,
    cancelledAt: offer.cancelledAt,
    createdAt: offer.createdAt,
    createdInMarketplaceAt: offer.marketplaceCreatedAt,
    lastModifiedAt: offer.lastModifiedAt,
    openedInstructionsAt: offer.openedInstructionsAt,
    sentAt: offer.sentAt,
    viewedOfferAt: offer.viewedOfferAt,
    offerExpirationAt: offer.acceptByDate,
  };
};
