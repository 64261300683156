import React from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from '../../../formSchema';
import { Box } from 'vendor/material';
import { DataId } from '../../../../../../generic/analytics';
import { Dimension } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

interface DimensionsCounterProps {
  marketplaceDimensions: Dimension[];
}

const DimensionsCounter: React.FunctionComponent<DimensionsCounterProps> = ({
  marketplaceDimensions,
}) => {
  const {
    values: { dimensions: formDimension },
  } = useFormikContext<FormValues>();

  const marketplaceDimensionApiNames = marketplaceDimensions.map(
    ({ apiName }) => apiName,
  );

  const newDimensions = formDimension.filter(
    ({ apiName: formApiName }) =>
      formApiName &&
      !marketplaceDimensionApiNames.some((apiName) => formApiName === apiName),
  );

  const newDimensionsCount = newDimensions.length;

  return (
    <Box data-id={DataId.DimensionsCounter} mb={2}>
      Product dimensions used: {marketplaceDimensionApiNames.length}
      {newDimensionsCount > 0 && ` + ${newDimensionsCount} new`}
    </Box>
  );
};

export default DimensionsCounter;
