import React from 'react';
import NotConnectedIconUrl from '../assets/not_connected_icon.svg';
import { makeStyles } from 'vendor/material';

const notConnectedPageStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: '48px 24px',
  },
  headerText: {
    fontWeight: 600,
    fontSize: '24px',
  },
  bodyText: {
    fontSize: '16px',
  },
}));

export default function NotConnectedPage() {
  const classes = notConnectedPageStyles();
  return (
    <div className={classes.container}>
      <img src={NotConnectedIconUrl} alt="Not Connected" />
      <h2 className={classes.headerText}>
        The Tackle app is installed, but not connected to Tackle
      </h2>
      <p className={classes.bodyText}>
        Reach out to your Salesforce Admin to finish getting connected so that
        you can use Tackle.
      </p>
    </div>
  );
}
