import { Switch, Redirect, Route, useRouteMatch } from 'react-router';
import AceInvitationDetails from './AceInvitationDetails';

/** Route to handle rendering the proper cloud invitation detail page.
 *  To support a new cloud type, add a new route here
 */
const UnifiedInvitationDetails = () => {
  const routeMatch = useRouteMatch();
  const { path } = routeMatch;
  return (
    <Switch>
      <Route
        exact
        path={`${path}/aws/:invitationId`}
        component={AceInvitationDetails}
      />
      <Redirect to={`/co-sell?tab=invitations`} />
    </Switch>
  );
};

export default UnifiedInvitationDetails;
