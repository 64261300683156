import { getLatestOfferActivitySlug } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import { Offer } from '../../types/Offer';
import { ActivitySlug } from '../../types/TackleOffer';

const startPollingSlugs = new Set<ActivitySlug>([
  ActivitySlug.VendorCreatedMarketplaceOfferPending,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationPending,
  ActivitySlug.VendorCancelledMarketplaceOfferPending,
]);

export const shouldStartPolling =
  (latestActivitySlug: ActivitySlug | null) => () =>
    startPollingSlugs.has(latestActivitySlug);

const stopPollingSlugs = new Set<ActivitySlug>([
  ActivitySlug.VendorCreatedMarketplaceOfferSuccess,
  ActivitySlug.VendorCreatedMarketplaceOfferFailed,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed,
  ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
  ActivitySlug.VendorCancelledMarketplaceOfferFailed,
]);

export const stopPollingPredicate = (o: Offer) => {
  const polledOfferLatestActivitySlug = getLatestOfferActivitySlug(o);
  return stopPollingSlugs.has(polledOfferLatestActivitySlug);
};

export const MarketplaceOperationPendingPollingInterval = 7500;
