import React, { useContext } from 'react';
import { Grid, IconButton, makeStyles } from 'vendor/material';
import { Delete } from 'mdi-material-ui';
import TextField from '../../../../../../../../../../components/FieldsPricing/TextField/TextField';
import { getFormattedError } from '../../../../../../generic/utils/field/fieldUtils';
import { Field } from 'formik';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import DateSelector from '../../../../../../generic/DateSelector/DateSelector';
import { startOfTodayAsUtcDatetime } from '../../../../../../generic/utils/date/dateUtils';
import { CurrencyCode, currencySymbolByCurrencyCode } from 'utils/currency';
import { FieldKeys } from './formTypes';
import classNames from 'classnames';
import {
  DataId,
  OffersProductArea,
  PageLocation,
} from '../../../../../../generic/analytics';
import { ampli } from 'utils/analytics/ampli/index';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

interface ScheduleRowProps {
  index: number;
  onRemove: (i: number) => void;
  currencyCode: CurrencyCode;
  allowDelete: boolean;
}

const useStyles = makeStyles((theme) => ({
  scheduleCounterGridItem: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  invoiceDateGridItem: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  invoiceAmountGridItem: {
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },
  deleteActionGridItem: {
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  deleteButton: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(1.75),
  },
}));

const ScheduleRow: React.FunctionComponent<ScheduleRowProps> = ({
  index,
  onRemove,
  currencyCode,
  allowDelete,
}) => {
  const classes = useStyles();
  const { offerIsMarketplaceEditable } = useContext(OfferContext);
  const schedulePath = `${ProductAndPricingFieldKey.Schedules}[${index}]`;
  const invoiceDateFieldKey = `${schedulePath}.${FieldKeys.InvoiceDate}`;
  const invoiceAmountFieldKey = `${schedulePath}.${FieldKeys.InvoiceAmount}`;
  const currencySymbol = currencySymbolByCurrencyCode[currencyCode];

  const fieldKeyToLabel = {
    [invoiceDateFieldKey]: 'Invoice date',
    [invoiceAmountFieldKey]: 'Invoice amount',
  };

  const onDeleteScheduleClicked = () => {
    ampli.buttonClicked(
      {
        button_product_area: OffersProductArea,
        button_location: PageLocation.OfferEditPage,
        button_name: DataId.DeleteScheduleButton,
      },
      { extra: { marketplace: Marketplace.Aws } },
    );

    onRemove(index);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={1}
        sm={1}
        xs={1}
        className={classes.scheduleCounterGridItem}
      >
        {index + 1}
      </Grid>
      <Grid item md={5} xs className={classes.invoiceDateGridItem}>
        <Field name={invoiceDateFieldKey}>
          {({ field, meta }) => (
            <DateSelector
              {...field}
              data-id={`${DataId.ScheduleInvoiceDateField}-${index}`}
              label={`${fieldKeyToLabel[field.name]} *`}
              minDate={startOfTodayAsUtcDatetime().toISO()}
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled={!offerIsMarketplaceEditable}
            />
          )}
        </Field>
      </Grid>
      <Grid item md={5} xs className={classes.invoiceAmountGridItem}>
        <Field name={invoiceAmountFieldKey}>
          {({ field, meta }) => (
            <TextField
              {...field}
              data-id={`${DataId.ScheduleInvoiceAmountField}-${index}`}
              label={`${fieldKeyToLabel[field.name]} *`}
              type="number"
              mode="numberformat"
              numberPrefix={currencySymbol}
              fixedDecimalScale
              decimalScale={2}
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled={!offerIsMarketplaceEditable}
            />
          )}
        </Field>
      </Grid>
      {allowDelete && (
        <Grid
          item
          md={1}
          sm={1}
          xs={1}
          className={classNames(
            classes.deleteActionGridItem,
            classes.deleteButton,
          )}
        >
          <IconButton
            data-id={`${DataId.DeleteScheduleButton}-${index}`}
            onClick={onDeleteScheduleClicked}
            disabled={!offerIsMarketplaceEditable}
          >
            <Delete />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ScheduleRow;
