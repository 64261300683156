/** TODO: update this to use `as const satisfies Recordtype
 *  to get better type inference and auto complete.
 *  the linter prevented me from writing it like that.
 */
export const awsCoSellToFormFieldEnumMap: Record<
  string,
  { label: string; name: string }
> = {
  awsAccountId: {
    label: 'AWS account ID',
    name: 'awsAccountId',
  },
  awsMarketplaceOffers: {
    label: 'Marketplace offer ID',
    name: 'awsMarketplaceOffers',
  },
  awsProducts: {
    label: 'AWS products',
    name: 'awsProducts',
  },
  'customer.account.address.countryCode': {
    label: 'Country',
    name: 'countryCode',
  },
  'customer.account.address.city': {
    label: 'City',
    name: 'city',
  },
  'customer.account.address.postalCode': {
    label: 'Postal code',
    name: 'postalCode',
  },
  'customer.account.address.stateOrRegion': {
    label: 'State/Province',
    name: 'state',
  },
  'customer.account.address.streetAddress': {
    label: 'Address',
    name: 'address',
  },
  'customer.account.companyName': {
    label: 'Customer company name',
    name: 'customerCompanyName',
  },
  'customer.account.websiteUrl': {
    label: 'Customer website',
    name: 'customerWebsite',
  },
  'customer.account.industry': {
    label: 'Industry vertical',
    name: 'industry',
  },
  'customer.account.otherIndustry': {
    label: 'Industry other',
    name: 'industryOther',
  },
  'customer.contacts': {
    label: 'Customer contact',
    /** not a real field, but will need to point to a section */
    name: 'customerContacts',
  },
  'customer.contacts.email': {
    label: 'Customer email',
    name: 'customerEmail',
  },
  'customer.contacts.firstName': {
    label: 'Customer first name',
    name: 'customerFirstName',
  },
  'customer.contacts.lastName': {
    label: 'Customer last name',
    name: 'customerLastName',
  },
  'customer.contacts.phone': {
    label: 'Customer phone',
    name: 'customerPhone',
  },
  'customer.contacts.businessTitle': {
    label: 'Customer title',
    name: 'customerTitle',
  },
  duns: {
    label: 'Customer data universal number system (DUNS)',
    name: 'duns',
  },

  'lifeCycle.nextSteps': {
    label: 'Next Step',
    name: 'nextStep',
  },
  'lifeCycle.targetCloseDate': {
    label: 'Target close date',
    name: 'targetCloseDate',
  },
  'marketing.awsFundingUsed': {
    label: 'Marketing development funds',
    name: 'isMarketingDevelopmentFunded',
  },
  'marketing.campaignName': {
    label: 'Marketing campaign',
    name: 'campaignName',
  },
  'marketing.channels': {
    label: 'Marketing activity channel',
    name: 'marketingActivityChannel',
  },
  'marketing.source': {
    label: 'Opportunity source',
    name: 'marketingSource',
  },
  'marketing.useCases': {
    label: 'Marketing activity use case',
    name: 'marketingActivityUseCases',
  },
  nationalSecurity: {
    label: 'Classified national security information',
    name: 'nationalSecurity',
  },
  opportunityTeam: {
    label: 'Primary contact',
    /** not a real field, but will need to point to a section */
    name: 'primaryContact',
  },
  'opportunityTeam.email': {
    label: 'Primary contact email',
    name: 'primaryContactEmail',
  },
  'opportunityTeam.firstName': {
    label: 'Primary contact first name',
    name: 'primaryContactFirstName',
  },
  'opportunityTeam.lastName': {
    label: 'Primary contact last name',
    name: 'primaryContactLastName',
  },
  'opportunityTeam.phone': {
    label: 'Primary contact phone',
    name: 'primaryContactPhone',
  },
  'opportunityTeam.businessTitle': {
    label: 'Primary contact title',
    name: 'primaryContactTitle',
  },
  opportunityType: {
    label: 'Opportunity type',
    name: 'opportunityType',
  },
  partnerNeedsFromAws: {
    label: 'Partner needs from AWS',
    name: 'primaryNeedsFromAws',
  },
  'project.additionalComments': {
    label: 'Additional comments',
    name: 'additionalComments',
  },
  'project.apnPrograms': {
    label: 'APN programs',
    name: 'apnPrograms',
  },
  'project.competitorName': {
    label: 'Competitive tracking',
    name: 'competitiveTracking',
  },
  'project.customerBusinessProblem': {
    label: 'Customer business problem',
    name: 'customerBusinessProblem',
  },
  'project.customerUseCase': {
    label: 'Use case',
    name: 'customerUseCase',
  },
  'project.deliveryModels': {
    label: 'Delivery models',
    name: 'deliveryModels',
  },
  'project.expectedCustomerSpend': {
    label: 'Expected customer spend',
    /** not a real field, but will need to point to a section */
    name: 'expectedCustomerSpend',
  },
  'project.otherSolutionDescription': {
    label: 'Other solution offered',
    name: 'otherSolutionDescription',
  },
  'project.salesActivities': {
    label: 'Sales activities',
    name: 'salesActivities',
  },
  'project.title': {
    label: 'Partner project title',
    name: 'projectTitle',
  },
  solutions: {
    label: 'Solutions',
    name: 'solutions',
  },

  stage: {
    label: 'Co-Sell stage',
    name: 'stage',
  },
} as const;

/** form fields not added bc not in data mapper schema
 *  TODO: look into these fields to see if they are required
 *  - partnerNeedType
 *  - parentOppId
 *  - solutionsOfferedToggle
 *  - otherCompetitorNames
 */
