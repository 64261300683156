import {
  OpportunitiesFilterPanelKeyLabel,
  OpportunitiesFilterPanelKeyOptions,
  OpportunitiesFilterPanelKeyType,
  InvitationsFilterPanelKeyLabel,
  InvitationsFilterPanelKeyType,
  opportunitiesFilterPanelKeys,
  invitationsFilterPanelKeys,
  invitationsCloudStatusOptions,
} from './constants';

export const getActivePanelFilters = ({
  searchParams,
  isInvitations,
}: {
  searchParams: URLSearchParams;
  isInvitations?: boolean;
}) => {
  if (isInvitations) {
    return Array.from(searchParams)
      .filter(([key]) => invitationsFilterPanelKeys.includes(key))
      .map(([key, value]) => ({
        key,
        label: InvitationsFilterPanelKeyLabel[key],
        type: InvitationsFilterPanelKeyType[key],
        value,
        title: invitationsCloudStatusOptions.find(
          (option) => option.value === value,
        )?.title,
        options: InvitationsFilterPanelKeyLabel[key],
      }));
  }
  return Array.from(searchParams)
    .filter(([key]) => opportunitiesFilterPanelKeys.includes(key))
    .map(([key, value]) => ({
      key,
      label: OpportunitiesFilterPanelKeyLabel[key],
      type: OpportunitiesFilterPanelKeyType[key],
      value,
      options: OpportunitiesFilterPanelKeyOptions[key],
    }));
};
