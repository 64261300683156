import { useCanvasSession } from '../src/useCanvasSession';
import { NewAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/NewAwsPrivateOfferPage';
import { ViewAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/view/ViewAwsPrivateOfferPage';
import { EditAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/EditAwsPrivateOfferPage';
import { CloneAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/CloneAwsPrivateOfferPage';
import { OffersCanvasAppContentPayload } from '../src/lib/salesforceCanvasSession';
import useOffersCanvasActions from './useOffersCanvasActions';
import { AmendAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/AmendAwsPrivateOfferPage';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

const OffersCanvasApp = () => {
  const { getOrRenewAccessToken, payload } =
    useCanvasSession<OffersCanvasAppContentPayload>();
  const { navigate } = useOffersCanvasActions();
  const accessTokenProvider = getOrRenewAccessToken;

  if (payload.action === 'create') {
    return (
      <NewAwsPrivateOfferCanvasPage
        accessTokenProvider={accessTokenProvider}
        offerType={payload.offerType}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onAmend={(offerId: string, offer?: AwsPrivateOffer) =>
          navigate({
            action: 'amend',
            cloud: 'aws',
            offerId,
            poId: offer?.poId,
            domain: 'offers',
          })
        }
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
        opportunityId={
          payload.record.objectName === 'Opportunity' ? payload.record.Id : null
        }
        mode={payload.newOrAbo}
      />
    );
  }
  if (payload.action === 'view') {
    return (
      <ViewAwsPrivateOfferCanvasPage
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
        afterArchive={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onEdit={() =>
          // this is not working... I think maybe salesforce does not like trying to navigate back to the same page with different params
          navigate({
            action: 'edit',
            cloud: 'aws',
            poId: payload.poId,
            domain: 'offers',
          })
        }
        onOpenOffer={(offer: AwsPrivateOffer) =>
          navigate({
            action: 'view',
            cloud: 'aws',
            poId: offer.poId,
            domain: 'offers',
          })
        }
      />
    );
  }
  if (payload.action === 'edit') {
    return (
      <EditAwsPrivateOfferCanvasPage
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
      />
    );
  }
  if (payload.action === 'clone') {
    return (
      <CloneAwsPrivateOfferCanvasPage
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
      />
    );
  }
  if (payload.action === 'amend') {
    return (
      <AmendAwsPrivateOfferCanvasPage
        offerId={payload.offerId}
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        onAmend={(offerId: string, offer?: AwsPrivateOffer) =>
          navigate({
            action: 'amend',
            cloud: 'aws',
            offerId,
            poId: offer?.poId,
            domain: 'offers',
          })
        }
        onOpenOffer={(offer: AwsPrivateOffer) =>
          navigate({
            action: 'view',
            cloud: 'aws',
            poId: offer.poId,
            domain: 'offers',
          })
        }
      />
    );
  }
};

export default OffersCanvasApp;
