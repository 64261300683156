import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './tackle-prospect-zero-state-animation.json';

const TackleProspectZeroStateAnimation = (
  props: Omit<LottieComponentProps, 'animationData'>,
) => (
  <Lottie
    animationData={animationData}
    loop={false}
    style={{ width: 140 }}
    {...props}
  />
);

export default TackleProspectZeroStateAnimation;
