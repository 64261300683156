import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { User } from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';

interface IntercomProps {
  currentUser?: User;
  vendorId: string;
  vendorType: string;
}

export const Intercom: React.FC<IntercomProps> = ({
  currentUser,
  vendorId,
  vendorType,
}) => {
  const { update } = useIntercom();

  useEffect(() => {
    if (currentUser) {
      const details = {
        name: currentUser.fullName,
        email: currentUser.email,
        userId: currentUser.intercomUserId,
        company: {
          companyId: vendorId,
          customAttributes: {
            vendor_type: vendorType,
          },
        },
        userHash: currentUser.intercomHash,
      };

      update(details);
    }
  }, [update, currentUser, vendorId, vendorType]);

  useEffect(() => {
    update();
  }, [update]);

  return null;
};
