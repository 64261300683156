import React, { useContext } from 'react';
import { Select } from '@tackle-io/platform-ui';
import { Field, useFormikContext } from 'formik';
import { CurrencyCode, getCurrencyResource } from 'utils/currency';
import { FieldKey } from '../formTypes';
import { FormValues } from '../../formSchema';
import { getFormattedError } from '../../../../../generic/utils/field/fieldUtils';
import { Optional } from 'utils/optional/optional';
import { findMatchingAwsProduct } from '../utils/productUtils';
import { DataId } from '../../../../../generic/analytics';
import { AWSProduct } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { AwsEditOfferVendorContext } from 'pages/PrivateOffers/pages/Next/aws/edit/AwsEditOfferContext/AwsEditOfferContextProvider';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.CurrencyCode]: 'Currency',
};

const toCurrencyOption =
  (marketplaceCurrencies: CurrencyCode[]) => (cc: CurrencyCode) => {
    const optionText = getCurrencyResource(cc);
    const disabled =
      cc !== CurrencyCode.UnitedStatesDollar &&
      marketplaceCurrencies.length > 0 &&
      marketplaceCurrencies.indexOf(cc) === -1;

    return (
      <option key={cc} value={cc} disabled={disabled}>
        {optionText}
      </option>
    );
  };

const CurrencySelector: React.FunctionComponent = () => {
  const {
    values: { productRef },
  } = useFormikContext<FormValues>();
  const { productsByProductId } = useContext(OfferPageContext);
  const { offerIsMarketplaceEditable } = useContext(OfferContext);

  const { marketplaceCurrencies } = useContext(AwsEditOfferVendorContext);
  const currencyCodes = Object.values(CurrencyCode);

  const currencyOptions = currencyCodes.map(
    toCurrencyOption(marketplaceCurrencies),
  );

  const selectedProduct = findMatchingAwsProduct(
    productRef,
    productsByProductId as { [pId: string]: AWSProduct },
  );

  const hasUsageDimensions = Optional.ofNullable(selectedProduct)
    .map((p) => p?.pricing?.usageFees?.length > 0)
    .orElse(false);

  return (
    <Field name={FieldKey.CurrencyCode}>
      {({ field, meta }) => (
        <Select
          {...field}
          data-id={DataId.CurrencyField}
          label={`${fieldKeyToLabel[FieldKey.CurrencyCode]} *`}
          error={getFormattedError(field.name, fieldKeyToLabel, meta)}
          disabled={hasUsageDimensions || !offerIsMarketplaceEditable}
        >
          {currencyOptions}
        </Select>
      )}
    </Field>
  );
};

export default CurrencySelector;
