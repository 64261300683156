import React, { useContext } from 'react';
import { ActivityItem as OfferActivityItem } from '@tackle-io/platform-ui';
import { ACTIVITY_SLUG_TO_ACTION_DESCRIPTION } from './constants';
import OfferPageContext from '../OfferPageContext/offerPageContext';
import { Activity, ActivitySlug } from '../types/TackleOffer';

interface ActivityItemProps {
  activity: Activity;
}

const ActivityItem: React.FC<ActivityItemProps> = ({ activity }) => {
  const { users } = useContext(OfferPageContext);
  const {
    slug,
    createdAt,
    userId,
    metadata: {
      user: { email, name },
    },
  } = activity;

  const auth0User = users?.find((user) => user.userId === userId);
  const avatar = auth0User?.picture ?? null;

  const actionDescription =
    ACTIVITY_SLUG_TO_ACTION_DESCRIPTION[slug as ActivitySlug];
  let username = auth0User?.name;
  if (!username) username = name || email;

  if (username === 'Buyer' && !actionDescription?.includes('viewed offer')) {
    username = 'Tackle';
  }

  const date =
    new Date(createdAt).toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }) +
    ' | ' +
    new Date(createdAt).toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });

  return (
    <OfferActivityItem
      avatar={avatar}
      user={username}
      message={actionDescription}
      date={date}
    />
  );
};

export default ActivityItem;
