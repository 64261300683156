import React from 'react';
import { Box, makeStyles, Typography } from 'vendor/material/index';

interface LabelAndValueProps {
  label: string;
  value: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  value: {
    fontSize: 16,
    fontWeight: 400,
  },
}));

const LabelAndValue: React.FunctionComponent<LabelAndValueProps> = ({
  label,
  value,
}) => {
  const classes = useStyles();
  const valueToShow = !value ? '-' : value;

  const wrapValueWithTypography =
    typeof valueToShow === 'string' || typeof valueToShow === 'number';

  return (
    <Box>
      <Typography className={classes.label}>{label}</Typography>
      {wrapValueWithTypography ? (
        <Typography className={classes.value}>{valueToShow}</Typography>
      ) : (
        valueToShow
      )}
    </Box>
  );
};

export default LabelAndValue;
