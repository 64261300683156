import { makeStyles } from 'vendor/material';

export const useDisconnectButtonStyles = makeStyles((theme) => ({
  disconnectButton: {
    '&&': {
      color: '#DE350B',
      background: 'transparent',
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    '&:hover': {
      background: 'rgba(222, 53, 11, 0.1)',
    },
  },
  popupContainer: {
    width: '100%',
    height: '100%',
    padding: '24px',
    background: '#FFF',
    boxShadow: 'none',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  popupImage: {
    width: '180px',
    height: '60.84px',
    marginBottom: '16px',
    marginTop: '8px',
  },
  popupActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  popupTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#253858',
    marginBottom: '16px',
    textAlign: 'center',
  },
  popupDescription: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#5E6C84',
    marginBottom: '24px',
    textAlign: 'center',
  },
  confirmButton: {
    background: '#DE350B',
    color: '#FFF',
    borderRadius: '4px',
    padding: '8px 16px',
    fontWeight: 600,
    fontSize: '16px',
    '&:hover': {
      background: '#BF2600',
    },
  },
  cancelButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '4px',
    border: '1px solid #C1C7D0',
    background: '#FFF',
    color: '#0052CC',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));
