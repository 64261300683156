import { FieldKey, FormValues } from './formSchema';
import {
  AwsPrivateOffer,
  ResellerAgreementType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.ResellerAgreementType]: ResellerAgreementType.NoneSelected,
  [FieldKey.ResellerAgreementDocumentUrns]: [],
  [FieldKey.ResellerAgreementFiles]: [],
};

export const initialFormValuesForExistingOffer = (
  offer: AwsPrivateOffer,
): FormValues => {
  const { partnerOffer } = offer;
  const resellerAgreement = partnerOffer?.resellerAgreement;
  const resellerAgreementDocumentUrns = resellerAgreement?.documentUrns || [];
  const resellerAgreementFiles = resellerAgreement?.files || [];

  const resellerAgreementType =
    resellerAgreement?.type || ResellerAgreementType.NoneSelected;

  return {
    ...initialFormValuesForNewOffer,
    [FieldKey.ResellerAgreementType]: resellerAgreementType,
    [FieldKey.ResellerAgreementDocumentUrns]: resellerAgreementDocumentUrns,
    [FieldKey.ResellerAgreementFiles]: resellerAgreementFiles,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: AwsPrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: AwsPrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);
