import { Box, Typography, makeStyles } from 'vendor/material';
import LockedChest from './assets/LockedChest';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.NEUTRAL900,
    fontSize: theme.typography.pxToRem(20),
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(30),
    wordWrap: 'break-word',
  },
  description: {
    color: theme.palette.NEUTRAL900,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(20),
    wordWrap: 'break-word',
  },
}));

const NoPermissionBanner: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <LockedChest />
      <Typography className={classes.title}>You need access!</Typography>
      <Typography className={classes.description}>
        Your permissions are not configured to view or request scores. Contact
        your Salesforce admin to request changes.
      </Typography>
    </Box>
  );
};

export default NoPermissionBanner;
