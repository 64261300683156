import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  sortHeaderContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  sortable: { cursor: 'pointer' },
  arrow: {
    position: 'relative',
    top: '2px',
    left: '4px',
  },
  marginLeftSort: {
    marginLeft: theme.spacing(1),
  },
  arrowSpaceholder: {
    width: '16px',
    display: 'inline-block',
  },
}));

export default useStyles;
