import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  invitationsTableContainer: {
    color: theme.palette.NEUTRAL900,
    display: 'contents',
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(128),
    maxWidth: theme.spacing(152),
  },
  paginationDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px 0`,
  },
  paginationDescription: {
    marginRight: '1em',
  },
  emptyIcon: {
    color: theme.palette.NEUTRAL050,
    filter: 'grayscale(1)',
  },
  emptyTable: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
  },
  emptyHeader: {
    fontSize: theme.spacing(3.5),
    fontWeight: 300,
    lineHeight: 1.25,
    marginBottom: theme.spacing(2),
  },
  emptyDescription: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.NEUTRAL700,
  },
}));

export default useStyles;
