import {
  CLOUD_SALES_STAGE_KEY,
  CLOUD_STATUS_KEY,
  HAS_CRM_ID_KEY,
  HAS_ERRORS_KEY,
  SOURCE_KEY,
} from '../../pages/CoSellLandingPage/helpers/urlParamsHelper';
import {
  AceInvitationCloudStatusEnum,
  AceOpportunityReviewStatusEnum,
  AceOpportunitySalesStageEnum,
} from '../../types/enums';
import { CrmIdEnum } from '../../types/enums/CrmIdEnum';
import { ErrorsEnum } from '../../types/enums/ErrorsEnum';

export enum FILTER_TYPES {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
}
export const opportunitiesFilterPanelKeys = [
  CLOUD_STATUS_KEY,
  CLOUD_SALES_STAGE_KEY,
  SOURCE_KEY,
  HAS_ERRORS_KEY,
  HAS_CRM_ID_KEY,
];

export const invitationsFilterPanelKeys = [CLOUD_STATUS_KEY];

export const OpportunitiesFilterPanelKeyLabel = {
  [SOURCE_KEY]: 'Source',
  [CLOUD_STATUS_KEY]: 'Co-Sell Status',
  [CLOUD_SALES_STAGE_KEY]: 'Co-Sell stage',
  [HAS_ERRORS_KEY]: 'One or more errors',
  [HAS_CRM_ID_KEY]: 'Has CRM ID',
};

export const InvitationsFilterPanelKeyLabel = {
  [CLOUD_STATUS_KEY]: 'Invitation status',
};

export const OpportunitiesFilterPanelKeyType = {
  [CLOUD_STATUS_KEY]: FILTER_TYPES.MULTI_SELECT,
  [CLOUD_SALES_STAGE_KEY]: FILTER_TYPES.MULTI_SELECT,
  [SOURCE_KEY]: FILTER_TYPES.MULTI_SELECT,
  [HAS_ERRORS_KEY]: FILTER_TYPES.MULTI_SELECT,
  [HAS_CRM_ID_KEY]: FILTER_TYPES.MULTI_SELECT,
};

export const InvitationsFilterPanelKeyType = {
  [CLOUD_STATUS_KEY]: FILTER_TYPES.MULTI_SELECT,
};

export const sourceOptions = [
  {
    title: 'AWS',
    value: 'Inbound', // AWS originated
  },
  {
    title: 'Partner',
    value: 'Outbound', // Partner originated
  },
];

export const opportunitiesCloudStatusOptions = [
  {
    title: 'Draft',
    value: 'Draft',
  },
  {
    title: 'Pending Submission',
    value: AceOpportunityReviewStatusEnum.PENDING_SUBMISSION,
  },
  {
    title: 'Submitted',
    value: AceOpportunityReviewStatusEnum.SUBMITTED,
  },
  {
    title: 'In Review',
    value: AceOpportunityReviewStatusEnum.IN_REVIEW,
  },
  {
    title: 'Action Required',
    value: AceOpportunityReviewStatusEnum.ACTION_REQUIRED,
  },
  {
    title: 'Approved',
    value: AceOpportunityReviewStatusEnum.APPROVED,
  },
  {
    title: 'Rejected',
    value: AceOpportunityReviewStatusEnum.REJECTED,
  },
];

export const invitationsCloudStatusOptions = [
  {
    title: 'Pending',
    value: AceInvitationCloudStatusEnum.PENDING,
  },
  {
    title: 'Accepted',
    value: AceInvitationCloudStatusEnum.ACCEPTED,
  },
  {
    title: 'Rejected',
    value: AceInvitationCloudStatusEnum.REJECTED,
  },
  {
    title: 'Expired',
    value: AceInvitationCloudStatusEnum.EXPIRED,
  },
];

export const cloudSalesStageOptions = [
  {
    title: 'Prospect',
    value: AceOpportunitySalesStageEnum.PROSPECT,
  },
  {
    title: 'Qualified',
    value: AceOpportunitySalesStageEnum.QUALIFIED,
  },
  {
    title: 'Technical Validation',
    value: AceOpportunitySalesStageEnum.TECHNICAL_VALIDATION,
  },
  {
    title: 'Business Validation',
    value: AceOpportunitySalesStageEnum.BUSINESS_VALIDATION,
  },
  {
    title: 'Committed',
    value: AceOpportunitySalesStageEnum.COMMITTED,
  },
  {
    title: 'Launched',
    value: AceOpportunitySalesStageEnum.LAUNCHED,
  },
  {
    title: 'Closed Lost',
    value: AceOpportunitySalesStageEnum.CLOSED_LOST,
  },
];

export const hasErrorsOptions = [
  {
    title: 'AWS',
    value: ErrorsEnum.AWS_ERRORS,
  },
  {
    title: 'Salesforce',
    value: ErrorsEnum.SALESFORCE_ERRORS,
  },
  {
    title: 'No errors',
    value: ErrorsEnum.NO_ERRORS,
  },
];

export const hasCrmIdOptions = [
  {
    title: 'Yes',
    value: CrmIdEnum.YES,
  },
  {
    title: 'No ID',
    value: CrmIdEnum.NO_ID,
  },
];

export const OpportunitiesFilterPanelKeyOptions = {
  [CLOUD_STATUS_KEY]: opportunitiesCloudStatusOptions,
  [CLOUD_SALES_STAGE_KEY]: cloudSalesStageOptions,
  [SOURCE_KEY]: sourceOptions,
  [HAS_ERRORS_KEY]: hasErrorsOptions,
  [HAS_CRM_ID_KEY]: hasCrmIdOptions,
};

export const InvitationsFilterPanelKeyOptions = {
  [CLOUD_STATUS_KEY]: invitationsCloudStatusOptions,
};

// TODO: Add Solution, Country, State/Province/Has offers filters when APIs are available

export const opportunitiesFilterSet = [
  {
    key: SOURCE_KEY,
    label: OpportunitiesFilterPanelKeyLabel[SOURCE_KEY],
    type: OpportunitiesFilterPanelKeyType[SOURCE_KEY],
    options: OpportunitiesFilterPanelKeyOptions[SOURCE_KEY],
    placeholder: 'All',
  },
  {
    key: CLOUD_STATUS_KEY,
    label: OpportunitiesFilterPanelKeyLabel[CLOUD_STATUS_KEY],
    type: OpportunitiesFilterPanelKeyType[CLOUD_STATUS_KEY],
    options: OpportunitiesFilterPanelKeyOptions[CLOUD_STATUS_KEY],
    placeholder: 'All',
  },
  {
    key: CLOUD_SALES_STAGE_KEY,
    label: OpportunitiesFilterPanelKeyLabel[CLOUD_SALES_STAGE_KEY],
    type: OpportunitiesFilterPanelKeyType[CLOUD_SALES_STAGE_KEY],
    options: OpportunitiesFilterPanelKeyOptions[CLOUD_SALES_STAGE_KEY],
    placeholder: 'All',
  },
  {
    key: HAS_ERRORS_KEY,
    label: OpportunitiesFilterPanelKeyLabel[HAS_ERRORS_KEY],
    type: OpportunitiesFilterPanelKeyType[HAS_ERRORS_KEY],
    options: OpportunitiesFilterPanelKeyOptions[HAS_ERRORS_KEY],
    placeholder: 'All',
    newGroup: {
      label: 'Conditions',
    },
  },
  {
    key: HAS_CRM_ID_KEY,
    label: OpportunitiesFilterPanelKeyLabel[HAS_CRM_ID_KEY],
    type: OpportunitiesFilterPanelKeyType[HAS_CRM_ID_KEY],
    options: OpportunitiesFilterPanelKeyOptions[HAS_CRM_ID_KEY],
    placeholder: 'All',
  },
];

export const invitationsFilterSet = [
  {
    key: CLOUD_STATUS_KEY,
    label: InvitationsFilterPanelKeyLabel[CLOUD_STATUS_KEY],
    type: InvitationsFilterPanelKeyType[CLOUD_STATUS_KEY],
    options: InvitationsFilterPanelKeyOptions[CLOUD_STATUS_KEY],
    placeholder: 'All',
  },
];
