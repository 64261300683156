import React from 'react';
import { Box, Tooltip } from 'vendor/material';
import { ArrowUp, ArrowDown } from 'mdi-material-ui';
import useStyles from './SortHeader.styles';
import classNames from 'classnames';
import {
  OpportunityColumnKey,
  SORT_FIELD_KEY,
  SORT_ORDER_KEY,
  SortDirection,
  OpportunitySortState,
  PAGE_KEY,
  FROM_KEY,
} from '../../../../helpers/urlParamsHelper';
import { useHistory, useLocation } from 'react-router-dom';

interface SortHeaderProps {
  columnKey: OpportunityColumnKey;
  sortState: OpportunitySortState;
  disabled?: boolean;
}

const SortHeader: React.FC<SortHeaderProps> = ({
  children,
  columnKey,
  sortState,
  disabled,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { sortKey, sortDirection } = sortState || {};
  const onHeaderClicked = () => {
    const newParams = new URLSearchParams(location.search);
    newParams.delete(SORT_FIELD_KEY);
    newParams.delete(SORT_ORDER_KEY);
    newParams.delete(PAGE_KEY);
    newParams.delete(FROM_KEY);

    if (columnKey !== sortKey) {
      // First click on a new column
      newParams.append(SORT_FIELD_KEY, columnKey);
      newParams.append(SORT_ORDER_KEY, SortDirection.DESC);
    } else if (columnKey === sortKey && sortDirection !== SortDirection.ASC) {
      // Second click on the same column
      newParams.append(SORT_FIELD_KEY, columnKey);
      newParams.append(SORT_ORDER_KEY, SortDirection.ASC);
    } else if (columnKey === sortKey && sortDirection === SortDirection.ASC) {
      // Third click on the same column, reset the sort state to default
    }
    const correctedPath = `${location.pathname}?${newParams.toString()}`;
    history.push(correctedPath);
  };

  const ArrowComponent = sortDirection === 'asc' ? ArrowUp : ArrowDown;
  const tooltipTitle =
    sortDirection === 'asc'
      ? 'Change sort to default'
      : 'Change sort direction to ascending';

  return (
    <Box
      className={classNames(classes.sortHeaderContainer, {
        [classes.sortable]: !disabled,
      })}
      onClick={onHeaderClicked}
    >
      {children}
      {columnKey === sortKey ? (
        <Tooltip title={tooltipTitle}>
          <ArrowComponent fontSize="inherit" className={classes.arrow} />
        </Tooltip>
      ) : (
        <Box className={classes.arrowSpaceholder} />
      )}
    </Box>
  );
};

export default SortHeader;
