import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { FieldKey } from '../formTypes';
import { Box, Grid, RadioGroup } from 'vendor/material';
import { Radio, Select } from '@tackle-io/platform-ui';
import FutureDatedConfig from './FutureDatedConfig/FutureDatedConfig';
import StartOnAcceptanceConfig from './StartOnAcceptanceConfig/StartOnAcceptanceConfig';
import { FormValues } from '../../formSchema';
import { setDurationValue } from './effects/effects';
import { DataId } from '../../../../../generic/analytics';
import {
  BillingTerm,
  DurationType,
  PaymentModel,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.PaymentModel]: 'Payment model',
};

const PricingConfig: React.FunctionComponent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const { mode } = useContext(OfferPageContext);
  const { offerIsMarketplaceEditable, offerIsAmendment } =
    useContext(OfferContext);
  const isAmendmentMode = mode === OfferPageMode.Amend || offerIsAmendment;

  useEffect(() => {
    setDurationValue(
      values.billingTerm as BillingTerm,
      values.billingTermCadence as DurationType,
      values.durationInCadence,
      values.serviceStartAt,
      values.serviceEndAt,
      setFieldValue,
    );
  }, [
    values.billingTerm,
    values.billingTermCadence,
    values.durationInCadence,
    values.serviceStartAt,
    values.serviceEndAt,
    setFieldValue,
  ]);

  return (
    <Box mb={3}>
      <Field name={FieldKey.PaymentModel}>
        {({ field }) => (
          <Box mb={2}>
            <Select
              {...field}
              data-id={DataId.PaymentModelField}
              label={`${fieldKeyToLabel[FieldKey.PaymentModel]} *`}
              disabled={!offerIsMarketplaceEditable || isAmendmentMode}
            >
              <option
                key={PaymentModel.PaymentSchedule}
                value={PaymentModel.PaymentSchedule}
              >
                Payment schedule
              </option>
              <option
                key={PaymentModel.PerProduct}
                value={PaymentModel.PerProduct}
              >
                Upfront pricing
              </option>
            </Select>
          </Box>
        )}
      </Field>
      <Field name={FieldKey.BillingTerm}>
        {({ field, form: { setFieldValue } }) => {
          const onChange = (e) => {
            const value = e.currentTarget.value as BillingTerm;
            const billingTermCadence =
              value === BillingTerm.Custom
                ? values.billingTermCadence || DurationType.Months
                : null;

            field.onChange(e);
            setFieldValue(FieldKey.BillingTermCadence, billingTermCadence);
          };

          return (
            !isAmendmentMode && (
              <Box>
                {values.offerType !== OfferType.PartnerResale && (
                  <Box mb={2} ml={1}>
                    <Grid container>
                      <RadioGroup {...field} onChange={onChange}>
                        <Grid item>
                          <Radio
                            data-id={DataId.BillingTermField}
                            value={BillingTerm.Custom}
                            label="Start on acceptance"
                            disabled={!offerIsMarketplaceEditable}
                          />
                        </Grid>
                        <Grid item>
                          <Radio
                            data-id={DataId.BillingTermField}
                            value={BillingTerm.FutureDated}
                            label="Future dated"
                            disabled={!offerIsMarketplaceEditable}
                          />
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Box>
                )}
                {field.value === BillingTerm.Custom && (
                  <StartOnAcceptanceConfig />
                )}
                {field.value === BillingTerm.FutureDated && (
                  <FutureDatedConfig />
                )}
              </Box>
            )
          );
        }}
      </Field>
    </Box>
  );
};

export default PricingConfig;
