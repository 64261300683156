import * as yup from '../../../../../../../../utils/yup-extended';
import {
  FieldKey as ProductSelectorFieldKeys,
  formSchema as productFormSchema,
  FormValues as ProductFormValues,
} from '../../../../generic/ProductSelector/formSchema';
import { dimensionsSchema } from './DimensionsConfig/DimensionRow/formSchema';
import { schedulesSchema } from './ScheduleConfig/ScheduleRow/formSchema';
import {
  FormUsageDimension,
  usageDimensionsSchema,
} from './UsageDimensionsConfig/UsageDimensionRow/formSchema';
import { CurrencyCode } from 'utils/currency';
import { FormSchedule } from './ScheduleConfig/ScheduleRow/formTypes';
import { FieldKey } from './formTypes';
import {
  BillingTerm,
  DurationType,
  PaymentModel,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKeys as NonUserInputFieldKey } from '../formTypes';
import { FormDimension } from './DimensionsConfig/DimensionRow/formTypes';

interface NonUserInputFormValues {
  version: string;
  durationValue: number | null;
}

export type FormValues = {
  [FieldKey.PaymentModel]: string | null;
  [FieldKey.BillingTerm]: string | null;
  [FieldKey.BillingTermCadence]: string | null;
  [FieldKey.DurationInCadence]: number | null;
  [FieldKey.ServiceStartAt]: string | null;
  [FieldKey.ServiceEndAt]: string | null;
  [FieldKey.CurrencyCode]: string | null;
  [FieldKey.Dimensions]: FormDimension[];
  [FieldKey.UsageDimensions]: FormUsageDimension[];
  [FieldKey.Schedules]: FormSchedule[];
} & NonUserInputFormValues &
  ProductFormValues;

const versionSchema = yup.string().when('$', {
  is: isForYupExtended(
    ProductSelectorFieldKeys.ProductRef,
    (pr: string | null) => !!pr,
  ),
  then: (schema: yup.StringSchema) => schema.required(),
  otherwise: (schema: yup.StringSchema) => schema.nullable(),
});

const nonUserInputFormSchema: yup.ObjectSchema<NonUserInputFormValues> =
  yup.object({
    version: versionSchema,
    durationValue: yup.number().nullable(),
  });

const paymentModelSchema = yup.string().when('$', {
  is: isForYupExtended(
    ProductSelectorFieldKeys.ProductRef,
    (pr: string | null) => !!pr,
  ),
  then: (schema: yup.StringSchema) =>
    schema
      .oneOf([
        PaymentModel.PaymentSchedule,
        PaymentModel.PerProduct,
        PaymentModel.PayGo,
      ])
      .required()
      .progress(true),
  otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
});

const billingTermSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [ProductSelectorFieldKeys.ProductRef, FieldKey.BillingTerm],
      (pr: string | null, bt: BillingTerm) =>
        !!pr && (bt === BillingTerm.Custom || bt === BillingTerm.FutureDated),
    ),
    then: (schema: yup.StringSchema) =>
      schema
        .oneOf([BillingTerm.Custom, BillingTerm.FutureDated])
        .required()
        .progress(true),
    otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
  });

const billingTermCadenceSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [ProductSelectorFieldKeys.ProductRef, FieldKey.BillingTerm],
      (pr: string | null, bt: BillingTerm) => !!pr && bt === BillingTerm.Custom,
    ),
    then: (schema: yup.StringSchema) =>
      schema.oneOf(Object.values(DurationType)).required().progress(true),
    otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
  });

const durationInCadenceSchema = yup
  .number()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [ProductSelectorFieldKeys.ProductRef, FieldKey.BillingTerm],
      (pr: string | null, bt: BillingTerm) => !!pr && bt === BillingTerm.Custom,
    ),
    then: (schema: yup.NumberSchema) => schema.min(1).max(60).progress(true),
    otherwise: (schema: yup.NumberSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [NonUserInputFieldKey.CreateInMarketplace, FieldKey.BillingTerm],
      (cim: boolean, bt: BillingTerm) => cim && bt === BillingTerm.Custom,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const stringRequiredFutureDatedStartDateSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      [ProductSelectorFieldKeys.ProductRef, FieldKey.BillingTerm],
      (pr: string | null, bt: BillingTerm) =>
        !!pr && bt === BillingTerm.FutureDated,
    ),
    then: (schema: yup.StringSchema) => schema.progress(true),
    otherwise: (schema: yup.StringSchema) => schema.progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [NonUserInputFieldKey.CreateInMarketplace, FieldKey.BillingTerm],
      (cim: boolean, bt: BillingTerm) => cim && bt === BillingTerm.FutureDated,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const stringRequiredWhenFutureDatedTermSchema = yup
  .string()
  .when('$', {
    is: isForYupExtended(
      [
        ProductSelectorFieldKeys.ProductRef,
        FieldKey.BillingTerm,
        FieldKey.PaymentModel,
      ],
      (pr: string | null, bt: string, pm: string) =>
        !!pr && (bt === BillingTerm.FutureDated || pm === PaymentModel.PayGo),
    ),
    then: (schema: yup.StringSchema) => schema.nullable().progress(true),
    otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
  })
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        FieldKey.BillingTerm,
        FieldKey.PaymentModel,
      ],
      (cim: boolean, bt: BillingTerm, pm: PaymentModel) =>
        cim && (bt === BillingTerm.FutureDated || pm === PaymentModel.PayGo),
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const currencyCodeSchema = yup.string().when('$', {
  is: isForYupExtended(
    ProductSelectorFieldKeys.ProductRef,
    (pr: string | null) => !!pr,
  ),
  then: (schema: yup.StringSchema) =>
    schema.oneOf(Object.values(CurrencyCode)).required().progress(true),
  otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
});

export const formSchema: yup.ObjectSchema<FormValues> = yup
  .object({
    [FieldKey.PaymentModel]: paymentModelSchema,
    [FieldKey.BillingTerm]: billingTermSchema,
    [FieldKey.BillingTermCadence]: billingTermCadenceSchema,
    [FieldKey.DurationInCadence]: durationInCadenceSchema,
    [FieldKey.ServiceStartAt]: stringRequiredFutureDatedStartDateSchema,
    [FieldKey.ServiceEndAt]: stringRequiredWhenFutureDatedTermSchema,
    [FieldKey.CurrencyCode]: currencyCodeSchema,
    [FieldKey.Dimensions]: dimensionsSchema,
    [FieldKey.UsageDimensions]: usageDimensionsSchema,
    [FieldKey.Schedules]: schedulesSchema,
  })
  .concat(nonUserInputFormSchema)
  .concat(productFormSchema);
