import {
  ContextValueSetters,
  NewOfferOfferSubmissionErrorsKey,
  OfferApiFunctions,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import {
  MsftPrivateOffer,
  Source,
  StartOn,
  State,
  Status,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/MsftPrivateOffer';
import { useCallback } from 'react';
import {
  ActivitySlug,
  Marketplace,
  OfferType,
} from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import useOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/useOfferApi';
import { msftPrivateOfferJSONToMsftPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/msftPrivateOfferJSONToMsftPrivateOffer';
import { OfferAPIKey } from '../ApiContext/offerAPIKey';
import { offersMsftApi } from '../api/offerAPIs';
import { msftPrivateOfferToMsftPrivateOfferJSON } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/msftPrivateOfferToMsftPrivateOfferJSON';

type UseMsftPrivateOfferApi = OfferApiFunctions<MsftPrivateOffer> & {
  createOffer: (
    offer: Partial<MsftPrivateOffer>,
    createInMarketplace: boolean,
  ) => Promise<MsftPrivateOffer | null>;
};

const mockOffer: MsftPrivateOffer = {
  poId: 'po-id',
  marketplace: Marketplace.Azure,
  acceptedAt: null,
  acceptedSource: 'accepted-source',
  archivedAt: null,
  createdAt: '2025-02-01T00:00:00.000Z',
  cancelledAt: null,
  lastModifiedAt: '2025-03-01T00:00:00.000Z',
  lastUpdateSource: 'last-updated-source',
  viewedOfferAt: null,
  sentAt: null,
  submittedAt: '2025-02-02T00:00:00.000Z',
  openedInstructionsAt: null,
  marketplaceAcceptanceLink: {},
  salesforce: {
    customObjectId: 'custom-object-id',
    opportunityId: 'opportunity-id',
  },
  vendorId: 'vendor-id',
  vendorName: 'vendor name',
  productContactEmail: 'product contact email',
  state: State.DraftState,
  status: Status.DraftStatus,
  offerType: OfferType.Direct,
  quoteNumber: 'quote#',
  marketplaceFee: 0.25,
  activities: [
    {
      slug: ActivitySlug.VendorCreatedOffer,
      userId: null,
      activityType: '',
      createdAt: '2025-02-10T00:00:00.000Z',
      metadata: {
        user: { email: 'justin.freeland@tackle.io', name: 'Justin Freeland' },
      },
    },
  ],
  archived: false,
  version: 'version',
  productRef: 'MPSELLERTESTVENDOR-azure-1738956220',
  productName: 'Azure 2.x Test Listing',
  source: Source.Tackle,
  salesforceOpportunityId: 'opportunity-id',
  submittedToCloud: true,
  marketplaceCreatedAt: null,
  name: 'name',
  description: 'description',
  buyerDetails: {
    companyName: 'company name',
    billingAccountId: '00000000-0000-0000-0000-000000000000',
    buyers: [
      {
        fullName: 'full name',
        emailAddress: 'email@email.com',
        title: 'ceo',
      },
    ],
  },
  renewal: true,
  eula: {
    type: 'eula-type',
    files: ['file1'],
  },
  usersToNotify: [
    {
      contactType: 'auth0',
      firstName: 'Justin',
      lastName: 'Freeland',
      email: 'justin.freeland@tackle.io',
    },
  ],
  pricing: null,
  preparerEmail: 'prepare-email@email.com',
  startOn: StartOn.Custom,
  startDate: '2025-04-01T00:00:00.000Z',
  endDate: '2026-04-01T00:00:00.000Z',
  acceptByDate: '2025-03-01T00:00:00.000Z',
  offerRef: 'offer-ref',
  offerState: {
    draft: { rawMessage: 'drafted' },
    lastModified: '2025-03-01T00:00:00.000Z',
  },
  metadata: {
    metadataKey1: 'metadataValue1',
  },
};

const convertToMsftPrivateOffer = async (response: any) =>
  msftPrivateOfferJSONToMsftPrivateOffer(response);

const useMsftPrivateOfferApi = (
  accessTokenProvider: () => Promise<string>,
  contextValueSetters: ContextValueSetters,
): UseMsftPrivateOfferApi => {
  const { setOfferSubmissionErrors } = contextValueSetters;

  const { stateTrackingGet, stateTrackingSubmit } =
    useOfferApi(contextValueSetters);

  const handleOfferSubmissionError = useCallback(
    (poId: string) =>
      async (response): Promise<null> => {
        const body = await response.response.text();
        const offerSubmissionError = JSON.parse(body) as SchemaValidationError;

        setOfferSubmissionErrors((previousOfferSubmissionErrors) => ({
          ...previousOfferSubmissionErrors,
          [poId]: offerSubmissionError,
        }));

        return null;
      },
    [setOfferSubmissionErrors],
  );

  const getOffer = useCallback(
    async (poId: string): Promise<MsftPrivateOffer | null> => {
      // return mockOffer;
      return stateTrackingGet(
        OfferAPIKey.Offer,
        () => offersMsftApi(accessTokenProvider).get(`api/offer/${poId}`),
        convertToMsftPrivateOffer,
      );
    },
    // [],
    [stateTrackingGet, accessTokenProvider],
  );

  const getOfferSilently = useCallback(async (poId: string) => {
    return mockOffer;
  }, []);

  const createOffer = useCallback(
    async (
      offer: Partial<MsftPrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<MsftPrivateOffer | null> => {
      const json = msftPrivateOfferToMsftPrivateOfferJSON(offer);

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () => offersMsftApi(accessTokenProvider).post(`api/offer`, { json }),
        convertToMsftPrivateOffer,
        handleOfferSubmissionError(NewOfferOfferSubmissionErrorsKey),
      );
    },
    // [],
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const updateOffer = useCallback(
    async (
      poId: string,
      updatedOffer: Partial<MsftPrivateOffer>,
      createInMarketplace: boolean,
    ) => {
      return mockOffer;
    },
    [],
  );

  const cancelOffer = useCallback(async (poId: string) => {
    return;
  }, []);

  const sendBuyerInstructions = useCallback(async (poId: string) => {
    return;
  }, []);

  const archiveOffer = useCallback(async (poId: string) => {
    return;
  }, []);

  return {
    getOffer,
    getOfferSilently,
    createOffer,
    updateOffer,
    cancelOffer,
    sendBuyerInstructions,
    archiveOffer,
  };
};

export default useMsftPrivateOfferApi;
