import React, { useContext } from 'react';
import { Sale } from 'mdi-material-ui';
import { Banner, Card } from '@tackle-io/platform-ui';
import { Box, Grid } from 'vendor/material';
import LabelAndValue from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/components/LabelAndValue';
import { formatDateFieldValue } from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import OfferContext, {
  OfferContextValues,
} from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

const OfferDetailsSection: React.FunctionComponent = () => {
  const { offer } = useContext(
    OfferContext,
  ) as OfferContextValues<AwsPrivateOffer>;

  return (
    <Card
      title="Offer details"
      icon={<Sale />}
      bodyStyle={{ backgroundColor: '#FAFBFC' }}
    >
      {!!offer.createdInMarketplaceAt && (
        <Box mb={4}>
          <Banner
            title="This offer has been created in the Cloud marketplace and this section cannot be edited."
            borderPosition="top"
          />
        </Box>
      )}
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <LabelAndValue
              label="Created at"
              value={formatDateFieldValue(offer.createdAt)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <LabelAndValue
              label="Offer acceptance deadline"
              value={formatDateFieldValue(offer.offerExpirationAt)}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default OfferDetailsSection;
