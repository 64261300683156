const EulaSvg = () => (
  <svg
    width="24"
    height="29"
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99998 14.5C9.01034 13.7076 9.32974 12.9506 9.89012 12.3902C10.4505 11.8298 11.2076 11.5104 12 11.5C12.7924 11.5104 13.5495 11.8298 14.1098 12.3902C14.6702 12.9506 14.9896 13.7076 15 14.5C14.9896 15.2925 14.6702 16.0495 14.1098 16.6099C13.5495 17.1703 12.7924 17.4897 12 17.5C11.2076 17.4897 10.4505 17.1703 9.89012 16.6099C9.32974 16.0495 9.01034 15.2925 8.99998 14.5ZM12 23.5L16 24.5V21.42C14.7937 22.1465 13.408 22.5206 12 22.5C10.592 22.5206 9.20631 22.1465 7.99998 21.42V24.5M12 8.50004C11.212 8.48566 10.4293 8.6326 9.70015 8.93183C8.971 9.23106 8.31075 9.67625 7.75998 10.24C7.1901 10.7914 6.73976 11.4541 6.43697 12.187C6.13419 12.9199 5.98544 13.7072 5.99998 14.5C5.98956 15.2878 6.14032 16.0695 6.44298 16.7969C6.74564 17.5243 7.19381 18.1821 7.75998 18.73C8.30818 19.2993 8.96728 19.75 9.69655 20.0544C10.4258 20.3589 11.2098 20.5105 12 20.5C12.7902 20.5105 13.5741 20.3589 14.3034 20.0544C15.0327 19.75 15.6918 19.2993 16.24 18.73C16.8062 18.1821 17.2543 17.5243 17.557 16.7969C17.8597 16.0695 18.0104 15.2878 18 14.5C18.0145 13.7072 17.8658 12.9199 17.563 12.187C17.2602 11.4541 16.8099 10.7914 16.24 10.24C15.6892 9.67625 15.029 9.23106 14.2998 8.93183C13.5707 8.6326 12.788 8.48566 12 8.50004ZM20 14.5C19.9787 15.4599 19.7856 16.4082 19.43 17.3C19.1096 18.2075 18.6248 19.0481 18 19.78V27.5L12 25.5L5.99998 27.5V19.78C4.70567 18.3265 3.99349 16.4463 3.99998 14.5C3.98236 13.4506 4.18002 12.4087 4.58077 11.4387C4.98151 10.4686 5.57684 9.59103 6.32998 8.86004C7.06369 8.10013 7.94535 7.49867 8.92055 7.09277C9.89575 6.68686 10.9438 6.48514 12 6.50004C13.0562 6.48514 14.1042 6.68686 15.0794 7.09277C16.0546 7.49867 16.9363 8.10013 17.67 8.86004C18.4231 9.59103 19.0185 10.4686 19.4192 11.4387C19.8199 12.4087 20.0176 13.4506 20 14.5Z"
      fill="#253858"
    />
  </svg>
);

export default EulaSvg;
