import * as yup from '../../../../../../../../../../utils/yup-extended';
import { duplicateInvoiceDatesTest } from './schemaTests';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { FieldKeys, FormSchedule } from './formTypes';
import { PaymentModel } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKey as ProductSelectorFieldKeys } from 'pages/PrivateOffers/pages/Next/generic/ProductSelector/formSchema';
import { FieldKeys as NonUserInputFieldKey } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formTypes';

const invoiceDateTypeSchema = yup.string().oneOf([null]).nullable();

const hasProductSelectedWithPaymentSchedule = isForYupExtended(
  [ProductSelectorFieldKeys.ProductRef, ProductAndPricingFieldKey.PaymentModel],
  (pr: string | null, pm: PaymentModel) =>
    !!pr && pm === PaymentModel.PaymentSchedule,
);

const trackProgressWhenProductSelectedAndPaymentSchedule = {
  is: hasProductSelectedWithPaymentSchedule,
  then: (schema: yup.MixedSchema) => schema.progress(true),
  otherwise: (schema: yup.MixedSchema) => schema.progress(false),
};

const invoiceDateSchema = yup
  .string()
  .nullable()
  .when('$', trackProgressWhenProductSelectedAndPaymentSchedule)
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (cim: boolean, pm: PaymentModel) =>
        cim && pm === PaymentModel.PaymentSchedule,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  });

const invoiceAmountSchema = yup
  .number()
  .nullable()
  .min(0)
  .when('$', trackProgressWhenProductSelectedAndPaymentSchedule)
  .when('$', {
    is: isForYupExtended(
      [
        NonUserInputFieldKey.CreateInMarketplace,
        ProductAndPricingFieldKey.PaymentModel,
      ],
      (cim: boolean, pm: PaymentModel) =>
        cim && pm === PaymentModel.PaymentSchedule,
    ),
    then: (schema: yup.NumberSchema) => schema.required(),
  });

const scheduleSchema = yup.object({
  [FieldKeys.InvoiceDateType]: invoiceDateTypeSchema,
  [FieldKeys.InvoiceDate]: invoiceDateSchema,
  [FieldKeys.InvoiceAmount]: invoiceAmountSchema,
});

export const schedulesSchema = yup.array<FormSchedule>().when('$', {
  is: hasProductSelectedWithPaymentSchedule,
  then: (schema: yup.ArraySchema<FormSchedule>) =>
    schema
      .when('$', {
        is: isForYupExtended(
          ProductAndPricingFieldKey.Schedules,
          (s: FormSchedule[]) => s.length === 0,
        ),
        then: (schema: yup.ArraySchema<FormSchedule>) =>
          schema.min(1, 'At least 1 scheduled payment is required'),
        otherwise: (schema: yup.ArraySchema<FormSchedule>) =>
          schema
            .of(scheduleSchema)
            .test('Duplicate invoice dates', '', duplicateInvoiceDatesTest),
      })
      .progress(true),
});
