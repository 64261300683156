import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import CreateAceOpportunityForm from 'packages/cosell/src/components/UnifiedOpportunityForm/AceOpportunityForm/CreateAceOpportunityForm';
import { Loader } from '@tackle-io/platform-ui';
import { DataMapperFieldErrorsBanner } from 'packages/cosell/src/components/DataMapperBanners/DataMapperFieldErrorsBanner';
import { DataMapperLoadingErrorBanner } from 'packages/cosell/src/components/DataMapperBanners/DataMapperLoadingErrorBanner';
import { DataMapperNotConfiguredBanner } from 'packages/cosell/src/components/DataMapperBanners/DataMapperNotConfiguredBanner';
import {
  Cloud,
  Domain,
} from 'pages/Settings/Account/components/FieldMapper/utils/constants';

import { useAwsCoSellMappedFields } from 'packages/cosell/src/hooks/useAwsCoSellMappedFields';

/** TODO: merge the domain & cloud enums from fieldMapper and co-sell so a single version for each is used */
export const CreateOpportunityPage = ({ onClose }: { onClose: () => void }) => {
  const params = useParams<{ cloud: Cloud; crmId: string }>();

  invariant(
    params?.crmId,
    'crmId is undefined: The Salesforce opportunity id (crmId) is required to create a Co-sell opportunity in Salesforce Canvas',
  );

  const awsMappedFieldsQuery = useAwsCoSellMappedFields({
    crmId: params.crmId,
    enabled: params.cloud === Cloud.AWS,
    formType: 'create',
  });

  if (awsMappedFieldsQuery.isInitialLoading) {
    return <Loader />;
  }

  const mappedData = awsMappedFieldsQuery.mappedData;

  const banners = (
    <>
      {!!mappedData?.fieldsWithErrors && (
        <DataMapperFieldErrorsBanner
          errorFields={mappedData.fieldsWithErrors}
        />
      )}

      {awsMappedFieldsQuery.isError && (
        <DataMapperLoadingErrorBanner
          domain={Domain.CO_SELL}
          isRefetching={awsMappedFieldsQuery.isRefetching}
          refetch={awsMappedFieldsQuery.refetch}
        />
      )}
      {!mappedData && !awsMappedFieldsQuery.isError && (
        <DataMapperNotConfiguredBanner domain={Domain.CO_SELL} />
      )}
    </>
  );

  return (
    <CreateAceOpportunityForm
      banners={banners}
      crmId={params.crmId}
      /** @ts-expect-error -- need to look at the mappedData return type to get proper inference */
      initialValues={mappedData?.initialValues}
      onCancel={onClose}
      onCreated={onClose}
    />
  );
};

export default CreateOpportunityPage;
