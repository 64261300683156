import { useCallback } from 'react';
import { offersApi } from 'pages/PrivateOffers/pages/Next/generic/api/offerAPIs';
import {
  Pricing,
  AwsPrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import {
  awsPrivateOfferJSONToAwsPrivateOffer,
  toPricing,
} from 'pages/PrivateOffers/pages/Next/generic/api/transformers/awsPrivateOfferJSONToAwsPrivateOffer';
import { awsPrivateOfferToAwsPrivateOfferJSON } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/awsPrivateOfferToAwsPrivateOfferJSON';
import { AwsPrivateOfferRequestJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOfferRequestJSON';
import { AwsPrivateOfferResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOfferResponseJSON';
import { AwsPrivateOffersResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffersResponseJSON';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import {
  ContextValueSetters,
  NewOfferOfferSubmissionErrorsKey,
  OfferApiFunctions,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { PricingJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOfferJSON';
import { Agreement } from '../api/types/Agreement';
import { AgreementsResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/AgreementsResponseJSON';
import { agreementJSONToAgreements } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/agreementJSONToAgreements';
import useOfferApi from './useOfferApi';

export type UseAwsPrivateOfferApi = OfferApiFunctions<AwsPrivateOffer> & {
  getOfferForAgreement: (offerId: string) => Promise<AwsPrivateOffer | null>;
  getAgreementMappedAsPrivateOffer: (offerId: string) => Promise<any>;
  getOffersForOpportunity: (
    opportunityId: string,
  ) => Promise<AwsPrivateOffer[] | null>;
  createOffer: (
    offer: Partial<AwsPrivateOffer>,
    createInMarketplace: boolean,
  ) => Promise<AwsPrivateOffer | null>;
  getVendorCurrencies: () => Promise<string[]>;
  getAwsMarketplacePricing: (encryptedProductId: string) => Promise<Pricing>;
  getAgreementsForBuyer: (
    buyerAccountNumber: string,
    offerId?: string,
    activeOnly?: boolean,
  ) => Promise<Agreement[]>;
};

const convertPrivateOfferResponseToPrivateOffer = async (
  response: AwsPrivateOfferResponseJSON | null,
) =>
  response
    ? awsPrivateOfferJSONToAwsPrivateOffer(response.private_offer)
    : null;

const convertPrivateOffersResponseToPrivateOffers = async (
  response: AwsPrivateOffersResponseJSON | null,
) =>
  response
    ? response.private_offers?.map(awsPrivateOfferJSONToAwsPrivateOffer)
    : null;

const convertToPricing = async (responseJson: PricingJSON | null) =>
  toPricing(responseJson);

const convertToAgreements = async (responseJson: AgreementsResponseJSON) =>
  agreementJSONToAgreements(responseJson.entitlements);

const useAwsPrivateOfferApi = (
  accessTokenProvider: () => Promise<string>,
  contextValueSetters: ContextValueSetters,
): UseAwsPrivateOfferApi => {
  const { setOfferSubmissionErrors } = contextValueSetters;

  const {
    stateTrackingGet,
    stateTrackingSubmit,
    stateTrackingSubmitWithEmptyResponse,
  } = useOfferApi(contextValueSetters);

  const handleOfferSubmissionError = useCallback(
    (poId: string) =>
      async (response): Promise<null> => {
        const body = await response.response.text();
        const offerSubmissionError = JSON.parse(body) as SchemaValidationError;

        setOfferSubmissionErrors((previousOfferSubmissionErrors) => ({
          ...previousOfferSubmissionErrors,
          [poId]: offerSubmissionError,
        }));

        return null;
      },
    [setOfferSubmissionErrors],
  );

  const fetchOffer = useCallback(
    (poId: string, silent: boolean = false) =>
      stateTrackingGet(
        silent ? OfferAPIKey.Silent : OfferAPIKey.Offer,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/private-offers/${poId}`,
          ),
        convertPrivateOfferResponseToPrivateOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getOffer = useCallback(
    async (poId: string): Promise<AwsPrivateOffer | null> => fetchOffer(poId),
    [fetchOffer],
  );

  const getOfferSilently = useCallback(
    async (poId: string): Promise<AwsPrivateOffer | null> =>
      fetchOffer(poId, true),
    [fetchOffer],
  );

  const getOfferForAgreement = useCallback(
    (offerId: string): Promise<AwsPrivateOffer | null> =>
      stateTrackingGet(
        OfferAPIKey.AgreementOffer,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/private-offers?offer_ref=${offerId}&limit=1`,
          ),
        async (r: AwsPrivateOffersResponseJSON | null) => {
          const privateOffers =
            await convertPrivateOffersResponseToPrivateOffers(r);
          return privateOffers?.at(0);
        },
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAgreementMappedAsPrivateOffer = useCallback(
    (offerId: string): Promise<any> =>
      stateTrackingGet(
        OfferAPIKey.Silent,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/marketplace/aws/offers/${offerId}`,
          ),
        convertPrivateOfferResponseToPrivateOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getOffersForOpportunity = useCallback(
    async (opportunityId: string): Promise<AwsPrivateOffer[] | null> =>
      stateTrackingGet(
        OfferAPIKey.OffersForOpportunity,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/private-offers?salesforce_opportunity_id=${opportunityId}`,
          ),
        convertPrivateOffersResponseToPrivateOffers,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const createOffer = useCallback(
    async (
      offer: Partial<AwsPrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<AwsPrivateOffer | null> => {
      const json: AwsPrivateOfferRequestJSON = {
        create_in_marketplace: createInMarketplace,
        send_buyer_instructions: false,
        dry_run: false,
        private_offer: awsPrivateOfferToAwsPrivateOfferJSON(offer),
      };

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersApi(accessTokenProvider).post('public/v2/private-offers', {
            json,
          }),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferSubmissionError(NewOfferOfferSubmissionErrorsKey),
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const updateOffer = useCallback(
    async (
      poId: string,
      updatedOffer: Partial<AwsPrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<AwsPrivateOffer | null> => {
      const json: AwsPrivateOfferRequestJSON = {
        create_in_marketplace: createInMarketplace,
        send_buyer_instructions: false,
        dry_run: false,
        private_offer: awsPrivateOfferToAwsPrivateOfferJSON(updatedOffer),
      };

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersApi(accessTokenProvider).put(
            `public/v2/private-offers/${poId}`,
            { json },
          ),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferSubmissionError(poId),
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const sendBuyerInstructions = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(
        OfferAPIKey.SendBuyerInstructions,
        () =>
          offersApi(accessTokenProvider).post(
            `public/v2/private-offers/${poId}/send-buyer-instructions`,
          ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const cancelOffer = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(OfferAPIKey.CancelOffer, () =>
        offersApi(accessTokenProvider).post(
          `public/v2/private-offers/${poId}/cancellation`,
        ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const archiveOffer = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(OfferAPIKey.ArchiveOffer, () =>
        offersApi(accessTokenProvider).delete(
          `public/v2/private-offers/${poId}`,
        ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const getVendorCurrencies = useCallback(
    async (): Promise<string[]> =>
      stateTrackingGet(OfferAPIKey.VendorCurrencies, () =>
        offersApi(accessTokenProvider).get(
          `public/v2/marketplace/aws/seller/allowed-currencies`,
        ),
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAwsMarketplacePricing = useCallback(
    async (encryptedProductId: string): Promise<Pricing> =>
      stateTrackingGet(
        OfferAPIKey.MarketplacePricing,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/marketplace/aws/products/${encryptedProductId}/pricing`,
          ),
        convertToPricing,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAgreementsForBuyer = useCallback(
    (
      buyerAccountNumber: string = '',
      offerId: string = '',
      activeOnly: boolean = false,
    ): Promise<Agreement[]> => {
      const activeSearchParam = activeOnly ? '&status=ACTIVE' : '';
      const buyerAccountSearchParam = buyerAccountNumber
        ? `&buyer_billing_account_ref=${buyerAccountNumber}`
        : '';

      const offerIdSearchParam = offerId ? `&offerid=${offerId}` : '';
      const url = `public/v2/marketplace/aws/entitlements?limit=20${activeSearchParam}${buyerAccountSearchParam}${offerIdSearchParam}`;

      return stateTrackingGet(
        OfferAPIKey.ActiveAgreements,
        () => offersApi(accessTokenProvider).get(url),
        convertToAgreements,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  return {
    getOffer,
    getOfferSilently,
    getOfferForAgreement,
    getAgreementMappedAsPrivateOffer,
    getOffersForOpportunity,
    createOffer,
    updateOffer,
    cancelOffer,
    sendBuyerInstructions,
    archiveOffer,
    getVendorCurrencies,
    getAwsMarketplacePricing,
    getAgreementsForBuyer,
  };
};

export default useAwsPrivateOfferApi;
