import {
  ActivitySlug,
  Marketplace,
  OfferType,
} from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

export interface MsftPrivateOffer {
  poId?: string;
  marketplace: Marketplace.Azure;
  acceptedAt?: string;
  acceptedSource?: string;
  archivedAt?: string;
  createdAt: string;
  cancelledAt?: string;
  lastModifiedAt: string;
  lastUpdateSource?: string;
  viewedOfferAt?: string;
  sentAt?: string;
  submittedAt?: string;
  openedInstructionsAt?: string;
  marketplaceAcceptanceLink?: MarketplaceAcceptanceLink;
  salesforce: Salesforce;
  vendorId: string;
  vendorName: string;
  productContactEmail: string;
  state: State;
  status: Status;
  offerType: OfferType;
  quoteNumber: string;
  marketplaceFee: number;
  activities: POActivity[];
  archived: boolean;
  version: string;
  productRef: string;
  productName: string;
  source: Source;
  salesforceOpportunityId?: string;
  submittedToCloud: boolean;
  marketplaceCreatedAt?: string;
  name?: string;
  description?: string;
  buyerDetails: BuyerDetails;
  renewal: boolean;
  eula: EULA;
  usersToNotify: UserToNotify[];
  pricing: Pricing;
  preparerEmail: string;
  startOn: StartOn;
  startDate: string;
  endDate: string;
  acceptByDate: string;
  offerRef: string;
  offerState: OfferState;
  metadata: { [k: string]: string };
}

export interface OfferState {
  draft?: OfferStateDetail;
  submitted?: OfferStateDetail;
  accepted?: OfferStateDetail;
  currentCloud?: OfferStateDetail;
  lastModified: string;
}

export interface OfferStateDetail {
  rawMessage: string;
}

export interface Pricing {
  planId: string;
  planName: string;
  description: string;
  contractDuration: ContractDuration;
  paymentModel: string;
  paymentInstallments: PaymentInstallment;
  paymentSchedule: ScheduleDetail[];
  dimensions: { [k: string]: Dimension };
}

export interface PaymentInstallment {
  pricePerPaymentInUsd: number;
}

export interface ContractDuration {
  type: string;
  frequency: number;
}

export enum StartOn {
  Acceptance = 'acceptance',
  Custom = 'custom',
}

export interface UserToNotify {
  contactType: string;
  value?: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Buyer {
  fullName: string;
  emailAddress: string;
  title: string;
}

export interface BuyerDetails {
  companyName: string;
  billingAccountId: string;
  buyers: Buyer[];
}

export interface EULA {
  type?: string;
  files: string[];
}

export interface Salesforce {
  customObjectId?: string;
  opportunityId?: string;
}

export interface Dimension {
  enabled: boolean;
  id: string;
  unitOfMeasure: string;
  pricePerUnitInUsd: number;
  includedQuantities: DimensionQuantity[];
}

export interface DimensionQuantity {
  dimensionTerm: DimensionTerm;
  unlimitedQuantity?: boolean;
  quantity?: number;
}

export interface DimensionTerm {
  type: string;
  frequency: number;
}

export interface ScheduleDetail {
  chargeDate: string;
  pricePerPaymentInUsd: number;
  note: string;
}

export interface POActivity {
  activityType: string;
  slug?: ActivitySlug;
  userId?: string;
  createdAt: string;
  metadata: POActivityMetadata;
  details?: string;
}

export type POActivityMetadata = { [k: string]: any };

export interface MarketplaceAcceptanceLink {}

export enum State {
  DraftState = 'draft',
  PendingState = 'pending',
  SubmittedToCloudState = 'submitted_in_cloud',
  CreatedInCloudState = 'created_in_cloud',
  EmailSentState = 'email_sent',
  InstructionsOpenedState = 'instructions_opened',
  ViewedState = 'viewed',
  AcceptedState = 'accepted',
  SubscribedState = 'subscribed',
  AttentionNeededState = 'attention_needed',
  PendingCancellationState = 'pending_cancellation',
  CanceledState = 'canceled',
}

export enum Status {
  DraftStatus = 'draft_status',
  CreatedInCloudStatus = 'created_in_cloud',
  SentToCloudStatus = 'sent_to_cloud',
  PendingCancellationStatus = 'pending_cancellation',
  CanceledStatus = 'canceled',
  ErroredStatus = 'errored',
}

export enum Source {
  Tackle = 'tackle',
  Salesforce = 'salesforce',
}
