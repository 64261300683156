import * as yup from '../../../../../../utils/yup-extended';
import { isForYupExtended } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKeys as NonUserInputFieldKey } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formTypes';

export enum FieldKey {
  ProductRef = 'productRef',
}

export interface FormValues {
  [FieldKey.ProductRef]: string | null;
}

const productRefSchema = yup
  .string()
  .nullable()
  .when('$', {
    is: isForYupExtended(
      NonUserInputFieldKey.CreateInMarketplace,
      (cim: boolean) => cim,
    ),
    then: (schema: yup.StringSchema) => schema.required(),
  })
  .progress(true);

export const formSchema = yup.object<FormValues>({
  [FieldKey.ProductRef]: productRefSchema,
});
