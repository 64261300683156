import { Optional } from 'utils/optional/optional';
import { FieldKey } from '../../formTypes';
import { FormDimension } from '../DimensionRow/formTypes';
import { FormikHelpers } from 'formik/dist/types';
import { FormValues } from '../../../formSchema';
import { toMatchingAwsProduct } from '../../utils/productUtils';
import { Pricing } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import {
  AWSProduct,
  AWSProductPricingContractDimension,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';

const toFormContractDimensions = (
  contractDimensions: AWSProductPricingContractDimension[],
): FormDimension[] =>
  contractDimensions.map((cd) => ({
    name: cd.name,
    apiName: cd.sku,
    price: null,
    quantity: null,
  }));

export const defaultToProductContractDimensions = async (
  productRef: string | null,
  productsByProductId: { [pId: string]: AWSProduct },
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
  hasDimensions: boolean,
) => {
  if (hasDimensions) {
    return;
  }

  await Optional.ofNullable(productRef)
    .map(toMatchingAwsProduct(productsByProductId))
    .map((p) => p?.pricing?.contractDimensions)
    .map(toFormContractDimensions)
    .ifPresentAsync(async (dimensions) => {
      await setFieldValue(FieldKey.Dimensions, dimensions);
    });
};

export const getMarketplacePricingLoader =
  (
    productRef: string | null,
    productsByProductId: { [pId: string]: AWSProduct },
    getAwsMarketplacePricing: (epid: string) => Promise<Pricing>,
    setMarketplacePricing: (v: Pricing | null) => void,
  ) =>
  async () => {
    setMarketplacePricing(null);

    await Optional.ofNullable(productRef)
      .map(toMatchingAwsProduct(productsByProductId))
      .filter((p) => !!p.encryptedProductid)
      .ifPresentAsync(async (p) => {
        const productMarketplacePricing = await getAwsMarketplacePricing(
          p.encryptedProductid,
        );
        setMarketplacePricing(productMarketplacePricing);
      });
  };
