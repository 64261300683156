import { FormValues } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formSchema';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Optional } from 'utils/optional/optional';
import {
  AWSProduct,
  Product,
  ProductStatus,
} from '../../../generic/api/types/Product';
import { OfferMetadataField } from 'pages/PrivateOffers/pages/Next/generic/AdditionalFieldsFormSection/formSchema';
import { RenewalType } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/BasicInformationFormSection/formSchema';
import { toFinite } from 'lodash';
import {
  dimensionsToFormDimensions,
  schedulesToFormSchedules,
  usageDimensionsToFormUsageDimensions,
} from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ProductAndPricingFormSection/initialFormValues';
import { toMatchingProduct } from '../../../generic/utils/product/productUtils';
import { mapBuyersToFormBuyers } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/BasicInformationFormSection/initialFormValues';
import { usersToNotifToFormUsersToNotify } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/NotifyUsersFormSection/initialFormValues';

const valueOrNullIfError = <T>(value: T): T | null =>
  value === 'ERROR' ? null : value;

const isValidProduct = (product: Product): boolean =>
  !!product.encryptedProductid &&
  (product.status === ProductStatus.Published ||
    product.status === ProductStatus.ReadyForPreview);

const toMergedOfferMetadataFields =
  (initialOfferMetadataFields: OfferMetadataField[]) =>
  (metadata: AwsPrivateOffer['offerMetadata']): OfferMetadataField[] => {
    const initialOfferMetadataFieldByKey = initialOfferMetadataFields.reduce(
      (acc: { [k: string]: OfferMetadataField }, v: OfferMetadataField) => {
        acc[v.key] = v;
        return acc;
      },
      {},
    );

    const mappedOfferMetadataEntries = Object.entries(metadata);

    const mappedOfferMetadataKeys = new Set<string>(
      mappedOfferMetadataEntries.map(([key]) => key),
    );

    const mappedOfferMetadataFields = mappedOfferMetadataEntries.map(
      ([key, value]) => {
        const isDisabled = Optional.ofNullable(
          initialOfferMetadataFieldByKey[key],
        )
          .map((omf) => omf.isDisabled)
          .orElse(false);

        return { key, value, isDisabled };
      },
    );

    const remainingInitialOfferMetadataFields =
      initialOfferMetadataFields.filter(
        (omf) => !mappedOfferMetadataKeys.has(omf.key),
      );

    return [
      ...remainingInitialOfferMetadataFields,
      ...mappedOfferMetadataFields,
    ];
  };

export const mergeInitialValuesWithSalesforceDataMapping = (
  initialValues: FormValues,
  dataMapperOffer: Partial<AwsPrivateOffer> | null,
  productsByProductId: { [pId: string]: Product },
): FormValues =>
  Optional.ofNullable(dataMapperOffer)
    .map((offer) => {
      const {
        cloud: initialCloud,
        opportunityId: initialOpportunityId,
        offerType: initialOfferType,
        sourceOfferId: initialSourceId,
        partnerName: initialPartnerNae,
        partnerAccountNumber: initialPartnerAccountNumber,
        buyerAccountNumber: initialBuyerAccountNumber,
        companyName: initialCompanyName,
        buyers: initialBuyers,
        offerName: initialOfferName,
        offerDescription: initialDescription,
        renewal: initialRenewal,
        renewalType: initialRenewalType,
        paymentModel: initialPaymentModel,
        billingTerm: initialBillingTerm,
        billingTermCadence: initialBillingTermCadence,
        durationInCadence: initialDurationInCadence,
        serviceStartAt: initialServiceStartAt,
        serviceEndAt: initialServiceEndAt,
        currencyCode: initialCurrencyCode,
        dimensions: initialDimensions,
        usageDimensions: initialUsageDimensions,
        schedules: initialSchedules,
        productRef: initialProductRef,
        eulaType: initialEulaType,
        eulaDocumentUrns: initialEulaDocumentUrns,
        resellerAgreementType: initialResellerAgreementType,
        resellerAgreementDocumentUrns: initialResellerAgreementDocumentUrns,
        marketplaceFee: initialMarketplaceFee,
        offerMetadataFields: initialOfferMetadataFields,
        offerAcceptanceDeadline: initialOfferAcceptanceDeadline,
        usersToNotify: initialUsersToNotify,
        ...restInitialValues
      } = initialValues;

      const cloud = valueOrNullIfError(offer.marketplace) || initialCloud;
      const mappedSalesforce = valueOrNullIfError(offer.salesforce);
      const mappedOfferMetadata = valueOrNullIfError(offer.offerMetadata);

      const opportunityId =
        valueOrNullIfError(mappedSalesforce?.opportunityId) ||
        initialOpportunityId;

      const offerType = valueOrNullIfError(offer.offerType) || initialOfferType;

      const sourceOfferId =
        valueOrNullIfError(offer.sourceOfferId) || initialSourceId;

      const mappedPartnerOffer = valueOrNullIfError(offer.partnerOffer);

      const partnerName =
        valueOrNullIfError(mappedPartnerOffer?.partnerName) ||
        initialPartnerNae;

      const partnerAccountNumber =
        valueOrNullIfError(mappedPartnerOffer?.partnerRef) || initialPartnerNae;

      const buyerAccountNumber =
        valueOrNullIfError(offer.buyerBillingAccountRef) ||
        initialBuyerAccountNumber;

      const companyName =
        valueOrNullIfError(offer.buyerCompanyName) || initialCompanyName;

      const mappedBuyers = valueOrNullIfError(offer.buyers);

      const buyers = Optional.ofNullable(mappedBuyers)
        .map(mapBuyersToFormBuyers)
        .orElse(initialBuyers);

      const offerName = valueOrNullIfError(offer.offerName) || initialOfferName;

      const offerDescription =
        valueOrNullIfError(offer.offerDescription) || initialDescription;

      const mappedRenewal = valueOrNullIfError(offer.renewal);

      const renewal =
        mappedRenewal === null ? initialRenewal : Boolean(mappedRenewal);

      const mappedRenewalMovingToMarketplace = valueOrNullIfError(
        offer.awsRenewalMovingToMarketplace,
      );

      const renewalType =
        mappedRenewalMovingToMarketplace === null
          ? initialRenewalType
          : Boolean(mappedRenewalMovingToMarketplace)
          ? RenewalType.NewAwsMarketplaceCustomer
          : RenewalType.ExistingAwsMarketplaceCustomer;

      const mappedPricing = valueOrNullIfError(offer.pricing);

      const paymentModel =
        valueOrNullIfError(mappedPricing?.paymentModel) || initialPaymentModel;

      const billingTerm =
        valueOrNullIfError(mappedPricing?.billingTerm) || initialBillingTerm;

      const billingTermCadence =
        valueOrNullIfError(mappedPricing?.durationType) ||
        initialBillingTermCadence;

      const mappedDuration = valueOrNullIfError(mappedPricing?.duration);

      const durationInCadence =
        mappedDuration && mappedDuration !== '0'
          ? toFinite(mappedDuration)
          : initialDurationInCadence;

      const serviceStartAt =
        valueOrNullIfError(mappedPricing?.serviceStartAt) ||
        initialServiceStartAt;

      const serviceEndAt =
        valueOrNullIfError(mappedPricing?.serviceEndAt) || initialServiceEndAt;

      const currencyCode =
        valueOrNullIfError(mappedPricing?.currencyCode) || initialCurrencyCode;

      const mappedDimensions = valueOrNullIfError(mappedPricing?.dimensions);

      const dimensions = Optional.ofNullable(mappedDimensions)
        .map(dimensionsToFormDimensions)
        .orElse(initialDimensions);

      const mappedProductId = valueOrNullIfError(offer.productId);

      const matchingProduct = Optional.ofNullable(mappedProductId)
        .map(toMatchingProduct(productsByProductId))
        .filter(isValidProduct)
        .orElse(null);

      const productRef = Optional.ofNullable(matchingProduct)
        .map((p) => p.productid)
        .orElse(initialProductRef);

      const mappedUsageDimensions = valueOrNullIfError(
        mappedPricing?.usageDimensions,
      );

      const usageDimensions = Optional.ofNullable(matchingProduct)
        .map((p) => {
          const awsProduct = p as AWSProduct;

          return Optional.ofNullable(awsProduct?.pricing)
            .map((pricing) => {
              const mappedUsageDimensionsBySku = mappedUsageDimensions.reduce(
                (acc, ud) => {
                  acc[ud.sku] = ud;
                  return acc;
                },
                {},
              );

              const usageFees = pricing.usageFees || [];

              return usageFees.map((uf) => {
                const matchingMappedUsageDimension =
                  mappedUsageDimensionsBySku[uf.sku];

                return {
                  ...uf,
                  price: matchingMappedUsageDimension?.price || uf.price,
                };
              });
            })
            .orElse([]);
        })
        .map(usageDimensionsToFormUsageDimensions)
        .orElse(initialUsageDimensions);

      const mappedSchedules = valueOrNullIfError(mappedPricing?.schedule);

      const schedules = Optional.ofNullable(mappedSchedules)
        .map(schedulesToFormSchedules)
        .orElse(initialSchedules);

      const marketplaceFee =
        valueOrNullIfError(mappedPricing?.marketplaceFee) ||
        initialMarketplaceFee;

      const mappedEula = valueOrNullIfError(offer.eula);
      const eulaType = valueOrNullIfError(mappedEula?.type) || initialEulaType;

      const eulaDocumentUrns =
        valueOrNullIfError(mappedEula?.documentUrns) || initialEulaDocumentUrns;

      const mappedResellerAgreement = valueOrNullIfError(
        mappedPartnerOffer?.resellerAgreement,
      );

      const resellerAgreementType =
        valueOrNullIfError(mappedResellerAgreement?.type) ||
        initialResellerAgreementType;

      const resellerAgreementDocumentUrns =
        valueOrNullIfError(mappedResellerAgreement?.documentUrns) ||
        initialResellerAgreementDocumentUrns;

      const offerMetadataFields = Optional.ofNullable(mappedOfferMetadata)
        .map(toMergedOfferMetadataFields(initialOfferMetadataFields))
        .orElse(initialOfferMetadataFields);

      const offerAcceptanceDeadline =
        valueOrNullIfError(offer.offerExpirationAt) ||
        initialOfferAcceptanceDeadline;

      const mappedExtraData = valueOrNullIfError(offer.extraData);
      const mappedUsersToNotify = valueOrNullIfError(mappedExtraData?.users);

      const usersToNotify = Optional.ofNullable(mappedUsersToNotify)
        .map(usersToNotifToFormUsersToNotify)
        .orElse(initialUsersToNotify);

      return {
        cloud,
        opportunityId,
        offerType,
        sourceOfferId,
        partnerName,
        partnerAccountNumber,
        buyerAccountNumber,
        companyName,
        buyers,
        offerName,
        offerDescription,
        renewal,
        renewalType,
        paymentModel,
        billingTerm,
        billingTermCadence,
        durationInCadence,
        serviceStartAt,
        serviceEndAt,
        currencyCode,
        dimensions,
        usageDimensions,
        schedules,
        productRef,
        marketplaceFee,
        eulaType,
        eulaDocumentUrns,
        resellerAgreementType,
        resellerAgreementDocumentUrns,
        offerMetadataFields,
        offerAcceptanceDeadline,
        usersToNotify,
        ...restInitialValues,
      } as FormValues;
    })
    .orElse(initialValues);
