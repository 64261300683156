import React, { useContext, useEffect, useMemo, useState } from 'react';
import OfferFormSection from '../../../../../generic/OfferFormSection/OfferFormSection';
import { Box, Grid, Skeleton } from 'vendor/material';
import { FieldArray, useFormikContext } from 'formik';
import { FormValues } from '../../formSchema';
import DimensionRow from './DimensionRow/DimensionRow';
import { Button } from '@tackle-io/platform-ui';
import DimensionsCounter from './DimensionsCounter/DimensionsCounter';
import DimensionsLoadFailure from './DimensionsLoadFailure/DimensionsLoadFailure';
import { FieldKey } from '../formTypes';
import {
  defaultToProductContractDimensions,
  getMarketplacePricingLoader,
} from './effects/effects';
import {
  DataId,
  OffersProductArea,
  PageLocation,
} from '../../../../../generic/analytics';
import { ampli } from 'utils/analytics/ampli/index';
import { newDimension } from './DimensionRow/formTypes';
import {
  Dimension,
  Pricing,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { AWSProduct } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import { getFormattedErrorIgnoringTouched } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import FieldError from 'pages/PrivateOffers/components/FieldError/FieldError';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const DimensionsConfig: React.FunctionComponent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [loadingMarketplacePricing, setLoadingMarketplacePricing] =
    useState(false);

  const { productRef, dimensions } = values;
  const { productsByProductId } = useContext(OfferPageContext);
  const { offerIsMarketplaceEditable } = useContext(OfferContext);

  const {
    awsApi: { getAwsMarketplacePricing },
  } = useContext(ApiContext);

  const [marketplacePricing, setMarketplacePricing] = useState<Pricing | null>(
    null,
  );

  const dimensionsAreEmpty = dimensions.every((d) => !d.name && !d.apiName);

  useEffect(() => {
    (async () => {
      await defaultToProductContractDimensions(
        productRef,
        productsByProductId as { [pId: string]: AWSProduct },
        setFieldValue,
        !dimensionsAreEmpty,
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRef, productsByProductId, setFieldValue]);

  const loadMarketplacePricing = useMemo(
    () =>
      getMarketplacePricingLoader(
        productRef,
        productsByProductId as { [pId: string]: AWSProduct },
        getAwsMarketplacePricing,
        setMarketplacePricing,
      ),
    [productRef, productsByProductId, getAwsMarketplacePricing],
  );

  useEffect(() => {
    setLoadingMarketplacePricing(true);

    (async () => {
      await loadMarketplacePricing();

      setLoadingMarketplacePricing(false);
    })();

    return () => {
      setMarketplacePricing(null);
    };
  }, [loadMarketplacePricing]);

  const marketplaceDimensions = marketplacePricing?.dimensions as Dimension[];

  return (
    <OfferFormSection title="Dimensions">
      <Box>
        <FieldArray name={FieldKey.Dimensions}>
          {({ form, push, replace, remove }) => {
            const onAddDimensionClicked = () => {
              ampli.buttonClicked(
                {
                  button_product_area: OffersProductArea,
                  button_location: PageLocation.OfferEditPage,
                  button_name: DataId.AddDimensionButton,
                },
                { extra: { marketplace: Marketplace.Aws } },
              );

              push(newDimension);
            };

            const meta = form.getFieldMeta(FieldKey.Dimensions);

            const fieldError = getFormattedErrorIgnoringTouched(
              FieldKey.Dimensions,
              { [FieldKey.Dimensions]: 'Dimensions' },
              meta,
            );

            return (
              <Box>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    {loadingMarketplacePricing ? (
                      <Skeleton />
                    ) : (
                      dimensions.map((d, i: number) => (
                        <Grid item md={12} key={`dimension-${i}-${d.name}`}>
                          <DimensionRow
                            index={i}
                            onReplace={replace}
                            onRemove={remove}
                            form={form}
                            marketplacePricing={marketplacePricing}
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Box>
                {marketplacePricing && (
                  <Box mb={2}>
                    <DimensionsCounter
                      marketplaceDimensions={marketplaceDimensions || []}
                    />
                  </Box>
                )}
                {!loadingMarketplacePricing && !marketplacePricing && (
                  <Box mb={2}>
                    <DimensionsLoadFailure onRetry={loadMarketplacePricing} />
                  </Box>
                )}
                <Box>
                  <Button
                    data-id={DataId.AddDimensionButton}
                    appearance="primary"
                    variant="text"
                    onClick={onAddDimensionClicked}
                    disabled={!offerIsMarketplaceEditable}
                  >
                    Add dimension
                  </Button>
                </Box>
                {fieldError && <FieldError error={fieldError} />}
              </Box>
            );
          }}
        </FieldArray>
      </Box>
    </OfferFormSection>
  );
};

export default DimensionsConfig;
