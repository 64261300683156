import { SalesforceDataMappingResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMappingResponseJSON';
import { SalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMapping';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Optional } from 'utils/optional/optional';
import { awsPrivateOfferJSONToAwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/awsPrivateOfferJSONToAwsPrivateOffer';

export const salesforceDataMappingJSONToSalesforceDataMapping = (
  salesforceDataMappingResponseJSON: SalesforceDataMappingResponseJSON,
): SalesforceDataMapping => {
  const {
    errors,
    mappedFields: { private_offer },
  } = salesforceDataMappingResponseJSON;

  const privateOffer: Partial<AwsPrivateOffer> = Optional.ofNullable(
    private_offer,
  )
    .map(awsPrivateOfferJSONToAwsPrivateOffer)
    .orElse(null);

  return {
    errors: errors ?? {},
    privateOffer,
  };
};
