import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import { Grid, Typography, makeStyles } from 'vendor/material';
import TackleLogo from 'images/tackle-logo';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: theme.spacing(1),
    width: '100vw',
    margin: 0,
  },
  tackleAppBar: {
    background: theme.palette.TEAL900,
    height: '4.5rem',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '1rem',
    width: '100vw',
  },
}));

const ArchiveOfferScreen = ({
  setShowDrawer,
  handleArchiveOffer,
  isLoading,
}: {
  setShowDrawer: (show: boolean) => void;
  handleArchiveOffer: () => void;
  isLoading: boolean;
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.tackleAppBar}>
        <TackleLogo theme="light" width="104px" />
      </div>
      <Grid
        container
        direction="column"
        spacing={2}
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <ProviderIcon provider="aws" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h5">
              Are you sure you want to archive the offer?
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              This will hide it from your Tackle private offers list. The offer
              will not be deleted.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs>
            <Button
              fullWidth
              variant="outlined"
              appearance="primary"
              onClick={() => {
                setShowDrawer(false);
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              fullWidth
              appearance="primary"
              onClick={() => handleArchiveOffer()}
              loading={isLoading}
              disabled={isLoading}
            >
              Archive offer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ArchiveOfferScreen;
