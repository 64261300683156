import {
  BuyerDetailsJSON,
  BuyerJSON,
  ContractDurationJSON,
  DimensionJSON,
  DimensionQuantityJSON,
  EULAJSON,
  MsftPrivateOfferJSON,
  OfferStateJSON,
  POActivityJSON,
  PricingJSON,
  SalesforceJSON,
  ScheduleDetailJSON,
  UserToNotifyJSON,
} from '../types/MsftPrivateOfferJSON';
import { Optional } from 'utils/optional/optional';
import {
  Buyer,
  BuyerDetails,
  ContractDuration,
  Dimension,
  DimensionQuantity,
  EULA,
  MarketplaceAcceptanceLink,
  MsftPrivateOffer,
  OfferState,
  POActivity,
  Pricing,
  Salesforce,
  ScheduleDetail,
  Source,
  StartOn,
  State,
  Status,
  UserToNotify,
} from '../types/MsftPrivateOffer';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const toSalesforce = (salesforce: SalesforceJSON | null): Salesforce | null =>
  Optional.ofNullable(salesforce)
    .map((sf) => ({
      customObjectId: sf.custom_object_id,
      opportunityId: sf.opportunity_id,
    }))
    .orElse(null);

const toActivities = (activities: POActivityJSON[]): POActivity[] =>
  activities.map((a) => ({
    activityType: a.activity_type,
    slug: a.slug,
    userId: a.user_id,
    createdAt: a.created_at,
    metadata: a.metadata,
  }));

const toBuyers = (buyers: BuyerJSON[]): Buyer[] =>
  buyers.map((b) => ({
    fullName: b.full_name,
    emailAddress: b.email_address,
    title: b.title,
  }));

const toBuyerDetails = (
  buyerDetails: BuyerDetailsJSON | null,
): BuyerDetails | null =>
  Optional.ofNullable(buyerDetails)
    .map((bd) => ({
      companyName: bd.company_name,
      billingAccountId: bd.billing_account_id,
      buyers: toBuyers(bd.buyers || []),
    }))
    .orElse(null);

const toEULA = (eula: EULAJSON | null): EULA | null =>
  Optional.ofNullable(eula)
    .map((e) => ({
      type: e.type,
      files: e.files || [],
    }))
    .orElse(null);

const toUsersToNotify = (usersToNotify: UserToNotifyJSON[]): UserToNotify[] =>
  usersToNotify.map((u) => ({
    contactType: u.contact_type,
    value: u.value,
    firstName: u.first_name,
    lastName: u.last_name,
    email: u.email,
  }));

const toContractDimension = (cd: ContractDurationJSON): ContractDuration => ({
  type: cd.type,
  frequency: cd.frequency,
});

const toScheduleDetails = (schedules: ScheduleDetailJSON[]): ScheduleDetail[] =>
  schedules.map((s) => ({
    chargeDate: s.charge_date,
    pricePerPaymentInUsd: s.price_per_payment_in_usd,
    note: s.note,
  }));

const toDimensionQuantities = (
  dimensionQuantities: DimensionQuantityJSON[],
): DimensionQuantity[] =>
  dimensionQuantities.map((dq) => ({
    dimensionTerm: dq.dimension_term,
    unlimitedQuantity: dq.unlimited_quantity,
    quantity: dq.quantity,
  }));

const toDimension = (dimension: DimensionJSON): Dimension => {
  const includedQuantities = toDimensionQuantities(
    dimension.included_quantities || [],
  );

  return {
    enabled: dimension.enabled,
    id: dimension.id,
    unitOfMeasure: dimension.unit_of_measure,
    pricePerUnitInUsd: dimension.price_per_unit_in_usd,
    includedQuantities,
  };
};

const toDimensions = (dimensions: {
  [k: string]: DimensionJSON;
}): {
  [k: string]: Dimension;
} =>
  Object.entries(dimensions).reduce(
    (acc: { [k: string]: Dimension }, entry: [string, DimensionJSON]) => {
      const key = entry[0];
      const dimension = entry[1];

      acc[key] = toDimension(dimension);

      return acc;
    },
    {},
  );

const pricingJSONToPricing = (pricing: PricingJSON): Pricing => {
  const contractDuration = Optional.ofNullable(pricing.contract_duration)
    .map(toContractDimension)
    .orElse(null);

  const paymentInstallments = Optional.ofNullable(pricing.payment_installments)
    .map((pi) => ({
      pricePerPaymentInUsd: pi.price_per_payment_in_usd,
    }))
    .orElse(null);

  const paymentSchedule = toScheduleDetails(pricing.payment_schedule || []);
  const dimensions = toDimensions(pricing.dimensions || {});

  return {
    planId: pricing.plan_id,
    planName: pricing.plan_name,
    description: pricing.description,
    contractDuration,
    paymentModel: pricing.payment_model,
    paymentInstallments,
    paymentSchedule,
    dimensions,
  };
};

const toPricing = (pricing: PricingJSON | null): Pricing | null =>
  Optional.ofNullable(pricing).map(pricingJSONToPricing).orElse(null);

const toOfferState = (offerState: OfferStateJSON): OfferState => {
  return Optional.ofNullable(offerState)
    .map((os) => {
      const draft = Optional.ofNullable(os.draft)
        .map((d) => ({ rawMessage: d.raw_message }))
        .orElse(null);

      const submitted = Optional.ofNullable(os.submitted)
        .map((s) => ({ rawMessage: s.raw_message }))
        .orElse(null);

      const accepted = Optional.ofNullable(os.accepted)
        .map((a) => ({ rawMessage: a.raw_message }))
        .orElse(null);

      const currentCloud = Optional.ofNullable(os.current_cloud)
        .map((cc) => ({ rawMessage: cc.raw_message }))
        .orElse(null);

      return {
        draft,
        submitted,
        accepted,
        currentCloud,
        lastModified: os.last_modified,
      };
    })
    .orElse(null);
};

export const msftPrivateOfferJSONToMsftPrivateOffer = (
  offer: MsftPrivateOfferJSON,
): MsftPrivateOffer => {
  const salesforce = toSalesforce(offer.salesforce);
  const activities = toActivities(offer.activities || []);
  const buyerDetails = toBuyerDetails(offer.buyer_details);
  const eula = toEULA(offer.eula);
  const pricing = toPricing(offer.pricing);
  const offerState = toOfferState(offer.offer_state);
  const usersToNotify = toUsersToNotify(offer.users_to_notify || []);

  const marketplaceAcceptanceLink =
    offer.marketplace_acceptance_link as MarketplaceAcceptanceLink;

  return {
    poId: offer.po_id,
    marketplace: Marketplace.Azure,
    acceptedAt: offer.accepted_at,
    acceptedSource: offer.accepted_source,
    archivedAt: offer.archived_at,
    createdAt: offer.created_at,
    cancelledAt: offer.cancelled_at,
    lastModifiedAt: offer.last_modified_at,
    lastUpdateSource: offer.last_update_source,
    viewedOfferAt: offer.viewed_offer_at,
    sentAt: offer.sent_at,
    submittedAt: offer.submitted_at,
    openedInstructionsAt: offer.opened_instructions_at,
    marketplaceAcceptanceLink,
    salesforce,
    vendorId: offer.vendor_id,
    vendorName: offer.vendor_name,
    productContactEmail: offer.product_contact_email,
    state: offer.state as State,
    status: offer.status as Status,
    offerType: offer.offer_type,
    quoteNumber: offer.quote_number,
    marketplaceFee: offer.marketplace_fee,
    activities,
    archived: offer.archived,
    version: offer.version,
    productRef: offer.product_ref,
    productName: offer.product_name,
    source: offer.source as Source,
    salesforceOpportunityId: offer.salesforce_opportunity_id,
    submittedToCloud: offer.submitted_to_cloud,
    marketplaceCreatedAt: offer.marketplace_created_at,
    name: offer.name,
    description: offer.description,
    buyerDetails,
    renewal: offer.renewal,
    eula,
    usersToNotify,
    pricing,
    preparerEmail: offer.preparer_email,
    startOn: offer.start_on as StartOn,
    startDate: offer.start_date,
    endDate: offer.end_date,
    acceptByDate: offer.accept_by_date,
    offerRef: offer.offer_ref,
    offerState,
    metadata: offer.metadata,
  };
};
