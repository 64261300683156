import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import OhNo from '../../../../../Error/Error';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from 'vendor/material';
import {
  Banner,
  Button,
  Link,
  Modal,
  ProviderIcon,
} from '@tackle-io/platform-ui';
import { ErrorBoundary, Page } from '../../../../../../components';
import { logTags } from '../logTags';
import { isEqual } from 'lodash';
import { DataId, OffersProductArea, PageLocation } from '../analytics';
import { ampli } from 'utils/analytics/ampli/index';
import { AlertCircle } from 'mdi-material-ui';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import OfferProgressBar from 'pages/PrivateOffers/pages/Next/generic/OfferProgressBar/OfferProgressBar';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import {
  getLatestOfferActivitySlug,
  getMarketplaceErrors,
} from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import MarketplaceErrorsBanner from 'pages/PrivateOffers/pages/Next/generic/MarketplaceErrorsBanner/MarketplaceErrorsBanner';
import OfferSubmissionErrorsBanner from 'pages/PrivateOffers/pages/Next/generic/OfferSubmissionErrorsBanner/OfferSubmissionErrorsBanner';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferContext from '../OfferContext/offerContext';
import OfferPageContext from '../OfferPageContext/offerPageContext';
import AmendmentContext from '../../aws/edit/AmendmentContext/AmendmentContext';
import BuyerAgreementsContext from 'pages/PrivateOffers/pages/Next/aws/edit/BuyerAgreementsContext/BuyerAgreementsContext';
import { useRbac } from 'utils/rbac';
import usePolling from 'pages/PrivateOffers/pages/Next/generic/hooks/usePolling';
import {
  MarketplaceOperationPendingPollingInterval,
  shouldStartPolling,
  stopPollingPredicate,
} from '../utils/polling/marketplaceOperationPollingUtils';
import { Offer } from '../types/Offer';
import { Marketplace, OfferType } from '../types/TackleOffer';

interface RequiredFormValues {
  cloud: string;
  offerType: string;
}

interface EditOfferPageProps<FV extends RequiredFormValues> {
  children: React.ReactElement;
  sidePanel: React.ReactElement;
  dataMappingErrors: { [k: string]: string };
  initialFormValues: FV;
  formValues: FV;
  onSaveDraft: () => Promise<void>;
  onSubmitToCloud: () => Promise<void>;
  createInMarketplace: boolean;
  createdInMarketplaceBanner?: React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
  titleGrid: { paddingLeft: theme.spacing(1) },
  errorListItem: {
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorListItemIcon: {
    marginTop: theme.spacing(0.5),
    minWidth: 0,
    paddingRight: theme.spacing(1),
  },
  amendmentTextContainer: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    '& a': {
      textDecoration: 'none',
    },
  },
  bold: {
    fontWeight: 700,
  },
  actionsFooter: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: '#FFFFFF',
  },
}));

const offerApiContainerStyle = { scrollMarginTop: 100 };

const offerTypeToTextResource = {
  [OfferType.Direct]: 'direct',
  [OfferType.PartnerResale]: 'partner',
};

const fireAmplitudeButtonClickEvent = (
  buttonId: DataId,
  cloud: string,
  offerType: string,
) => {
  ampli.buttonClicked(
    {
      button_product_area: OffersProductArea,
      button_location: PageLocation.OfferEditPage,
      button_name: buttonId,
    },
    { extra: { cloud, offerType } },
  );
};

const EditOfferPage = <FV extends RequiredFormValues>({
  children,
  sidePanel,
  dataMappingErrors,
  initialFormValues,
  formValues,
  onSaveDraft,
  onSubmitToCloud,
  createInMarketplace,
  createdInMarketplaceBanner,
}: EditOfferPageProps<FV>) => {
  const { hasPermission } = useRbac();
  const classes = useStyles();
  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);

  const [
    newOfferWithExistingAgreementsModalOpen,
    setNewOfferWithExistingAgreementsModalOpen,
  ] = useState(false);

  const { cloud, offerType } = initialFormValues;
  const { mode, onCancel, onOpenOffer } = useContext(OfferPageContext);

  const {
    tackleOfferForMode: offer,
    offerHasPendingMarketplaceOp,
    setOffer,
  } = useContext(OfferContext);

  const isAmendingOffer = mode === OfferPageMode.Amend;
  const { offerId, sourceOffer } = useContext(AmendmentContext);

  const { agreementsForBuyer, setAgreementsSidePanelOpen } = useContext(
    BuyerAgreementsContext,
  );

  const offerCreatedInMarketplace = !!offer?.createdInMarketplaceAt;
  const canClickSave =
    hasPermission('offers:UpdateOffer') ||
    (hasPermission('offers:CreateDraftOffer') && !offerCreatedInMarketplace);

  const offerTypeText = isAmendingOffer
    ? 'Create amended offer'
    : mode === OfferPageMode.Associate
    ? 'Associate offer'
    : offer
    ? offer.buyerCompanyName
    : `Create ${offerTypeToTextResource[offerType]} offer`;

  const {
    apiForMarketplace: { getOfferSilently },
    getOfferSubmissionError,
    isSubmitting,
  } = useContext(ApiContext);

  const formValuesHaveNotChanged = isEqual(initialFormValues, formValues);
  const offerSubmissionError = getOfferSubmissionError(offer?.poId);
  const offerApiErrorRef = useRef(null);
  const latestActivitySlug = getLatestOfferActivitySlug(offer);

  const updateEditOfferContextOffer = useCallback(
    (o: Offer) => {
      setOffer(o);
    },
    [setOffer],
  );

  const { isPolling } = usePolling<Offer>({
    startPollingPredicate: shouldStartPolling(latestActivitySlug),
    pollingFn: async () => await getOfferSilently(offer.poId),
    stopPollingPredicate,
    afterPollingComplete: updateEditOfferContextOffer,
    pollingInterval: MarketplaceOperationPendingPollingInterval,
  });

  useEffect(() => {
    if (!offerSubmissionError) {
      offerApiErrorRef.current = null;

      return;
    }

    if (offerApiErrorRef.current) {
      offerApiErrorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [offerSubmissionError, offerApiErrorRef]);

  const closeCancelConfirmationModal = () => {
    setCancelConfirmModalOpen(false);
  };

  const handleCancel = () => {
    if (!formValuesHaveNotChanged) {
      setCancelConfirmModalOpen(true);

      return;
    }

    fireAmplitudeButtonClickEvent(DataId.CancelEditButton, cloud, offerType);
    onCancel(offer);
  };

  const onSaveDraftClicked = async () => {
    fireAmplitudeButtonClickEvent(DataId.SaveDraftButton, cloud, offerType);

    await onSaveDraft();
  };

  const closeNewOfferWithExistingAgreementsModal = () => {
    setNewOfferWithExistingAgreementsModalOpen(false);
  };

  const onSubmitToCloudClicked = async () => {
    if (mode === OfferPageMode.New && agreementsForBuyer.length > 0) {
      setNewOfferWithExistingAgreementsModalOpen(true);

      return;
    }

    fireAmplitudeButtonClickEvent(
      DataId.SubmitToMarketplaceButton,
      cloud,
      offerType,
    );

    await onSubmitToCloud();
  };

  const hasDataMapperErrors = Object.keys(dataMappingErrors).length > 0;
  const marketplaceErrors = getMarketplaceErrors(offer?.activities);
  const submittingOffer = isSubmitting(OfferAPIKey.Offer);

  return (
    <ErrorBoundary renderError={OhNo} logTags={logTags}>
      <Page>
        <Box mt={3}>
          <OfferProgressBar offerType={offerType as OfferType} offer={offer} />
        </Box>
        <Box pt={2} pb={2} mt={2} mb={2}>
          <Grid
            container
            spacing={2}
            className={classes.titleGrid}
            alignItems="center"
          >
            <Grid item>
              <ProviderIcon provider={cloud as Marketplace} />
            </Grid>
            <Grid item>
              <Typography variant="h5">{offerTypeText}</Typography>
            </Grid>
          </Grid>
          {isAmendingOffer && (
            <Box display="span" className={classes.amendmentTextContainer}>
              <Typography variant="subtitle1" display="inline">
                You're amending an existing contract. This form is pre-filled
                with details from the source contract:{' '}
              </Typography>
              <span>
                {sourceOffer ? (
                  <Link
                    to=""
                    onClick={(e: React.MouseEvent<HTMLLinkElement>) => {
                      e.preventDefault();

                      onOpenOffer(sourceOffer);
                    }}
                  >
                    {offerId}
                  </Link>
                ) : (
                  offerId
                )}
              </span>
              <Typography variant="subtitle1" display="inline">
                . To amend a different contract,{' '}
              </Typography>
              <span>
                <Link
                  to=""
                  onClick={(e: React.MouseEvent<HTMLLinkElement>) => {
                    e.preventDefault();

                    setAgreementsSidePanelOpen(true);
                  }}
                >
                  return to the active contracts.
                </Link>
              </span>
            </Box>
          )}
          {offer && !isAmendingOffer && (
            <Box>
              <Typography variant="subtitle1">{offer.offerName}</Typography>
            </Box>
          )}
        </Box>
        {isPolling && (
          <Box mb={4}>
            <Banner
              type="info"
              borderPosition="top"
              title="Marketplace operation in progress"
              body={
                'The offer can not be edited while it is being updated. Please wait a few moments while we get a response from the cloud.'
              }
            />
          </Box>
        )}
        {hasDataMapperErrors && (
          <Box mb={4}>
            <Banner
              type="danger"
              borderPosition="top"
              title="Data mapper errors"
              body={
                <List disablePadding>
                  {Object.entries(dataMappingErrors).map(([key, value], i) => (
                    <ListItem
                      key={`data-mapper-error-${i}`}
                      className={classes.errorListItem}
                    >
                      <ListItemIcon className={classes.errorListItemIcon}>
                        <AlertCircle />
                      </ListItemIcon>
                      <ListItemText primary={`${key}: ${value}`} />
                    </ListItem>
                  ))}
                </List>
              }
              isCollapsible
              defaultOpen
            />
          </Box>
        )}
        {marketplaceErrors.length > 0 && !offer?.cancelledAt && (
          <Box mb={4}>
            <MarketplaceErrorsBanner marketplaceErrors={marketplaceErrors} />
          </Box>
        )}
        {offerSubmissionError && (
          <div ref={offerApiErrorRef} style={offerApiContainerStyle}>
            <Box mb={4}>
              <OfferSubmissionErrorsBanner
                poId={offer?.poId}
                offerSubmissionError={offerSubmissionError}
              />
            </Box>
          </div>
        )}
        {createdInMarketplaceBanner && (
          <Box mb={4}>{createdInMarketplaceBanner}</Box>
        )}
        <Grid container spacing={4}>
          <Grid item md={9}>
            {children}
          </Grid>
          <Grid item md={3}>
            <Box
              position="sticky"
              top={32}
              display={{ sm: 'none', md: 'block' }}
            >
              {sidePanel}
            </Box>
          </Grid>
        </Grid>
      </Page>
      <Box className={classes.actionsFooter}>
        <Box p={1}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                data-id={DataId.CancelEditButton}
                variant="outlined"
                appearance="primary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            {canClickSave && (
              <Grid item>
                <Button
                  data-id={DataId.SaveDraftButton}
                  variant={
                    offerCreatedInMarketplace ||
                    mode === OfferPageMode.Associate
                      ? undefined
                      : 'outlined'
                  }
                  appearance="primary"
                  onClick={onSaveDraftClicked}
                  loading={submittingOffer && !createInMarketplace}
                  disabled={offerHasPendingMarketplaceOp}
                >
                  {offerCreatedInMarketplace
                    ? 'Update offer'
                    : mode === OfferPageMode.Associate
                    ? 'Save offer'
                    : 'Save draft'}
                </Button>
              </Grid>
            )}
            {hasPermission('offers:CreateOfferOnMarketplace') &&
              !offerCreatedInMarketplace &&
              mode !== OfferPageMode.Associate && (
                <Grid item>
                  <Button
                    data-id={DataId.SubmitToMarketplaceButton}
                    appearance="primary"
                    onClick={onSubmitToCloudClicked}
                    loading={submittingOffer && createInMarketplace}
                    disabled={offerHasPendingMarketplaceOp}
                  >
                    Submit to cloud
                  </Button>
                </Grid>
              )}
          </Grid>
        </Box>
        <Modal
          open={cancelConfirmModalOpen}
          width="small"
          title="You have unsaved changes"
          onClose={closeCancelConfirmationModal}
          footerActions={
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  data-id={DataId.KeepEditingButton}
                  appearance="white"
                  onClick={closeCancelConfirmationModal}
                >
                  Keep editing
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-id={DataId.DiscardChangesButton}
                  appearance="primary"
                  onClick={() => {
                    onCancel(offer);
                  }}
                >
                  Discard changes
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Box mb={4}>
            <Typography>
              Discard changes and return to the private offers list?
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={newOfferWithExistingAgreementsModalOpen}
          width="medium"
          title="Are you sure you want to create a new offer?"
          onClose={closeNewOfferWithExistingAgreementsModal}
          footerActions={
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  data-id={DataId.KeepEditingButton}
                  appearance="white"
                  onClick={closeNewOfferWithExistingAgreementsModal}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-id={DataId.DiscardChangesButton}
                  appearance="primary"
                  onClick={async () => {
                    setNewOfferWithExistingAgreementsModalOpen(false);
                    await onSubmitToCloud();
                  }}
                >
                  Continue with new offer
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Box mb={4}>
            <Typography display="inline">
              Before you do, make sure to review any active contracts you may
              have with this buyer. Creating a new offer when an active contract
              exists{' '}
            </Typography>
            <Typography display="inline" className={classes.bold}>
              may cause the buyer to receive an error{' '}
            </Typography>{' '}
            <Typography display="inline">
              when accepting the new offer.
            </Typography>
          </Box>
        </Modal>
      </Box>
    </ErrorBoundary>
  );
};

export default EditOfferPage;
