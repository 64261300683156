import { ClipboardText, Delete } from 'mdi-material-ui';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Divider } from '@tackle-io/platform-ui';
import TextField from 'components/FieldsPricing/TextField/TextField';
import { Grid, IconButton } from 'vendor/material/index';
import { Field, FieldArray, useFormikContext } from 'formik';
import {
  FieldKey,
  OfferMetadataField,
} from 'pages/PrivateOffers/pages/Next/generic/AdditionalFieldsFormSection/formSchema';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { Optional } from 'utils/optional/optional';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

interface AdditionalFieldsFormSectionProps {
  sectionId: string;
  sectionTitle: string;
}

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.MarketplaceFee]: 'Marketplace fee',
};

const AdditionalFieldsFormSection: React.FunctionComponent<
  AdditionalFieldsFormSectionProps
> = ({ sectionId, sectionTitle }) => {
  const { values, setFieldValue } = useFormikContext<{
    productRef: string | null;
    offerMetadataFields: OfferMetadataField[];
  }>();

  const { productRef } = values;
  const { productsByProductId } = useContext(OfferPageContext);
  const { offerHasPendingMarketplaceOp } = useContext(OfferContext);

  useEffect(() => {
    (async () => {
      const matchingProduct = findMatchingProduct(
        productRef,
        productsByProductId,
      );

      await Optional.ofNullable(matchingProduct?.contractStandardFields)
        .map((contractStandardFields) => {
          const existingAdditionalFields = values.offerMetadataFields;
          const existingAdditionalFieldTitles = new Set<string>(
            existingAdditionalFields.map((f) => f.key),
          );
          const fieldTitlesFromProduct = new Set<string>(
            contractStandardFields.map((f) => f.title),
          );
          const existing = existingAdditionalFields.map((f) => ({
            [FieldKey.OfferMetadataFieldLabel]: f.key,
            [FieldKey.OfferMetadataFieldValue]: f.value,
            [FieldKey.IsDisabled]: fieldTitlesFromProduct.has(f.key),
          }));
          const newAdditionalFieldsFromProduct = contractStandardFields
            .filter((f) => !existingAdditionalFieldTitles.has(f.title))
            .map((f) => ({
              [FieldKey.OfferMetadataFieldLabel]: f.title,
              [FieldKey.OfferMetadataFieldValue]: '',
              [FieldKey.IsDisabled]: true,
            }));

          return [...newAdditionalFieldsFromProduct, ...existing];
        })
        .ifPresentAsync(async (v) => {
          await setFieldValue(FieldKey.OfferMetadataFields, v);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRef, productsByProductId]);

  return (
    <div id={sectionId}>
      <Card
        title={`${sectionTitle} (optional)`}
        subtitle="Enter custom fields and values for a complete bookable artifact. Marketplace fee is optional."
        icon={<ClipboardText />}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Field name={FieldKey.MarketplaceFee}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  mode="numberformat"
                  fixedDecimalScale
                  suffix="%"
                  numberPrefix=""
                  type="number"
                  data-id={DataId.MarketplaceFeeField}
                  label={fieldKeyToLabel[FieldKey.MarketplaceFee]}
                  error={getFormattedError(
                    FieldKey.MarketplaceFee,
                    fieldKeyToLabel,
                    meta,
                  )}
                  helperText="Optional: Used in order notifications and reporting for accurate gross and net total contract value amounts."
                  disabled={offerHasPendingMarketplaceOp}
                />
              )}
            </Field>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <FieldArray name={FieldKey.OfferMetadataFields}>
            {({
              form: {
                values: { offerMetadataFields },
              },
              push,
              remove,
            }) => (
              <>
                {offerMetadataFields.map(
                  (metadataField: OfferMetadataField, index: number) => {
                    const fieldIndex = `${FieldKey.OfferMetadataFields}[${index}]`;
                    const labelFieldName = `${fieldIndex}.${FieldKey.OfferMetadataFieldLabel}`;
                    const valueFieldName = `${fieldIndex}.${FieldKey.OfferMetadataFieldValue}`;

                    return (
                      <Grid
                        item
                        container
                        spacing={2}
                        alignItems="flex-start"
                        key={`additional-field-${index}`}
                      >
                        <Grid item xs>
                          <Field name={labelFieldName}>
                            {({ field, meta }) => (
                              <TextField
                                {...field}
                                disabled={
                                  metadataField.isDisabled ||
                                  offerHasPendingMarketplaceOp
                                }
                                data-id={DataId.OfferMetaDataLabelField}
                                label="Label"
                                error={getFormattedError(
                                  field.name,
                                  { [field.name]: 'Label' },
                                  meta,
                                )}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs>
                          <Field name={valueFieldName}>
                            {({ field, meta }) => (
                              <TextField
                                {...field}
                                data-id={DataId.OfferMetaDataValueField}
                                label="Value"
                                error={getFormattedError(
                                  field.name,
                                  { [field.name]: 'Value' },
                                  meta,
                                )}
                                disabled={offerHasPendingMarketplaceOp}
                              />
                            )}
                          </Field>
                        </Grid>
                        {!metadataField.isDisabled && (
                          <Grid item>
                            <IconButton
                              onClick={() => remove(index)}
                              disabled={offerHasPendingMarketplaceOp}
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    );
                  },
                )}
                <Grid item>
                  <Button
                    appearance="primary"
                    variant="text"
                    onClick={() => {
                      const newAdditionalField = {
                        [FieldKey.OfferMetadataFieldLabel]: '',
                        [FieldKey.OfferMetadataFieldValue]: '',
                        [FieldKey.IsDisabled]: false,
                      };
                      push(newAdditionalField);
                    }}
                    disabled={offerHasPendingMarketplaceOp}
                  >
                    Add field
                  </Button>
                </Grid>
              </>
            )}
          </FieldArray>
        </Grid>
      </Card>
    </div>
  );
};

export default AdditionalFieldsFormSection;
