import React, { useContext } from 'react';
import { Box, Grid } from 'vendor/material';
import { Field, useFormikContext } from 'formik';
import { FieldKey } from '../../formTypes';
import { Select } from '@tackle-io/platform-ui';
import TextField from '../../../../../../../../../../components/FieldsPricing/TextField/TextField';
import { getFormattedError } from '../../../../../../generic/utils/field/fieldUtils';
import { FormValues } from '../../../formSchema';
import { DataId } from '../../../../../../generic/analytics';
import { DurationType } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import DateSelector from 'pages/PrivateOffers/pages/Next/generic/DateSelector/DateSelector';
import {
  maxFutureDatedStartDate,
  minFutureDatedEndDate,
} from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/utils/dateUtils';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.BillingTermCadence]: 'Billing terms',
  [FieldKey.DurationInCadence]: 'Offer duration',
  [FieldKey.ServiceStartAt]: 'Max service start date',
};

const billingTermCadenceToDisplayText = {
  [DurationType.Months]: 'months',
};

const StartOnAcceptanceConfig: React.FunctionComponent = () => {
  const {
    values: { offerType, billingTermCadence },
  } = useFormikContext<FormValues>();
  const { offerIsMarketplaceEditable } = useContext(OfferContext);
  const gridItemMdSize = offerType === OfferType.PartnerResale ? 4 : 6;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={gridItemMdSize} xs={12}>
          <Field name={FieldKey.BillingTermCadence}>
            {({ field }) => (
              <Select
                {...field}
                data-id={DataId.BillingTermCadenceField}
                label={`${fieldKeyToLabel[FieldKey.BillingTermCadence]} *`}
                disabled={!offerIsMarketplaceEditable}
              >
                <option key={DurationType.Months} value={DurationType.Months}>
                  Months
                </option>
              </Select>
            )}
          </Field>
        </Grid>
        <Grid item md={gridItemMdSize} xs={12}>
          <Field name={FieldKey.DurationInCadence}>
            {({ field, meta }) => {
              const label = `${fieldKeyToLabel[FieldKey.DurationInCadence]}${
                !!billingTermCadence
                  ? ` in ${billingTermCadenceToDisplayText[billingTermCadence]}`
                  : ''
              } *`;

              return (
                <TextField
                  {...field}
                  data-id={DataId.OfferDurationField}
                  label={label}
                  defaultValue={field.value}
                  type="number"
                  mode="numberformat"
                  numberPrefix={''}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  disabled={!offerIsMarketplaceEditable}
                />
              );
            }}
          </Field>
        </Grid>
        {offerType === OfferType.PartnerResale && (
          <Grid item md={4} xs={12}>
            <Field name={FieldKey.ServiceStartAt}>
              {({ field, meta }) => (
                <DateSelector
                  {...field}
                  label={fieldKeyToLabel[FieldKey.ServiceStartAt]}
                  minDate={minFutureDatedEndDate().toISO()}
                  maxDate={maxFutureDatedStartDate().toISO()}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  disabled={!offerIsMarketplaceEditable}
                />
              )}
            </Field>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StartOnAcceptanceConfig;
