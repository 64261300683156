import * as yup from '../../../../../../../utils/yup-extended';
import {
  formSchema as basicInformationFormSchema,
  FormValues as BasicInformationFormValues,
} from './BasicInformationFormSection/formSchema';
import {
  formSchema as endUserLicenseAgreementFormSchema,
  FormValues as EndUserLicenseAgreementFormValues,
} from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/EndUserLicenseAgreementForSection/formSchema';
import {
  formSchema as resellerAgreementFormSchema,
  FormValues as ResellerAgreementFormValues,
} from './ResllerAgreementForSection/formSchema';
import {
  formSchema as notifyUsersFormSchema,
  FormValues as NotifyUsersFormValues,
} from '../../../generic/NotifyUsersSelector/formSchema';
import {
  formSchema as offerValidityFormSchema,
  FormValues as OfferValidityFormValues,
} from './OfferValidityPeriodFormSection/formSchema';
import {
  formSchema as productAndPricingFormSchema,
  FormValues as ProductAndPricingFormValues,
} from './ProductAndPricingFormSection/formSchema';
import {
  formSchema as registrationDetailsFormSchema,
  FormValues as RegistrationDetailsFormValues,
} from 'pages/PrivateOffers/pages/Next/generic/RegistrationDetailsFormSection/formSchema';
import {
  formSchema as additionalFieldsFormSchema,
  FormValues as AdditionalFieldsFormValues,
} from 'pages/PrivateOffers/pages/Next/generic/AdditionalFieldsFormSection/formSchema';
import {
  formSchema as linkOfferFormSchema,
  FormValues as LinkOfferFormValues,
} from './LinkOfferFormSection/formSchema';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';

interface NonUserInputFormValues {
  cloud: string;
  opportunityId?: string;
  offerType: string;
  sourceOfferId?: string;
  createInMarketplace?: boolean;
}

export type FormValues = NonUserInputFormValues &
  BasicInformationFormValues &
  ProductAndPricingFormValues &
  EndUserLicenseAgreementFormValues &
  ResellerAgreementFormValues &
  RegistrationDetailsFormValues &
  AdditionalFieldsFormValues &
  OfferValidityFormValues &
  NotifyUsersFormValues &
  LinkOfferFormValues;

const nonUserInputFormSchema = yup.object<NonUserInputFormValues>({
  cloud: yup.string().required(),
  offerType: yup.string().required(),
  createInMarketplace: yup.boolean().nullable(),
});

export const formSchema = (mode: OfferPageMode): yup.ObjectSchema<FormValues> =>
  nonUserInputFormSchema
    .concat(basicInformationFormSchema)
    .concat(productAndPricingFormSchema)
    .concat(endUserLicenseAgreementFormSchema)
    .concat(resellerAgreementFormSchema)
    .concat(registrationDetailsFormSchema)
    .concat(additionalFieldsFormSchema)
    .concat(offerValidityFormSchema)
    .concat(notifyUsersFormSchema)
    .concat(linkOfferFormSchema(mode));
