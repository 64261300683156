import { Box, Grid } from 'vendor/material';
import { Field } from 'formik';
import { FieldKey } from '../../formTypes';
import DateSelector from '../../../../../../generic/DateSelector/DateSelector';
import { startOfTodayAsUtcDatetime } from '../../../../../../generic/utils/date/dateUtils';
import {
  maxFutureDatedStartDate,
  minFutureDatedEndDate,
} from '../../../utils/dateUtils';
import { getFormattedError } from '../../../../../../generic/utils/field/fieldUtils';
import React, { useContext } from 'react';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.ServiceStartAt]: 'Start date',
  [FieldKey.ServiceEndAt]: 'End date',
};

const FutureDatedConfig: React.FunctionComponent = () => {
  const { offerIsMarketplaceEditable } = useContext(OfferContext);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Field name={FieldKey.ServiceStartAt}>
            {({ field, meta }) => (
              <DateSelector
                {...field}
                label={`${fieldKeyToLabel[FieldKey.ServiceStartAt]} *`}
                minDate={startOfTodayAsUtcDatetime().toISO()}
                maxDate={maxFutureDatedStartDate().toISO()}
                error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                disabled={!offerIsMarketplaceEditable}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={3}>
          <Field name={FieldKey.ServiceEndAt}>
            {({ field, meta }) => (
              <DateSelector
                {...field}
                label={`${fieldKeyToLabel[FieldKey.ServiceEndAt]} *`}
                minDate={minFutureDatedEndDate().toISO()}
                error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                disabled={!offerIsMarketplaceEditable}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FutureDatedConfig;
