import React, { useContext, useState } from 'react';
import { Box, Chip, Grid, makeStyles, Typography } from 'vendor/material';
import TextField from 'components/FieldsPricing/TextField/TextField';
import { At, Email, Magnify } from 'mdi-material-ui';
import { FieldKey, FormUserToNotify } from './formSchema';
import { FieldArray, useFormik } from 'formik';
import {
  AccountAvatar,
  Button,
  Divider,
  UserPicker,
} from '@tackle-io/platform-ui';
import { Optional } from 'utils/optional/optional';
import * as yup from 'utils/yup-extended';
import { getFormattedError } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';
import { Auth0User } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0User';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';
import OfferContext from '../OfferContext/offerContext';

type UserEmailToUserMap = { [email: string]: Auth0User };

type UserToNotifyWithIndex = {
  userToNotify: FormUserToNotify;
  index: number;
};

enum UserToNotifyType {
  Auth0 = 'auth0',
  Email = 'email',
}

type UsersToNotifyWithIndexGroupedByUserToNotifyType = {
  [utnt: string]: UserToNotifyWithIndex[];
};

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    margin: theme.spacing(2, 0),
    marginBottom: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  chipRoot: {
    backgroundColor: theme.palette.NEUTRAL030,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL700,
    '&:focus': {
      backgroundColor: theme.palette.NEUTRAL040,
    },
  },
  deleteIcon: {
    color: theme.palette.NEUTRAL500,
    '&:hover': {
      color: theme.palette.NEUTRAL300,
    },
  },
  userRows: {
    '&:nth-child(odd)': {
      background: theme.palette.NEUTRAL020,
    },
  },
  addEmailContainer: {
    backgroundColor: theme.palette.NEUTRAL020,
    paddingTop: theme.spacing(1),
    marginLeft: -theme.spacing(2.5),
    marginRight: -theme.spacing(2.5),
    marginBottom: -theme.spacing(4.5),
  },
  addEmailFieldsGrid: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  emailInputContainer: {
    '& label': {
      marginBottom: 0,
    },
  },
}));

const groupByContactType =
  (auth0UsersKeyedByEmail: UserEmailToUserMap) =>
  (
    a: UsersToNotifyWithIndexGroupedByUserToNotifyType,
    b: FormUserToNotify,
    i: number,
  ): UsersToNotifyWithIndexGroupedByUserToNotifyType => {
    const userToNotifyType = auth0UsersKeyedByEmail[b.email]
      ? UserToNotifyType.Auth0
      : UserToNotifyType.Email;

    return {
      ...a,
      [userToNotifyType]: [
        ...a[userToNotifyType],
        { userToNotify: b, index: i },
      ],
    };
  };

enum EmailFormFieldKey {
  Email = 'email',
}

interface FormValues {
  [EmailFormFieldKey.Email]: string;
}

const initialValues: FormValues = { [EmailFormFieldKey.Email]: '' };

const validationSchema = yup.object<FormValues>({
  [EmailFormFieldKey.Email]: yup.string().email(),
});

const NotifyUsersSelector: React.FunctionComponent = () => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const { users } = useContext(OfferPageContext);
  const { offerHasPendingMarketplaceOp } = useContext(OfferContext);
  const lowerCasedSearch = search.toLowerCase();

  const {
    values: emailFormValues,
    handleChange: handleEmailChange,
    handleBlur: handleEmailBlur,
    errors: emailFormErrors,
    getFieldMeta,
    setFieldValue,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const onSearchChanged = ({ target: { value: newSearch } }) => {
    setSearch(newSearch);
  };

  const chipClasses = {
    root: classes.chipRoot,
    deleteIcon: classes.deleteIcon,
  };

  const filteredUsers = users
    .filter(
      (u) =>
        u.email.toLowerCase().includes(lowerCasedSearch) ||
        u.name.toLowerCase().includes(lowerCasedSearch),
    )
    .map((u) => ({ ...u, id: u.userId, displayName: u.name }));

  return (
    <FieldArray name={FieldKey.UsersToNotify}>
      {({
        push,
        remove,
        form: {
          values: { usersToNotify: usersToNotifyAny },
        },
      }) => {
        const usersToNotify = usersToNotifyAny as FormUserToNotify[];

        const auth0UsersKeyedByEmail: UserEmailToUserMap = users.reduce(
          (a: UserEmailToUserMap, b: Auth0User) => ({
            ...a,
            [b.email]: b,
          }),
          {},
        );

        const usersToNotifyGroupedByContactType = usersToNotify.reduce(
          groupByContactType(auth0UsersKeyedByEmail),
          {
            [UserToNotifyType.Auth0]: [],
            [UserToNotifyType.Email]: [],
          },
        );

        const auth0UsersToNotify =
          usersToNotifyGroupedByContactType[UserToNotifyType.Auth0];

        const selectedAuth0UserIds = auth0UsersToNotify.map(
          ({ userToNotify: { email } }) => {
            const matchingUser = auth0UsersKeyedByEmail[email];
            return matchingUser?.userId;
          },
        );

        const onUserClicked = (userId: string) => {
          const alreadySelectedUser = usersToNotify.find(
            (u: FormUserToNotify) => {
              const auth0User = auth0UsersKeyedByEmail[u.email];
              return auth0User?.userId === userId;
            },
          );

          Optional.ofNullable(alreadySelectedUser).ifPresentOrElse(
            (user) => {
              const userIndex = usersToNotify.indexOf(user);
              remove(userIndex);
            },
            () => {
              const user = users.find((u) => u.userId === userId);
              push({ contactType: UserToNotifyType.Auth0, email: user.email });
            },
          );
        };

        const emailUsersToNotify =
          usersToNotifyGroupedByContactType[UserToNotifyType.Email];

        const onAddEmailClicked = () => {
          push({ email: emailFormValues.email.trim() });

          setFieldValue(EmailFormFieldKey.Email, '');
          setFieldTouched(EmailFormFieldKey.Email, false);
        };

        const onUserRemoved = (index: number) => () => {
          remove(index);
        };

        const emailFieldMeta = getFieldMeta(EmailFormFieldKey.Email);

        const disableAddEmailButton =
          !emailFormValues.email ||
          (Object.keys(emailFormErrors).length > 0 && emailFieldMeta.touched) ||
          offerHasPendingMarketplaceOp;

        return (
          <>
            {users.length > 0 && (
              <>
                <Box mb={2}>
                  <TextField
                    name="notifyUsersSearch"
                    iconLeft={<Magnify />}
                    placeholder="Filter users..."
                    onChange={onSearchChanged}
                    disabled={offerHasPendingMarketplaceOp}
                  />
                </Box>
                <Box mb={2}>
                  <Box mb={2}>
                    <Grid container spacing={1}>
                      {auth0UsersToNotify.map(({ userToNotify, index }) => {
                        const matchingUser =
                          auth0UsersKeyedByEmail[userToNotify.email];

                        return (
                          <Grid
                            item
                            key={`auth0-user-to-notify-${matchingUser?.email}`}
                          >
                            <Chip
                              label={matchingUser?.name}
                              avatar={
                                <AccountAvatar
                                  src={matchingUser?.picture}
                                  alt={matchingUser?.name}
                                />
                              }
                              classes={chipClasses}
                              clickable={false}
                              onDelete={onUserRemoved(index)}
                              disabled={offerHasPendingMarketplaceOp}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Divider />
                  <UserPicker
                    users={filteredUsers}
                    selectedUserIds={selectedAuth0UserIds}
                    style={{ maxHeight: 300 }}
                    disabled={offerHasPendingMarketplaceOp}
                    onClick={onUserClicked}
                    listItemClasses={classes.userRows}
                  />
                </Box>
              </>
            )}
            {users.length === 0 && (
              <Box mb={2}>
                <Typography>No users were found</Typography>
              </Box>
            )}
            <Divider />
            <Box mb={2} className={classes.addEmailContainer}>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justifyContent="center"
                className={classes.addEmailFieldsGrid}
              >
                <Grid item md={9} sm={10} xs={12}>
                  <TextField
                    name="email"
                    iconLeft={<Email />}
                    placeholder="Not listed above? Enter an email address..."
                    value={emailFormValues.email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    error={getFormattedError(
                      EmailFormFieldKey.Email,
                      { email: 'Email' },
                      emailFieldMeta,
                    )}
                    disabled={offerHasPendingMarketplaceOp}
                  />
                </Grid>
                <Grid item md={3} sm={2} xs={12} style={{ marginTop: 4 }}>
                  <Button
                    variant="outlined"
                    disabled={disableAddEmailButton}
                    onClick={onAddEmailClicked}
                    fullWidth
                  >
                    Add email
                  </Button>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    {emailUsersToNotify.map(({ userToNotify, index }) => (
                      <Grid
                        item
                        key={`email-user-to-notify-${userToNotify.email}`}
                      >
                        <Chip
                          label={userToNotify.email}
                          avatar={<At />}
                          classes={chipClasses}
                          clickable={false}
                          onDelete={onUserRemoved(index)}
                          disabled={offerHasPendingMarketplaceOp}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      }}
    </FieldArray>
  );
};

export default NotifyUsersSelector;
