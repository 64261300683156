import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, useTheme } from 'vendor/material';
import { getSourceTooltip, OpportunitiesTableColumnNames } from '../../utils';
import useStyles from './AceOpportunityRow.styles';
import { TableRowCell } from '../TableRowCell';
import classNames from 'classnames';
import type { ColumnWidths, AceOpportunitySummary } from '../../types';
import { AwsCoSellStatusTag } from 'packages/cosell/src/components/AwsCoSellStatusTag';
import { AwsCoSellStageTag } from 'packages/cosell/src/components/AwsCoSellStageTag';
import { OfficeBuilding } from 'mdi-material-ui';
import ToolTipCopy from 'packages/cosell/src/components/ToolTipCopy/ToolTipCopy';
import { formatDate } from 'packages/cosell/src/utilities/formatDate';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants';
import { OptimizedTooltip } from 'packages/cosell/src/components/OptimizedTooltip/OptimizedTooltip';
import { DisplayCloudType, SourceEnum } from 'packages/cosell/src/types/enums';
import { ProviderIcon } from '@tackle-io/platform-ui';

interface AceOpportunityRowProps {
  opportunity: AceOpportunitySummary;
  columnWidths: ColumnWidths;
  dataId?: string;
  style?: React.CSSProperties;
}

export const AceOpportunityRow: React.FC<AceOpportunityRowProps> = ({
  opportunity,
  columnWidths,
  dataId,
  style,
}) => {
  const opportunityId = opportunity.id;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const sourceIcon =
    opportunity.source === SourceEnum.INBOUND ? (
      <span>
        <ProviderIcon provider={DisplayCloudType.AWS} fontSize="medium" />
      </span>
    ) : (
      <OfficeBuilding />
    );

  const handleRowClick = () => {
    opportunity.cloudProviderId
      ? history.push(`${COSELL_PATH}/opportunity/aws/${opportunity.id}`)
      : history.push(`${COSELL_PATH}/opportunity/aws/${opportunity.id}/edit`);
  };

  if (columnWidths === undefined) return null;
  return (
    <div
      className={classNames(classes.tableRow)}
      role="row"
      aria-label={`Opportunity ID ${opportunityId}`}
      data-id={dataId}
      onClick={handleRowClick}
      style={style}
    >
      <TableRowCell
        key={`${opportunityId}--disbursement_date`}
        columnName={OpportunitiesTableColumnNames.PROJECT_NAME}
        columnWidths={columnWidths}
      >
        <>
          <Grid item>
            <div style={{ display: 'flex', gap: theme.spacing(1) }}>
              <OptimizedTooltip
                position="bottom"
                content={getSourceTooltip(opportunity.source)}
              >
                {sourceIcon}
              </OptimizedTooltip>
              <Typography variant="body1">
                <ToolTipCopy
                  content={opportunity.title}
                  name="Project name"
                  hideNameInTooltip={true}
                  hideName={true}
                  maxWidth={310}
                />
              </Typography>
            </div>
          </Grid>
          <Grid className={classNames(classes['mt-0.5'], classes.flex)}>
            <span
              className={classNames(classes.flex, classes.smallLabel)}
              style={{ marginRight: '8px' }}
            >
              {opportunity.cloudProviderId ? (
                <ToolTipCopy
                  content={opportunity.cloudProviderId}
                  name="Cloud ID"
                  hideNameInTooltip={true}
                />
              ) : (
                `Cloud ID - `
              )}
            </span>
            {opportunity.crmId ? (
              <span className={classNames(classes.flex, classes.smallLabel)}>
                <ToolTipCopy
                  content={opportunity.crmId}
                  name="CRM ID"
                  hideNameInTooltip={true}
                />
              </span>
            ) : null}
          </Grid>
        </>
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--customer`}
        columnName={OpportunitiesTableColumnNames.CUSTOMER}
        columnWidths={columnWidths}
      >
        <>
          <ToolTipCopy
            content={opportunity.companyName}
            name="Customer name"
            hideNameInTooltip={true}
            hideName={true}
          />
        </>
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--cosell_status`}
        columnName={OpportunitiesTableColumnNames.COSELL_STATUS}
        columnWidths={columnWidths}
      >
        {opportunity.cloudStatus && (
          <AwsCoSellStatusTag status={opportunity.cloudStatus} />
        )}
      </TableRowCell>

      <TableRowCell
        key={`${opportunityId}--cosell_stage`}
        columnName={OpportunitiesTableColumnNames.COSELL_STAGE}
        columnWidths={columnWidths}
      >
        {opportunity.cloudSalesStage && (
          <AwsCoSellStageTag stage={opportunity.cloudSalesStage} />
        )}
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--date_created`}
        columnName={OpportunitiesTableColumnNames.DATE_CREATED}
        columnWidths={columnWidths}
      >
        <>
          <Grid item>
            <OptimizedTooltip
              position="bottom"
              content={
                opportunity.cloudCreated
                  ? `Submitted to AWS on ${formatDate(
                      opportunity.cloudCreated,
                    )}`
                  : `Draft started on ${formatDate(opportunity.created)}`
              }
            >
              <Typography variant="body1">
                {formatDate(opportunity.created)}
              </Typography>
            </OptimizedTooltip>
          </Grid>
          <Grid className={classes['mt-0.25']}>
            <OptimizedTooltip
              position="bottom"
              content={
                opportunity.cloudLastModified
                  ? `Updated in AWS on ${formatDate(
                      opportunity.cloudLastModified,
                    )}`
                  : `Updated in Tackle on ${formatDate(
                      opportunity.lastModified,
                    )}`
              }
            >
              <span className={classes.smallLabel}>
                Updated {formatDate(opportunity.lastModified)}
              </span>
            </OptimizedTooltip>
          </Grid>
        </>
      </TableRowCell>
      {/* TODO: Enable Sync status when API team supports accurately*/}
      {/* <TableRowCell
        key={`${opportunityId}--sync_status`}
        columnName={OpportunitiesTableColumnNames.SYNC_STATUS}
        columnWidths={columnWidths}
      >
        <SyncStatus
          syncStatus={getSyncStatus({
            pendingRequestIds: opportunity.pendingRequestIds,
            hasError: opportunity.hasCloudErrors,
          })}
        />
      </TableRowCell> */}
    </div>
  );
};

export default AceOpportunityRow;
