import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  activeFilterIndicator: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.75),
    fill: theme.palette.RED300,
    fontSize: theme.spacing(1.5),
    cursor: 'pointer',
  },
  filterButton: {
    position: 'relative',
    backgroundColor: theme.palette.NEUTRAL000,
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
