const DraftOfferSvg = () => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.38477 20.8388C1.83477 20.8388 1.36393 20.643 0.972266 20.2513C0.580599 19.8597 0.384766 19.3888 0.384766 18.8388V2.83884C0.384766 2.28884 0.580599 1.818 0.972266 1.42634C1.36393 1.03467 1.83477 0.838837 2.38477 0.838837H10.3848L16.3848 6.83884V18.8388C16.3848 19.3888 16.1889 19.8597 15.7973 20.2513C15.4056 20.643 14.9348 20.8388 14.3848 20.8388H2.38477ZM9.38477 7.83884V2.83884H2.38477V18.8388H14.3848V7.83884H9.38477Z"
      fill="#253858"
    />
  </svg>
);

export default DraftOfferSvg;
