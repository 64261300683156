import { Loader } from '@tackle-io/platform-ui';
import { useCanvasSession } from './useCanvasSession';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import CanvasTackleIntegrationStatus from './components/CanvasTackleIntegrationStatus';
import TackleSidebarWidgetForSalesforce from './components/TackleSidebarWidgetForSalesforce';
import ErrorBoundary from 'components/ErrorBoundary';
import CoSellCanvasApp from 'packages/salesforce-canvas/cosell/src/components/CoSellCanvasApp';
import OffersCanvasApp from 'packages/salesforce-canvas/offers/OffersCanvasApp';
import Error from 'pages/Error/Error';
import { IntercomProvider } from 'react-use-intercom';
import { Intercom } from './IntercomCanvas/IntercomCanvas';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import NotConnectedPage from './components/NotConnectedPage';

const AuthorizedCanvasApp = () => {
  const { context, payload } = useCanvasSession();
  const componentId = context?.parameters?.componentId;
  const domain = payload?.domain;

  if (componentId === 'Setup') {
    return <CanvasTackleIntegrationStatus />;
  }

  if (componentId === 'TackleWidget') {
    return <TackleSidebarWidgetForSalesforce />;
  }

  if (componentId === 'CanvasApp' && domain === 'cosell') {
    return <CoSellCanvasApp />;
  }

  if (componentId === 'CanvasApp' && domain === 'offers') {
    return <OffersCanvasApp />;
  }

  return (
    <div>
      No component found for {componentId} with domain of {domain}.
    </div>
  );
};

const CanvasApp = () => {
  const {
    context,
    isAuthorized,
    isLoadingCanvasSession,
    vendorId,
    vendorType,
    initialComponentId,
  } = useCanvasSession();
  const classes = useCanvasAppStyles();
  const componentId = context?.parameters?.componentId;
  const isConnected = context?.parameters?.isConnected;

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  if (!isConnected && initialComponentId !== 'Setup') {
    return <NotConnectedPage />;
  }

  if (!isAuthorized) {
    return <CanvasTackleIntegrationStatus />;
  }

  if (!componentId) {
    return <div>No context provided.</div>;
  }

  const { user } = context;

  return (
    <ErrorBoundary renderError={Error}>
      <AwsOpportunityActionsProvider>
        <div className={classes.canvasAppContainer}>
          <AuthorizedCanvasApp />
        </div>
      </AwsOpportunityActionsProvider>
      <Intercom
        currentUser={user}
        vendorId={vendorId}
        vendorType={vendorType}
      />
    </ErrorBoundary>
  );
};

const CanvasAppWithSessionProvider = () => {
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
  const ENABLE_INTERCOM = !!INTERCOM_APP_ID;

  return (
    <ReactQueryClientProvider>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        shouldInitialize={ENABLE_INTERCOM}
        autoBoot={ENABLE_INTERCOM}
      >
        <CanvasApp />
      </IntercomProvider>
    </ReactQueryClientProvider>
  );
};

export default CanvasAppWithSessionProvider;
