import { useMutation } from '@tanstack/react-query';
import { crmConnectorSalesforceApi } from '../../../api/tackleApiClient';
import { toast } from 'react-toastify';
import { useCanvasSession } from '../../useCanvasSession';
import { Box, Typography } from 'vendor/material';

export function useDisconnectSalesforce() {
  const { context } = useCanvasSession();

  const mutation = useMutation({
    mutationFn: async ({ instanceUrl }: { instanceUrl: string }) => {
      if (!context) {
        throw new Error('No active Salesforce session found.');
      }

      const response = await crmConnectorSalesforceApi.delete('register');

      if (!response.ok || response.status !== 200) {
        toast.error('Failed to disconnect. Please try again.');
        throw new Error('Failed to disconnect. Please try again.');
      }

      return { instanceUrl };
    },
    onSuccess: () => {
      toast.success(
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{ marginRight: '8px' }}
            >
              <g clipPath="url(#clip0_918_7147)">
                <circle cx="12" cy="12" r="12" fill="white" />
                <path
                  d="M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                  fill="#00875A"
                />
              </g>
              <defs>
                <clipPath id="clip0_918_7147">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Typography
              variant="body1"
              style={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#FFFFFF',
                marginRight: '8px',
              }}
            >
              Successfully disconnected from Salesforce!
            </Typography>
          </Box>
          <Box onClick={() => toast.dismiss()} style={{ cursor: 'pointer' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_918_7151)">
                <path d="M0 0H24V24H0V0Z" fill="none" />
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#FFFFFF"
                />
              </g>
              <defs>
                <clipPath id="clip0_918_7151">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
        </Box>,
        {
          style: {
            backgroundColor: '#00875A',
            color: '#FFFFFF',
          },
          autoClose: false,
          closeButton: false,
        },
      );
    },
    onError: (error: Error) => {
      toast.error(
        error.message ||
          'An unexpected error occurred. Please try again later.',
      );
    },
  });

  const disconnectSalesforce = async (
    closeModal: () => void,
    instanceUrl: string,
  ) => {
    try {
      await mutation.mutateAsync({ instanceUrl });
      closeModal();
    } catch (error) {
      console.error('Failed to disconnect:', error);
    }
  };

  return { disconnectSalesforce };
}
