import { History } from 'history';
import { Offer } from '../../types/Offer';

export const toPrivateOfferAfterSubmit =
  (history: History) => (offer: Offer) => {
    history.push({
      pathname: `/private-offers/${offer.marketplace}/${offer.poId}`,
    });
  };

export const toPrivateOfferAfterArchive = (history: History) => () => {
  history.push({
    pathname: `/private-offers`,
    search: '?update=true',
  });
};

export const toPrivateOfferOnCancel =
  (history: History) => (offer: Offer | null) => {
    history.push({
      pathname: offer
        ? `/private-offers/${offer.marketplace}/${offer.poId}`
        : '/private-offers',
    });
  };

export const toPrivateOfferOnOpenOffer = (offer: Offer) => {
  window.open(`/private-offers/${offer.marketplace}/${offer.poId}`);
};
