import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './CoSellInvitationsTable.styles';
import type { ColumnWidths, HeaderColumn, AceInvitation } from './types';
import {
  InvitationsTableColumnNames,
  defaultInvitationColumnsObj,
} from './utils';
import { GeneralEmptyStateIcon } from 'packages/cosell/assets/GeneralEmptyStateIcon';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import useInvitationsListQuery from 'packages/cosell/src/hooks/useInvitationsListQuery';
import { AceInvitationRow, TableHeader } from './components';
import { PAGE_LIMIT } from '../../helpers/urlParamsHelper';
import {
  CoSellPagination,
  TableError,
  TableLoadingRows,
} from '../../components';

export const renderAceInvitationRows = ({
  invitations,
  columnWidths,
}: {
  invitations: AceInvitation[];
  columnWidths: ColumnWidths;
}) =>
  invitations.map((invitation, rowNumber) => {
    const invitationKey = invitation.id;
    return (
      <AceInvitationRow
        key={`${invitationKey}_${rowNumber}__invitation_id`}
        invitation={invitation}
        columnWidths={columnWidths}
        dataId={
          rowNumber === 0
            ? coSellLandingPageDataId.INVITATIONS_TABLE_HEADER_ROW
            : undefined
        }
      />
    );
  });

export const CoSellInvitationsTable = () => {
  const classes = useStyles();
  // TODO: Update column widths so that there's a horizontal scrollbar on smaller than 1024px screen
  const [columnWidths, setColumnWidths] = useState({
    ...defaultInvitationColumnsObj,
  });
  const [incomingPage, setIncomingPage] = useState<number | null>(null);

  useEffect(() => {
    if (typeof incomingPage === 'number' && tableHeaderContainerEl.current) {
      tableHeaderContainerEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [incomingPage]);

  const {
    invitationsList,
    invitationsListError,
    isInvitationsListLoading,
    total,
    currentPageCount,
    isNextPage,
    nextIndex,
  } = useInvitationsListQuery();

  const invitations = invitationsList?.invitations || [];

  const tableHeaderContainerEl = useRef<HTMLDivElement>(null);

  //* TODO: Enable Sync status when API team supports accurately*/
  const AceInvitationHeaders: HeaderColumn[] = [
    {
      field: InvitationsTableColumnNames.PROJECT_NAME,
      headerName: 'Project name',
      dataId: coSellLandingPageDataId.INVITATIONS_PROJECT_NAME_HEADER,
    },
    {
      field: InvitationsTableColumnNames.CUSTOMER,
      headerName: 'Customer',
      dataId: coSellLandingPageDataId.INVITATIONS_CUSTOMER_HEADER,
    },
    {
      field: InvitationsTableColumnNames.INVITATION_STATUS,
      headerName: 'Invitation status',
      dataId: coSellLandingPageDataId.INVITATIONS_STATUS_HEADER,
    },
    {
      field: InvitationsTableColumnNames.DATE_RECEIVED,
      headerName: 'Date received',
      dataId: coSellLandingPageDataId.INVITATIONS_DATE_RECEIVED_HEADER,
    },
    {
      field: InvitationsTableColumnNames.EXPIRATION_DATE,
      headerName: 'Expiration date',
      dataId: coSellLandingPageDataId.INVITATIONS_EXPIRATION_DATE_HEADER,
    },
    // {
    //   field: InvitationsTableColumnNames.SYNC_STATUS,
    //   headerName: 'Sync status',
    //   dataId: coSellLandingPageDataId.INVITATIONS_SYNC_STATUS_HEADER,
    // },
  ];

  const emptyResults = invitations.length < 1;

  if (invitationsListError) return <TableError />;

  const isEmptyTable = !isInvitationsListLoading && emptyResults;

  const isLoading = isInvitationsListLoading;

  const emptyTableContent = (
    <Box className={classes.emptyTable}>
      <GeneralEmptyStateIcon className={classes.emptyIcon} />
      <Typography className={classes.emptyHeader}>No results found</Typography>
      <Typography component={'p'} className={classes.emptyDescription}>
        Try changing your search criteria to find what you're looking for.
      </Typography>
    </Box>
  );

  return (
    <>
      <div className={classes.invitationsTableContainer} role="grid">
        <div ref={tableHeaderContainerEl} />
        <TableHeader
          tableColumns={AceInvitationHeaders}
          setColumnWidths={setColumnWidths}
        />
        {isLoading ? (
          <TableLoadingRows pageLimit={PAGE_LIMIT} />
        ) : (
          <>
            {isEmptyTable
              ? emptyTableContent
              : invitations.map((invitation, rowNumber) => {
                  const invitationKey = invitation.id;
                  return (
                    <AceInvitationRow
                      key={`${invitationKey}_${rowNumber}__invitation_id`}
                      invitation={invitation}
                      columnWidths={columnWidths}
                      dataId={
                        rowNumber === 0
                          ? coSellLandingPageDataId.INVITATIONS_TABLE_HEADER_ROW
                          : undefined
                      }
                    />
                  );
                })}
          </>
        )}
      </div>
      {!isEmptyTable && (
        <CoSellPagination
          setIncomingPage={setIncomingPage}
          total={total}
          currentPageCount={currentPageCount}
          isNextPage={isNextPage}
          nextIndex={nextIndex}
        />
      )}
    </>
  );
};
