import { FormikErrors } from 'formik';
import { EventError } from 'packages/cosell/src/hooks/useOpportunityEventsQuery/extractLatestAceOpportunityEventErrors';
import { UpdateAceOpportunityFormValues } from '../AceOpportunityForm/AceOpportunityFormValues';
import { getFormFieldByAwsErrorFieldName } from './getFormFieldByAwsErrorFieldName';

export const mapErrors = (
  initialErrors: EventError[],
): FormikErrors<UpdateAceOpportunityFormValues> => {
  if (!initialErrors) return {};
  const mappedErrors = initialErrors.reduce((acc, item) => {
    if (!item.error.fieldName) {
      return acc;
    }
    const fieldName = getFormFieldByAwsErrorFieldName({
      name: item.error.fieldName,
      key: 'name',
    });
    const userFriendlyErrorMessage = item.error.errorMessage
      ? mapErrorMessage(item.error.fieldName, item.error.errorMessage)
      : 'Unknown error, no error message provided';
    acc[fieldName] = userFriendlyErrorMessage;
    return acc;
  }, {} as Record<string, string>);
  return mappedErrors;
};

export const getInitialTouched = (
  mappedErrors: FormikErrors<UpdateAceOpportunityFormValues>,
) => {
  return Object.keys(mappedErrors).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as Record<string, boolean>);
};

export const mapErrorMessage = (
  apiFieldName: string,
  apiErrorMessage: string,
) => {
  const formFieldLabel = getFormFieldByAwsErrorFieldName({
    name: apiFieldName,
    key: 'label',
  });
  /** required field case */
  if (apiErrorMessage.includes('required')) {
    return `Required field missing. Enter ${chooseArticle(
      formFieldLabel,
    )} ${formFieldLabel}.`;
  }
  /** invalid error case */
  if (apiErrorMessage.includes('failed to satisfy constraint')) {
    return apiErrorMessage.includes('must satisfy enum value set')
      ? 'Invalid value. Select an option from the list' // for dropdown fields
      : `Invalid value. Enter a valid ${formFieldLabel}`;
  }

  /** all other error cases */
  return apiErrorMessage
    .replace(`${apiFieldName}`, formFieldLabel)
    .replace(/['"]/g, ''); // some error messages have quotes around the field name
};

export const chooseArticle = (fieldName: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  return vowels.includes(fieldName[0].toLowerCase()) ? 'an' : 'a';
};
