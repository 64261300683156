import { createContext } from 'react';
import { User } from 'pages/PrivateOffers/pages/Next/generic/api/types/User';
import { Auth0User } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0User';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferRunFrom } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerRunFrom';
import { Offer } from 'pages/PrivateOffers/pages/Next/generic/types/Offer';
import { Marketplace } from '../types/TackleOffer';

export interface OfferPageContextValues<O extends Offer> {
  marketplace: Marketplace;
  user: User | null;
  users: Auth0User[];
  productsByProductId: { [pId: string]: Product };
  mode: OfferPageMode;
  runFrom: OfferRunFrom;
  afterSubmit?: (offer: O) => void;
  onEdit?: (offer: O) => void;
  onAmend?: (offerId: string, offer: O | null) => void;
  onCancel?: (offer: O | null) => void;
  onOpenOffer?: (offer: O) => void;
  afterArchive?: (offer: O) => void;
}

const OfferPageContext = createContext<OfferPageContextValues<Offer>>({
  marketplace: Marketplace.Aws,
  user: null,
  users: [],
  productsByProductId: {},
  mode: OfferPageMode.New,
  runFrom: OfferRunFrom.Platform,
});

export default OfferPageContext;
