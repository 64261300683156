import EditForm from './EditForm/EditForm';
import { initialFormValuesForExistingOffer } from './EditForm/initialFormValues';
import React, { useContext } from 'react';
import { FormValues } from './EditForm/formSchema';
import { formValuesToPrivateOffer } from './EditForm/formValuesToPrivateOffer';
import LoadAwsPrivateOfferPage, {
  AwsPrivateOfferCanvasPage,
  AwsPrivateOfferCanvasPageProps,
} from 'pages/PrivateOffers/pages/Next/aws/shared/AwsPrivateOfferPage';
import { mergeExistingOfferWithUpdates } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/mergeExistingOfferWithUpdates';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferContext, {
  OfferContextValues,
} from '../../generic/OfferContext/offerContext';
import OfferPageContext from '../../generic/OfferPageContext/offerPageContext';
import AmendmentContextProvider from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContextProvider';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';

const EditAwsPrivateOfferPage: React.FunctionComponent = () => {
  const {
    awsApi: { updateOffer },
  } = useContext(ApiContext);

  const { afterSubmit } = useContext(OfferPageContext);

  const { offer, offerIsAmendment, product } = useContext(
    OfferContext,
  ) as OfferContextValues<AwsPrivateOffer>;

  const initialValues = initialFormValuesForExistingOffer(offer, product);

  const onSubmit = async (formValues: FormValues) => {
    const offerUpdates = formValuesToPrivateOffer(formValues, offerIsAmendment);

    const offerWithUpdatesMerged = mergeExistingOfferWithUpdates(
      offer,
      offerUpdates,
    );

    const updatedOffer = await updateOffer(
      offer.poId,
      offerWithUpdatesMerged,
      formValues.createInMarketplace,
    );

    if (updatedOffer) {
      afterSubmit(updatedOffer);
    }
  };

  return <EditForm initialValues={initialValues} onSubmit={onSubmit} />;
};

interface AmendmentProviderWrappedPageProps {
  children: React.ReactElement;
}

const AmendmentProviderWrappedPage: React.FunctionComponent<
  AmendmentProviderWrappedPageProps
> = ({ children }) => {
  const { offer } = useContext(
    OfferContext,
  ) as OfferContextValues<AwsPrivateOffer>;

  return (
    <AmendmentContextProvider offerId={offer?.sourceOfferId}>
      {children}
    </AmendmentContextProvider>
  );
};

export const EditAwsPrivateOfferCanvasPage: React.FunctionComponent<
  Omit<AwsPrivateOfferCanvasPageProps, 'mode' | 'children'>
> = (props) => (
  <AwsPrivateOfferCanvasPage {...props} mode={OfferPageMode.Edit}>
    <AmendmentProviderWrappedPage>
      <EditAwsPrivateOfferPage />
    </AmendmentProviderWrappedPage>
  </AwsPrivateOfferCanvasPage>
);

const EditAwsPrivateOfferPlatformPage: React.FunctionComponent = () => (
  <LoadAwsPrivateOfferPage mode={OfferPageMode.Edit}>
    <AmendmentProviderWrappedPage>
      <EditAwsPrivateOfferPage />
    </AmendmentProviderWrappedPage>
  </LoadAwsPrivateOfferPage>
);

export default EditAwsPrivateOfferPlatformPage;
